import React, { useState } from "react";
import AdminPortal from "./AdminPortal";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { format, addYears } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";
import { LOCAL_STORAGE_KEYS } from "../../utils/constant";

// Mutations
import CREATE_AFFILIATE from "../../gql/mutation/base/createCouponCodeByAdmin.mutations";

function AddAffiliate() {
  const navigate = useNavigate();

  const [couponCode, setCouponCode] = useState("");
  const [email, setEmail] = useState("");

  // Mutations
  const [createAffiliateMutation, { error: createEventError }] =
    useMutation(CREATE_AFFILIATE);

  // Submit to create Event
  async function createCouponCodeByAdmin() {
    if (email == "" || email == null) {
      toast.error("Please enter email of Affiliate!");
      return;
    } else if (couponCode == "" || couponCode == null) {
      toast.error("Please enter a coupon code!");
      return;
    }

    const { data } = await createAffiliateMutation({
      variables: {
        adminUserId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        email,
        couponCode,
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      },
    });

    if (data.createCouponCodeByAdmin.errorMessage == null) {
      toast.success("Successfully created affiliate");
      navigate("/admin/affiliates");
    } else {
      toast.error(data.createCouponCodeByAdmin.errorMessage);
    }
  }

  return (
    <AdminPortal>
      <div className="md:w-11/12 mx-auto bg-slate-700 flex justify-between items-center px-4 py-3 mt-5 mb-3 ">
        <h1 className="font-display font-bold text-lg">Create Affiliate</h1>{" "}
        <Link
          to={"/admin/affiliates"}
          className="bg-slate-800 py-1 px-2 rounded"
        >
          Back
        </Link>
      </div>

      <div className="max-w-lg mx-auto bg-slate-700 mt-5 py-3 px-4">
        <h3 className="text-center font-bold text-[26px] mb-4">
          Create Affiliate
        </h3>

        {/* Email */}
        {/* Email */}
        <div className="my-3">
          <label className="mr-16 pr-3 font-bold">
            Email <b style={{ color: "red" }}>*</b>
          </label>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter affiliate's email"
          />
        </div>

        {/* Coupon Code */}
        {/* Coupon Code */}
        <div className="my-3">
          <label className="mr-1 pr-3 font-bold">
            Coupon Code <b style={{ color: "red" }}>*</b>
          </label>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => setCouponCode(e.target.value)}
            placeholder="Enter affiliate's coupon code"
          />
        </div>

        {/* SUBMIT button */}
        <button
          className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
          onClick={() => {
            createCouponCodeByAdmin();
          }}
        >
          Submit
        </button>
      </div>
    </AdminPortal>
  );
}
export default AddAffiliate;
