import React, { useState, useContext, useEffect, useRef } from "react";
import { collection_data } from "../../data/collection_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlay,
  faClose,
  faCopy,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { Tab, TabList } from "react-tabs";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { format, formatDistance } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Calendar from "react-calendar";
import Modal from "react-modal";
import differenceInYears from "date-fns/differenceInYears";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {
  Player,
  BigPlayButton,
  StaticPlayerInstanceMethods,
} from "video-react";
import AdminPortal from "./AdminPortal";
// Share
import {
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  FacebookShareButton,
  TwitterShareButton,
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
} from "react-share";
import copy from "copy-to-clipboard";

// CSS
// import "react-calendar/dist/Calendar.css";
import "video-react/dist/video-react.css";

// Query
import FIND_VIDEO_DETAILS from "../../gql/query/base/findVideoDetails.query";
import FIND_BASE_PLAYERS from "../../gql/query/base/findBasePlayers.query";

// MUTATION
import UPDATE_VIDEO_PLAYER_TAG from "../../gql/mutation/base/updateVideoPlayerTag.mutations";

import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  PAYMENT_METHOD,
  STRIPE_PAYMENT_RESPONSE_STATUSES,
  RANDOM_AVATAR_URLS,
  OTHER_VIDEO_TYPES_TO_FETCH,
} from "../../utils/constant";
import HeadLine from "../../component/bSports/HeadLine";
import { da } from "date-fns/locale";

const BaseVideoPlay = () => {
  // Common data
  const navigate = useNavigate();
  const userId = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);
  const { videoId } = useParams();

  // Query parameters
  const search = useLocation().search;
  const otherVideoTypes = new URLSearchParams(search).get("otherVideoTypes");

  // Video Player stat
  const playerRef = useRef(null);

  // Tag states
  const [selectedPlayerIds, setSelectedPlayerIds] = useState([]);

  // Modal state
  const [playerTagModal, setShowPlayerTagModal] = useState(false);

  // Query
  const {
    data: findVideoDetails,
    loading: findVideoDetailsLoading,
    error: findVideoDetailsError,
    refetch: findVideoDetailsRefetch,
  } = useQuery(FIND_VIDEO_DETAILS, {
    variables: {
      videoId,
      otherVideoTypes,
    },
  });

  const {
    data: basePlayers,
    loading: basePlayersLoading,
    error: basePlayersError,
    refetch: basePlayersRefetch,
  } = useQuery(FIND_BASE_PLAYERS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // Mutations
  const [
    updatePlayerTag,
    { error: updatePlayerTagError, loading: updatePlayerTagLoading },
  ] = useMutation(UPDATE_VIDEO_PLAYER_TAG);

  useEffect(() => {
    function fetchData() {
      findVideoDetailsRefetch();
    }
    fetchData();
  }, []);

  useEffect(() => {
    function setData() {
      setSelectedPlayerIds(findVideoDetails?.findVideoDetails?.playerIds);
    }
    if (findVideoDetails?.findVideoDetails?.playerIds?.length > 0) {
      setData();
    }
  }, [findVideoDetails]);

  // Accept Invitation Modal functions
  function closePlayerTagModal() {
    setShowPlayerTagModal(false);

    // Set selected player IDS to it's previous state
    if (findVideoDetails?.findVideoDetails?.playerIds) {
      setSelectedPlayerIds(
        findVideoDetails?.findVideoDetails?.playerIds
          ? findVideoDetails?.findVideoDetails?.playerIds
          : []
      );
    }
  }

  // Update Player Tag
  async function updateVideoPlayerTag(streamingRecordId) {
    const { data } = await updatePlayerTag({
      variables: {
        streamingRecordId,
        playerIds: selectedPlayerIds,
      },
    });

    if (data.updateVideoPlayerTag.errorMessage == null) {
      findVideoDetailsRefetch();
      toast.success("Successfully updated players tag!");
      closePlayerTagModal();
    } else {
      toast.error(data.updateVideoPlayerTag.errorMessage);
    }
  }

  const copyToClipboard = (url) => {
    copy(url);
    toast.success("Successfully coppied link to share!");
  };

  if (findVideoDetailsLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (findVideoDetailsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  const videoDetails = findVideoDetails.findVideoDetails;
  const otherVideos = findVideoDetails.findVideoDetails.otherVideos;

  return (
    <AdminPortal>
      <div className="max-w-6xl mx-auto bg-slate-700 mt-7 py-3 px-8 mb-8 rounded-md">
        {/* Container */}
        <div className="font-semibold my-auto mt-5">
          <h1 className="text-2xl mb-3" style={{ color: "white" }}>
            Video Highlight
          </h1>
          {/* Items */}
          <div className="flex justify-between flex-wrap">
            {/* VIDEO PLAYER */}
            {/* VIDEO PLAYER */}
            <div className="w-full md:w-3/4">
              <div className="md:mr-5">
                {/* video player */}
                <div style={{ borderRadius: "6px", overflow: "hidden" }}>
                  {
                    <Player
                      ref={playerRef}
                      autoPlay={true}
                      poster={videoDetails.videoThumbnailUrl}
                      height={"200px"}
                      width={"200px"}
                    >
                      <source src={videoDetails.url} height={"100px"} />
                      <BigPlayButton position="center" />
                    </Player>
                  }
                </div>

                {/* VIDEO Info + SHARE button */}
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {/* VIDEO info */}
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ marginRight: "10px" }}>
                      <img
                        src={
                          RANDOM_AVATAR_URLS[videoDetails.userDetails.avatar]
                            ? RANDOM_AVATAR_URLS[
                                videoDetails.userDetails.avatar
                              ]
                            : RANDOM_AVATAR_URLS["user4_asset4"]
                        }
                        alt="Basement Sports"
                        className={`md:block w-[90px] mr-2`}
                        style={{ width: "40px", height: "40px" }}
                      />
                    </div>

                    <div style={{}}>
                      <div
                        className="pb-0"
                        style={{
                          color: "white",
                          fontSize: "20px",
                          fontWeight: "bolder",
                        }}
                      >
                        {videoDetails.caption}
                      </div>
                      {videoDetails?.createdAt && (
                        <div
                          className="pb-0"
                          style={{
                            fontSize: "13px",
                            color: "#aaaaaa",
                            fontWeight: "bold",
                          }}
                        >
                          {formatDistance(
                            new Date(),
                            new Date(videoDetails.createdAt)
                          )}{" "}
                          ago
                        </div>
                      )}
                    </div>
                  </div>

                  {/* SHARE button at the right panel */}
                  {/* <div
                    style={{
                      display: "flex",
                      width: "95px",
                      height: "40px",
                      backgroundColor: "#212121",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "30px",
                    }}
                    className="onHover"
                    onClick={() => {
                      // openShareModal("Share Video", "video", videoDetails);
                    }}
                  ></div> */}

                  {/* SHARE */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <EmailShareButton
                      url={`${window.location.origin}/video/${videoId}?otherVideoTypes=base`}
                      separator={"\n"}
                      className="mr-2"
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                    <WhatsappShareButton
                      url={`${window.location.origin}/video/${videoId}?otherVideoTypes=base`}
                      separator=":: "
                      className="mr-2"
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    <TelegramShareButton
                      url={`${window.location.origin}/video/${videoId}?otherVideoTypes=base`}
                      className="mr-2"
                    >
                      <TelegramIcon size={32} round />
                    </TelegramShareButton>
                    <FacebookShareButton
                      url={`${window.location.origin}/video/${videoId}?otherVideoTypes=base`}
                      className="mr-2"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={`${window.location.origin}/video/${videoId}?otherVideoTypes=base`}
                      className="mr-2"
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <button
                      onClick={() =>
                        copyToClipboard(
                          `${window.location.origin}/video/${videoId}?otherVideoTypes=base`
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faCopy}
                        size="2x"
                        className="text-sm font-bold py-1 px-1 rounded-full max-h-7"
                        style={{
                          backgroundColor: "#0000008a",
                          color: "#FFFFFF",
                        }}
                        fade={true}
                      />
                    </button>
                  </div>
                </div>

                {/* SPACER */}
                <div
                  style={{
                    height: "0px",
                    borderTop: "1px solid #5252526e",
                    marginTop: "15px",
                  }}
                ></div>

                {/* TAGGED PLAYERS SECTION */}
                <div
                  className="pb-0 pt-5"
                  style={{
                    color: "gold",
                    fontSize: "17px",
                    fontWeight: "bold",
                  }}
                >
                  #Tagged Players
                </div>

                {/* Tagged plaers list */}
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {videoDetails.playersDetails.map((user) => (
                    <div
                      style={{
                        border: "1px solid #525252fa",
                        display: "flex",
                        flexWrap: "wrap",
                        borderRadius: "5px",
                        alignItems: "center",
                      }}
                      className="px-2 py-2 mr-2 mt-2"
                    >
                      <img
                        src={
                          RANDOM_AVATAR_URLS[user.avatar]
                            ? RANDOM_AVATAR_URLS[user.avatar]
                            : RANDOM_AVATAR_URLS["user4_asset4"]
                        }
                        alt="Basement Sports"
                        className={`md:block w-[90px] mr-2`}
                        style={{ width: "30px", height: "30px" }}
                      />
                      <div>{user.name}</div>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => {
                      setShowPlayerTagModal(true);
                    }}
                    className="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Manage Tag
                  </button>
                </div>
              </div>
            </div>

            {/* OTHER VIDEOS */}
            {/* OTHER VIDEOS */}
            <div className="w-full md:w-1/4">
              {otherVideos.map((video, index) => {
                return (
                  <div className="mb-3">
                    <div
                      className="cursor-pointer"
                      style={{
                        position: "relative",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                      onClick={() =>
                        navigate(
                          `/admin/video/${video._id}?otherVideoTypes=${otherVideoTypes}`
                        )
                      }
                    >
                      {/* <div className="rounded-lg py-1 px-2 lg:px-6 shadow-md shadow-black/30 my-3"> */}
                      <img
                        src={
                          video?.videoThumbnailUrl
                            ? video.videoThumbnailUrl
                            : "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
                        }
                        alt="Basement Sports"
                        className={`md:block w-[90px] object-cover `}
                        style={{ width: "100%", height: "130px" }}
                      />

                      {/* PLAY ICON */}
                      <div
                        style={{
                          color: "yellow",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "80%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon icon={faCirclePlay} size="3x" />
                      </div>

                      {/* video details */}
                      <div
                        style={{
                          backgroundColor: "#00000033",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="px-2 py-2"
                      >
                        {/* left panel */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* avatar */}
                          <img
                            src={
                              RANDOM_AVATAR_URLS[
                                videoDetails.userDetails.avatar
                              ]
                                ? RANDOM_AVATAR_URLS[
                                    videoDetails.userDetails.avatar
                                  ]
                                : RANDOM_AVATAR_URLS["user4_asset4"]
                            }
                            alt="Basement Sports"
                            className={`md:block w-[90px] mr-2`}
                            style={{ width: "35px", height: "35px" }}
                          />
                          {/* video caption & base name */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {/* video caption */}
                            <div
                              className="text-sm"
                              style={{
                                fontWeight: "700",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                minWidth: "50px",
                                maxWidth: "140px",
                              }}
                            >
                              {video.caption}
                            </div>
                            {/* base name */}
                            <div className="text-xs" style={{ opacity: "0.5" }}>
                              @{"base"}
                            </div>
                          </div>
                        </div>

                        {/* right panel */}
                        <div style={{ display: "flex" }}>
                          <div style={{ opacity: "0.5", fontSize: "10px" }}>
                            {formatDistanceToNow(Number(video.createdAt))} ago
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* ACCEPT INVITATION MODAL */}
      {/* ACCEPT INVITATION MODAL */}
      <Modal
        isOpen={playerTagModal}
        onRequestClose={closePlayerTagModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#00000033",
          },
        }}
      >
        {/* MODAL CLOSE BUTTON */}
        <button onClick={closePlayerTagModal} style={{ float: "right" }}>
          <FontAwesomeIcon className="mt-1" icon={faClose} />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Manage Tag{" "}
          <FontAwesomeIcon
            className="mt-1"
            style={{ color: "white" }}
            icon={faUsers}
          />
        </h1>

        {/* ALL KIDS as the member of the BASE */}
        {basePlayersLoading ? (
          <div
            className="mt-10"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
            }}
          >
            <DataLoading loadingType="success" />
          </div>
        ) : (
          <div
            style={{ height: "450px" }}
            className="overflow-y-scroll overflow-x-hidden"
          >
            {/* SHOW KIDS */}
            {basePlayers &&
            basePlayers.findBasePlayers &&
            basePlayers.findBasePlayers.length > 0 ? (
              <div>
                {basePlayers.findBasePlayers.map((kid, index) => {
                  return (
                    <div>
                      {/* KIDS */}
                      <div
                        className="bg-black/20 mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
                        key={index}
                        style={{
                          borderLeft: "5px solid yellow",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* KID AVATAR + Name + Check button */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="py-2"
                        >
                          {/* KID AVATAR */}
                          <img
                            src={
                              RANDOM_AVATAR_URLS[kid.avatar]
                                ? RANDOM_AVATAR_URLS[kid.avatar]
                                : RANDOM_AVATAR_URLS["user4_asset4"]
                            }
                            alt="Basement Sports"
                            className="max-h-17"
                            style={{
                              color: "white",
                              width: "100px",
                              borderRight: "1px dotted #ffffff45",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                            }}
                          />

                          {/* KID INFO section */}
                          <div className="font-semibold ml-5">
                            {/* CAPTION */}
                            <div>
                              Name:{" "}
                              <b style={{ color: "#ccd0e0" }}>{kid.name}</b>
                            </div>
                          </div>
                        </div>

                        {/* Action Part (Check box / Button) for each KID */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* Check if KID was selected or not */}
                          <div
                            onClick={() => {
                              if (selectedPlayerIds.includes(kid.userId)) {
                                // Remove Array reference & create a new one
                                let prevSelectedKidIds = [...selectedPlayerIds];
                                // Get KID ID index
                                let index = prevSelectedKidIds.indexOf(
                                  kid.userId
                                );
                                // SPLICE out the KID ID
                                prevSelectedKidIds.splice(index, 1);
                                // SET new ARRAY of IDs
                                setSelectedPlayerIds(prevSelectedKidIds);
                              } else {
                                setSelectedPlayerIds([
                                  ...selectedPlayerIds,
                                  kid.userId,
                                ]);
                              }
                            }}
                            className={`w-5 h-5 border-2 rounded-full border-yellow-300 mr-5 ${
                              selectedPlayerIds.includes(kid.userId)
                                ? "bg-yellow-300"
                                : null
                            }`}
                          ></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>No Base Players Yet to Tag!</div>
            )}
          </div>
        )}

        {/* FINAL SUBMIT BUTTON for payment/ Free for Join */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          className="mt-5"
        >
          {updatePlayerTagLoading ? (
            <button
              className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
              style={{
                backgroundColor: "#0000008a",
                color: "#FFFFFF",
              }}
            >
              Loading...
            </button>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
                style={{
                  backgroundColor: "#0000008a",
                  color: "#FFFFFF",
                }}
                onClick={async () => {
                  updateVideoPlayerTag(findVideoDetails?.findVideoDetails?._id);
                }}
              >
                Apply
              </button>
            </div>
          )}
        </div>
      </Modal>
    </AdminPortal>
  );
};

export default BaseVideoPlay;
