import { gql } from "@apollo/client";

export default gql`
  query findASoccerMatch($_id: String) {
    findASoccerMatch(_id: $_id) {
      _id
      team
      teamId
      against
      againstId
      time
      place
      winner
      timeLength
      restTime
      teamLogo
      againstLogo
      teamOneGoals
      teamTwoGoals
      hasEnded
      hasFirstMatchEnded
      firstHalf
      totalPlay
      goneToTieBreaker
      teamOneTieBreakerGoals
      teamTwoTieBreakerGoals
      teamOneSeriesWin
      teamTwoSeriesWin
      teamOneSeriesGoals
      teamTwoSeriesGoals
      isSeries
      endedByTb
      bestOf
      teamOneSeriesPenalties
      teamTwoSeriesPenalties
      fullSeriesPenaltyStatus

      teamMembers {
        _id
        userId
        name
        avatar
        score
        jersey
      }

      againstMembers {
        _id
        userId
        name
        avatar
        score
        jersey
      }

      base {
        name
        logo
      }
    }
  }
`;
