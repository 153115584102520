import React, { useEffect, useState } from "react";
import INDIVIDUAL_COUNTRY_NFT from "../../../gql/query/nft/findIndividualCountryMintedNft.query";
import { useQuery } from "@apollo/client";
import DataLoading from "../../../component/loader/DataLoading";
import { Tab, TabList } from "react-tabs";

function NftRanking() {
  const [sortedData, setSortedData] = useState([]);

  const [nftCollectionType, setNftCollectionType] = useState("Female");
  const [itemsTabs, setItemsTabs] = useState(2);

  const { data, loading, error, refetch } = useQuery(INDIVIDUAL_COUNTRY_NFT, {
    variables: {
      collectionType: nftCollectionType,
    },
  });

  const collectionItemsTabs = [
    {
      id: 1,
      text: "Men's",
      icon: "items",
    },
    {
      id: 2,
      text: " Women's",
      icon: "activities",
    },
  ];

  function sortedFunction(dataArray) {
    setSortedData(() =>
      dataArray.sort((a, b) => {
        return b.total - a.total;
      })
    );
  }

  useEffect(() => {
    if (data) {
      sortedFunction(
        JSON.parse(JSON.stringify(data.findIndividualCountryMintedNft))
      );
    }
  }, [data]);

  if (error) return <DataLoading loadingType="error" />;

  return (
    <div className="relative pt-40 pb-24 px-5 dark:bg-jacarta-800">
      <TabList className="nav nav-tabs dark:border-jacarta-600 border-jacarta-100 mb-12 flex items-center justify-center border-b">
        {collectionItemsTabs.map(({ id, text, icon }) => {
          return (
            <Tab
              className="nav-item"
              key={id}
              onClick={async () => {
                if (id === 1) {
                  await setNftCollectionType("Male");
                } else if (id === 2) {
                  await setNftCollectionType("Female");
                }
                setItemsTabs(id);
              }}
            >
              <button
                className={
                  itemsTabs === id
                    ? "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white active"
                    : "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
                }
              >
                <span className="font-display text-base font-medium">
                  {text}
                </span>
              </button>
            </Tab>
          );
        })}
      </TabList>
      {loading ? (
        <DataLoading loadingType="success" />
      ) : (
        <div>
          <div class="mb-6 grid grid-cols-3 gap-3 justify-bewteen w-full md:w-2/3 mx-auto">
            <h1 className="mx-auto font-display">Ranking</h1>
            <h1 className="mx-auto font-display">Country/Team</h1>
            <h1 className="mx-auto font-display"># of NFTs Minted</h1>
          </div>
          {sortedData.map((item, index) => (
            <div
              key={index}
              className="grid grid-cols-3 gap-3 justify-bewteen w-full md:w-2/3 mx-auto"
            >
              <h1 className="mx-auto my-2 font-display">#{index + 1}</h1>
              <div className="flex my-2 mx-auto w-[150px] items-center">
                <img
                  src={"/images/countries/" + item.name + ".png"}
                  alt={item}
                  className="rounded-2lg mr-2"
                  height={48}
                  width={48}
                  objectFit="cover"
                />
                <h1 className="font-display">{item.name}</h1>
              </div>
              <h1 className="mx-auto my-2 font-display">{item.total}</h1>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default NftRanking;
