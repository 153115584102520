import gql from "graphql-tag";

export default gql`
  mutation addBaseMemberByAdmin(
    $baseSlug: String
    $email: String
    $kids: [acceptInvitationKids]
  ) {
    addBaseMemberByAdmin(baseSlug: $baseSlug, email: $email, kids: $kids) {
      errorMessage
      successMessage
    }
  }
`;
