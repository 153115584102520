import gql from "graphql-tag";

export default gql`
  mutation mintMultipleNftWithKidCoin(
    $collectionType: String
    $paymentBy: String!
    $nfts: [nftAvailabilityCheckObject]!
    $transaction_id: String!
    $paidKidCoin: Int
  ) {
    mintMultipleNftWithKidCoin(
      collectionType: $collectionType
      paymentBy: $paymentBy
      nfts: $nfts
      transaction_id: $transaction_id
      paidKidCoin: $paidKidCoin
    ) {
      _id
      paymentMethod
      paymentFor
      paymentBy
      paymentByDetails {
        _id
        name
      }
      nftId
      nftDetails {
        _id
        url
      }
      amountToPay
      paidAmount
      currency
      paymentStatus
      paymentTransactionId
      createdAt
      errorMessage
    }
  }
`;
