import React, { useState, useEffect } from "react";
import AdminPortal from "./AdminPortal";
import Calendar from "react-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { format, formatISO } from "date-fns";
import { faRotateBack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";

// Query
import FIND_BASE_EVENTS_BY_FILTER from "../../gql/query/base/findBaseEventsByFilter.query";

import {
  EVENTS_FILTER_BY,
  EVENTS_TYPE,
  LOCAL_STORAGE_KEYS,
  MONTHS_BY_NUMBER,
} from "../../utils/constant";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";

function Event() {
  const navigate = useNavigate();
  let baseId = localStorage.getItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);

  // Calender State
  const [dateValue, setDateValue] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedFilterType, setFilterType] = useState(EVENTS_FILTER_BY.DATE);

  // Search filter STATE
  const [searchEventName, setSearchEventName] = useState("");

  // Query
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_BASE_EVENTS_BY_FILTER, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      particularDate: dateValue,
      filterBy: EVENTS_FILTER_BY.DATE,
    },
    notifyOnNetworkStatusChange: true,
  });

  // Refetch event data
  useEffect(() => {
    function refetchEventData() {
      eventsRefetch();
    }
    refetchEventData();
  }, []);

  // Handle Calender Actions
  function handleClickDay(date) {
    // Today date
    const currentDate = new Date();

    // First convert the date from STRING to DATE format
    const selectedDate = new Date(date);

    // Set current Hour, Minute, Second (As DatePicker can't handle it)
    selectedDate.setHours(currentDate.getHours());
    selectedDate.setMinutes(currentDate.getMinutes());
    selectedDate.setSeconds(currentDate.getSeconds());

    let formattedDate = formatISO(selectedDate, { representation: "complete" });

    setDateValue(formattedDate);

    // Update filter STATE
    setFilterType(EVENTS_FILTER_BY.DATE);
    setSearchEventName("");
  }

  function handleOnActiveStartDateChange(view) {
    // Refetch data by MONTH
    eventsRefetch({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      year: view.activeStartDate.getFullYear(),
      month: view.activeStartDate.getMonth(),
      filterBy: EVENTS_FILTER_BY.MONTH,
    });

    // Update filter STATE
    setFilterType(EVENTS_FILTER_BY.MONTH);

    // Update Year & Month
    setSelectedYear(view.activeStartDate.getFullYear());
    setSelectedMonth(view.activeStartDate.getMonth());
  }

  // Handle Calender Event Selection
  const redirectToParticipantsList = (eventId) => {
    // Perform any actions you want here, such as navigating to a new page
    navigate(`/admin/participants/${eventId}`);
  };

  // search event by name
  const searchEventByName = debounce(async (searchName) => {
    // If "searchName" has a valid string. ELSE search by today date
    if (searchName && searchName != "") {
      // Update STATE
      setFilterType(EVENTS_FILTER_BY.SEARCH_NAME);

      // Call to backend for checking this coupon code
      eventsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        searchName,
        filterBy: EVENTS_FILTER_BY.SEARCH_NAME,
      });
    } else {
      // Update STATE
      setFilterType(EVENTS_FILTER_BY.DATE);

      // Call to backend for checking this coupon code
      eventsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        particularDate:
          !dateValue || dateValue == "" ? new Date() : new Date(dateValue), // Check if "dateValue" has value. If not then set today date as "particularDate" & refetch. ELSE IF "dateValue" has value, then refetch with that selected date
        filterBy: EVENTS_FILTER_BY.DATE,
      });
    }
  }, 400);

  if (eventsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <AdminPortal>
      <div className="bg-slate-700 flex justify-between mb-3 px-5 py-3 mt-8">
        <h3 className="font-display font-bold text-lg">Events Calendar</h3>
        <Link
          to={"/admin/add-event"}
          className="bg-slate-800 py-1 px-2 rounded"
        >
          + Add Event
        </Link>
      </div>

      {/* FILTER & SEARCH bar */}
      <div
        className="flex justify-between mb-3 px-5 mt-5 py-1 flex-wrap"
        style={{
          alignItems: "center",
          backgroundColor: "#1f2e49c9",
          borderRadius: "5px",
        }}
      >
        {/* FILTER */}
        <div className="mt-2">
          {/* FILTER BY DATE */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
              onClick={() => {
                // Update STATE
                setFilterType(EVENTS_FILTER_BY.DATE);
                setSearchEventName("");

                // Check if "dateValue" has value. If not then set today date as "particularDate" & refetch
                // ELSE IF "dateValue" has value, then refetch with that selected date
                if (!dateValue || dateValue == "") {
                  let todayDate = new Date();
                  // Now refetch data
                  eventsRefetch({
                    baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                    particularDate: todayDate,
                    filterBy: EVENTS_FILTER_BY.DATE,
                  });
                } else if (dateValue && dateValue != "") {
                  let selectedDateValue = new Date(dateValue);
                  // Now refetch data
                  eventsRefetch({
                    baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                    particularDate: selectedDateValue,
                    filterBy: EVENTS_FILTER_BY.DATE,
                  });
                }
              }}
              style={{
                opacity: selectedFilterType == EVENTS_FILTER_BY.DATE ? 1 : 0.4,
              }}
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Filter events by date."
            >
              Filter by Date
            </button>

            {/* FILTER BY MONTH */}
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
              onClick={() => {
                // Update state
                setFilterType(EVENTS_FILTER_BY.MONTH);
                setSearchEventName("");

                // Check if "dateValue" has value. If not then set today date (today year & today month) & refetch
                // ELSE IF "dateValue" has value, then refetch with that selected date (Year & month from that selected date)
                if (!dateValue || dateValue == "") {
                  let todayDate = new Date();
                  let year = todayDate.getFullYear();
                  let month = todayDate.getMonth();
                  // Now refetch data
                  eventsRefetch({
                    baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                    year,
                    month,
                    filterBy: EVENTS_FILTER_BY.MONTH,
                  });
                  // Update Year & Month
                  setSelectedYear(year);
                  setSelectedMonth(month);
                } else if (dateValue && dateValue != "") {
                  let selectedDateValue = new Date(dateValue);
                  let year = selectedDateValue.getFullYear();
                  let month = selectedDateValue.getMonth();
                  // Now refetch data
                  eventsRefetch({
                    baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                    year,
                    month,
                    filterBy: EVENTS_FILTER_BY.MONTH,
                  });
                  // Update Year & Month
                  setSelectedYear(year);
                  setSelectedMonth(month);
                }
              }}
              style={{
                opacity: selectedFilterType == EVENTS_FILTER_BY.MONTH ? 1 : 0.4,
              }}
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Filter events by month!"
            >
              Filter by Month
            </button>

            {/* UP_COMING EVENTS */}
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2 mb-2"
              style={{
                opacity:
                  selectedFilterType == EVENTS_FILTER_BY.UPCOMING ? 1 : 0.4,
              }}
              onClick={() => {
                // Update STATE
                setFilterType(EVENTS_FILTER_BY.UPCOMING);
                setSearchEventName("");
                // Re-fetch events
                eventsRefetch({
                  baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                  filterBy: EVENTS_FILTER_BY.UPCOMING,
                });
              }}
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Filter all upcoming events!"
            >
              Upcoming
            </button>

            {/* PAST EVENTS */}
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
              style={{
                opacity: selectedFilterType == EVENTS_FILTER_BY.PAST ? 1 : 0.4,
              }}
              onClick={() => {
                // Update STATE
                setFilterType(EVENTS_FILTER_BY.PAST);
                setSearchEventName("");
                // Re-fetch events
                eventsRefetch({
                  baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                  filterBy: EVENTS_FILTER_BY.PAST,
                });
              }}
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Filter all past events!"
            >
              Past
            </button>

            {/* ALL EVENTS */}
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
              style={{
                opacity: selectedFilterType == EVENTS_FILTER_BY.ALL ? 1 : 0.4,
              }}
              onClick={() => {
                // Update state
                setFilterType(EVENTS_FILTER_BY.ALL);
                setSearchEventName("");
                // Re-fetch events
                eventsRefetch({
                  baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                  filterBy: EVENTS_FILTER_BY.ALL,
                });
              }}
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Filter all events!"
            >
              All
            </button>

            <div
              className=" mb-2"
              data-tooltip-id="tooltip-content"
              data-tooltip-content="Reset filter"
            >
              <FontAwesomeIcon
                className="mt-1 cursor-pointer"
                icon={faRotateBack}
                onClick={() => {
                  handleClickDay(new Date());
                }}
              />
            </div>
          </div>
        </div>

        {/* SEARCH bar */}
        <div>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            placeholder="Search events by name"
            onChange={(e) => {
              // Update STATE
              setSearchEventName(e.target.value);
              // Call to re-fetch with debounce
              searchEventByName(e.target.value);
            }}
            value={searchEventName}
          />
        </div>
      </div>

      {/* FILTER & CALENDER & SCHDULEs */}
      <div className="flex justify-between flex-wrap">
        {/* FILTER & CALENDER */}
        <div className="w-full md:w-1/2">
          {/* CALENDER */}
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="mt-3 mb-4"
          >
            <Calendar
              value={dateValue ? dateValue : new Date()}
              onClickDay={handleClickDay}
              className="bg-black/20"
              onActiveStartDateChange={handleOnActiveStartDateChange}
            />
          </div>
        </div>
        {/* SCHDULES */}
        {eventsLoading ? (
          <div className="mt-20 w-full md:w-1/2">
            <DataLoading loadingType="success" />
          </div>
        ) : (
          <div className="w-full md:w-1/2 ">
            {eventsData.findBaseEventsByFilter.length > 0 && (
              <div
                className="md:ml-5 mt-2"
                style={{ fontWeight: "bold", fontSize: "15px" }}
              >
                {selectedFilterType == EVENTS_FILTER_BY.DATE ? (
                  <div>
                    {eventsData.findBaseEventsByFilter.length} events were found
                    on{" "}
                    <b style={{ color: "gold" }}>
                      {dateValue && dateValue != ""
                        ? format(new Date(dateValue), "do MMMM, yyyy")
                        : format(new Date(), "do MMMM, yyyy")}
                    </b>
                    !
                  </div>
                ) : selectedFilterType == EVENTS_FILTER_BY.MONTH ? (
                  <div>
                    {eventsData.findBaseEventsByFilter.length} events were found
                    in{" "}
                    <b style={{ color: "gold" }}>
                      {MONTHS_BY_NUMBER[selectedMonth]} {selectedYear}
                    </b>
                  </div>
                ) : selectedFilterType == EVENTS_FILTER_BY.UPCOMING ? (
                  <div>
                    {eventsData.findBaseEventsByFilter.length}{" "}
                    <b style={{ color: "gold" }}>upcoming</b> events were found!
                  </div>
                ) : selectedFilterType == EVENTS_FILTER_BY.PAST ? (
                  <div>
                    {eventsData.findBaseEventsByFilter.length} events have
                    <b style={{ color: "gold" }}> passed</b>!
                  </div>
                ) : selectedFilterType == EVENTS_FILTER_BY.ALL ? (
                  <div>
                    A <b style={{ color: "gold" }}>total</b> of{" "}
                    {eventsData.findBaseEventsByFilter.length} events were
                    found!
                  </div>
                ) : (
                  <div>
                    <b style={{ color: "gold" }}>
                      {eventsData?.findBaseEventsByFilter?.length
                        ? eventsData?.findBaseEventsByFilter?.length
                        : ""}
                    </b>{" "}
                    Events found!
                  </div>
                )}
              </div>
            )}

            <div>
              {eventsData.findBaseEventsByFilter?.map((event, index) => {
                return (
                  <div>
                    {/* DATE + EVENT info + BOOK session button */}
                    <div
                      className="mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 md:ml-5 py-2"
                      key={index}
                      style={{
                        backgroundColor: "#86939140",
                        borderLeft: "5px solid yellow",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* DATE & Event INFO */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="py-2"
                      >
                        {/* DATE section */}
                        <div
                          style={{
                            color: "white",
                            width: "80px",
                            borderRight: "1px dotted #ffffff45",
                            paddingLeft: "15px",
                          }}
                          className="text-2xl"
                        >
                          {format(new Date(event.dateTime), "EEE MMM dd")}
                          <div style={{ fontWeight: "bold", fontSize: "13px" }}>
                            ( {format(new Date(event.dateTime), "yyyy")} )
                          </div>
                        </div>

                        {/* Schdule INFO section */}
                        <div className="font-semibold ml-5">
                          {/* TIME */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div className="w-3 h-3 rounded-full bg-blue-500 flex items-center justify-center">
                              <div className="w-1 h-1 rounded-full bg-black"></div>
                            </div>
                            {/* Time value */}
                            <div
                              style={{ color: "#8F9BB3" }}
                              className="text-sm ml-2"
                            >
                              {format(new Date(event.dateTime), "h:mm aa")}
                            </div>
                          </div>

                          {event.eventType == EVENTS_TYPE.SPECIAL_EVENTS ? (
                            <>
                              {event.caption ? (
                                <div className="text-xl mt-1 mb-2">
                                  {event.caption}
                                </div>
                              ) : (
                                <div className="text-xl mt-1 mb-2">
                                  Special Event
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {/* CAPTION */}
                              <div className="text-sm">
                                Event Type:{" "}
                                <b style={{ color: "#ccd0e0" }}>
                                  {event.eventType}
                                </b>
                              </div>

                              {/* CAPTION */}
                              {event.caption && (
                                <div className="text-sm">
                                  Title:{" "}
                                  <b style={{ color: "#ccd0e0" }}>
                                    {event.caption}
                                  </b>
                                </div>
                              )}
                            </>
                          )}

                          {/* DURATIOON */}
                          {event.duration && (
                            <div className="text-sm">
                              Duration:{" "}
                              <b style={{ color: "#ccd0e0" }}>
                                {event.duration}
                              </b>
                            </div>
                          )}

                          {/* SPORTS */}
                          {event.sports && event.sports.length > 0 && (
                            <div className="text-sm">
                              Sports:{" "}
                              <b style={{ color: "#ccd0e0" }}>
                                {event.sports.join(", ")}
                              </b>
                            </div>
                          )}

                          {/* GAME TYPE */}
                          {event.gameType && (
                            <div className="text-sm">
                              Game Type:{" "}
                              <b style={{ color: "#ccd0e0" }}>
                                {event.gameType}
                              </b>
                            </div>
                          )}

                          {/* SLOT */}
                          {event.slot && (
                            <div className="text-sm">
                              #SLOT:{" "}
                              <b style={{ color: "#ccd0e0" }}>{event.slot}</b>
                            </div>
                          )}

                          {/* PRICE */}
                          {event.eventPrice && (
                            <div
                              className="text-md mt-2"
                              style={{ color: "#00fff5" }}
                            >
                              Price: ${event.eventPrice}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="mr-5">
                        {/* Event update button */}
                        <button
                          className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded mr-2"
                          onClick={() => {
                            navigate(`${`/admin/update-event/${event._id}`}`);
                          }}
                        >
                          Details
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}

              {(!eventsData.findBaseEventsByFilter ||
                eventsData.findBaseEventsByFilter.length == 0) && (
                <div
                  className="text-center mt-10"
                  style={{ fontWeight: "bold", fontSize: "30px" }}
                >
                  {selectedFilterType == EVENTS_FILTER_BY.DATE ? (
                    <div>
                      No events have been scheduled for{" "}
                      <b style={{ color: "gold" }}>
                        {dateValue && dateValue != ""
                          ? format(new Date(dateValue), "do MMMM, yyyy")
                          : format(new Date(), "do MMMM, yyyy")}
                      </b>{" "}
                      yet.
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.MONTH ? (
                    <div>
                      No events have been scheduled for{" "}
                      <b style={{ color: "gold" }}>
                        {MONTHS_BY_NUMBER[selectedMonth]} {selectedYear}
                      </b>{" "}
                      yet.
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.UPCOMING ? (
                    <div>
                      No
                      <b style={{ color: "gold" }}> upcoming</b> events were
                      found!
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.PAST ? (
                    <div>
                      No events have
                      <b style={{ color: "gold" }}> passed</b>!
                    </div>
                  ) : selectedFilterType == EVENTS_FILTER_BY.ALL ? (
                    <div>No events found!</div>
                  ) : (
                    "No events found!"
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </AdminPortal>
  );
}
export default Event;
