import React, { useEffect, useState } from "react";
import { useLocation, Navigate, Link } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import FORGET_PASSWORD from "../../../gql/mutation/auth/forgetPassword.mutation";
import CommonBackdrop from "../../../component/common/CommonBackdrop";

export default function ForgetPass() {
  const location = useLocation();
  const data = location.state;
  let comeFrom = null;
  if (data) {
    comeFrom = data.comeFrom;
  }
  const bgImage = "/images/page-title/WWC-NFT-Cover.png";
  //mutation
  const [forgetPassword, { loading, error }] = useMutation(FORGET_PASSWORD);

  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    // Valid format:  example@something.com, example@something.io
  }

  function validatePhone(phone) {
    // let reg = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{6})$/
    let reg = /^\+?\d+$/;
    let isphone = phone.match(reg);

    if (isphone) {
      return true;
    } else {
      return false;
    }
  }

  async function logInFunc() {
    if (email != null) {
      // Email validation check
      const checkMail = validateEmail(email);
      const checkPhone = validatePhone(email);
      if (!checkMail && !checkPhone) {
        window.alert("You typed invalid mail");
        return null;
      }

      const { data } = await forgetPassword({
        variables: { email },
      });

      if (data.forgetPassword.errorMessage === null) {
        window.alert(
          `A verification code has sent to your ${
            checkMail ? "email" : "phone"
          }`
        );

        if (comeFrom) {
          // return <Navigate to="/login" state={{ comeFrom: "/admin" }} />;
          navigate("/login", { state: { comeFrom } });
        } else {
          navigate("/login");
        }
      }
      if (data.forgetPassword.errorMessage)
        window.alert(data.forgetPassword.errorMessage);
    } else {
      window.alert("Please Enter Your Email");
    }
  }

  useEffect(() => {
    if (localStorage.getItem("userid")) {
      navigate("/");
    }
  }, []);

  return (
    <CommonBackdrop>
      <div className="flex items-center mx-auto  w-[400px]">
        <div className="sm:w-96 max-w-lg bg-gray-700 px-6 py-8 rounded-lg w-full ">
          <h1 className="text-center text-yellow-300 font-display font-bold mb-6 text-4xl">
            Forget Password
          </h1>

          <input
            onChange={(e) => setEmail(e.target.value)}
            style={{ textTransform: "lowercase" }}
            type="text"
            className="border-2 border-yellow-300 focus:border-yellow-400 text-stone-600 font-bold font-display w-full py-3 px-5 rounded-full mb-4"
            name="email"
            placeholder="Email"
          />

          <button
            onClick={() => logInFunc()}
            type="submit"
            className="w-full bg-yellow-300 hover:bg-transparent flex items-center justify-center rounded-full border-2 border-yellow-300 mb-4 py-4 px-8 text-center text-gray-700 duration-500 ease-in-out hover:text-yellow-300 font-extrabold font-display "
          >
            {loading ? "Sending..." : "Send Code"}
          </button>
          <div className="text-center text-sm text-white mt-4">
            A verification code will be sent to your email or phone
          </div>
        </div>
      </div>
    </CommonBackdrop>
  );
}
