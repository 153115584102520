import { gql } from "@apollo/client";

export default gql`
  query findAHockeyMatch($_id: String) {
    findAHockeyMatch(_id: $_id) {
      _id
      team
      teamId
      againstId
      against
      time
      place
      winner
      timeLength
      restTime
      teamLogo
      againstLogo
      teamOneGoals
      teamTwoGoals
      hasEnded
      hasFirstMatchEnded
      firstHalf
      totalPlay
      goneToTieBreaker
      teamOneTieBreakerGoals
      teamTwoTieBreakerGoals
      teamOneSeriesWin
      teamTwoSeriesWin
      teamOneSeriesGoals
      teamTwoSeriesGoals
      isSeries
      endedByTb
      bestOf
      teamOneSeriesPenalties
      teamTwoSeriesPenalties
      fullSeriesPenaltyStatus

      teamMembers {
        userId
        name
        avatar
        score
        jersey
      }

      againstMembers {
        userId
        name
        avatar
        score
        jersey
      }

      base {
        name
        logo
      }
    }
  }
`;
