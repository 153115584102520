import React, { useEffect } from "react";
import TopCollection from "../../../component/collections/TopCollection";
import { useNavigate } from "react-router-dom";
import CommonBackdrop from "../../../component/common/CommonBackdrop";

function MintNfts() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("userid")) {
      navigate("/login");
    }
  }, []);

  return (
    <CommonBackdrop>
      <div className="px-10">
        <TopCollection />
      </div>
    </CommonBackdrop>
  );
}

export default MintNfts;
