import gql from "graphql-tag";

export default gql`
  mutation mintMultipleNft(
    $collectionType: String
    $paymentMethod: String!
    $paymentBy: String!
    $nfts: [nftAvailabilityCheckObject]!
    $token: String
    $paymentResponse: String!
    $toPublicAddress: String
    $purchaseAsAGiftEmail: String
    $couponCode: String
    $giftMessage: String
  ) {
    mintMultipleNft(
      collectionType: $collectionType
      paymentMethod: $paymentMethod
      paymentBy: $paymentBy
      nfts: $nfts
      token: $token
      paymentResponse: $paymentResponse
      toPublicAddress: $toPublicAddress
      purchaseAsAGiftEmail: $purchaseAsAGiftEmail
      couponCode: $couponCode
      giftMessage: $giftMessage
    ) {
      _id
      paymentMethod
      paymentFor
      paymentBy
      paymentByDetails {
        _id
        name
      }
      nftId
      nftDetails {
        _id
        url
      }
      amountToPay
      paidAmount
      currency
      paymentStatus
      paymentTransactionId
      createdAt
      errorMessage
    }
  }
`;
