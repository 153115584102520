import { gql } from "@apollo/client";

export default gql`
  query findASelfPlayMatch($_id: String!) {
    findASelfPlayMatch(_id: $_id) {
      _id
      participantsIds
      gameType
      baseId
      successScore
      timeLength

      participantsPlayer {
        _id
        name
        userId
        teamId
        createdBy
        avatar

        soccerStats {
          avatar
          soccerAvatar
          totalWin
          totalLoss
          totalNumberOfGames
          score
          fouls
          totalRedCard
          totalYellowCard
          shotAtGoal
          shotSaved
          playedAgainstTeam
        }

        hockeyStats {
          hockeyAvatar
          totalWin
          totalLoss
          totalNumberOfGames
          score
          fouls
          totalRedCard
          totalYellowCard
          shotAtGoal
          shotSaved
          playedAgainstTeam
        }

        baseBallStats {
          baseBallAvatar
          totalWin
          totalLoss
          totalNumberOfGames
          runs
          playedAgainstTeam
        }
      }
    }
  }
`;
