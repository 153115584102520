import { gql } from "@apollo/client";
export default gql`
  query findHighlightDetails($highlightId: String, $userId: String) {
    findHighlightDetails(highlightId: $highlightId, userId: $userId) {
      _id
      url
      caption
      baseDetails {
        _id
        logo
        name
      }

      otherPhotos {
        _id
        url
        caption
        baseDetails {
          _id
          logo
          name
        }
      }

      otherVideos {
        _id
        userDetails {
          avatar
        }
        likes
        videoThumbnailUrl
        caption
        createdAt
      }
    }
  }
`;
