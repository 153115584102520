import gql from "graphql-tag";

export default gql`
  mutation checkEventCouponValidity(
    $couponCode: String!
    $eventId: String
    $paymentFor: String
  ) {
    checkEventCouponValidity(
      couponCode: $couponCode
      eventId: $eventId
      paymentFor: $paymentFor
    ) {
      additionalData
      successMessage
      errorMessage
    }
  }
`;
