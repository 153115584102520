import React, { useState } from "react";

import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

const Sidebar = ({
  nftCollectionType,
  setChangeCurrentPage,
  setCountryFilter,
  countryFilter,
  setSkinFilter,
  skinFilter,
  setPositionFilter,
  positionFilter,
  setJerseyFilter,
  jerseyFilter,
  setLegFilter,
  legFilter,
  setHairFilter,
  hairFilter,
  setMouthFilter,
  mouthFilter,
  extraFilterFunction,
}) => {
  const [filterType, setFilterType] = useState(["country"]);

  const NFT_COUNTRIES = [
    "Qatar",
    "Ecuador",
    "Senegal",
    "Netherlands",
    "England",
    "Iran",
    "USA",
    "Wales",
    "Argentina",
    "Saudi Arabia",
    "Mexico",
    "Poland",
    "France",
    "Australia",
    "Denmark",
    "Tunisia",
    "Spain",
    "Costa Rica",
    "Germany",
    "Japan",
    "Belgium",
    "Canada",
    "Portugal",
    "Uruguay",
    "Brazil",
    "Cameroon",
    "Croatia",
    "Ghana",
    "Morocco",
    "Serbia",
    "South Korea",
    "Switzerland",
  ];

  NFT_COUNTRIES.sort((a, b) => a.charAt(0).localeCompare(b.charAt(0)));

  const WOMEN_NFT_COUNTRIES = [
    "New Zealand",
    "Norway",
    "Philippines",
    "Switzerland",
    "Australia",
    "Ireland",
    "Nigeria",
    "Canada",
    "Spain",
    "Costa Rica",
    "Zambia",
    "Japan",
    "England",
    "Haiti",
    "Denmark",
    "China",
    "USA",
    "Vietnam",
    "Netherlands",
    "Portugal",
    "France",
    "Jamaica",
    "Brazil",
    "Panama",
    "Sweden",
    "South Africa",
    "Italy",
    "Argentina",
    "Germany",
    "Morocco",
    "Colombia",
    "South Korea",
  ];
  WOMEN_NFT_COUNTRIES.sort((a, b) => a.charAt(0).localeCompare(b.charAt(0)));

  const JERSEY = [
    "Qatar Home",
    "Qatar Away",
    "Ecuador Home",
    "Ecuador Away",
    "Senegal Home",
    "Senegal Away",
    "Netherlands Home",
    "Netherlands Away",
    "England Home",
    "England Away",
    "Iran Home",
    "Iran Away",
    "USA Home",
    "USA Away",
    "Wales Home",
    "Wales Away",
    "Argentina Home",
    "Argentina Away",
    "Saudi Arabia Home",
    "Saudi Arabia Away",
    "Mexico Home",
    "Mexico Away",
    "Poland Home",
    "Poland Away",
    "France Home",
    "France Away",
    "Australia Home",
    "Australia Away",
    "Denmark Home",
    "Denmark Away",
    "Tunisia Home",
    "Tunisia Away",
    "Spain Home",
    "Spain Away",
    "Costa Rica Home",
    "Costa Rica Away",
    "Germany Home",
    "Germany Away",
    "Japan Home",
    "Japan Away",
    "Belgium Home",
    "Belgium Away",
    "Canada Home",
    "Canada Away",
    "Portugal Home",
    "Portugal Away",
    "Uruguay Home",
    "Uruguay Away",
    "Brazil Home",
    "Brazil Away",
    "Cameroon Home",
    "Cameroon Away",
    "Croatia Home",
    "Croatia Away",
    "Ghana Home",
    "Ghana Away",
    "Morocco Home",
    "Morocco Away",
    "Serbia Home",
    "Serbia Away",
    "South Korea Home",
    "South Korea Away",
    "Switzerland Home",
    "Switzerland Away",
  ];
  JERSEY.sort((a, b) => a.charAt(0).localeCompare(b.charAt(0)));

  const WOMEN_JERSEY = [
    "New Zealand Home",
    "New Zealand Away",
    "Norway Home",
    "Norway Away",
    "Philippines Home",
    "Philippines Away",
    "Switzerland Home",
    "Switzerland Away",
    "Australia Home",
    "Australia Away",
    "Ireland Home",
    "Ireland Away",
    "Nigeria Home",
    "Nigeria Away",
    "Canada Home",
    "Canada Away",
    "Spain Home",
    "Spain Away",
    "Costa Rica Home",
    "Costa Rica Away",
    "Zambia Home",
    "Zambia Away",
    "Japan Home",
    "Japan Away",
    "England Home",
    "England Away",
    "Haiti Home",
    "Haiti Away",
    "Denmark Home",
    "Denmark Away",
    "China Home",
    "China Away",
    "USA Home",
    "USA Away",
    "Vietnam Home",
    "Vietnam Away",
    "Netherlands Home",
    "Netherlands Away",
    "Portugal Home",
    "Portugal Away",
    "France Home",
    "France Away",
    "Jamaica Home",
    "Brazil Away",
    "Brazil Home",
    "Panama Away",
    "Panama Home",
    "Sweden Home",
    "Sweden Away",
    "South Africa Home",
    "South Africa Away",
    "Italy Home",
    "Italy Away",
    "Argentina Home",
    "Argentina Away",
    "Germany Home",
    "Germany Away",
    "Morocco Home",
    "Morocco Away",
    "Colombia Home",
    "Colombia Away",
    "South Korea Home",
    "South Korea Away",
  ];
  WOMEN_JERSEY.sort((a, b) => a.charAt(0).localeCompare(b.charAt(0)));

  const SKIN = ["Black", "Brown", "Brown Dark", "Light", "Pale", "Tanned"];

  const LEG = ["Left", "Right"];

  const MOUTH = [
    "Brood",
    "Concerned",
    "Disbelief",
    "Happy",
    "Sad",
    "Serious",
    "Smile",
    "Tongue",
    "Upset",
  ];

  const HAIR = [
    "Long Hair Fro Brown",
    "Hair Bun Brown",
    "Hair Ceaser Black",
    "Hair Ceaser Black Beard Chin Black",
    "Hair Ceaser Black Beard Full Brown",
  ];

  const WOMEN_HAIR = [
    "Long Hair Dreads Brown Dark",
    "Hair Bun Blonde Moustache Thick Blonde",
    "Long Hair Wavy Blonde",
    "Long Hair Bun Messy Brown Dark",
    "Long Hair Bun Brown Dark",
    "Long Hair Bun Black",
    "Long Hair Fro Black",
    "Long Hair Fro Brown",
    "Long Hair Bun Bob Black",
    "Long Hair Bun Messy Black",
  ];

  const POSITION = ["Attacking", "Creative", "Defensive"];

  function selectFilterType(name) {
    let temFilter = JSON.parse(JSON.stringify(filterType));
    if (temFilter.includes(name)) {
      temFilter = temFilter.filter((item) => item != name);
    } else {
      temFilter.push(name);
    }

    setFilterType(temFilter);
  }

  async function selectFilterSection(filterType, name) {
    if (filterType === "country") {
      let temFilter = JSON.parse(JSON.stringify(countryFilter));
      if (countryFilter.includes(name)) {
        temFilter = temFilter.filter((item) => item != name);
      } else {
        temFilter.push(name);
      }
      setCountryFilter(temFilter);

      if (extraFilterFunction) {
        await extraFilterFunction(
          temFilter,
          skinFilter,
          mouthFilter,
          positionFilter,
          legFilter,
          hairFilter,
          jerseyFilter
        );
      }
    } else if (filterType === "skin") {
      let temFilter = JSON.parse(JSON.stringify(skinFilter));
      if (skinFilter.includes(name)) {
        temFilter = temFilter.filter((item) => item != name);
      } else {
        temFilter.push(name);
      }
      setSkinFilter(temFilter);

      if (extraFilterFunction) {
        await extraFilterFunction(
          countryFilter,
          temFilter,
          mouthFilter,
          positionFilter,
          legFilter,
          hairFilter,
          jerseyFilter
        );
      }
    } else if (filterType === "position") {
      let temFilter = JSON.parse(JSON.stringify(positionFilter));
      if (positionFilter.includes(name)) {
        temFilter = temFilter.filter((item) => item != name);
      } else {
        temFilter.push(name);
      }
      setPositionFilter(temFilter);

      if (extraFilterFunction) {
        await extraFilterFunction(
          countryFilter,
          skinFilter,
          mouthFilter,
          temFilter,
          legFilter,
          hairFilter,
          jerseyFilter
        );
      }
    } else if (filterType === "leg") {
      let temFilter = JSON.parse(JSON.stringify(legFilter));
      if (legFilter.includes(name)) {
        temFilter = temFilter.filter((item) => item != name);
      } else {
        temFilter.push(name);
      }
      setLegFilter(temFilter);

      if (extraFilterFunction) {
        await extraFilterFunction(
          countryFilter,
          skinFilter,
          mouthFilter,
          positionFilter,
          temFilter,
          hairFilter,
          jerseyFilter
        );
      }
    } else if (filterType === "hair") {
      let temFilter = JSON.parse(JSON.stringify(hairFilter));
      if (hairFilter.includes(name)) {
        temFilter = temFilter.filter((item) => item != name);
      } else {
        temFilter.push(name);
      }
      setHairFilter(temFilter);

      if (extraFilterFunction) {
        await extraFilterFunction(
          countryFilter,
          skinFilter,
          mouthFilter,
          positionFilter,
          legFilter,
          temFilter,
          jerseyFilter
        );
      }
    } else if (filterType === "jersey") {
      let temFilter = JSON.parse(JSON.stringify(jerseyFilter));
      if (jerseyFilter.includes(name)) {
        temFilter = temFilter.filter((item) => item != name);
      } else {
        temFilter.push(name);
      }
      setJerseyFilter(temFilter);

      if (extraFilterFunction) {
        await extraFilterFunction(
          countryFilter,
          skinFilter,
          mouthFilter,
          positionFilter,
          legFilter,
          hairFilter,
          temFilter
        );
      }
    } else if (filterType === "mouth") {
      let temFilter = JSON.parse(JSON.stringify(mouthFilter));
      if (mouthFilter.includes(name)) {
        temFilter = temFilter.filter((item) => item != name);
      } else {
        temFilter.push(name);
      }
      setMouthFilter(temFilter);

      if (extraFilterFunction) {
        await extraFilterFunction(
          countryFilter,
          skinFilter,
          temFilter,
          positionFilter,
          legFilter,
          hairFilter,
          jerseyFilter
        );
      }
    }
  }

  return (
    <div>
      {/* Country filter section */}
      <div
        className={`${
          filterType.includes("country")
            ? "h-80 duration-500"
            : "h-12 duration-500"
        } block  rounded overflow-y-scroll no-scrollbar  hover:shadow-lg bg-black/40 shadow mb-3`}
      >
        {filterType.includes("country") ? (
          <div
            onClick={() => selectFilterType("country")}
            className="flex justify-between  items-center px-5  py-4 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-white">
              Country
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-down text-white"
              aria-hidden="true"
            ></i>
          </div>
        ) : (
          <div
            onClick={() => selectFilterType("country")}
            className=" flex justify-between  items-center px-5 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-white">
              Country
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-up text-white"
              aria-hidden="true"
            ></i>
          </div>
        )}
        <div
          key={nftCollectionType}
          className={`${filterType.includes("country") ? "block" : "hidden"}`}
        >
          <div className="border-t border-white" />

          {(nftCollectionType === "Male"
            ? NFT_COUNTRIES
            : WOMEN_NFT_COUNTRIES
          ).map((name, index) => {
            return (
              <div
                onClick={() => {
                  selectFilterSection("country", name);
                  setChangeCurrentPage(1);
                }}
                key={index}
                className="mt-2 flex items-center px-5 py-2 hover:shadow-lg cursor-pointer"
              >
                <div className="h-5 w-5 border-2 border-accent-dark dark:border-white rounded flex items-center mr-3 ">
                  {countryFilter.includes(name) && (
                    <i className="fa fa-check text-white "></i>
                  )}
                </div>

                <p className="font-display text-white">{name}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Skin filter section */}
      <div
        className={`block ${
          filterType.includes("skin")
            ? "h-80 duration-500"
            : "h-12 duration-500"
        } rounded overflow-y-scroll no-scrollbar  hover:shadow-lg bg-black/40 mb-3`}
      >
        {filterType.includes("skin") ? (
          <div
            onClick={() => selectFilterType("skin")}
            className=" flex justify-between  items-center px-5  py-4 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-jacarta-500 dark:text-white">
              Skin
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-down text-white"
              aria-hidden="true"
            ></i>
          </div>
        ) : (
          <div
            onClick={() => selectFilterType("skin")}
            className="  flex justify-between  items-center px-5 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-jacarta-500 dark:text-white">
              Skin
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-up text-jacarta-500 dark:text-white"
              aria-hidden="true"
            ></i>
          </div>
        )}
        <div
          className={`${
            filterType.includes("skin") ? "block" : "hidden "
          } duration-500`}
        >
          <div className="border-t-2 border-jacarta-500 dark:border-white" />
          {SKIN.map((name, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  selectFilterSection("skin", name);
                  setChangeCurrentPage(1);
                }}
                className="mt-2 flex items-center px-5 py-2 hover:shadow-lg cursor-pointer "
              >
                <div className="h-5 w-5 border-2 border-accent-dark dark:border-white rounded flex items-center mr-3">
                  {skinFilter.includes(name) && (
                    <i className="fa fa-check text-accent-dark dark:text-white"></i>
                  )}
                </div>
                <p className="font-display text-accent-dark dark:text-white">
                  {name}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Mouth filter section */}
      <div
        className={`block ${
          filterType.includes("mouth")
            ? "h-80 duration-500"
            : "h-12 duration-500"
        } rounded overflow-y-scroll no-scrollbar  hover:shadow-lg bg-black/40 mb-3`}
      >
        {filterType.includes("mouth") ? (
          <div
            onClick={() => selectFilterType("mouth")}
            className=" flex justify-between  items-center px-5  py-4 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-jacarta-500 dark:text-white">
              Mouth
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-down text-jacarta-500 dark:text-white"
              aria-hidden="true"
            ></i>
          </div>
        ) : (
          <div
            onClick={() => selectFilterType("mouth")}
            className="  flex justify-between  items-center px-5 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-jacarta-500 dark:text-white">
              Mouth
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-up text-jacarta-500 dark:text-white"
              aria-hidden="true"
            ></i>
          </div>
        )}
        <div className={`${filterType.includes("mouth") ? "block" : "hidden"}`}>
          <div className="border-t-2 border-jacarta-500 dark:border-white" />
          {MOUTH.map((name, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  selectFilterSection("mouth", name);
                  setChangeCurrentPage(1);
                }}
                className="mt-2 flex items-center px-5 py-2 hover:shadow-lg cursor-pointer "
              >
                <div className="h-5 w-5 border-2 border-accent-dark dark:border-white rounded flex items-center mr-3">
                  {mouthFilter.includes(name) && (
                    <i className="fa fa-check text-accent-dark dark:text-white"></i>
                  )}
                </div>
                <p className="font-display text-accent-dark dark:text-white">
                  {name}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Postion filter section */}
      <div
        className={`block ${
          filterType.includes("position")
            ? "h-80 duration-500"
            : "h-12 duration-500"
        } rounded overflow-y-scroll no-scrollbar  hover:shadow-lg bg-black/40 mb-3`}
      >
        {filterType.includes("position") ? (
          <div
            onClick={() => selectFilterType("position")}
            className="flex justify-between  items-center px-5  py-4 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-jacarta-500 dark:text-white">
              Position
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-down text-jacarta-500 dark:text-white"
              aria-hidden="true"
            ></i>
          </div>
        ) : (
          <div
            onClick={() => selectFilterType("position")}
            className=" flex justify-between  items-center px-5 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-jacarta-500 dark:text-white">
              Position
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-up text-jacarta-500 dark:text-white"
              aria-hidden="true"
            ></i>
          </div>
        )}
        <div
          className={`${filterType.includes("position") ? "block" : "hidden"}`}
        >
          <div className="border-t-2 border-jacarta-500 dark:border-white" />
          {POSITION.map((name, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setChangeCurrentPage(1);
                  selectFilterSection("position", name);
                }}
                className="mt-2 flex items-center px-5 py-2 hover:shadow-lg cursor-pointer "
              >
                <div className="h-5 w-5 border-2 border-accent-dark dark:border-white rounded flex items-center mr-3">
                  {positionFilter.includes(name) && (
                    <i className="fa fa-check text-accent-dark dark:text-white"></i>
                  )}
                </div>
                <p className="font-display text-accent-dark dark:text-white">
                  {name}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Dominant Leg filter section */}
      <div
        className={`block ${
          filterType.includes("leg") ? "h-80 duration-500" : "h-12 duration-500"
        } rounded overflow-y-scroll no-scrollbar  hover:shadow-lg bg-black/40 mb-3`}
      >
        {filterType.includes("leg") ? (
          <div
            onClick={() => selectFilterType("leg")}
            className="flex justify-between  items-center px-5  py-4 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-jacarta-500 dark:text-white">
              Dominant Leg
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-down text-jacarta-500 dark:text-white"
              aria-hidden="true"
            ></i>
          </div>
        ) : (
          <div
            onClick={() => selectFilterType("leg")}
            className=" flex justify-between  items-center px-5 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-jacarta-500 dark:text-white">
              Dominant Leg
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-up text-jacarta-500 dark:text-white"
              aria-hidden="true"
            ></i>
          </div>
        )}
        <div className={`${filterType.includes("leg") ? "block" : "hidden"}`}>
          <div className="border-t-2 border-jacarta-500 dark:border-white" />
          {LEG.map((name, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  selectFilterSection("leg", name);
                  setChangeCurrentPage(1);
                }}
                className="mt-2 flex items-center px-5 py-2 hover:shadow-lg cursor-pointer "
              >
                <div className="h-5 w-5 border-2 border-accent-dark dark:border-white rounded flex items-center mr-3">
                  {legFilter.includes(name) && (
                    <i className="fa fa-check text-accent-dark dark:text-white"></i>
                  )}
                </div>
                <p className="font-display text-accent-dark dark:text-white">
                  {name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      {/* Hair filter section */}
      <div
        className={`block ${
          filterType.includes("hair")
            ? "h-80 duration-500"
            : "h-12 duration-500"
        } rounded overflow-y-scroll no-scrollbar  hover:shadow-lg bg-black/40 mb-3`}
      >
        {filterType.includes("hair") ? (
          <div
            onClick={() => selectFilterType("hair")}
            className="flex justify-between  items-center px-5  py-4 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-jacarta-500 dark:text-white">
              Hair
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-down text-jacarta-500 dark:text-white"
              aria-hidden="true"
            ></i>
          </div>
        ) : (
          <div
            onClick={() => selectFilterType("hair")}
            className="  flex justify-between  items-center px-5 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-jacarta-500 dark:text-white">
              Hair
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-up text-jacarta-500 dark:text-white"
              aria-hidden="true"
            ></i>
          </div>
        )}
        <div className={`${filterType.includes("hair") ? "block" : "hidden"}`}>
          <div className="border-t-2 border-jacarta-500 dark:border-white" />
          {(nftCollectionType === "Male" ? HAIR : WOMEN_HAIR).map(
            (name, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    selectFilterSection("hair", name);
                    setChangeCurrentPage(1);
                  }}
                  className="mt-2 flex  px-5 py-2 hover:shadow-lg cursor-pointer "
                >
                  <div className="h-5 w-5 border-2 border-accent-dark dark:border-white rounded flex items-center mr-3">
                    {hairFilter.includes(name) && (
                      <i className="fa fa-check text-accent-dark dark:text-white"></i>
                    )}
                  </div>
                  <p className="font-display dark:text-white">{name}</p>
                </div>
              );
            }
          )}
        </div>
      </div>

      {/* Jersy filter section */}
      <div
        className={`block ${
          filterType.includes("jersey")
            ? "h-80 duration-500"
            : "h-12 duration-500"
        } rounded overflow-y-scroll no-scrollbar  hover:shadow-lg bg-black/40 mb-3`}
      >
        {filterType.includes("jersey") ? (
          <div
            onClick={() => selectFilterType("jersey")}
            className="flex justify-between  items-center px-5  py-4 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-jacarta-500 dark:text-white">
              Jersey
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-down text-jacarta-500 dark:text-white"
              aria-hidden="true"
            ></i>
          </div>
        ) : (
          <div
            onClick={() => selectFilterType("jersey")}
            className="  flex justify-between  items-center px-5 cursor-pointer"
          >
            <h1 className="pt-3 mb-2 font-display text-lg font-medium text-jacarta-500 dark:text-white">
              Jersey
            </h1>
            <i
              style={{ fontSize: "150%" }}
              class="fa fa-angle-up text-jacarta-500 dark:text-white"
              aria-hidden="true"
            ></i>
          </div>
        )}
        <div
          key={nftCollectionType}
          className={`${filterType.includes("jersey") ? "block" : "hidden"}`}
        >
          <div className="border-t-2 border-jacarta-500 dark:border-white" />
          {(nftCollectionType === "Male" ? JERSEY : WOMEN_JERSEY).map(
            (name, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    selectFilterSection("jersey", name);
                    setChangeCurrentPage(1);
                  }}
                  className="mt-2 flex items-center px-5 py-2 hover:shadow-lg cursor-pointer "
                >
                  <div className="h-5 w-5 border-2 border-accent-dark dark:border-white rounded flex items-center mr-3">
                    {jerseyFilter.includes(name) && (
                      <i className="fa fa-check text-accent-dark dark:text-white"></i>
                    )}
                  </div>
                  <p className="font-display text-accent-dark dark:text-white">
                    {name}
                  </p>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
