import { gql } from "@apollo/client";

export default gql`
  query findMyAllKidsToSubscribeBase($parentId: String!, $baseSlug: String!) {
    findMyAllKidsToSubscribeBase(parentId: $parentId, baseSlug: $baseSlug) {
      _id
      name
      email
      age
      dateOfBirth
      gender
      avatar
      player {
        _id
        sport
        parentRelation
      }

      paymentDetails {
        _id
        paymentMethod
        paymentFor
        paymentBy
        amountToPay
        paidAmount
        currency
        paymentStatus
        paymentTransactionId
        subscriptionDate
        createdAt
        errorMessage
      }
      createdAt
    }
  }
`;
