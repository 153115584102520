import { gql } from "@apollo/client";

export default gql`
  query getAgoraChannelAudiences($channelName: String) {
    getAgoraChannelAudiences(channelName: $channelName) {
      agoraResponse
      successMessage
      errorMessage
    }
  }
`;
