import gql from "graphql-tag";

export default gql`
  mutation checkCouponCodeValidity($couponCode: String!) {
    checkCouponCodeValidity(couponCode: $couponCode) {
      successMessage
      errorMessage
    }
  }
`;
