import gql from "graphql-tag";

export default gql`
  mutation updateEvent(
    $eventId: String
    $caption: String
    $dateTime: String
    $eventPrice: Int
    $duration: Int
    $sports: [String]
    $gameType: String
    $slot: Int
    $description: String
    $easyDescription: String
    $bannerImage: String
    $promoCodes: [PromoCodes]
    $address: String
  ) {
    updateEvent(
      eventId: $eventId
      caption: $caption
      dateTime: $dateTime
      eventPrice: $eventPrice
      duration: $duration
      sports: $sports
      gameType: $gameType
      slot: $slot
      description: $description
      easyDescription: $easyDescription
      bannerImage: $bannerImage
      promoCodes: $promoCodes
      address: $address
    ) {
      _id
    }
  }
`;
