import { gql } from "@apollo/client";

export default gql`
  query checkMultipleNftsAvailableOrNot($nfts: [nftAvailabilityCheckObject]) {
    checkMultipleNftsAvailableOrNot(nfts: $nfts) {
      successMessage
      notAvailableCountries {
        country
        available
      }
    }
  }
`;
