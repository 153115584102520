import { gql } from "@apollo/client";
export default gql`
  query findSinglePlayerStats($_id: String) {
    findSinglePlayerStats(_id: $_id) {
      _id
      name
      userId
      teamId
      createdBy
      avatar
      description
      sport
      parentRelation
      soccerStats {
        avatar
        soccerAvatar
        totalWin
        totalLoss
        totalNumberOfGames
        score
        fouls
        totalRedCard
        totalYellowCard
        shotAtGoal
        shotSaved
        playedAgainstTeam
      }
      hockeyStats {
        hockeyAvatar
        totalWin
        totalLoss
        totalNumberOfGames
        score
        fouls
        totalRedCard
        totalYellowCard
        shotAtGoal
        shotSaved
        playedAgainstTeam
      }
      baseBallStats {
        baseBallAvatar
        totalWin
        totalLoss
        totalNumberOfGames
        runs
        playedAgainstTeam
      }
      soccerTeams {
        _id
        teamName
        mascot
        members {
          name
          avatar
        }
      }
      baseBallTeams {
        _id
        teamName
        mascot
      }
      hockeyTeams {
        _id
        teamName
        mascot
      }
      user {
        _id
        city
        country
        hasClaimed
        createdBy
        age
        dateOfBirth
        gender
        name
        avatar
        followFollowers {
          _id
          type
          typeId
          followerIds
          followers {
            _id
            name
            avatar
          }
          followings {
            _id
            name
            avatar
          }
        }
        createdAt
        role
        stripeCustomerId
        parentDetails {
          name
          player {
            _id
          }
        }
      }
      base {
        name
        logo
      }
      photos {
        _id
        url
        teamIds
        playerIds
        gameId
        baseId
        caption
        baseDetails {
          _id
          logo
          name
          members
        }
      }
      streamingRecords {
        _id
        userId
        userDetails {
          _id
          avatar
        }
        url
        city
        country
        baseId
        gameId
        sport
        videoThumbnailUrl
        caption
        createdAt
        baseDetails {
          _id
          logo
          name
          members
        }
      }
      selfPlay {
        _id
        totalPlayers
        participantsIds
        gameType
        createdBy
        ranking
        finished
        participantsPlayer {
          _id
          name
          userId
          teamId
          createdBy
          avatar
          soccerStats {
            avatar
            soccerAvatar
            totalWin
            totalLoss
            totalNumberOfGames
            score
            fouls
            totalRedCard
            totalYellowCard
            shotAtGoal
            shotSaved
            playedAgainstTeam
          }
          hockeyStats {
            hockeyAvatar
            totalWin
            totalLoss
            totalNumberOfGames
            score
            fouls
            totalRedCard
            totalYellowCard
            shotAtGoal
            shotSaved
            playedAgainstTeam
          }
          baseBallStats {
            baseBallAvatar
            totalWin
            totalLoss
            totalNumberOfGames
            runs
            playedAgainstTeam
          }
        }
        timeLength
        ranking
        finished
        running
        baseId
        base {
          _id
          name
          logo
        }
        createdAt
      }
    }
  }
`;
