import { gql } from "@apollo/client";

export default gql`
  query findATournament($id: String) {
    findATournament(id: $id) {
      _id
      tournamentName
      tournamentType
      isSeries
      bestOf
      createdBy
      status
      teams
      teamIds
      mascots
      semiFinalStatus
      finalStatus
      hasSemiBeenIntroduced
      numberOfInnings
      isRunning
      tournamentGameType
      time
      isOpen
      invitedTeams
      place
      winner
      isWorldSeries

      comments {
        _id
        body
        likes
        post
        tournamentId
        createdBy {
          avatar
          name
        }
        createdAt
      }

      quarterFinalTeams {
        _id
        tournamentId
        teamId
        againstId
        against
        againstLogo
        team
        teamLogo
        isSeries
        winner
        hasEnded
        isRunning
        byeTeam
        isSeries
        bestOf
        isWorldSeries
        tournamentId
        seriesTeamOneWin
        seriesTeamTwoWin
        teamOneSO
        teamTwoSO
        teamOneLOB
        teamTwoLOB
        teamOneHits
        teamTwoHits
        teamOnePC
        teamTwoPC
        teamOneBB
        teamTwoBB
        place
        time
        teamOneRuns
        teamTwoRuns
        teamOneExtraRuns
        teamTwoExtraRuns
        extraInnings
        battingTeam
        currentInnings
        teamMembers {
          name
          avatar
          userId
          teamId
          pitcher
          totalPitch
          isOut
          battingInHomeBase
          jersey
        }
        againstMembers {
          name
          avatar
          userId
          teamId
          pitcher
          totalPitch
          isOut
          battingInHomeBase
          jersey
        }

        base {
          _id
          name
          logo
        }
      }

      semiFinalTeams {
        _id
        tournamentId
        teamId
        againstId
        against
        againstLogo
        team
        teamLogo
        winner
        hasEnded
        isWorldSeries
        isRunning
        byeTeam
        isSeries
        bestOf
        seriesTeamOneWin
        seriesTeamTwoWin
        teamOneSO
        teamTwoSO
        teamOneLOB
        teamTwoLOB
        teamOneHits
        teamTwoHits
        teamOnePC
        teamTwoPC
        teamOneBB
        teamTwoBB
        place
        time
        teamOneRuns
        teamTwoRuns
        teamOneExtraRuns
        teamTwoExtraRuns
        allGamesExtraInningsListed
        allGamesExtraRuns {
          teamOneExtraRuns
          teamTwoExtraRuns
        }
        extraInnings
        battingTeam
        currentInnings
        teamMembers {
          name
          avatar
          userId
          teamId
          pitcher
          totalPitch
          isOut
          battingInHomeBase
          jersey
        }
        againstMembers {
          name
          avatar
          userId
          teamId
          pitcher
          totalPitch
          isOut
          battingInHomeBase
          jersey
        }

        base {
          _id
          name
          logo
        }
      }

      finalTeams {
        teamId
        againstId
        against
        againstLogo
        team
        teamLogo
        winner
        isSeries
        isRunning
        bestOf
        isWorldSeries
        seriesTeamOneWin
        seriesTeamTwoWin
        teamOneSO
        teamTwoSO
        teamOneLOB
        teamTwoLOB
        teamOneHits
        teamTwoHits
        teamOnePC
        teamTwoPC
        teamOneBB
        teamTwoBB
        place
        time
        teamOneRuns
        teamTwoRuns
        teamOneExtraRuns
        teamTwoExtraRuns
        extraInnings
        battingTeam
        currentInnings
        tournamentId
        teamMembers {
          name
          avatar
          userId
          teamId
          pitcher
          totalPitch
          isOut
          battingInHomeBase
          jersey
        }
        againstMembers {
          name
          avatar
          userId
          teamId
          pitcher
          totalPitch
          isOut
          battingInHomeBase
          jersey
        }

        base {
          _id
          name
          logo
        }
      }

      base {
        _id
        name
        logo
      }

      photos {
        url
        sport
        city
        country
        teamIds
        caption
      }
    }
  }
`;
