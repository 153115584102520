import { gql } from "@apollo/client";

export default gql`
  query checkBaseEventSubscription($userId: String!, $baseSlug: String!) {
    checkBaseEventSubscription(userId: $userId, baseSlug: $baseSlug) {
      _id
      paymentMethod
      paymentFor
      paymentBy
      amountToPay
      paidAmount
      currency
      paymentStatus
      paymentTransactionId
      subscriptionDate
      createdAt
      errorMessage
    }
  }
`;
