import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { Link, useNavigate, useParams } from "react-router-dom";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import AdminPortal from "./AdminPortal";
import Select from "react-select";
import { debounce } from "lodash";

// Query
import FIND_ALL_VIDEOS from "../../gql/query/base/findAllVideosByFilter.query";

import {
  LOCAL_STORAGE_KEYS,
  RANDOM_AVATAR_URLS,
  OTHER_VIDEO_TYPES_TO_FETCH,
} from "../../utils/constant";

const BaseHighlights = () => {
  // Common data
  const navigate = useNavigate();

  // Search filter STATE
  const [searchVideoName, setSearchVideoName] = useState("");

  // Dropdown SPORT filter states
  const [selectedSportForComponent, setSelectedSportForComponent] = useState({
    value: "all",
    label: "All Sport",
  });

  // Dropdown Options of Sports
  const sportsOption = [
    { value: "all", label: "All Sport" },
    { value: "soccer", label: "Soccer" },
    { value: "hockey", label: "Hockey" },
    { value: "baseBall", label: "Baseball" },
    { value: "basketBall", label: "Basketball" },
  ];

  // Set custom style in react-select
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black", // Set the color for all options
    }),
  };

  // Query
  const {
    data: findAllVideos,
    loading: findAllVideosLoading,
    error: findAllVideosError,
    refetch: refetchAllVideoHighlights,
  } = useQuery(FIND_ALL_VIDEOS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
    notifyOnNetworkStatusChange: true,
  });

  // Handle Sports dropdown
  const handleSelectSportChange = (selectedOptions) => {
    setSelectedSportForComponent(selectedOptions);

    // Refetch data
    refetchAllVideoHighlights({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      sport: selectedOptions.value,
      searchName: null,
    });

    // Update STATE
    setSearchVideoName("");
  };

  // search event by name
  const searchVideosByName = debounce(async (searchName) => {
    // If "searchName" has a valid string. ELSE search by today date
    refetchAllVideoHighlights({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      searchName,
      sport: "all",
    });

    // Update STATE
    setSelectedSportForComponent({
      value: "all",
      label: "All Sport",
    });
  }, 400);

  if (findAllVideosError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <AdminPortal>
      <div className="max-w-6xl mx-auto bg-slate-700 mt-10 py-3 px-8 mb-8 rounded-md">
        {/* VIDEOS */}
        <div className="font-semibold my-auto mt-4 mb-2">
          {/* FILTER */}
          <div
            className="mt-4 mb-2 flex items-center font-display text-yellow-300 text-lg px-3 py-3 flex-wrap"
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#00000033",
              borderRadius: "5px",
            }}
          >
            {/* FILTER LEFT PANEL */}
            {/* FILTER LEFT PANEL */}
            <div className="flex">
              <h1
                className="text-center font-bold text-2xl"
                style={{ color: "white" }}
              >
                Video Highlights
              </h1>
            </div>

            {/* FILTER RIGHT PANEL */}
            {/* FILTER RIGHT PANEL */}
            <div className="flex" style={{ alignItems: "center" }}>
              <div
                className="mr-2"
                data-tooltip-id="tooltip-content"
                data-tooltip-content="Filter videos by Sport"
              >
                <Select
                  className="w-40"
                  value={selectedSportForComponent}
                  onChange={handleSelectSportChange}
                  options={sportsOption}
                  isMulti={false}
                  styles={customStyles}
                />
              </div>

              <input
                className="w-56 border-2 border-slate-500 px-2 py-1 bg-transparent rounded text-white font-bold font-display"
                placeholder="Search videos by name"
                onChange={(e) => {
                  // Update STATE
                  setSearchVideoName(e.target.value);
                  // Call to re-fetch with debounce
                  searchVideosByName(e.target.value);
                }}
                value={searchVideoName}
                data-tooltip-id="tooltip-content"
                data-tooltip-content="Search videos by name"
              />
            </div>
          </div>

          {/* videos */}
          <div
            className="flex flex-wrap"
            style={{
              alignItems: "center",
            }}
          >
            {findAllVideosLoading && (
              <div
                className="mt-4"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <DataLoading loadingType="success" />
              </div>
            )}
            {!findAllVideosLoading &&
              findAllVideos.findAllVideosByFilter.map((video, index) => {
                return (
                  <div
                    className="w-full md:w-1/3"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="mr-3 my-3 cursor-pointer"
                      style={{
                        position: "relative",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                      onClick={() =>
                        navigate(
                          `/admin/video/${video._id}?otherVideoTypes=${OTHER_VIDEO_TYPES_TO_FETCH.fetchSameBaseVideos}`
                        )
                      }
                    >
                      {/* <div className="rounded-lg py-1 px-2 lg:px-6 shadow-md shadow-black/30 my-3"> */}
                      <img
                        src={
                          video?.videoThumbnailUrl
                            ? video.videoThumbnailUrl
                            : "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
                        }
                        alt="Basement Sports"
                        className={`md:block w-[90px] object-cover `}
                        style={{ width: "100%", height: "200px" }}
                      />

                      {/* PLAY ICON */}
                      <div
                        style={{
                          color: "yellow",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon icon={faCirclePlay} size="3x" />
                      </div>

                      {/* video details */}
                      <div
                        style={{
                          backgroundColor: "#00000033",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="px-4 py-4"
                      >
                        {/* left panel */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* avatar */}
                          <img
                            src={
                              RANDOM_AVATAR_URLS[video.userDetails.avatar]
                                ? RANDOM_AVATAR_URLS[video.userDetails.avatar]
                                : RANDOM_AVATAR_URLS["user4_asset4"]
                            }
                            alt="Basement Sports"
                            className={`md:block w-[90px] mr-2`}
                            style={{ width: "40px", height: "40px" }}
                          />
                          {/* video caption & base name */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {/* video caption */}
                            <div
                              className="text-sm"
                              style={{
                                fontWeight: "700",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                minWidth: "50px",
                                maxWidth: "140px",
                              }}
                            >
                              {video.caption}
                            </div>
                            {/* base name */}
                            <div className="text-sm" style={{ opacity: "0.5" }}>
                              @{"base"}
                            </div>
                          </div>
                        </div>

                        {/* right panel */}
                        <div style={{ display: "flex" }}>
                          <div className="text-xs" style={{ opacity: "0.5" }}>
                            {formatDistanceToNow(Number(video.createdAt))} ago
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {!findAllVideosLoading &&
              findAllVideos.findAllVideosByFilter.length == 0 && (
                <div>No videos yet</div>
              )}
          </div>
        </div>
      </div>
    </AdminPortal>
  );
};

export default BaseHighlights;
