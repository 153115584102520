import React, { useEffect, useState, useRef } from "react";

// UTILITIES
import iconFinder from "../../utils/iconFinder.js";

export default function SoccerAndHockeyScoreCard({
  containerStyle,
  team,
  teamLogo,
  teamOneGoals,
  against,
  againstLogo,
  teamTwoGoals,
  firstHalf,
}) {
  return (
    <div style={containerStyle}>
      {/* CARD */}
      <div style={{ position: "relative", width: "300px" }}>
        {/* LIGHT Black background */}
        <div
          style={{
            backgroundColor: "#121d26",
            opacity: 0.7,
            width: "300px",
            height: "110px",
            borderRadius: "6px",
          }}
        ></div>

        {/* All other Elements */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          {/* SPONSORS */}
          <div
            style={{
              display: "flex",
              padding: "8px",
              justifyContent: "space-between",
            }}
          >
            <a target="_blank" href="https://www.basementsports.com/">
              <img
                src={iconFinder("basmentsportsStreamingCardLogo")}
                style={{
                  width: "140px",
                }}
              />
            </a>
            <a target="_blank" href="https://www.kidcoin.com/">
              <img
                src={iconFinder("kidcoin")}
                style={{
                  width: "97px",
                }}
              />
            </a>
          </div>

          {/* SEPERATOR */}
          <div
            style={{
              backgroundColor: "#8a8a8a",
              width: "300px",
              height: "0.5px",
            }}
          ></div>

          {/* TEAM & SCCORE INFO */}
          <div style={style.teamInfoContainer}>
            <div style={style.logoContainer}>
              <img src={iconFinder(teamLogo)} style={style.avatar} />
            </div>
            <div style={style.teamNameContainer}>
              {team?.toUpperCase()?.slice(0, 5)}
            </div>
            <div style={style.teamScoreContainer}>
              {teamOneGoals} - {teamTwoGoals}
            </div>
            <div
              style={{ ...style.teamNameContainer, justifyContent: "right" }}
            >
              {against?.toUpperCase()?.slice(0, 5)}
            </div>
            <div style={style.logoContainer}>
              <img src={iconFinder(againstLogo)} style={style.avatar} />
            </div>
          </div>

          {/* 1st HALF/ 2nd HALF */}
          <div
            style={{
              textAlign: "center",
              fontWeight: 800,
              fontSize: "small",
              color: "white",
            }}
          >
            {firstHalf ? "1st Half" : "2nd Half"}
          </div>
        </div>
      </div>
    </div>
  );
}

// STYLE
const style = {
  container: {
    position: "absolute",
    top: 60,
    left: 20,
    width: "300px",
  },
  teamInfoContainer: {
    width: "300px",
    display: "flex",
    flexDirection: "row",
    padding: "8px",
  },
  logoContainer: {
    width: "33px",
    borderRight: "5px",
  },
  avatar: {
    width: "33px",
    height: "33px",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  teamNameContainer: {
    width: "80px",
    paddingLeft: "8px",
    paddingRight: "8px",
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontSize: "larger",
    color: "white",
  },
  teamScoreContainer: {
    width: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffe10a",
    fontWeight: 700,
    fontSize: "larger",
  },
};
