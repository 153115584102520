// This is inactive account
// const STRIPE_CREDENTIALS = {
//   SECRET_KEY:
//     "sk_test_51LzwAbExPMOV92wnMe44VFnZfJhUrwjUThQE8u4fLixv2cd6sTjNfS9oOu6ME5wKbOqFq5dceibgbmfH0oxVy7y100pO3V7NkE",
//   PUBLISHABLE_KEY:
//     "pk_test_51LzwAbExPMOV92wntqVB29rLeCBkyhdqqcHZfTXYzQwJpUNsRLkBgR0SKhdkYyIdonWv968jQMFWrWVkKh6jk8fZ00IUlwqBvX",
//   CURRENCY: "USD",
// };

// This is basement sports officail account fot testing
const STRIPE_CREDENTIALS = {
  SECRET_KEY:
    "sk_test_51HUYdrDMlw6iYeIlIxkLroorHjo0I14HdFt9Bls05oX1Q1OMYq9dzpX4ikyQdGx7u6lgxLyE18inb9U1uVA4FtJN00cWRFklSz",
  PUBLISHABLE_KEY:
    "pk_test_51HUYdrDMlw6iYeIlm17qAYWbYjHL5xFo6BHg3euOYXxsLG801358yNqlA7Vi127sv1f2NPtuZkGde6gRhSVs4mDQ00EPeSqsrW",
  CURRENCY: "USD",
};

// This is basement sports officail account for real data
// const STRIPE_CREDENTIALS = {
//   SECRET_KEY:
//     "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",
//   PUBLISHABLE_KEY:
//     "pk_live_51HUYdrDMlw6iYeIlyY11qSumoJ0U8f8OXwxNJeTPMyasUkRjVfv1JOQagskc45YI4YedFysGHSuQap0GdNiUTxpj00UAY5iy8N",
//   CURRENCY: "USD",
// };

const EACH_NFT_AFFILIATE_COUPON_DISCOUNT_PRICE_FOR_ALL_USER_IN_DOLLAR = 3;
const SUPER_BASE_COUPON_CODE_INFO = {
  SINGLE_EVENT_PURCHASE_AFFILIATE_COMMISSON_IN_DOLLAR: 10, // Comission for Affiliate user
  SINGLE_EVENT_PURCHASE_DISCOUNT_IN_DOLLAR: 5, // Discount for customer
  MONTHLY_SUBSCRIPTION_AFFILIATE_COMMISSON_IN_DOLLAR: 30, // Comission for Affiliate user
  MONTHLY_SUBSCRIPTION_DISCOUNT_IN_DOLLAR: 20, // Discount for customer
};

const PAYMENT_FOR = {
  MINT_NFT: "mintNft",
  GIFT_NFT: "giftNft",
  PURCHASE_KIDCOIN: "purchaseKidcoin",
  PURCHASE_SINGLE_EVENT_OF_BASE: "singleEvent",
  PURCHASE_FULL_MONTH_EVENT_OF_BASE: "fullMonthEvent",
  PURCHASE_SLOT_FOR_BASE_EVENT: "slotPurchase",
};

const PAYMENT_METHOD = {
  STRIPE: "Stripe",
  KIDCOIN: "Kidcoin",
};

const PAYMENT_CURRENCY = {
  USD: "usd",
  KIDCOIN: "Kidcoin",
};

const STRIPE_PAYMENT_RESPONSE_STATUSES = {
  SUCCEEDED: "succeeded",
  PENDING: "pending",
  FAILED: "failed",
};

const USER_TYPE = {
  SUPER_BASE_OWNER: "baseOwner",
};

const LOCAL_STORAGE_KEYS = {
  USER_ID: "userid",
  USER_NAME: "bsports_user_name",
  USER_EMAIL: "user_email",
  USER_KIDCOIN_TOKEN: "kidcointoken",
  USER_AFFILIATE_COUPON_CODE: "affiliateCouponCode",
  USER_TYPE: "userType",
  SUPER_BASE_ID: "baseId",
  SUPER_BASE_SLUG: "anarstudio",
};

const EVENT_PAY = {
  PER_EVENT_PAY: 25,
  PER_MONTH_PAY: 200,
};

const ADMIN_USER_IDS = [
  "6357bfa35b840e0029926baf", // Kaiyes
  "6357b8385b840e0029925cd1", // Anis
  "6357b8455b840e0029925d0c", // Shahdath
  "6357b86c5b840e0029925d39", // Khairul
  "6357d8a55b840e0029929d3f", // Mustafa
  "637273df0b5b4f00290b7363", // Arman
  "6359561b0026c200293d9e3f", // Micah
  "64a6dd4df52ecd3dc0e73b5d", // Dylan
  "64a6de26f52ecd3dc0e7435a", // kai

  "651a6921bd81474c38666386", //Shahadath Local
];

const CONTACT_FORM_URL = "https://app.basementsports.com/";

const CONTACT_FORM_TYPES = {
  BSPORTS_CONTACT: "bSportsContact",
  CONTACT_TO_BASE_OWNER: "contactToBaseOwner",
  CONTACT_TO_BSPORT_BASE_OWNER: "contactToBSportBaseOwner",
};

const RANDOM_AVATAR_URLS = {
  user1_asset1:
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1695641045/asset1_f18h3y.png",
  user2_asset2:
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1695641173/asset2_hixip5.png",
  user3_asset3:
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1695641221/asset3_ipewfo.png",
  user4_asset4:
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1695641269/asset4_azmmcs.png",
  user5_asset5:
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1695641322/asset5_lc2sgx.png",
  user6_asset6:
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1695641378/asset6_cuwath.png",
  user7_asset7:
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1695641414/asset7_enjtyu.png",
  user8_asset8:
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1695641454/asset8_ypgor9.png",
  user9_asset9:
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1695641500/asset9_eupo9o.png",
  user10_asset10:
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1695641530/asset10_nj5y8u.png",
};

// Stripe Webhook URL types
const PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE =
  "bSportBaseSingleEventPurchase";
const PROJECT_BSPORTS_SUPER_BASE_MONTHLY_SUBSCRIPTION =
  "bSportBaseMonthlySubscription";
const PROJECT_BSPORTS_STRIPE_SETUP = "bSportStripeSetuup";
const PROJECT_BSPORTS_SUPER_BASE_EVENT_SLOT_PURCHASE =
  "bSportBaseEventSlotPurchase";

const EVENTS_TYPE = {
  SPECIAL_EVENTS: "Special Events",
};

const CLOUDINARY_INFO = {
  CLOUD_NAME: "dva2poi3c",
  UPLOAD_PRESET: "blueLinerfile",
};

const OTHER_VIDEO_TYPES_TO_FETCH = {
  fetchSamePlayerVideos: "player",
  fetchSameBaseVideos: "base",
  fetchSameEventVideos: "event",
};

const BASEMENT_SPORTS_WEBSITE_URL = "https://app.basementsports.com/";
const BASEMENT_SPORTS_STAGING_WEBSITE_URL =
  "https://basementsports-staging.netlify.app/";

const EVENTS_FILTER_BY = {
  DATE: "date",
  MONTH: "month",
  UPCOMING: "upcoming",
  PAST: "past",
  ALL: "all",
  SEARCH_NAME: "name",
};

const MONTHS_BY_NUMBER = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

const TOURNAMENT_STATUS = {
  FINAL: "final",
  SEME_FINAL: "semiFinal",
  QUATER_FINAL: "quaterFinal",
};

const SOCKET_API_URL = "https://bsports-socket-staging.herokuapp.com/";

// KID management
const USER_ROLES = {
  PARENT: "parent",
  KID: "kid",
};

const BASEMENT_SPORTS_OFFICAL_LOCATION =
  "150 Bay Street, Suite 718, Jersey City, NJ 07302";

module.exports = {
  STRIPE_CREDENTIALS,
  EACH_NFT_AFFILIATE_COUPON_DISCOUNT_PRICE_FOR_ALL_USER_IN_DOLLAR,
  PAYMENT_FOR,
  PAYMENT_METHOD,
  PAYMENT_CURRENCY,
  STRIPE_PAYMENT_RESPONSE_STATUSES,
  USER_TYPE,
  LOCAL_STORAGE_KEYS,
  ADMIN_USER_IDS,
  CONTACT_FORM_URL,
  CONTACT_FORM_TYPES,
  EVENT_PAY,
  SUPER_BASE_COUPON_CODE_INFO,
  RANDOM_AVATAR_URLS,
  PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
  PROJECT_BSPORTS_SUPER_BASE_MONTHLY_SUBSCRIPTION,
  PROJECT_BSPORTS_STRIPE_SETUP,
  PROJECT_BSPORTS_SUPER_BASE_EVENT_SLOT_PURCHASE,
  EVENTS_TYPE,
  CLOUDINARY_INFO,
  OTHER_VIDEO_TYPES_TO_FETCH,
  BASEMENT_SPORTS_WEBSITE_URL,
  BASEMENT_SPORTS_STAGING_WEBSITE_URL,
  EVENTS_FILTER_BY,
  MONTHS_BY_NUMBER,
  TOURNAMENT_STATUS,
  SOCKET_API_URL,
  USER_ROLES,
  BASEMENT_SPORTS_OFFICAL_LOCATION,
};
