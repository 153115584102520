import { gql } from "@apollo/client";

export default gql`
  query findEvent($eventId: String!) {
    findEvent(eventId: $eventId) {
      _id
      eventType
      caption
      dateTime
      duration
      sports
      gameType
      slot
      participantKidIds
      checkedInUserIds
      participantKidDetails {
        _id
        name
        avatar
        gender
      }

      participantParentIds

      eventPrice
      description
      bannerImage

      address

      promoCodes {
        code
      }

      bookedBy
      invitedTo {
        email
        acceptStatus
        userId
      }

      streamingRecords {
        _id
        userDetails {
          _id
          avatar
        }
        url
        videoThumbnailUrl
        caption
        createdAt
      }
    }
  }
`;
