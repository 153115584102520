import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { collection_data } from "../../data/collection_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faCopy } from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { Tab, TabList } from "react-tabs";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { io } from "socket.io-client";
import { useQuery, useMutation } from "@apollo/client";
import { format, formatDistance } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Calendar from "react-calendar";
import Modal from "react-modal";
import differenceInYears from "date-fns/differenceInYears";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import fitScoreDataForAllGameOrTournament, {
  findRestTime,
} from "../../utils/fitScoreDataForAllGameOrTournament.js";
import AgoraRTC, {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
  ClientRole,
} from "agora-rtc-react";

// COMPONENTS
import LiveStreamingView from "../../component/base/liveStreamingView";

// Share
import {
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  FacebookShareButton,
  TwitterShareButton,
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
} from "react-share";
import copy from "copy-to-clipboard";

// Query
import FIND_STREAM_DETAILS from "../../gql/query/base/findMatchStreamById.query.js";
import FIND_ALL_LIVE_STREAMS from "../../gql/query/base/findAllStreamsByFilter.query.js";
import FIND_SOCCER_MATCH from "../../gql/query/base/findASoccerMatch.js";
import FIND_SOCCER_TOURNAMENT_MATCH from "../../gql/query/base/findSoccerTournamentMatch.query.js";
import FIND_HOCKEY_MATCH from "../../gql/query/base/findAHockeyMatch.js";
import FIND_HOCKEY_TOURNAMENT_MATCH from "../../gql/query/base/findHockeyTournamentMatch.query.js";
import FIND_A_GAME from "../../gql/query/base/findAGame.query.js";
import FIND_A_TOURNAMENT from "../../gql/query/base/findATournament.query.js";
import GET_AGORA_CHANNEL_AUDIENCES from "../../gql/query/base/getAgoraChannelAudiences.query.js";
import FIND_A_SELF_GAME from "../../gql/query/base/findASelfPlayMatch.query.js";

import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  PAYMENT_METHOD,
  STRIPE_PAYMENT_RESPONSE_STATUSES,
  RANDOM_AVATAR_URLS,
  OTHER_VIDEO_TYPES_TO_FETCH,
  SOCKET_API_URL,
} from "../../utils/constant";
import HeadLine from "../../component/bSports/HeadLine";
import { da } from "date-fns/locale";

const StreamPlay = () => {
  // Common data
  const navigate = useNavigate();
  const userId = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);
  const { baseSlug, streamId } = useParams();

  // Query parameters
  const search = useLocation().search;
  const autoIsFullScreen = new URLSearchParams(search).get("isFullScreen");

  // Check If already requested for Join CChannel of Agora
  const _alreadyRequestedToJoinChannel = useRef(false);

  // Token
  const token = null;

  // State
  const [showVideoPlayerModal, setShowVideoPlayerModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedFilterOption, setSelectedFilterOption] = useState("all");
  const _socket = useRef(null);
  const _client = useRef(null);

  // Sound Special User Interaction Button
  const [autoPlayButton, setAutoPlayButton] = useState(false);

  // STREAMING STATEs
  const [selectedStream, setSelectedStream] = useState(null);
  const [inCall, setInCall] = useState(false);
  const [users, setUsers] = useState([]);
  const [liveTime, setLiveTime] = useState("");
  const [windowSize, setWindowSize] = useState(getWindowSize());

  // Full Screen STATEs
  const [isFullScreenStreaming, setIsFullScreenStreaming] = useState(false);
  const streamingElementRef = useRef(null);
  const fullScreenForStreaming = useFullScreenHandle();

  // GOAL & ANIMATION states
  const [isShowSoccerAndHockeyGoalCard, setShowSoccerAndHockeyGoalCard] =
    useState(false);

  // SELF play STATE
  const [currentHitPerson, setCurrentHitPerson] = useState(0);

  // Query
  const {
    data: findStreamDetails,
    loading: findStreamDetailsLoading,
    error: findStreamDetailsError,
    refetch: findStreamDetailsRefetch,
  } = useQuery(FIND_STREAM_DETAILS, {
    variables: {
      matchStreamId: streamId,
    },
  });

  const {
    data: findAllStreams,
    loading: findAllStreamsLoading,
    error: findAllStreamsError,
    refetch: findLiveStreamsRfetch,
  } = useQuery(FIND_ALL_LIVE_STREAMS, {
    variables: {
      baseSlug,
    },
  });

  const {
    loading: loadingSoccerMatch,
    error: errorSoccerMatch,
    data: dataSoccerMatch,
    refetch: refetchSoccerMatch,
  } = useQuery(FIND_SOCCER_MATCH, {
    variables: { _id: selectedStream?.gameId },
    skip: !(
      selectedStream &&
      (selectedStream?.gameName == "soccerSingle" ||
        selectedStream?.gameName == "soccerSeries")
    ),
  });

  const {
    data: soccerTournamentMatch,
    error: errorSoccerTournamentMatch,
    loading: loadingSoccerTournamentMatch,
    refetch: refetchSoccerTournamentMatch,
  } = useQuery(FIND_SOCCER_TOURNAMENT_MATCH, {
    variables: {
      tournamentId: selectedStream?.tournamentId,
      matchId: selectedStream?.gameId,
    },
    skip: !(selectedStream && selectedStream?.gameName == "soccerTournament"),
  });

  const {
    loading: loadingHockeyMatch,
    error: errorHockeyMatch,
    data: dataHockeyMatch,
    refetch: refetchHockeyMatch,
  } = useQuery(FIND_HOCKEY_MATCH, {
    variables: { _id: selectedStream?.gameId },
    skip: !(
      selectedStream &&
      (selectedStream?.gameName == "hockeySingle" ||
        selectedStream?.gameName == "hockeySeries")
    ),
  });

  const {
    data: hockeyTournamentMatch,
    error: errorHockeyTournamentMatch,
    loading: loadingHockeyTournamentMatch,
    refetch: refetchHockeyTournamentMatch,
  } = useQuery(FIND_HOCKEY_TOURNAMENT_MATCH, {
    variables: {
      tournamentId: selectedStream?.tournamentId,
      matchId: selectedStream?.gameId,
    },
    skip: !(selectedStream && selectedStream?.gameName == "hockeyTournament"),
  });

  const {
    loading: loadingBaseballMatch,
    error: errorBaseballMatch,
    data: dataBaseballMatch,
    refetch: refetchBaseballMatch,
  } = useQuery(FIND_A_GAME, {
    variables: { id: selectedStream?.gameId },
    skip: !(
      selectedStream &&
      (selectedStream?.gameName == "baseballSingle" ||
        selectedStream?.gameName == "baseballSeries")
    ),
  });

  const {
    data: baseballTournament,
    error: errorBaseballTournament,
    loading: loadingBaseballTournament,
    refetch: refetchBaseballTournament,
  } = useQuery(FIND_A_TOURNAMENT, {
    variables: { id: selectedStream?.tournamentId },
    skip: !(selectedStream && selectedStream?.gameName == "baseballTournament"),
  });

  const {
    data: selfPlayGameData,
    error: errorSelfPlayGame,
    loading: loadingSelfPlayGame,
    refetch: refetchSelfPlayGame,
  } = useQuery(FIND_A_SELF_GAME, {
    variables: { _id: selectedStream?.gameId },
    skip: !(
      selectedStream &&
      (selectedStream?.gameName == "SelfPlaySoccer" ||
        selectedStream?.gameName == "SelfPlayHockey" ||
        selectedStream?.gameName == "SelfPlayBaseball" ||
        selectedStream?.gameName == "SelfPlayBasketball")
    ),
  });

  const {
    loading: loadingAgoraChannelAudiences,
    error: errorAgoraChannelAudiences,
    data: agoraChannelAudiences,
    refetch: refetchAgoraChannelAudiences,
    startPolling,
  } = useQuery(GET_AGORA_CHANNEL_AUDIENCES, {
    variables: { channelName: selectedStream?.channelName },
    pollInterval: 2000,
    skip: !selectedStream,
  });

  const copyToClipboard = (url) => {
    copy(url);
    toast.success("Successfully coppied link to share!");
  };

  // Load the page on Top after loading
  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // RUN after successfully fetching all Streams & videos from DATABASE
  useEffect(() => {
    let setAutoMatchStream = async () => {
      // **** IF found the AUTO_SHOW_MATCH_STREAM ****
      if (findStreamDetails?.findMatchStreamById) {
        await joinChannel(findStreamDetails?.findMatchStreamById);

        // NOW Enable full screen streaming
        if (autoIsFullScreen == "true") {
          setTimeout(() => {
            setIsFullScreenStreaming(true);
          }, 3000);
        }
      }
    };
    // CALL method after successfully fetching data && also if the streamId was given
    if (!findStreamDetailsLoading && findStreamDetails && streamId) {
      setAutoMatchStream();
    }
  }, [findStreamDetailsLoading, findStreamDetails]);

  // SOCKET Initialization
  // *** Only for the first time 👇👇 ***
  useEffect(() => {
    let socketListener = async () => {
      const socket = io(`${SOCKET_API_URL}`, {
        mode: "cors",
        transports: ["websocket"],
      });
      _socket.current = socket;
    };
    socketListener();
  }, []);

  // If AGORA has failed to AUTO PLAY audio
  // *** Only for the first time 👇👇 ***
  useEffect(() => {
    let agoraAutoPlaySoundChecker = function () {
      // If AGORA has failed to AUTO PLAY audio then this bellow callback 👇👇 will be called
      // Then it will enable to show a BUTTON to enable audio
      AgoraRTC.onAutoplayFailed = () => {
        setAutoPlayButton(true);
      };
    };
    agoraAutoPlaySoundChecker();
  }, []);

  //  #### AGORA Initialization ####
  //  #### AGORA Initialization ####
  useEffect(() => {
    let agoraClientInitialization = async () => {
      const config = {
        mode: "live",
        codec: "vp8",
      };
      const theClient = createClient(config);
      _client.current = theClient();
    };
    agoraClientInitialization();
  }, [selectedStream]);

  // AGORA Client LISTENERS INITIALIZE. (When new STREAM will be selected the AGORA streaming listeners will be Re-initialized)
  // ****** This bellow 👇👇 USEEFFECT will run immediately after run the above 👆👆 "#### AGORA Initialization ####" USEEFFECT ******
  useEffect(() => {
    let init = async () => {
      _client?.current?.on("user-published", async (user, mediaType) => {
        await _client?.current.subscribe(user, mediaType);
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }
        if (
          user &&
          user.audioTrack &&
          user.audioTrack.play &&
          mediaType === "audio"
        ) {
          user?.audioTrack?.play();
        }
      });

      _client?.current?.on("user-unpublished", (user, type) => {
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });

      _client?.current?.on("user-left", (user) => {
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });
    };

    init();
  }, [_client.current]);

  // SOCKET RE-initialization (When new STREAM will be selected the SOCKET will be Re-Initialized)
  // ****** This bellow 👇👇 USEEFFECT will run immediately after run the above 👆👆 "#### AGORA Initialization ####" USEEFFECT ******
  useEffect(() => {
    let socketReInitialization = async () => {
      if (selectedStream) {
        _socket.current.on(
          `game-msg:${selectedStream.gameId ? selectedStream.gameId : null}`,
          (data) => {
            // Apply Current Time Change
            if (data.currentTime || data.currentTime == 0) {
              setLiveTime(data.currentTime);
            }

            // Apply GOAL change
            if (data.anotherAction) {
              if (
                selectedStream.gameName == "soccerSingle" ||
                selectedStream.gameName == "soccerSeries"
              ) {
                refetchSoccerMatch({
                  variables: {
                    _id: selectedStream.gameId,
                  },
                });
              } else if (selectedStream.gameName == "soccerTournament") {
                refetchSoccerTournamentMatch({
                  variables: {
                    tournamentId: selectedStream.tournamentId,
                    matchId: selectedStream.gameId,
                  },
                });
              } else if (
                selectedStream.gameName == "hockeySingle" ||
                selectedStream.gameName == "hockeySeries"
              ) {
                refetchHockeyMatch({
                  variables: {
                    _id: selectedStream.gameId,
                  },
                });
              } else if (selectedStream.gameName == "hockeyTournament") {
                refetchHockeyTournamentMatch({
                  variables: {
                    tournamentId: selectedStream.tournamentId,
                    matchId: selectedStream.gameId,
                  },
                });
              } else if (
                selectedStream.gameName == "SelfPlaySoccer" ||
                selectedStream.gameName == "SelfPlayHockey" ||
                selectedStream.gameName == "SelfPlayBaseball" ||
                selectedStream.gameName == "SelfPlayBasketball"
              ) {
                refetchSelfPlayGame({
                  variables: {
                    _id: selectedStream.gameId,
                  },
                });
              }
            } else {
              if (
                selectedStream.gameName == "baseballSingle" ||
                selectedStream.gameName == "baseballSeries"
              ) {
                refetchBaseballMatch({
                  variables: {
                    id: selectedStream.gameId,
                  },
                });
              } else if (selectedStream.gameName == "baseballTournament") {
                refetchBaseballTournament({
                  variables: {
                    id: selectedStream.tournamentId,
                  },
                });
              }
            }

            // GOAL or ANIMATION for GOAL
            if (data.goalSound) {
              setShowSoccerAndHockeyGoalCard(true);
              setTimeout(() => {
                setShowSoccerAndHockeyGoalCard(false);
              }, 3000);
            }

            // Self Play Set Current Player
            if (data.currentHitPerson) {
              setCurrentHitPerson(data.currentHitPerson);
            }
          }
        );
      }
    };
    socketReInitialization();

    return () => {
      _socket?.current?.off(
        `game-msg:${selectedStream?.gameId ? selectedStream.gameId : null}`
      );
    };
  }, [selectedStream]);

  // WINDOW SCREEN SIZE CHANGE
  useEffect(() => {
    function handleWindowResize() {
      // Commented the Screen REALOAD on SCREEN size change just for now 👇👇👇
      setWindowSize(getWindowSize());
      // leaveChannel();
      // window.location.reload();
    }
    window?.addEventListener("resize", handleWindowResize);

    return () => {
      window?.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // SET Live Time
  useEffect(() => {
    let setData = async () => {
      if (selectedStream) {
        let restTime = findRestTime(selectedStream.gameName, {
          soccerSingle: dataSoccerMatch,
          soccerTournament: soccerTournamentMatch,
          hockeySingle: dataHockeyMatch,
          hockeyTournament: hockeyTournamentMatch,
          selfPlay: selfPlayGameData,
        });
        setLiveTime(restTime);
      }
    };
    setData();
  }, [
    inCall,
    dataSoccerMatch,
    soccerTournamentMatch,
    dataHockeyMatch,
    hockeyTournamentMatch,
    selectedStream,
  ]);

  // POLL INTERVAL of GRAPHQL Query
  useEffect(() => {
    startPolling(5000);
  }, [startPolling]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  // JOIN channel
  let joinChannel = async (liveStreamGameData) => {
    if (inCall) {
      // Set _alreadyRequestedToJoinChannel as FALSE so that new JOIN request can be sent
      _alreadyRequestedToJoinChannel.current = false;
      // LEAVE from AGORA channel
      await _client?.current?.leave();
      await _client?.current?.removeAllListeners();
      // REMOVE all previous Broadcasters
      setUsers([]);
    }
    // NOW for this bellow line USEEFFECT will run & will create new _client
    setSelectedStream(liveStreamGameData);

    // JOIN Channel after 1 second delay
    setTimeout(async () => {
      // if not requested yet then send Channel JOIN request
      if (!_alreadyRequestedToJoinChannel.current) {
        // Set _alreadyRequestedToJoinChannel as TRUE so that if UseEffect is being called unnecessarily again then No Jon Request will be sent
        _alreadyRequestedToJoinChannel.current = true;

        await _client?.current?.setClientRole("audience");
        await _client?.current?.join(
          liveStreamGameData.appId,
          liveStreamGameData.channelName,
          token,
          null
        );
        setInCall(true);
        // REFETCH QUERIES with new selectedStream
        refetchAgoraChannelAudiences({
          variables: {
            channelName: liveStreamGameData.channelName,
          },
        });
      }
    }, 2000);
  };

  // LEAVE Channel
  let leaveChannel = async () => {
    // Set _alreadyRequestedToJoinChannel as FALSE so that new JOIN request can be sent
    _alreadyRequestedToJoinChannel.current = false;
    // LEAVE from AGORA Channel
    if (_client.current && inCall) {
      await _client.current.leave();
      _client.current.removeAllListeners();
    }
    // UPDATE STATE
    setInCall(false);
    setUsers([]);
    _client.current = null;
    setSelectedStream(null);
    // If STREAMING FULL SCREEN ON, then exit FULL SCREEN
    if (isFullScreenStreaming) {
      // document.exitFullscreen();
    }
  };

  const reportChangeFullScreen = useCallback(
    (state, handle) => {
      if (handle === fullScreenForStreaming) {
        setIsFullScreenStreaming(state);
      }
    },
    [fullScreenForStreaming]
  );

  // If audio AUTO PLAY failed then user will click a BUTTON
  // The button call this bellow method 👇👇 to start audio AUTO PLAY
  function handleAutoPlayButton() {
    toast.success("Streaming sound enabled!", {
      autoClose: 2000,
      theme: "light",
    });
    setAutoPlayButton(false);
  }

  // LEAVE channel on clean-up
  useEffect(() => {
    return async () => {
      await _client?.current?.leave();
      _client?.current?.removeAllListeners();
    };
  }, []);

  if (
    findStreamDetailsLoading ||
    loadingSoccerMatch ||
    loadingSoccerTournamentMatch ||
    loadingHockeyMatch ||
    loadingHockeyTournamentMatch ||
    loadingBaseballMatch ||
    loadingBaseballTournament ||
    loadingSelfPlayGame ||
    loadingAgoraChannelAudiences
  )
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (findStreamDetailsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  // SET DATA for different game variations
  // soccer/hockey/baseball --> single/series/tournament
  let scoreData = {
    team: null,
    teamOneGoals: null,
    teamLogo: null,
    against: null,
    teamTwoGoals: null,
    againstLogo: null,
    firstHalf: true,
    tournamentname: null,
    isWorldSeries: false,
  };

  if (selectedStream) {
    scoreData = fitScoreDataForAllGameOrTournament(
      selectedStream.gameName,
      selectedStream.gameId,
      {
        soccerSingle:
          dataSoccerMatch && dataSoccerMatch.findASoccerMatch
            ? dataSoccerMatch.findASoccerMatch
            : null,
        soccerTournament:
          soccerTournamentMatch &&
          soccerTournamentMatch.findSoccerTournamentMatch
            ? soccerTournamentMatch.findSoccerTournamentMatch
            : null,
        hockeySingle:
          dataHockeyMatch && dataHockeyMatch.findAHockeyMatch
            ? dataHockeyMatch.findAHockeyMatch
            : null,
        hockeyTournament:
          hockeyTournamentMatch &&
          hockeyTournamentMatch.findHockeyTournamentMatch
            ? hockeyTournamentMatch.findHockeyTournamentMatch
            : null,
        baseballSingle:
          dataBaseballMatch && dataBaseballMatch.findAGame
            ? dataBaseballMatch.findAGame
            : null,
        baseballTournament:
          baseballTournament && baseballTournament.findATournament
            ? baseballTournament.findATournament
            : null,
        tournamentStatus: selectedStream.tournamentStatus
          ? selectedStream.tournamentStatus
          : null,
      }
    );
  }

  // Set Agora Audences Data
  let agoraStreamingUsersCount = null;
  if (selectedStream) {
    agoraStreamingUsersCount =
      agoraChannelAudiences.getAgoraChannelAudiences &&
      agoraChannelAudiences.getAgoraChannelAudiences.agoraResponse
        ? JSON.parse(
            agoraChannelAudiences.getAgoraChannelAudiences.agoraResponse
          )
        : null;
  }

  return (
    <>
      {isFullScreenStreaming && (
        <LiveStreamingView
          windowSize={windowSize}
          isFullScreenStreaming={isFullScreenStreaming}
          users={users}
          scoreData={scoreData}
          liveTime={liveTime}
          selectedStream={selectedStream}
          agoraStreamingUsersCount={agoraStreamingUsersCount}
          leaveChannel={leaveChannel}
          setIsFullScreenStreaming={setIsFullScreenStreaming}
          fullScreenForStreaming={fullScreenForStreaming}
          autoIsFullScreen={autoIsFullScreen}
          isShowSoccerAndHockeyGoalCard={isShowSoccerAndHockeyGoalCard}
          selfPlayDetails={
            selfPlayGameData && selfPlayGameData.findASelfPlayMatch
              ? selfPlayGameData.findASelfPlayMatch
              : null
          }
          currentHitPerson={currentHitPerson}
          autoPlayButton={autoPlayButton}
          handleAutoPlayButton={handleAutoPlayButton}
        ></LiveStreamingView>
      )}

      {!isFullScreenStreaming && (
        <CommonBackdrop ref={streamingElementRef}>
          <div className="overflow-x-auto w-72 sm:w-10/12 md:w-11/12 mx-auto">
            <div className="w-full bg-black/20 px-10 h-10 flex items-center flex-row mt-5">
              <h1 className="text-yellow-300">Bases / </h1>
              <h1> Live Stream </h1>
            </div>

            {console.log(
              "--------:::: ",
              findStreamDetails.findMatchStreamById
            )}

            {/* Container */}
            <div className="font-semibold my-auto mt-5">
              {/* Items */}
              <div className="flex justify-between flex-wrap">
                {/* STREAM PLAYER */}
                {/* STREAM PLAYER */}
                <div className="w-full md:w-3/4">
                  <div className="md:mr-5">
                    {/* STREAM PLAYER */}
                    <div>
                      {/* 🔥 🔥 🔥 🔥  STREAM PLAY 🔥 🔥 🔥 🔥  */}
                      <LiveStreamingView
                        windowSize={windowSize}
                        isFullScreenStreaming={isFullScreenStreaming}
                        users={users}
                        scoreData={scoreData}
                        liveTime={liveTime}
                        selectedStream={selectedStream}
                        agoraStreamingUsersCount={agoraStreamingUsersCount}
                        leaveChannel={leaveChannel}
                        setIsFullScreenStreaming={setIsFullScreenStreaming}
                        fullScreenForStreaming={fullScreenForStreaming}
                        autoIsFullScreen={autoIsFullScreen}
                        isShowSoccerAndHockeyGoalCard={
                          isShowSoccerAndHockeyGoalCard
                        }
                        selfPlayDetails={
                          selfPlayGameData &&
                          selfPlayGameData.findASelfPlayMatch
                            ? selfPlayGameData.findASelfPlayMatch
                            : null
                        }
                        currentHitPerson={currentHitPerson}
                        autoPlayButton={autoPlayButton}
                        handleAutoPlayButton={handleAutoPlayButton}
                      ></LiveStreamingView>
                    </div>

                    <div
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      {/* STREAM Info + SHARE button */}
                      <div
                        style={{
                          marginTop: "15px",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* STREAM info */}
                        <div
                          style={{
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ marginRight: "10px" }}>
                            <img
                              src={
                                RANDOM_AVATAR_URLS[
                                  findStreamDetails.findMatchStreamById
                                    ?.userDetails?.avatar
                                ]
                                  ? RANDOM_AVATAR_URLS[
                                      findStreamDetails.findMatchStreamById
                                        ?.userDetails?.avatar
                                    ]
                                  : RANDOM_AVATAR_URLS["user4_asset4"]
                              }
                              alt="Basement Sports"
                              className={`md:block w-[90px] mr-2`}
                              style={{ width: "40px", height: "40px" }}
                            />
                          </div>

                          <div style={{}}>
                            <div
                              className="pb-0"
                              style={{
                                color: "white",
                                fontSize: "20px",
                                fontWeight: "bolder",
                              }}
                            >
                              {findStreamDetails.findMatchStreamById.caption}
                            </div>
                            {findStreamDetails.findMatchStreamById
                              ?.createdAt && (
                              <div
                                className="pb-0"
                                style={{
                                  fontSize: "13px",
                                  color: "#aaaaaa",
                                  fontWeight: "bold",
                                }}
                              >
                                {formatDistance(
                                  new Date(),
                                  new Date(
                                    Number(
                                      findStreamDetails.findMatchStreamById
                                        .createdAt
                                    )
                                  )
                                )}{" "}
                                ago
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* SHARE */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <EmailShareButton
                          url={`${window.location.href}`}
                          separator={"\n"}
                          className="mr-2"
                        >
                          <EmailIcon size={32} round />
                        </EmailShareButton>
                        <WhatsappShareButton
                          url={`${window.location.href}`}
                          separator=":: "
                          className="mr-2"
                        >
                          <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        <TelegramShareButton
                          url={`${window.location.href}`}
                          className="mr-2"
                        >
                          <TelegramIcon size={32} round />
                        </TelegramShareButton>
                        <FacebookShareButton
                          url={`${window.location.href}`}
                          className="mr-2"
                        >
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={window.location.href}
                          className="mr-2"
                        >
                          <TwitterIcon size={32} round />
                        </TwitterShareButton>
                        <button
                          onClick={() =>
                            copyToClipboard(`${window.location.href}`)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faCopy}
                            size="2x"
                            className="text-sm font-bold py-1 px-1 rounded-full max-h-7"
                            style={{
                              backgroundColor: "#0000008a",
                              color: "#FFFFFF",
                            }}
                            fade={true}
                          />
                        </button>
                      </div>
                    </div>

                    {/* SPACER */}
                    <div
                      style={{
                        height: "0px",
                        borderTop: "1px solid #5252526e",
                        marginTop: "15px",
                      }}
                    ></div>
                  </div>
                </div>

                {/* OTHER STREAMS */}
                {/* OTHER STREAMS */}
                <div className="w-full md:w-1/4">
                  {findAllStreamsLoading && (
                    <div style={{ display: "block", margin: "auto" }}>
                      <DataLoading loadingType="success" />
                    </div>
                  )}
                  {!findAllStreamsLoading &&
                    findAllStreams.findAllStreamsByFilter.length > 0 && (
                      <div>
                        <div
                          className="mb-2"
                          style={{ fontWeight: "bold", fontSize: "20px" }}
                        >
                          More Streams
                        </div>
                        {findAllStreams.findAllStreamsByFilter.map(
                          (stream, index) => {
                            return (
                              <div
                                className="mb-3"
                                style={{
                                  border:
                                    streamId == stream._id
                                      ? "1px solid #808037a3"
                                      : null,
                                  borderRadius:
                                    streamId == stream._id ? "10px" : null,
                                }}
                              >
                                <div
                                  className="cursor-pointer"
                                  style={{
                                    position: "relative",
                                    borderRadius: "10px",
                                    overflow: "hidden",
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/${LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG}/stream/${stream._id}?isFullScreen=false`
                                    )
                                  }
                                >
                                  {/* <div className="rounded-lg py-1 px-2 lg:px-6 shadow-md shadow-black/30 my-3"> */}
                                  <img
                                    src={
                                      "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
                                    }
                                    alt="Basement Sports"
                                    className={`md:block w-[90px] object-cover `}
                                    style={{ width: "100%", height: "130px" }}
                                  />

                                  {/* PLAY ICON */}
                                  <div
                                    style={{
                                      color: "yellow",
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      height: "80%",
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faCirclePlay}
                                      size="3x"
                                    />
                                  </div>

                                  {/* stream details */}
                                  <div
                                    style={{
                                      backgroundColor: "#00000033",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    className="px-2 py-2"
                                  >
                                    {/* left panel */}
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {/* avatar */}
                                      <img
                                        src={
                                          RANDOM_AVATAR_URLS[
                                            findStreamDetails
                                              .findMatchStreamById?.userDetails
                                              ?.avatar
                                          ]
                                            ? RANDOM_AVATAR_URLS[
                                                findStreamDetails
                                                  .findMatchStreamById
                                                  ?.userDetails?.avatar
                                              ]
                                            : RANDOM_AVATAR_URLS["user4_asset4"]
                                        }
                                        alt="Basement Sports"
                                        className={`md:block w-[90px] mr-2`}
                                        style={{
                                          width: "35px",
                                          height: "35px",
                                        }}
                                      />
                                      {/* stream caption & base name */}
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {/* stream caption */}
                                        <div
                                          className="text-sm"
                                          style={{
                                            fontWeight: "700",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            minWidth: "50px",
                                            maxWidth: "140px",
                                          }}
                                        >
                                          {stream.caption}
                                        </div>
                                        {/* base name */}
                                        <div
                                          className="text-xs"
                                          style={{ opacity: "0.5" }}
                                        >
                                          @{"base"}
                                        </div>
                                      </div>
                                    </div>

                                    {/* right panel */}
                                    <div style={{ display: "flex" }}>
                                      <div
                                        style={{
                                          opacity: "0.5",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {formatDistanceToNow(
                                          Number(stream.createdAt)
                                        )}{" "}
                                        ago
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </CommonBackdrop>
      )}
    </>
  );
};

export default StreamPlay;
