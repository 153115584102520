import { gql } from "@apollo/client";

export default gql`
  query findBaseDetails($baseSlug: String, $baseId: String) {
    findBaseDetails(baseSlug: $baseSlug, baseId: $baseId) {
      _id
      adminIds
    }
  }
`;
