import { gql } from "@apollo/client";

export default gql`
  query findMyNftHistory($userId: String!) {
    findMyNftHistory(userId: $userId) {
      _id
      paymentFor
      paymentBy
      giftReceiverUserId
      paymentMethod
      paidAmount
      paymentStatus
      nftIdsDetails {
        _id
        url
        nftName
        mintByWalletAddress
        openSeaParmaLink
      }
      createdAt
    }
  }
`;
