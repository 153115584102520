import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import SEND_MESSAGE from "../../../gql/mutation/contact/sendMessage.mutaion";
// import contactImg from "images/contact.jpg";
import { useQuery, useMutation } from "@apollo/client";
import { ToastContainer, toast } from "react-toastify";
import { FaCheckCircle } from "react-icons/fa";
import { format } from "date-fns";

// Mutations
import SAVE_BASE_CONTACT_INFO from "../../gql/mutation/base/saveBaseContactInfo.mutation";

import { CONTACT_FORM_TYPES, LOCAL_STORAGE_KEYS } from "../../utils/constant";

// Query
import FIND_BASE_EVENTS from "../../gql/query/base/findBaseDetails.query";
import CommonAuth from "../../component/auth/CommonAuth";
import DataLoading from "../../component/loader/DataLoading";
import CommonBackdrop from "../../component/common/CommonBackdrop";

const Registration = ({ match }) => {
  const contactImg = "/images/contact.jpg";
  // Mutations
  const [saveBaseContactInfo, { error: saveBaseContactInfoError }] =
    useMutation(SAVE_BASE_CONTACT_INFO);

  // Get the eventId from URL to join
  const { baseSlug, eventId } = useParams();
  const navigate = useNavigate();

  // Query
  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_EVENTS, {
    variables: {
      baseSlug,
    },
  });

  const [name, setName] = useState(null);
  const [childName, setChildName] = useState(null);
  const [email, setEmail] = useState(null);
  const [kidGender, setKidGender] = useState("");
  const [game, setGame] = useState([]);
  const [gurdian, setGurdian] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  const [submissionState, setSubmissionState] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);

  useEffect(() => {
    function setEventIdFromSlug() {
      setSelectedEventId(eventId);
    }
    setEventIdFromSlug();
    baseRefetch();
  }, []);

  const sports = [
    "Tennis",
    "Pickleball",
    "Basketball",
    "Hockey",
    "Volleyball",
    "Baseball",
    "Cricket",
    "Soccer",
    "Football",
  ];

  const relation = [
    "Father",
    "Mother",
    "Uncle",
    "Grandparent",
    "Coach",
    "Teacher",
    "Aunt",
    "Sibling",
    "Friend",
    "Cousin",
  ];

  const handleSports = (sportName) => {
    if (game.includes(sportName)) {
      setGame(game.filter((sport) => sport !== sportName));
    } else {
      setGame([...game, sportName]);
    }
  };

  async function submitContactInfo() {
    if (name == "" || !name) {
      toast.error("Please enter your full name!");
      return;
    } else if (email == "" || !email) {
      toast.error("Please enter your email or phone number!");
      return;
    } else if (childName == "" || !childName) {
      toast.error("Please enter your child's name!");
      return;
    } else if (kidGender == "" || !kidGender) {
      toast.error("Please select child's gender!");
      return;
    } else if (!game || game.length == 0) {
      toast.error(
        "Please choose the sports that your child is most interested in!"
      );
      return;
    } else if (gurdian == "" || !gurdian) {
      toast.error("Please select your relationship with the child!");
      return;
    }

    setLoadingState(true);
    console.log(name, email, childName, kidGender, game, gurdian);
    const { data } = await saveBaseContactInfo({
      variables: {
        name,
        email,
        contactType: CONTACT_FORM_TYPES.CONTACT_TO_BASE_OWNER,
        baseSlug,
        eventId,
        childName,
        kidGender,
        sports: game,
        relation: gurdian,
      },
    });

    if (data.saveBaseContactInfo.errorMessage == null) {
      toast.success("Successfully registered!");
      setLoadingState(false);
      // Show Thanku state
      // setSubmissionState(true);

      console.log("TTTTTTTTT: ", data.saveBaseContactInfo.user);

      // SET some login data to make the user as a logged in user
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER_ID,
        data.saveBaseContactInfo.user._id
      );
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER_NAME,
        data.saveBaseContactInfo.user.name
      );
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.USER_EMAIL,
        data.saveBaseContactInfo.user.email
      );
      if (data.saveBaseContactInfo.user?.kidCoinToken) {
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.USER_KIDCOIN_TOKEN,
          data.saveBaseContactInfo.user?.kidCoinToken
        );
      }
      if (data.saveBaseContactInfo.user?.affiliateCouponCode) {
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.USER_AFFILIATE_COUPON_CODE,
          data.saveBaseContactInfo.user?.affiliateCouponCode
        );
      }
      // Add iuser type in Local Storage
      if (data.saveBaseContactInfo.user?.userType) {
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.USER_TYPE,
          data.saveBaseContactInfo.user?.userType
        );
      }
      // Set Base ID in Local Storage
      if (
        data.saveBaseContactInfo.user?.baseInfo &&
        data.saveBaseContactInfo.user?.baseInfo._id
      ) {
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
          data.saveBaseContactInfo.user?.baseInfo._id
        );
      }

      // NAVIGATE to home page
      navigate("/");
    } else {
      toast.error(data.saveBaseContactInfo.errorMessage);
      setLoadingState(false);
    }
  }

  // Days OF Week
  const startDate = new Date();
  const daysOfWeek = [...Array(7)].map((_, index) => {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + index);
    return date;
  });

  // Select an Event
  async function handleEventSelection(event) {
    navigate(`/${baseSlug}/${event._id}`);
    setSelectedEventId(event._id);
    setSubmissionState(false);
  }

  if (baseLoading)
    return (
      <CommonBackdrop>
        <div className="mt-20">
          <DataLoading loadingType="success" />
        </div>
      </CommonBackdrop>
    );
  if (baseError) return <div>Sorry something wrong</div>;

  return (
    <CommonAuth>
      <div className=" lg:flex lg:justify-center">
        <div className="w-full  flex justify-center">
          {submissionState ? (
            <div className="flex flex-col items-center">
              <div
                style={{ backgroundColor: "black", opacity: 0.6 }}
                className="rounded-lg shadow-lg p-8 flex flex-col items-center justify-center mx-10"
              >
                <FaCheckCircle className="text-green-500 text-8xl" />
                <div className="text-white text-2xl mt-8 text-center">
                  Thank you for registering to be part of the very first
                  Basement Sports Super Base! You will receive an email and
                  follow-up with instructions for how to take the next steps in
                  securing your Membership. In the meantime, mark your calendar
                  for the big grand opening on{" "}
                  <b style={{ color: "yellow" }}>
                    September 13, 2023 @ 3pm ET in Jersey City
                  </b>
                  !
                </div>
              </div>
            </div>
          ) : (
            <div className="relative  z-10 px-[30px] ">
              <div className="px-2 py-5   ">
                <h1 className="text-end text-white font-display font-bold mb-4 text-4xl">
                  Join our Super Base
                </h1>
                <input
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="bg-transparent border-yellow-300 focus:border-yellow-300 text-white font-bold font-display w-full py-2 px-5 rounded-full mb-4"
                  name="firstname"
                  placeholder="Your Full Name"
                />

                {/* EMAIL */}
                {/* EMAIL */}
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="bg-transparent border-yellow-300 focus:border-yellow-300 text-white font-bold font-display w-full py-2 px-5 rounded-full mb-4"
                  name="email"
                  placeholder="Email Or Phone Number With Country Code"
                  style={{ textTransform: "lowercase" }}
                />

                {/* CHILD NAME */}
                {/* CHILD NAME */}
                <input
                  onChange={(e) => setChildName(e.target.value)}
                  type="text"
                  className="bg-transparent border-yellow-300 focus:border-yellow-300 text-white font-bold font-display w-full py-2 px-5 rounded-full mb-4"
                  name="childname"
                  placeholder="Your Child's Name"
                />

                {/* GENDER */}
                {/* GENDER */}
                <div class="flex items-center ml-2">
                  <div className="flex items-center">
                    <div
                      onClick={() => setKidGender("male")}
                      className={`box-border h-4 w-4 border-2 border-yellow-300 ${
                        kidGender === "male" ? "bg-yellow-300" : null
                      }`}
                    />
                    <label for="inline-radio" class="mx-2 text-sx font-small ">
                      Boy
                    </label>
                  </div>

                  <div className="flex items-center">
                    <div
                      onClick={() => setKidGender("female")}
                      className={`box-border h-4 w-4 border-2 border-yellow-300 ${
                        kidGender === "female" ? "bg-yellow-300" : null
                      }`}
                    />
                    <label for="inline-radio" class="mx-2 text-sx font-small ">
                      Girl
                    </label>
                  </div>
                </div>

                {/* RELATION */}
                {/* RELATION */}
                <div className="my-3">
                  <p className="ml-2 mb-1 text-md font-display ">
                    What is your Relationship with the child?
                  </p>
                  <div className="content-start">
                    {relation.map((item, index) => (
                      <button
                        key={index}
                        onClick={() => setGurdian(item)}
                        className={`${
                          gurdian == item ? "bg-black/60" : "bg-black/20"
                        } text-white  cursor-pointer m-1 px-3 py-1 rounded-full`}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </div>

                {/* SPORTS */}
                {/* SPORTS */}
                <div className="my-3">
                  <p className="ml-2 mb-1 text-md font-display ">
                    What sports is the child interested in?
                  </p>
                  <div className="content-start">
                    {sports.map((item, index) => (
                      <button
                        key={index}
                        onClick={() => handleSports(item)}
                        className={`${
                          game.includes(item) ? "bg-black/60" : "bg-black/20"
                        }  text-white   cursor-pointer m-1 px-3 py-1 rounded-full`}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </div>

                {loadingState ? (
                  <button
                    type="submit"
                    className="w-full bg-yellow-300 hover:bg-transparent flex items-center justify-center rounded-full border-2 border-yellow-300 mb-4 py-2 px-8 text-center text-gray-700 duration-500 ease-in-out hover:text-yellow-300 font-extrabold font-display "
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={() => submitContactInfo()}
                    className="w-full hover:bg-yellow-300  flex items-center justify-center rounded-full border-2 border-yellow-300 mb-4 py-2 px-8 text-center hover:text-gray-700 duration-500 ease-in-out text-yellow-300 font-extrabold font-display "
                  >
                    JOIN
                  </button>
                )}

                <button
                  //   type="submit"
                  className="w-full hover:bg-yellow-300 hover:bg-transparent flex items-center justify-center rounded-full border-2 border-yellow-300 mb-4 py-2 px-8 text-center hover:text-gray-700 duration-500 ease-in-out text-yellow-300 font-extrabold font-display "
                >
                  <Link to="/login">ALREADY HAVE AN ACCOUNT</Link>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </CommonAuth>
  );
};

export default Registration;
