import gql from "graphql-tag";

export default gql`
  mutation addBaseSubAdmin($userId: String, $email: String, $baseSlug: String) {
    addBaseSubAdmin(userId: $userId, email: $email, baseSlug: $baseSlug) {
      _id
      name
      errorMessage
    }
  }
`;
