import gql from "graphql-tag";

export default gql`
  mutation enrollKidsUsingSlot(
    $parentId: String
    $eventId: String
    $kidIds: [String]
  ) {
    enrollKidsUsingSlot(
      parentId: $parentId
      eventId: $eventId
      kidIds: $kidIds
    ) {
      successMessage
      errorMessage
    }
  }
`;
