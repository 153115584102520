import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LOG_IN from "../../../gql/mutation/auth/logIn.mutation";
import CommonAuth from "../../../component/auth/CommonAuth";
import { LOCAL_STORAGE_KEYS, USER_TYPE } from "../../../utils/constant";

export default function Login() {
  const location = useLocation();
  const data = location.state;
  let comeFrom = null;
  if (data) {
    comeFrom = data.comeFrom;
  }
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const USER = "userid";
  const TOKEN = "auth_token";
  const USER_NAME = "bsports_user_name";

  //mutation
  const [logIn, { loading, error }] = useMutation(LOG_IN);

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    // Valid format:  example@something.com, example@something.io
  }

  function validatePhone(phone) {
    // let reg = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{6})$/
    let reg = /^\+?\d+$/;
    let isphone = email.match(reg);

    if (isphone) {
      return true;
    } else {
      return false;
    }
  }

  async function logInFunc() {
    if (email != null && password != null) {
      // Email validation check
      const checkMail = validateEmail(email);
      const checkPhone = validatePhone(email);
      if (!checkMail && !checkPhone) {
        window.alert("error", "You typed invalid mail");
        return null;
      }

      const { data } = await logIn({
        variables: { email, password },
      });

      if (data.logIn.errorMessage) {
        window.alert(`${data.logIn.errorMessage}`);
      } else {
        localStorage.setItem(USER, data.logIn.user._id);
        localStorage.setItem(USER_NAME, data.logIn.user.name);
        localStorage.setItem("user_email", data.logIn.user.email);
        if (data.logIn.user.kidCoinToken) {
          localStorage.setItem("kidcointoken", data.logIn.user.kidCoinToken);
        }
        if (data.logIn.user.affiliateCouponCode) {
          localStorage.setItem(
            "affiliateCouponCode",
            data.logIn.user.affiliateCouponCode
          );
        }
        // Add iuser type in Local Storage
        if (data.logIn.user.userType) {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.USER_TYPE,
            data.logIn.user.userType
          );
        }
        // Set Base ID in Local Storage
        if (data.logIn.user.baseInfo && data.logIn.user.baseInfo._id) {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.SUPER_BASE_ID,
            data.logIn.user.baseInfo._id
          );
        }

        if (comeFrom) {
          navigate(comeFrom);
        } else {
          navigate("/");
        }
        // window.location.reload("/");
      }
    } else {
      window.alert("Please fill in email & password");
    }
  }

  useEffect(() => {
    if (localStorage.getItem("userid")) {
      if (comeFrom) {
        navigate(comeFrom);
      } else {
        navigate("/");
      }
    }
  }, []);

  return (
    <CommonAuth>
      <div className="sm:mt-[30px] ">
        <div className=" rounded-lg w-full ">
          {/* <h1 className="text-center text-yellow-300 font-display font-bold mb-6 text-4xl">
            Log In
          </h1> */}

          <h1 className="text-end text-white font-display font-bold mb-7 text-4xl mt-10">
            Sign in to your {comeFrom === "/admin" ? "Admin" : null} Account
          </h1>

          <input
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            className=" bg-transparent border-yellow-300 focus:border-yellow-300 text-white font-bold font-display w-full py-2 px-5 rounded-full mb-4"
            name="email"
            placeholder={email ? email : "Email"}
            style={{ textTransform: "lowercase" }}
          />

          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            className=" bg-transparent border-yellow-300 focus:border-yellow-300 text-white font-bold font-display w-full py-2 px-5 rounded-full mb-4"
            name="password"
            placeholder={password ? password : "Password"}
            style={{ textTransform: "lowercase" }}
          />
          <div className="mb-6 flex items-center justify-between">
            <div className="flex items-center min-h-[1.5rem] pl-3">
              {/* <input
                id="checked-checkbox"
                type="checkbox"
                value=""
                className="w-4 h-4 text-yellow-300  rounded focus:ring-yellow-300 ring-offset-gray-700 focus:ring-2"
              />
              <label
                for="checked-checkbox"
                className="ml-2 text-md font-medium text-yellow-300"
              >
                Checked state
              </label> */}
            </div>

            <Link
              className=" text-yellow-300 text-md font-bold font-display"
              to={"/forget-password"}
              state={comeFrom ? { comeFrom: "/admin" } : null}
            >
              Forgot password?
            </Link>
          </div>

          <button
            onClick={() => (!loading ? logInFunc() : null)}
            type="submit"
            className="w-1/2 mx-auto bg-black  flex items-center justify-center rounded-full  mb-4 py-4 px-8 text-center text-white duration-500 ease-in-out  font-extrabold font-display "
          >
            {!loading ? "Sign In" : "Wait..."}
          </button>
        </div>

        {comeFrom != "/admin" && (
          <div className="text-white text-sm text-center  mt-6 font-display">
            Don't Have An Account?{" "}
            <Link
              to={"/sign-up"}
              className="underline text-yellow-300 font-bold"
            >
              Register
            </Link>
            .
          </div>
        )}
      </div>
    </CommonAuth>
  );
}
