import gql from "graphql-tag";

export default gql`
  mutation createKidByParent(
    $parentId: String!
    $childName: String!
    $kidGender: String!
    $sports: [String]!
    $relation: String!
    $dateOfBirth: String
  ) {
    createKidByParent(
      parentId: $parentId
      childName: $childName
      kidGender: $kidGender
      sports: $sports
      relation: $relation
      dateOfBirth: $dateOfBirth
    ) {
      successMessage
      errorMessage
    }
  }
`;
