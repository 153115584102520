import React, { useEffect, useState, useRef } from "react";
import "video-react/dist/video-react.css";

export function PhotoCarousel() {
  return React.memo(function (props) {
    const { data, dataIndex } = props;
    const { url, caption, baseDetails } = data[dataIndex];

    // Window SIZE STATE
    const [windowSize, setWindowSize] = useState(getWindowSize());

    // WINDOW SCREEN SIZE CHANGE
    useEffect(() => {
      function handleWindowResize() {
        // Commented the Screen REALOAD on SCREEN size change just for now 👇👇👇
        setWindowSize(getWindowSize());
        // leaveChannel();
        // window.location.reload();
      }
      window?.addEventListener("resize", handleWindowResize);

      return () => {
        window?.removeEventListener("resize", handleWindowResize);
      };
    }, []);

    function getWindowSize() {
      const { innerWidth, innerHeight } = window;
      return { innerWidth, innerHeight };
    }

    return (
      <div
        style={{
          width: "100%",
          height: windowSize.innerHeight - 200,
          backgroundColor: "#46536d5c",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: "0px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "10px",
          borderRadius: "10px",
        }}
        className="my-slide-component slide-image-container"
      >
        <h2
          style={{
            color: "white",
            fontFamily: "sans-serif",
            fontWeight: "bold",
            marginTop: "7px",
            marginLeft: "10px",
            marginRight: "10px",
            marginBottom: "5px",
            textAlign: "center",
          }}
          className="text-3xl"
        >
          {caption}
        </h2>
        <div
          style={{
            width: "100%",
            height: windowSize.innerHeight - 200,
            display: "flex",
            justifyContent: "center",
            // padding: "20px",
          }}
        >
          <img
            style={{
              maxWidth: "100%",
              height: "100%",
              // maxHeight: "94%",
              marginBottom: "10px",
              objectFit: "contain",
              // borderRadius: 0,
              borderRadius: "5px",
            }}
            draggable={false}
            src={url}
          />
        </div>
      </div>
    );
  });
}

// STYLE
const style = {};
