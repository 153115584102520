const WOMEN_NFT_COUNTRIES = [
  {
    name: "New Zealand",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611384/country/New%20Zealand.png",
  },
  {
    name: "Norway",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611384/country/Norway.png",
  },

  {
    name: "Philippines",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611385/country/Philippines.png",
  },
  {
    name: "Switzerland",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611383/country/Switzerland.png",
  },
  {
    name: "Australia",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Australia.png",
  },
  {
    name: "Ireland",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/Ireland.png",
  },
  {
    name: "Nigeria",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611383/country/Nigeria.png",
  },
  {
    name: "Canada",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Canada.png",
  },
  {
    name: "Spain",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/Spain.png",
  },
  {
    name: "Costa Rica",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/Costa%20Rica.png",
  },
  {
    name: "Zambia",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611386/country/Zambia.png",
  },
  {
    name: "Japan",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Japan.png",
  },
  {
    name: "England",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/England.png",
  },
  {
    name: "Haiti",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611385/country/Haiti.png",
  },
  {
    name: "Denmark",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/Denmark.png",
  },
  {
    name: "China",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611381/country/China.png",
  },
  {
    name: "USA",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611383/country/USA.png",
  },
  {
    name: "Vietnam",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611385/country/Vietnam.png",
  },
  {
    name: "Netherlands",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611381/country/Netherlands.png",
  },
  {
    name: "Portugal",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611381/country/Portugal.png",
  },
  {
    name: "France",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/France.png",
  },
  {
    name: "Jamaica",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611384/country/Jamaica.png",
  },
  {
    name: "Brazil",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Brazil.png",
  },
  {
    name: "Panama",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611385/country/Panama.png",
  },
  {
    name: "Sweden",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611385/country/Sweden.png",
  },
  {
    name: "South Africa",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611385/country/South%20Africa.png",
  },
  {
    name: "Italy",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611384/country/Italy.png",
  },
  {
    name: "Argentina",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Argentina.png",
  },
  {
    name: "Germany",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/Germany.png",
  },
  {
    name: "Morocco",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Morocco.png",
  },
  {
    name: "Colombia",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/Colombia.png",
  },
  {
    name: "South Korea",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/South%20Korea.png",
  },
];

const NFT_COUNTRIES = [
  {
    name: "Qatar",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611381/country/Qatar.png",
  },
  {
    name: "Ecuador",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Ecuador.png",
  },
  {
    name: "Senegal",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/Senegal.png",
  },
  {
    name: "Netherlands",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611381/country/Netherlands.png",
  },
  {
    name: "England",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/England.png",
  },
  {
    name: "Iran",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/Iran.png",
  },
  {
    name: "USA",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611383/country/USA.png",
  },
  {
    name: "Wales",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611384/country/Wales.png",
  },
  {
    name: "Argentina",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Argentina.png",
  },
  {
    name: "Saudi Arabia",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/Saudi%20Arabia.png",
  },
  {
    name: "Mexico",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Mexico.png",
  },
  {
    name: "Poland",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611381/country/Poland.png",
  },
  {
    name: "France",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/France.png",
  },
  {
    name: "Australia",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Australia.png",
  },
  {
    name: "Denmark",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/Denmark.png",
  },
  {
    name: "Tunisia",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611384/country/Tunisia.png",
  },
  {
    name: "Spain",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/Spain.png",
  },
  {
    name: "Costa Rica",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/Costa%20Rica.png",
  },
  {
    name: "Germany",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/Germany.png",
  },
  {
    name: "Japan",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Japan.png",
  },
  {
    name: "Belgium",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Belgium.png",
  },
  {
    name: "Canada",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Canada.png",
  },
  {
    name: "Portugal",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611381/country/Portugal.png",
  },
  {
    name: "Uruguay",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611383/country/Uruguay.png",
  },
  {
    name: "Brazil",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Brazil.png",
  },
  {
    name: "Cameroon",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Cameroon.png",
  },
  {
    name: "Croatia",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Croatia.png",
  },
  {
    name: "Ghana",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Ghana.png",
  },
  {
    name: "Morocco",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Morocco.png",
  },
  {
    name: "Serbia",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611383/country/Serbia.png",
  },
  {
    name: "South Korea",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/South%20Korea.png",
  },
  {
    name: "Switzerland",
    url: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611383/country/Switzerland.png",
  },
];

const CART_COUNTRIES = {
  Qatar:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611381/country/Qatar.png",
  Ecuador:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Ecuador.png",
  Senegal:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/Senegal.png",
  Iran: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/Iran.png",
  Wales:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611384/country/Wales.png",
  "Saudi Arabia":
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/Saudi%20Arabia.png",
  Mexico:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Mexico.png",
  Poland:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611381/country/Poland.png",
  Tunisia:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611384/country/Tunisia.png",
  Belgium:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Belgium.png",
  Uruguay:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611383/country/Uruguay.png",
  Cameroon:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Cameroon.png",
  Croatia:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Croatia.png",
  Ghana:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Ghana.png",
  Serbia:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611383/country/Serbia.png",

  "New Zealand":
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611384/country/New%20Zealand.png",
  Norway:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611384/country/Norway.png",
  Philippines:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611385/country/Philippines.png",
  Switzerland:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611383/country/Switzerland.png",
  Australia:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Australia.png",
  Ireland:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/Ireland.png",
  Nigeria:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611383/country/Nigeria.png",
  Canada:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Canada.png",
  Spain:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/Spain.png",
  "Costa Rica":
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/Costa%20Rica.png",
  Zambia:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611386/country/Zambia.png",
  Japan:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Japan.png",
  England:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/England.png",
  Haiti:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611385/country/Haiti.png",
  Denmark:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/Denmark.png",
  China:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611381/country/China.png",
  USA: "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611383/country/USA.png",
  Vietnam:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611385/country/Vietnam.png",
  Netherlands:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611381/country/Netherlands.png",

  Portugal:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611381/country/Portugal.png",

  France:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/France.png",

  Jamaica:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611384/country/Jamaica.png",

  Brazil:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Brazil.png",

  Panama:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611385/country/Panama.png",

  Sweden:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611385/country/Sweden.png",

  "South Africa":
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611385/country/South%20Africa.png",

  Italy:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611384/country/Italy.png",

  Argentina:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611378/country/Argentina.png",

  Germany:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611379/country/Germany.png",

  Morocco:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611380/country/Morocco.png",

  Colombia:
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/Colombia.png",

  "South Korea":
    "https://res.cloudinary.com/dvqldxdfv/image/upload/v1692611382/country/South%20Korea.png",
};

export { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES, CART_COUNTRIES };
