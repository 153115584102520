import React from "react";
import AdminPortal from "./AdminPortal";

function Groups() {
  return (
    <AdminPortal>
      <div>Hellow</div>
    </AdminPortal>
  );
}
export default Groups;
