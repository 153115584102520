import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import AdminPortal from "./AdminPortal";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { LOCAL_STORAGE_KEYS } from "../../utils/constant";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faUsers,
  faUser,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import __ from "lodash";
import iconFinder from "../../utils/iconFinder";

// Query
import FIND_BASE_PLAYERS from "../../gql/query/base/findBasePlayers.query";
import FIND_A_EVENT from "../../gql/query/base/findEvent.query";

// MUTATION
import ADD_EVENT_PARTICIPANT from "../../gql/mutation/base/addEventParticipantByAdmin.mutations";

function AddEventParticipants() {
  const navigate = useNavigate();
  const { eventId } = useParams();

  // 2 Main STATE of Data (Kids with Parents & the users who have no kids yet)
  const [kidsWithParent, setKidsWithParents] = useState([]);
  const [kidsWithoutParent, setKidsWithoutParent] = useState([]);

  // 2 Main STATE of Data (Kids with Parents & the users who have no kids yet)
  const [kidsWithParentToShow, setKidsWithParentsToShow] = useState([]);
  const [kidsWithoutParentToShow, setKidsWithoutParentToShow] = useState([]);

  // Image state
  const [searchName, setSearchName] = useState(null);
  const [playersData, setPlayersData] = useState([]);

  // Add Member Form State
  const [kids, setKids] = useState([]);
  const [loadingUserId, setLoadingUserId] = useState(null);

  // Query
  const {
    data: basePlayers,
    loading: basePlayersLoading,
    error: basePlayersError,
    refetch: basePlayersRefetch,
  } = useQuery(FIND_BASE_PLAYERS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_A_EVENT, {
    variables: {
      eventId,
    },
  });

  // Mutations
  const [
    addEventParticipantByAdmin,
    {
      loading: addEventParticipantByAdminLoading,
      error: addEventParticipantByAdminError,
    },
  ] = useMutation(ADD_EVENT_PARTICIPANT);

  // Attach kids with Parents
  useEffect(() => {
    function manipulateBasePlayers() {
      if (basePlayers?.findBasePlayers) {
        const allPlayers = basePlayers?.findBasePlayers;

        // ✅ First array (If the "parentId" exists)
        // ✅ First array (If the "parentId" exists)
        const filteredArray = __.filter(
          allPlayers,
          (obj) =>
            obj.user?.parentId &&
            obj.user.parentId != "" &&
            obj.user.parentId != undefined
        );
        // Group by similar "parentId"
        const groupedArray = __.groupBy(
          filteredArray,
          (obj) => obj.user.parentId
        );
        // Now create an object like this format { "parentId1": { name: "parentId1_name", email: "parentId1_email", kids: all_kids } }
        const resultArray = __.map(groupedArray, (value, key) => {
          const parentDetails = value[0].user.parentDetails;
          const kids = value;
          return {
            _id: parentDetails?._id,
            name: parentDetails?.name,
            email: parentDetails?.email,
            avatar: parentDetails?.avatar,
            role: parentDetails?.role,
            playerId: parentDetails?.player?._id,
            kids: kids,
          };
        });

        // ✅ Second array (If the "parentId" not exists)
        // ✅ Second array (If the "parentId" not exists)
        const secondArray = __.filter(
          allPlayers,
          (player) =>
            !player.user.parentId ||
            player.user.parentId == "" ||
            player.user.parentId == undefined
        );
        // all parent User IDS those have KIDS
        let parentIdsThoseHaveKids = Object.keys(groupedArray);
        // Now remove those already added parents from this second array
        const finalSecondFilteredArray = secondArray.filter(
          (player) => !parentIdsThoseHaveKids.includes(player.user._id)
        );

        // Update STATE
        setKidsWithParents(resultArray);
        setKidsWithoutParent(finalSecondFilteredArray);
        // Update STATE (To Show)
        setKidsWithParentsToShow(resultArray);
        setKidsWithoutParentToShow(finalSecondFilteredArray);
      }
    }
    manipulateBasePlayers();
  }, [basePlayers]);

  // Refetch data on focus
  useEffect(() => {
    function refetchData() {
      basePlayersRefetch();
    }
    refetchData();
  }, []);

  // Check CouponCode Validity
  function searchByName(searchName) {
    if (!searchName || searchName == "") {
      setKidsWithParentsToShow(kidsWithParent);
      setKidsWithoutParentToShow(kidsWithoutParent);
      return;
    }

    // Search by Front-end STATE (For Parents who have KIDS)
    const filteredParentAndKids = JSON.parse(
      JSON.stringify(kidsWithParent)
    ).filter((parent) => {
      // If the name property matches the search string. If it does, we simply return true to keep the object in the output array.
      if (parent.name.toLowerCase().includes(searchName.toLowerCase())) {
        return true;
      } else if (parent.kids && parent.kids.length > 0) {
        /* If the name property doesn’t match, we check if the object has a 'kids' property.
         * If it does, we filter the 'kids' array to find any objects whose 'name' property matches the search string.
         * If we find any matches, we update the 'kids' property of the main object to include only the matching objects,
         * and then return true to keep the object in the output array.
         */
        const matchingKids = parent.kids.filter((kid) =>
          kid.name.toLowerCase().includes(searchName.toLowerCase())
        );
        if (matchingKids.length > 0) {
          parent.kids = matchingKids;
          return true;
        }
      }
      return false;
    });

    // Search by Front-end STATE (For Users who have no KIDS/Parent)
    const filteredNormalUsers = kidsWithoutParent.filter((player) => {
      if (player.name.toLowerCase().includes(searchName.toLowerCase())) {
        return true;
      }
      return false;
    });

    // Update STATE to show
    setKidsWithParentsToShow(filteredParentAndKids);
    setKidsWithoutParentToShow(filteredNormalUsers);
  }

  // ADD MEMBER RELATED METHODS
  const addKid = () => {
    // a function to add a new coupon object to the array
    setKids([
      ...kids,
      {
        name: "",
        // startDate: new Date(),
        // endDate: null,
      },
    ]);
  };

  // a function to remove the kid object from the array by index
  const removeKid = (index) => {
    setKids(kids.filter((_, i) => i !== index));
  };

  // a function to update a coupon object by index, key and value
  const handleChangeKid = (index, key, value) => {
    setKids(
      kids.map((coupon, i) =>
        i === index ? { ...coupon, [key]: value } : coupon
      )
    );
  };

  async function addUserAsParticipant(userId) {
    setLoadingUserId(userId);

    const { data } = await addEventParticipantByAdmin({
      variables: {
        eventId,
        userIdToAdd: userId,
      },
    });

    if (data.addEventParticipantByAdmin.errorMessage == null) {
      toast.success("Successfully added as participant!");
      eventsRefetch();
    } else {
      toast.error(data.addEventParticipantByAdmin.errorMessage);
    }

    setLoadingUserId(null);
  }

  if (basePlayersError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <AdminPortal>
      <div className="bg-slate-700 flex justify-between items-center mb-3 px-5 py-3 mt-8 rounded-md">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="cursor-pointer"
          >
            <FontAwesomeIcon className="mr-3" icon={faCircleArrowLeft} />
          </div>
          <h1 className="text-center font-bold text-2xl ml-7">
            Add Participants
          </h1>
        </div>

        <input
          className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
          placeholder="Search member by name"
          onChange={(e) => {
            searchByName(e.target.value);
          }}
        />
      </div>

      <div className="max-w-6xl mx-auto bg-slate-700 mt-8 pb-7 px-8 mb-8 rounded-md">
        {/* ✅✅✅✅✅ (Users who have a PARENT/KID relation) */}
        {!basePlayersLoading && kidsWithParentToShow.length > 0 && (
          <h1 className="text-center font-bold text-2xl pt-8">
            Base Members (Users who have a Parent/Kid relation)
          </h1>
        )}

        {!basePlayersLoading && kidsWithParentToShow.length > 0 && (
          <h1 className="font-bold text-xl mt-3">
            <b style={{ color: "yellow" }}>{kidsWithParentToShow.length}</b>{" "}
            Parents found
          </h1>
        )}

        <div className="">
          {!basePlayersLoading &&
            kidsWithParentToShow?.map((player, index) => {
              return (
                <div
                  className="mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
                  style={{
                    borderLeft: "5px solid green",
                    justifyContent: "space-between",
                    height: "100%",
                    backgroundColor: "#454f6680",
                    position: "relative",
                  }}
                >
                  {/* Family logo */}
                  <div
                    style={{ position: "absolute", bottom: 8, left: 8 }}
                    data-tooltip-id="tooltip-content"
                    data-tooltip-content={"Parent"}
                    className="cursor-pointer"
                  >
                    <FontAwesomeIcon
                      className="mr-3"
                      icon={faUsers}
                      size="2x"
                      opacity={0.1}
                    />
                  </div>
                  {/* Parent AVATAR + Name */}
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                    }}
                    className="py-2"
                  >
                    {/* Parent AVATAR */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={iconFinder(player.avatar)}
                        alt="Basement Sports"
                        className="max-h-17 cursor-pointer"
                        style={{
                          color: "white",
                          minWidth: "100px",
                          maxWidth: "100px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                        onClick={() => {
                          navigate(`/admin/kid-details/${player.playerId}`);
                        }}
                      />
                    </div>

                    {/* PARENT INFO SECTION */}
                    <div
                      className="ml-5"
                      style={{
                        borderRight: "1px dotted #ffffff45",
                        paddingRight: "60px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        minWidth: "250px",
                        maxWidth: "250px",
                        overflow: "hidden",
                      }}
                    >
                      {/* Parent Name */}
                      <div>{player.name}</div>
                      {/* Parent Email */}
                      <div>{player.email}</div>
                      <div>Role: {player.role}</div>
                    </div>

                    {/* KID DETAILS + KID GAME INFOS */}
                    <div
                      style={{
                        color: "#ccd0e0",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      className="pl-6 text-sm  pt-1 pb-3"
                    >
                      <div className="text-xl font-bold">
                        Kid list -{" "}
                        <b style={{ color: "yellow" }}>
                          {player.kids?.length ? player.kids?.length : 0}
                        </b>{" "}
                        Kids found
                      </div>
                      {/* ALL KIDS */}
                      {/* ALL KIDS */}
                      <div
                        style={{ display: "flex", flexWrap: "wrap" }}
                        className="mt-5"
                      >
                        {player.kids.map((kid) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              minWidth: "100px",
                            }}
                            className="mx-1 mb-2"
                          >
                            {/* KID AVATAR */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={iconFinder(kid.avatar)}
                                alt="Basement Sports"
                                className="max-h-17 cursor-pointer"
                                style={{
                                  color: "white",
                                  minHeight: "50px",
                                  maxHeight: "50px",
                                  paddingLeft: "15px",
                                  paddingRight: "15px",
                                }}
                                onClick={() => {
                                  navigate(`/admin/kid-details/${kid._id}`);
                                }}
                              />
                            </div>

                            {/* KID INFO SECTION */}
                            <div
                              style={{ textAlign: "center" }}
                              className="mt-1"
                            >
                              {/* KID Name */}
                              <div>{kid.name}</div>

                              {/* ADD BUTTON */}
                              {loadingUserId == kid?.userId || eventsLoading ? (
                                <div
                                  className="bg-blue-500 text-white font-bold py-1 px-3 mt-1 rounded"
                                  style={{ opacity: 0.5 }}
                                >
                                  Loading
                                </div>
                              ) : eventsData?.findEvent?.participantKidIds?.includes(
                                  kid?.userId
                                ) ||
                                eventsData?.findEvent?.participantParentIds?.includes(
                                  kid?.userId
                                ) ? (
                                <div
                                  className="bg-yellow-600 text-white font-bold py-1 px-3 mt-1 rounded"
                                  style={{ opacity: 0.5 }}
                                >
                                  Participated
                                </div>
                              ) : (
                                <div
                                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 mt-1 rounded cursor-pointer"
                                  onClick={() => {
                                    addUserAsParticipant(kid?.userId);
                                  }}
                                >
                                  Add
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {/* ✅✅✅✅✅ (Users who have a PARENT/KID relation) */}
        {!basePlayersLoading && kidsWithoutParentToShow.length > 0 && (
          <h1 className="pt-8 text-center font-bold text-2xl">
            Base Members (Users who have no Parent/Kid relation)
          </h1>
        )}

        {!basePlayersLoading && kidsWithoutParentToShow.length > 0 && (
          <h1 className="font-bold text-xl  mt-3">
            <b style={{ color: "yellow" }}>{kidsWithoutParentToShow.length}</b>{" "}
            General users found
          </h1>
        )}

        <div
          className="flex flex-wrap items-center font-display text-yellow-300 text-lg rounded-lg py-2"
          style={{
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          {!basePlayersLoading &&
            kidsWithoutParentToShow?.map((player, index) => {
              return (
                <div
                  style={{
                    height: "100%",
                    position: "relative",
                  }}
                  className="w-full md:w-1/2"
                >
                  {/* Family logo */}
                  <div
                    style={{ position: "absolute", bottom: 15, left: 20 }}
                    data-tooltip-id="tooltip-content"
                    data-tooltip-content={
                      player.user?.verified
                        ? "Verified User"
                        : "Not verified User"
                    }
                    className="cursor-pointer"
                  >
                    <FontAwesomeIcon
                      className="mr-3"
                      icon={player.user?.verified ? faUser : faMobileScreen}
                      size="xl"
                      opacity={0.3}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      backgroundColor: "#454f6680",
                      borderRadius: "5px",
                      borderLeft: `5px solid ${
                        player.user?.verified ? "#0095ff" : "red"
                      }`,
                    }}
                    className="mx-2 my-2 px-3 py-4 shadow-md shadow-black/30"
                  >
                    {/* Parent AVATAR */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={iconFinder(player.avatar)}
                        alt="Basement Sports"
                        style={{
                          color: "white",
                          minWidth: "100px",
                          maxWidth: "100px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                        className="cursor-pointer"
                        onClick={() => {
                          navigate(`/admin/kid-details/${player._id}`);
                        }}
                      />
                    </div>

                    {/* PARENT INFO SECTION */}
                    <div
                      className="ml-5"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        overflow: "hidden",
                      }}
                    >
                      {/* Parent Name */}
                      <div>{player.name}</div>
                      {/* Parent Email */}
                      <div className="text-sm">{player.user.email}</div>
                      {/* ADD BUTTON */}
                      <div className="mt-2">
                        {loadingUserId == player.user?._id || eventsLoading ? (
                          <button
                            className="bg-blue-500 text-white font-bold py-1 px-3 mt-1 rounded"
                            style={{ opacity: 0.5 }}
                          >
                            Loading
                          </button>
                        ) : eventsData?.findEvent?.participantKidIds?.includes(
                            player.user?._id
                          ) ||
                          eventsData?.findEvent?.participantParentIds?.includes(
                            player.user?._id
                          ) ? (
                          <button
                            className="bg-yellow-600 text-white font-bold py-1 px-3 mt-1 rounded"
                            style={{ opacity: 0.5 }}
                          >
                            Participated
                          </button>
                        ) : (
                          <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 mt-1 rounded"
                            onClick={() => {
                              addUserAsParticipant(player.user?._id);
                            }}
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {basePlayersLoading && (
          <div
            className="mt-10 mb-14"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
            }}
          >
            <DataLoading loadingType="success" />
          </div>
        )}
      </div>
    </AdminPortal>
  );
}
export default AddEventParticipants;
