import { gql } from "@apollo/client";

export default gql`
  query findPaymentsOfBase($baseSlug: String, $baseId: String) {
    findPaymentsOfBase(baseSlug: $baseSlug, baseId: $baseId) {
      _id
      paymentMethod
      paymentFor
      paymentBy
      paymentByDetails {
        _id
        name
        email
      }
      nftId
      nftDetails {
        _id
        url
      }
      amountToPay
      paidAmount
      currency
      paymentStatus
      paymentTransactionId
      eventDetails {
        eventType
        dateTime
      }
      subscriptionDate
      createdAt
      errorMessage
    }
  }
`;
