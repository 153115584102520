import gql from "graphql-tag";

export default gql`
  mutation addEventParticipantByAdmin($eventId: String, $userIdToAdd: String) {
    addEventParticipantByAdmin(eventId: $eventId, userIdToAdd: $userIdToAdd) {
      errorMessage
      successMessage
    }
  }
`;
