import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  LOCAL_STORAGE_KEYS,
  PAYMENT_CURRENCY,
  PAYMENT_FOR,
  PAYMENT_METHOD,
  STRIPE_PAYMENT_RESPONSE_STATUSES,
} from "../../../utils/constant";
import { format } from "date-fns";
import DataLoading from "../../../component/loader/DataLoading";
import { useNavigate } from "react-router-dom";
import { computeHeadingLevel } from "@testing-library/react";
import CommonBackdrop from "../../../component/common/CommonBackdrop";
import { Button, Modal } from "flowbite-react";
import { customModal } from "../../../component/theme/customModal";

// Query
import FIND_MY_NFT_HISTORY from "../../../gql/query/nft/findMyNftHistory.query";
import FIND_USER_DETAILS from "../../../gql/query/profile/me.query";
// Mutations
import MAKE_USER_AFFILIATE from "../../../gql/mutation/nft/makeUserAffiliate.mutations";
import INITIATE_STRIPE_SETUP_SESSION from "../../../gql/mutation/base/initiateStripeSetupSession.mutations";

const NftAccount = () => {
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem("userid"));
  const [modal, setModal] = useState(false);
  const [selectedNftImages, setSelectedNftImages] = useState([]);
  const [fetchingData, setFetchingDAta] = useState(false);

  const navigate = useNavigate();
  // CoverImage
  // CoverImage
  const bgImage = "/images/page-title/WWC-NFT-Cover.png";
  // Query
  const {
    data: findMyNftHistory,
    loading: loadingFindMyNftHistory,
    error: errorFindMyNftHistory,
    refetch: refetchFindMyNftHistory,
  } = useQuery(FIND_MY_NFT_HISTORY, {
    variables: {
      userId,
    },
  });
  const {
    data: findUserDetails,
    loading: loadingFindUserDetails,
    error: errorFindUserDetails,
    refetch: refetchFindUserDetails,
  } = useQuery(FIND_USER_DETAILS, {
    variables: {
      _id: userId,
    },
  });

  // Mutations
  const [
    makeAUserAffiliate,
    { loading: loadingMakeAUserAffiliate, error: errorMakeAUserAffiliate },
  ] = useMutation(MAKE_USER_AFFILIATE);
  const [
    initiateStripeSessionMutation,
    {
      loading: initiateStripeSessionMutationLoading,
      error: initiateStripeSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_SETUP_SESSION);

  async function makeUserAffiliate() {
    const { data } = await makeAUserAffiliate({
      variables: {
        userId: localStorage.getItem("userid"),
      },
    });

    if (data.makeUserAffiliate.errorMessage == null) {
      // console.log("Done No error");
      await refetchFindUserDetails();
      localStorage.setItem(
        "affiliateCouponCode",
        data.makeUserAffiliate.affiliateCouponCode
      );
      setSuccessModalShow(true);
      return;
    }
  }

  useEffect(() => {
    if (!localStorage.getItem("userid")) {
      navigate("/login");
    }
  }, []);

  // This function will call backend mutation to create a stripe session URL. After successfully getting the STRIPE URL we will navigate the user to that url
  async function initiateStripeCheckoutUi() {
    // Call the Mutation to get STRIPE session URL
    const { data } = await initiateStripeSessionMutation({
      variables: {
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      },
    });
    console.log("Session data: ", data);
    if (data.initiateStripeSetupSession.errorMessage == null) {
      window.location.href = data.initiateStripeSetupSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
    }
  }

  if (loadingFindMyNftHistory || loadingFindUserDetails || fetchingData) {
    return (
      <CommonBackdrop>
        <DataLoading loadingType="success" />
      </CommonBackdrop>
    );
  }

  return (
    <CommonBackdrop>
      {/* <!-- Login --> */}
      <div className="font-display  py-10 lg:py-20">
        <div className="lg:w-11/12 md:px-5 lg:px-0 lg:flex lg:justify-between mx-auto">
          {/* <!-- Left --> */}
          {/* <!-- Left --> */}
          <div className=" flex justify-center lg:text-start relative z-10 ">
            <div className="w-72 sm:w-96  p-3">
              <div className="mb-6">
                <h3 className="font-bold lg:text-start text-2xl text-white">
                  NFT Account
                </h3>

                {/* <img
                  src="/images/avatars/asset5.png"
                  className="h-16 w-16 bg-accent rounded-full"
                  alt="user-profile"
                /> */}
              </div>
              <div className="text-ellipsis break-words">
                {findUserDetails.me.publicWalletAddresses.length > 0 && (
                  <p className="text-lg mb-3 text-white">
                    {findUserDetails.me.name}'s ETH Wallet Addresses:{" "}
                    <span className="text-yellow-300">
                      {" "}
                      {findUserDetails.me.publicWalletAddresses.map(
                        (address) => (
                          <p>{address}</p>
                        )
                      )}
                    </span>
                  </p>
                )}
                {localStorage.getItem("affiliateCouponCode") ? (
                  <>
                    <p className="text-lg mb-3 text-white">
                      Your Affiliate Coupon Code is:{" "}
                      <span className="text-yellow-300">
                        {" "}
                        {localStorage.getItem("affiliateCouponCode")}
                      </span>
                    </p>
                    {findUserDetails.me.affiliateInfoDetails && (
                      <>
                        <p className="text-lg mb-3 text-white">
                          Total NFT sold:{" "}
                          <span className="text-yellow-300">
                            {" "}
                            {
                              findUserDetails.me.affiliateInfoDetails
                                .totalNftSell
                            }{" "}
                          </span>
                        </p>
                        <p className="text-lg mb-3 text-white">
                          Total NFT payout:{" "}
                          <span className="text-yellow-300">
                            ${" "}
                            {
                              findUserDetails.me.affiliateInfoDetails
                                .totalNftPayout
                            }
                          </span>
                        </p>
                      </>
                    )}
                  </>
                ) : (
                  <div className="block mb-2">
                    <input
                      className="relative bg-yellow-300 rounded"
                      type="checkbox"
                      onChange={() => {
                        makeUserAffiliate();
                      }}
                      id="exampleCheck2"
                    />
                    <label
                      className="inline-block pl-[0.15rem] hover:cursor-pointer border-b border-yellow-300/70  font-bold  text-lg  mb-3 text-yellow-300"
                      for="exampleCheck2"
                    >
                      Make yourself an Affiliate
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <!-- Right  --> */}
          {/* <!-- Right --> */}
          <div className="lg:w-7/12 flex lg:justify-between lg:flex-row flex-col-reverse ">
            {/* Middle Slot */}
            {/* Middle Slot */}
            <div className="w-72 sm:w-96 sm:mx-auto lg:mx-0 break-words px-2 sm:px-0">
              <div className="flex items-start justify-between">
                <h3 className="text-white font-display font-bold mb-4 text-3xl">
                  Mint your unique
                  <p className="text-yellow-300">World Cup NFTs</p>
                  <p> using kidcoin!</p>
                </h3>

                <img
                  src="/images/hero/kidcoin_logo.png"
                  className="h-20 w-20 rounded-full"
                  alt="user-profile"
                />
              </div>

              <p className="text-gray-300 font-display mb-5 text-md ">
                Login and Mint NFT by Kidcoin to get special discount with 40%
                offer. 1 NFT = 60K($10).
              </p>
              <p className="text-white font-display font-bold mb-2 text-3xl">
                Gifting <span className="text-yellow-300">NFTs</span>
              </p>
              <p className="text-gray-300 font-display mb-5 text-md ">
                Want a perfect holiday gift that doesn't need to be shipped?
                $10-60 digital collectibles can be minted and sent along with a
                holiday note to any valid email address.{" "}
                <span className="text-yellow-300">Get started!</span>
              </p>
              <p className="text-white font-display font-bold mb-2 text-3xl">
                Become an <span className="text-yellow-300">Affiliate</span>
              </p>
              <p className="text-gray-300 font-display mb-4 text-md ">
                If you want to join in the promotions Team, Login to make
                yourself Affiliate then simply copy & paste your Affiliate
                Coupon Code to your social media or email/chat to your friends,
                which benefits them with a $3 off deal (which you can use too),
                and earns you $7 per NFT sold.
              </p>
            </div>
            {/* Middle Slot End */}

            {/* <!-- Right Slot --> */}
            {/* <!-- Right Slot --> */}
          </div>
          {/* <!-- Right END --> */}
        </div>

        {/* Mint History Table */}
        {/* Mint History Table */}
        <div className="relative z-10">
          <h1 className="pt-16 pb-10 text-center text-4xl font-bold">
            MINT HISTORY
          </h1>

          {/* <!-- Table --> */}
          <div className="overflow-x-auto w-72 sm:w-10/12 md:w-11/12 mx-auto">
            <table className="bg-slate-700/80 border-yellow-100 border border-b-transparent text-sm mx-auto">
              <thead className="border-b border-yellow-100">
                <tr>
                  <th
                    className="lg:w-40 py-3 px-3 text-center"
                    role="columnheader"
                  >
                    <span>Type</span>
                  </th>
                  <th
                    className="lg:w-40 py-3 px-3 text-center"
                    role="columnheader"
                  >
                    <span className="">Wallet received NFts</span>
                  </th>
                  <th
                    className="lg:w-40 py-3 px-3 text-center"
                    role="columnheader"
                  >
                    <span>Total</span>
                  </th>
                  <th
                    className="lg:w-40 py-3 px-3 text-center"
                    role="columnheader"
                  >
                    <span>Payment Method</span>
                  </th>
                  <th
                    className="lg:w-40 py-3 px-3 text-center"
                    role="columnheader"
                  >
                    <span>Paid Amount</span>
                  </th>
                  <th
                    className="lg:w-40 py-3 px-3 text-center"
                    role="columnheader"
                  >
                    <span>Payment Status</span>
                  </th>
                  <th
                    className="lg:w-40 py-3 px-3 text-center"
                    role="columnheader"
                  >
                    <span>Transaction Date</span>
                  </th>
                </tr>
              </thead>

              {findMyNftHistory.findMyNftHistory.map((history, index) => {
                return (
                  <tbody>
                    <tr
                      className=" border-b border-yellow-100"
                      role="row"
                      key={index}
                    >
                      <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                        <span className="mr-2 lg:mr-4">{index + 1}</span>

                        <span className="text-sm font-semibold">
                          {history.paymentFor == PAYMENT_FOR.MINT_NFT
                            ? "MINT"
                            : history.giftReceiverUserId == userId
                            ? "Gift Received"
                            : "Gift Sent"}
                        </span>
                      </td>
                      <td
                        className="lg:w-40  py-3 px-3 text-center flex justify-center"
                        role="cell"
                      >
                        <span className="-ml-1" data-tippy-content="ETH">
                          <svg className="icon mr-1 h-4 w-4">
                            <use xlinkHref="/icons.svg#icon-ETH"></use>
                          </svg>
                        </span>
                        <span className="text-sm font-medium tracking-tight">
                          {history.nftIdsDetails[0]?.mintByWalletAddress
                            ? `${history.nftIdsDetails[0]?.mintByWalletAddress.slice(
                                0,
                                3
                              )}.....${history.nftIdsDetails[0]?.mintByWalletAddress.slice(
                                -5
                              )}`
                            : "Data not found"}
                        </span>
                      </td>
                      <td
                        className=" lg:w-40 py-3 px-3 text-center"
                        role="cell"
                      >
                        <span
                          onClick={() => {
                            setModal(true);
                            setSelectedNftImages(history.nftIdsDetails);
                          }}
                          className={"text-red cursor-pointer"}
                        >
                          {history.nftIdsDetails.length} NFT
                        </span>
                      </td>
                      <td
                        className="lg:w-40  py-3 px-3 text-center"
                        role="cell"
                      >
                        <span className={"text-blue"}>
                          {history.paymentMethod}
                        </span>
                      </td>
                      <td
                        className="lg:w-40  py-3 px-3 text-center flex justify-center"
                        role="cell"
                      >
                        <span className="-ml-1" data-tippy-content="ETH">
                          <svg className="icon mr-1 h-4 w-4">
                            <use xlinkHref="/icons.svg#icon-ETH"></use>
                          </svg>
                        </span>
                        <span className="text-sm font-medium tracking-tight">
                          {history.paymentMethod == PAYMENT_METHOD.STRIPE
                            ? `$${history.paidAmount / 100}`
                            : history.paidAmount}
                        </span>
                      </td>
                      <td
                        className="lg:w-40  py-3 px-3 text-center"
                        role="cell"
                      >
                        <span>
                          {history.paymentStatus ===
                          STRIPE_PAYMENT_RESPONSE_STATUSES.SUCCEEDED
                            ? "Success"
                            : history.paymentStatus}
                        </span>
                      </td>
                      <td
                        className="lg:w-40  py-3 px-3 text-center"
                        role="cell"
                      >
                        <span>
                          {format(Number(history.createdAt), "do MMM yyyy")}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>

        {/* <!-- end login --> */}

        {/* Modal  */}

        <Modal
          dismissible
          show={modal === true}
          onClose={() => setModal(undefined)}
          theme={customModal}
        >
          <Modal.Header>
            <div className="text-yellow-300">NFTs</div>
          </Modal.Header>
          <Modal.Body>
            {selectedNftImages.length > 0 ? (
              <div className="space-y-6">
                {selectedNftImages.map((nft, index) => (
                  <div className="text-center mb-5">
                    <img
                      className="h-44 w-44 mx-auto mb-2"
                      src={nft.url}
                      alt={nft.nftName}
                      loading="lazy"
                    />

                    <h3 className="text-white font-bold">NFT Serial</h3>
                    <p className="text-yellow-300">{nft.nftName}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center text-2xl text-red-400">
                No NFTs Minted
              </p>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </CommonBackdrop>
  );
};

export default NftAccount;
