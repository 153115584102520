import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  LOCAL_STORAGE_KEYS,
  PAYMENT_CURRENCY,
  PAYMENT_FOR,
  PAYMENT_METHOD,
  RANDOM_AVATAR_URLS,
  STRIPE_PAYMENT_RESPONSE_STATUSES,
} from "../../utils/constant";
import { format } from "date-fns";
import DataLoading from "../../component/loader/DataLoading";
import { useNavigate } from "react-router-dom";
import { computeHeadingLevel } from "@testing-library/react";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import { customModal } from "../../component/theme/customModal";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faUsers,
  faCirclePlay,
} from "@fortawesome/free-solid-svg-icons";

// Query
import FIND_MY_NFT_HISTORY from "../../gql/query/nft/findMyNftHistory.query";
import FIND_USER_DETAILS from "../../gql/query/profile/me.query";
import FIND_MY_KIDS from "../../gql/query/base/findKidsForParent.query";

// Mutations
import MAKE_USER_AFFILIATE from "../../gql/mutation/nft/makeUserAffiliate.mutations";
import DELETE_ACCOUNT from "../../gql/mutation/nft/deleteAccount.mutations";
import INITIATE_STRIPE_SETUP_SESSION from "../../gql/mutation/base/initiateStripeSetupSession.mutations";
import { toast } from "react-toastify";

const Profile = () => {
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem("userid"));
  const [modal, setModal] = useState(false);
  const [selectedNftImages, setSelectedNftImages] = useState([]);
  const [fetchingData, setFetchingDAta] = useState(false);

  const navigate = useNavigate();
  // CoverImage
  // CoverImage
  const bgImage = "/images/page-title/WWC-NFT-Cover.png";
  // Query

  const {
    data: findMyKids,
    loading: findMyKidsLoading,
    error: findMyKidsError,
    refetch: findMyKidsRefetch,
  } = useQuery(FIND_MY_KIDS, {
    variables: {
      parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    },
  });

  const {
    data: findUserDetails,
    loading: loadingFindUserDetails,
    error: errorFindUserDetails,
    refetch: refetchFindUserDetails,
  } = useQuery(FIND_USER_DETAILS, {
    variables: {
      _id: userId,
    },
  });

  // Accept Invitation Modal
  const [accountDeletionModal, setAccountDeletionModal] = useState(false);

  // Mutations
  const [
    makeAUserAffiliate,
    { loading: loadingMakeAUserAffiliate, error: errorMakeAUserAffiliate },
  ] = useMutation(MAKE_USER_AFFILIATE);

  const [
    deleteAccountMutation,
    { loading: deleteAccountLoading, error: deleteAccountError },
  ] = useMutation(DELETE_ACCOUNT);

  const [
    initiateStripeSessionMutation,
    {
      loading: initiateStripeSessionMutationLoading,
      error: initiateStripeSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_SETUP_SESSION);

  async function makeUserAffiliate() {
    const { data } = await makeAUserAffiliate({
      variables: {
        userId: localStorage.getItem("userid"),
      },
    });

    if (data.makeUserAffiliate.errorMessage == null) {
      // console.log("Done No error");
      await refetchFindUserDetails();
      localStorage.setItem(
        "affiliateCouponCode",
        data.makeUserAffiliate.affiliateCouponCode
      );
      setSuccessModalShow(true);
      return;
    }
  }

  useEffect(() => {
    if (!localStorage.getItem("userid")) {
      navigate("/login");
    }

    function userDeatails() {
      refetchFindUserDetails();
      findMyKidsRefetch();
    }
    userDeatails();
  }, []);

  // This function will call backend mutation to create a stripe session URL. After successfully getting the STRIPE URL we will navigate the user to that url
  async function initiateStripeCheckoutUi() {
    // Call the Mutation to get STRIPE session URL
    const { data } = await initiateStripeSessionMutation({
      variables: {
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      },
    });
    console.log("Session data: ", data);
    if (data.initiateStripeSetupSession.errorMessage == null) {
      window.location.href = data.initiateStripeSetupSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
    }
  }

  // Accept Invitation Modal functions
  function closeAccountDeletionModal() {
    setAccountDeletionModal(false);
  }

  // Account deleteion mutation
  async function deleteAccount() {
    const { data } = await deleteAccountMutation({
      variables: {
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      },
    });
    if (data.deleteAccount.errorMessage == null) {
      localStorage.removeItem("userid");
      localStorage.removeItem("bsports_user_name");
      localStorage.removeItem("user_email");
      localStorage.removeItem("kidcointoken");
      localStorage.removeItem("affiliateCouponCode");
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_TYPE);
      // setHeaderComponentReloader(headerComponentReloader + 1);
      navigate(`/`);

      toast.success("Successfully deleted account!"); // Here additionalData is the STRIPE checkout url (generated by our server)
    } else {
      toast.error(data.deleteAccount.errorMessage); // Here additionalData is the STRIPE checkout url (generated by our server)
    }
  }

  if (loadingFindUserDetails || fetchingData || findMyKidsLoading) {
    return (
      <CommonBackdrop>
        <DataLoading loadingType="success" />
      </CommonBackdrop>
    );
  }

  return (
    <CommonBackdrop>
      {/* <!-- Login --> */}
      <div className="font-display  py-10 lg:py-20">
        <div className="lg:w-11/12 md:px-5 lg:px-0 lg:flex lg:justify-between mx-auto">
          {/* <!-- Left --> */}
          {/* <!-- Left --> */}
          <div className=" flex justify-center lg:text-start relative ">
            <div className="w-72 sm:w-96  p-3">
              <div className="mb-6">
                <h3 className="font-bold lg:text-start text-2xl text-white">
                  Account Profile
                  <p className="text-yellow-300">
                    Basement Sports {findUserDetails.me.userStatus}
                  </p>
                </h3>

                {/* <img
                  src="/images/avatars/asset5.png"
                  className="h-16 w-16 bg-accent rounded-full"
                  alt="user-profile"
                /> */}
              </div>
              <div className="text-ellipsis break-words">
                <p className="text-lg  mb-3 text-white">
                  Username:{" "}
                  <span className="text-yellow-300">
                    {" "}
                    {localStorage.getItem("bsports_user_name")}
                  </span>
                </p>
                <p className="text-lg  mb-3 text-white">
                  Name:{" "}
                  <span className="text-yellow-300">
                    {" "}
                    {localStorage.getItem("bsports_user_name")}
                  </span>
                </p>
                <p className="text-lg mb-3 text-white">
                  Email:{" "}
                  <span className="text-yellow-300">
                    {" "}
                    {localStorage.getItem("user_email")}
                  </span>
                </p>

                {localStorage.getItem("affiliateCouponCode") ? (
                  <>
                    <p className="text-lg mb-3 text-white">
                      Your Affiliate Coupon Code is:{" "}
                      <span className="text-yellow-300">
                        {" "}
                        {localStorage.getItem("affiliateCouponCode")}
                      </span>
                    </p>
                  </>
                ) : (
                  <div className="block mb-2">
                    <input
                      className="relative bg-yellow-300 rounded"
                      type="checkbox"
                      onChange={() => {
                        makeUserAffiliate();
                      }}
                      id="exampleCheck2"
                    />
                    <label
                      className="inline-block pl-[0.15rem] hover:cursor-pointer border-b border-yellow-300/70  font-bold  text-lg  mb-3 text-yellow-300"
                      for="exampleCheck2"
                    >
                      Make yourself an Affiliate
                    </label>
                  </div>
                )}
                {findUserDetails.me.kidCoinToken && (
                  <p className="text-lg  mb-3 text-white">
                    Integration:{" "}
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 via-green-300 to-green-200">
                      {" "}
                      Kidcoin
                    </span>
                  </p>
                )}

                <div>
                  <p className="text-lg  mb-3 text-white">Your kids: </p>

                  {localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) &&
                    findMyKids &&
                    findMyKids.findKidsForParent &&
                    findMyKids.findKidsForParent.length > 0 && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {findMyKids.findKidsForParent.map((kid) => {
                          return (
                            <div
                              className="cursor-pointer mr-3"
                              onClick={() => {
                                navigate(`/kid-details/${kid.player._id}`);
                              }}
                            >
                              <img
                                src={
                                  RANDOM_AVATAR_URLS[kid.avatar]
                                    ? RANDOM_AVATAR_URLS[kid.avatar]
                                    : RANDOM_AVATAR_URLS["user4_asset4"]
                                }
                                alt="Basement Sports"
                                className="max-h-10 object-contain"
                              />

                              <h1>{kid.name}</h1>
                            </div>
                          );
                        })}
                      </div>
                    )}

                  <div
                    className="text-center border-[.5px] w-full py-2   border-yellow-100 items-center text-3xl text-yellow font-bold   mt-20 mb-10 rounded cursor-pointer"
                    onClick={initiateStripeCheckoutUi}
                  >
                    Add Payment Method
                  </div>

                  <button
                    className="mt-7 mb-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mx-auto block  w-full"
                    onClick={() => {
                      setAccountDeletionModal(true);
                    }}
                  >
                    Delete My Account
                  </button>
                  <div
                    className="text-xs"
                    style={{ color: "#c1e9efe8", alignSelf: "center" }}
                  >
                    <i style={{ color: "yellow" }}>NOTE: </i>Account deletion
                    will remove the account.
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Right  --> */}
          {/* <!-- Right --> */}
          <div className="lg:w-7/12 flex lg:justify-between lg:flex-row flex-col-reverse ">
            {/* Middle Slot */}
            {/* Middle Slot */}
            {/* <div className="w-72 sm:w-96 sm:mx-auto lg:mx-0 break-words px-2 sm:px-0">
              <div className="flex items-start justify-between">
                <h3 className="text-white font-display font-bold mb-4 text-3xl">
                  Mint your unique
                  <p className="text-yellow-300">World Cup NFTs</p>
                  <p> using kidcoin!</p>
                </h3>

                <img
                  src="/images/hero/kidcoin_logo.png"
                  className="h-20 w-20 rounded-full"
                  alt="user-profile"
                />
              </div>

              <p className="text-gray-300 font-display mb-5 text-md ">
                Login and Mint NFT by Kidcoin to get special discount with 40%
                offer. 1 NFT = 60K($10).
              </p>
              <p className="text-white font-display font-bold mb-2 text-3xl">
                Gifting <span className="text-yellow-300">NFTs</span>
              </p>
              <p className="text-gray-300 font-display mb-5 text-md ">
                Want a perfect holiday gift that doesn't need to be shipped?
                $10-60 digital collectibles can be minted and sent along with a
                holiday note to any valid email address.{" "}
                <span className="text-yellow-300">Get started!</span>
              </p>
              <p className="text-white font-display font-bold mb-2 text-3xl">
                Become an <span className="text-yellow-300">Affiliate</span>
              </p>
              <p className="text-gray-300 font-display mb-4 text-md ">
                If you want to join in the promotions Team, Login to make
                yourself Affiliate then simply copy & paste your Affiliate
                Coupon Code to your social media or email/chat to your friends,
                which benefits them with a $3 off deal (which you can use too),
                and earns you $7 per NFT sold.
              </p>
            </div> */}
            {/* Middle Slot End */}

            {/* <!-- Right Slot --> */}
            {/* <!-- Right Slot --> */}
            <div className="w-72 sm:max-w-sm sm:mx-auto lg:mx-0 lg:ml-5">
              <img
                src="/images/hero/mobile.png"
                alt="mobile"
                className="object-contain "
              />
            </div>
          </div>
          {/* <!-- Right END --> */}
        </div>
      </div>

      {/* ACCOUNT DELETIION MODAL */}
      {/* ACCOUNT DELETIION MODAL */}
      <Modal
        isOpen={accountDeletionModal}
        onRequestClose={closeAccountDeletionModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#00000033",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* MODAL CLOSE BUTTON */}
        <button onClick={closeAccountDeletionModal} style={{ float: "right" }}>
          <FontAwesomeIcon className="mt-1" icon={faClose} fade />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Delete Account{" "}
        </h1>

        <h1
          className="text-white-300 text-xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Are you sure you want to delete your account?
        </h1>

        <div
          className="text-xs"
          style={{ color: "#c1e9efe8", alignSelf: "center" }}
        >
          <i style={{ color: "yellow" }}>NOTE: </i>Account deletion will remove
          the account.
        </div>

        {/* FINAL SUBMIT BUTTON for payment/ Free for Join */}
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="mt-5"
        >
          {deleteAccountLoading ? (
            <button
              className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
              style={{
                backgroundColor: "#0000008a",
                color: "#FFFFFF",
              }}
            >
              Loading
            </button>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
                style={{
                  backgroundColor: "#0000008a",
                  color: "#FFFFFF",
                }}
                onClick={async () => {
                  deleteAccount();
                }}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </Modal>
    </CommonBackdrop>
  );
};

export default Profile;
