import gql from "graphql-tag";

export default gql`
  mutation initiateStripeBillingPortalSession($userId: String!) {
    initiateStripeBillingPortalSession(userId: $userId) {
      additionalData
      errorMessage
      successMessage
    }
  }
`;
