import { gql } from "@apollo/client";
export default gql`
  query findAllVideosByFilter(
    $baseSlug: String
    $kidPlayerIds: [String]
    $sport: String
    $searchName: String
  ) {
    findAllVideosByFilter(
      baseSlug: $baseSlug
      kidPlayerIds: $kidPlayerIds
      sport: $sport
      searchName: $searchName
    ) {
      _id
      userId
      userDetails {
        _id
        avatar
      }
      url
      likes
      videoThumbnailUrl
      caption
      createdAt
      baseDetails {
        logo
        name
      }
    }
  }
`;
