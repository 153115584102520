import React, { useState, useContext, useEffect } from "react";
import { collection_data } from "../../data/collection_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { Tab, TabList } from "react-tabs";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Calendar from "react-calendar";
import Modal from "react-modal";
import differenceInYears from "date-fns/differenceInYears";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
// import "react-calendar/dist/Calendar.css";

// Query
import FIND_KID_DETAILS from "../../gql/query/base/findSinglePlayerStats.query";

// MUTATION
import PARTICIPATE_EVENT from "../../gql/mutation/base/participateEvent.mutations";

import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  PAYMENT_METHOD,
  STRIPE_PAYMENT_RESPONSE_STATUSES,
  RANDOM_AVATAR_URLS,
  OTHER_VIDEO_TYPES_TO_FETCH,
} from "../../utils/constant";
import HeadLine from "../../component/bSports/HeadLine";
import { da } from "date-fns/locale";

const KidDetails = () => {
  // Common data
  const navigate = useNavigate();
  const { kidPlayerId } = useParams();

  // Query
  const {
    data: findMyKidPlayer,
    loading: findMyKidPlayerLoading,
    error: findMyKidPlayerError,
    refetch: findMyKidPlayerRefetch,
  } = useQuery(FIND_KID_DETAILS, {
    variables: {
      _id: kidPlayerId,
    },
  });

  if (findMyKidPlayerLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (findMyKidPlayerError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  const player = findMyKidPlayer.findSinglePlayerStats;

  return (
    <CommonBackdrop>
      <div className="overflow-x-auto w-72 sm:w-10/12 md:w-11/12 mx-auto">
        <div className="w-full bg-black/20 px-10 h-10 flex items-center flex-row mt-5">
          <h1 className="text-yellow-300">Bases / </h1>
          <h1> Kid Details </h1>
        </div>

        <div
          className="mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
          style={{
            borderLeft: "5px solid yellow",
            justifyContent: "space-between",
            height: "100%",
            backgroundColor: "#454f6680",
          }}
        >
          {/* KID AVATAR + Name + Check button */}
          <div
            style={{
              display: "flex",
              height: "100%",
            }}
            className="py-2"
          >
            {/* KID AVATAR */}
            <div
              style={{
                borderRight: "1px dotted #ffffff45",
                height: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={
                  RANDOM_AVATAR_URLS[player.user.avatar]
                    ? RANDOM_AVATAR_URLS[player.user.avatar]
                    : RANDOM_AVATAR_URLS["user4_asset4"]
                }
                alt="Basement Sports"
                className="max-h-17"
                style={{
                  color: "white",
                  width: "100px",

                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              />
            </div>

            {/* KID INFO SECTION */}
            <div
              className="ml-5"
              style={{
                borderRight: "1px dotted #ffffff45",
                paddingRight: "60px",
              }}
            >
              {/* kid Name */}
              <div>{player.user.name}</div>
              {/* kid age */}
              <div style={{ color: "#ccd0e0" }}>
                {player.user.age ? (
                  <div>{player.user.age} y/o</div>
                ) : player.user.dateOfBirth ? (
                  <div>
                    {differenceInYears(
                      new Date(),
                      new Date(player.user.dateOfBirth)
                    )}{" "}
                    y/o
                  </div>
                ) : null}
              </div>
              {/* kid gender */}
              <div>
                <b style={{ color: "#ccd0e0" }}>
                  {player.user.gender == "female" ? "Girl" : "Boy"}
                </b>
              </div>
              {/* relation with kid */}
              <div>
                <b style={{ color: "#ccd0e0" }}>{player.parentRelation}</b>
              </div>
              {/* Followe */}
              <div className="text-xs mt-1" style={{ color: "#ccd0e0" }}>
                Followers:{" "}
                {player.user?.followFollowers?.followers?.length
                  ? player.user.followFollowers.followers.length
                  : 0}
              </div>
              {/* Followings */}
              <div className="text-xs" style={{ color: "#ccd0e0" }}>
                Followings:{" "}
                {player.user?.followFollowers?.followings?.length
                  ? player.user.followFollowers.followings.length
                  : 0}
              </div>
            </div>

            {/* KID DETAILS + KID GAME INFOS */}
            <div
              style={{
                color: "#ccd0e0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              className="pl-6 text-sm  pt-1 pb-3"
            >
              {/* {player.user.createdAt && (
                <div>
                  Joined {formatDistanceToNow(Number(player.user.createdAt))}{" "}
                  ago.
                </div>
              )} */}

              {/* game icons */}
              <div style={{ display: "flex", opacity: 0.5 }} className="mt-5">
                <img
                  src={"/images/game/baseballLine.png"}
                  alt="Basement Sports"
                  className="max-h-8 mr-3"
                />
                <img
                  src={"/images/game/basketballLine.png"}
                  alt="Basement Sports"
                  className="max-h-8 mr-3"
                />
                <img
                  src={"/images/game/hockeyLine.png"}
                  alt="Basement Sports"
                  className="max-h-8 mr-3"
                />
                <img
                  src={"/images/game/soccerLine.png"}
                  alt="Basement Sports"
                  className="max-h-8 mr-3"
                />
                <img
                  src={"/images/game/footballLine.png"}
                  alt="Basement Sports"
                  className="max-h-8 mr-3"
                />
              </div>
            </div>
          </div>

          {/* KID premium status + Base symbol */}
          <div className="px-5">
            <div
              className="text-sm py-2 px-5 rounded-full"
              style={{
                backgroundColor: "#00000033",
                color: "#FFFFFF",
              }}
            >
              BASE MEMBER
            </div>
          </div>
        </div>

        {/* STATISTICS */}
        <div className="font-semibold my-auto mt-5">
          <h1 className="text-xl" style={{ color: "white" }}>
            Statistics
          </h1>

          {/* Game wise STATs */}
          <div className="flex justify-between flex-wrap">
            {/* SOCCER */}
            <div className="w-full md:w-1/3 md:pr-2">
              <div
                className="rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30 my-3"
                style={{
                  backgroundColor: "#454f6680",
                  borderLeft: "5px solid #00BA2E",
                }}
              >
                {/* title + soccer icon */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h1 className="text-lg text-center mb-3">Soccer</h1>
                  <img
                    src={"/images/game/soccerLine.png"}
                    alt="Basement Sports"
                    className="max-h-8 mr-3"
                  />
                </div>
                {/* statistics */}
                <div>
                  Total Goals:{" "}
                  {player.soccerStats?.score ? player.soccerStats?.score : 0}
                </div>
                <div>
                  Total Played games:{" "}
                  {player.soccerStats?.totalNumberOfGames
                    ? player.soccerStats.totalNumberOfGames
                    : 0}
                </div>
              </div>
            </div>

            {/* HOCKEY */}
            <div className="w-full md:w-1/3 md:px-2">
              <div
                className="rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30 my-3"
                style={{
                  backgroundColor: "#454f6680",
                  borderLeft: "5px solid #007DCF",
                }}
              >
                {/* title + soccer icon */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h1 className="text-lg text-center mb-3">Hockey</h1>
                  <img
                    src={"/images/game/hockeyLine.png"}
                    alt="Basement Sports"
                    className="max-h-8 mr-3"
                  />
                </div>
                {/* statistics */}
                <div>
                  Total Goals:{" "}
                  {player.hockeyStats?.score ? player.hockeyStats?.score : 0}
                </div>
                <div>
                  Total Played games:{" "}
                  {player.hockeyStats?.totalNumberOfGames
                    ? player.hockeyStats.totalNumberOfGames
                    : 0}
                </div>
              </div>
            </div>

            {/* BASEBALLA */}
            <div className="w-full md:w-1/3 md:pl-2">
              <div
                className="rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30 my-3"
                style={{
                  backgroundColor: "#454f6680",
                  borderLeft: "5px solid #E43247",
                }}
              >
                {/* title + soccer icon */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h1 className="text-lg text-center mb-3">Baseball</h1>
                  <img
                    src={"/images/game/baseballLine.png"}
                    alt="Basement Sports"
                    className="max-h-8 mr-3"
                  />
                </div>
                <div>
                  Total Goals:{" "}
                  {player.baseBallStats?.runs ? player.baseBallStats.runs : 0}
                </div>
                <div>
                  Total Played games:{" "}
                  {player.baseBallStats?.totalNumberOfGames
                    ? player.baseBallStats.totalNumberOfGames
                    : 0}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* VIDEOS */}
        {player.streamingRecords.length > 0 && (
          <div className="font-semibold my-auto mt-5">
            <h1 className="text-xl" style={{ color: "white" }}>
              Videos
            </h1>
            {/* videos */}
            <div
              className="flex flex-wrap"
              style={{
                alignItems: "center",
              }}
            >
              {player.streamingRecords.map((video, index) => {
                return (
                  <div
                    className="w-full md:w-1/3"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="mr-3 my-3 cursor-pointer"
                      style={{
                        position: "relative",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                      onClick={() =>
                        navigate(
                          `/video/${video._id}?otherVideoTypes=${OTHER_VIDEO_TYPES_TO_FETCH.fetchSamePlayerVideos}&playerUserId=${player.userId}`
                        )
                      }
                    >
                      {/* <div className="rounded-lg py-1 px-2 lg:px-6 shadow-md shadow-black/30 my-3"> */}
                      <img
                        src={
                          video?.videoThumbnailUrl
                            ? video.videoThumbnailUrl
                            : "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
                        }
                        alt="Basement Sports"
                        className={`md:block w-[90px] object-cover `}
                        style={{ width: "100%", height: "200px" }}
                      />

                      {/* PLAY ICON */}
                      <div
                        style={{
                          color: "yellow",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon icon={faCirclePlay} size="3x" />
                      </div>

                      {/* video details */}
                      <div
                        style={{
                          backgroundColor: "#00000033",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="px-4 py-4"
                      >
                        {/* left panel */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* avatar */}
                          <img
                            src={
                              RANDOM_AVATAR_URLS[player.user.avatar]
                                ? RANDOM_AVATAR_URLS[player.user.avatar]
                                : RANDOM_AVATAR_URLS["user4_asset4"]
                            }
                            alt="Basement Sports"
                            className={`md:block w-[90px] mr-2`}
                            style={{ width: "40px", height: "40px" }}
                          />
                          {/* video caption & base name */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {/* video caption */}
                            <div
                              className="text-lg"
                              style={{
                                fontWeight: "700",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                minWidth: "50px",
                                maxWidth: "140px",
                              }}
                            >
                              {video.caption}
                            </div>
                            {/* base name */}
                            <div className="text-sm" style={{ opacity: "0.5" }}>
                              @{"base"}
                            </div>
                          </div>
                        </div>

                        {/* right panel */}
                        <div style={{ display: "flex" }}>
                          <div className="text-xs" style={{ opacity: "0.5" }}>
                            {formatDistanceToNow(Number(video.createdAt))} ago
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {player.streamingRecords.length == 0 && <div>No videos yet</div>}
            </div>
          </div>
        )}

        {/* HIGHLIGHTS */}
        {player.photos.length > 0 && (
          <div className="font-semibold my-auto mt-5">
            <h1 className="text-xl" style={{ color: "white" }}>
              Highlights
            </h1>
            {/* videos */}
            <div
              className="flex flex-wrap"
              style={{
                alignItems: "center",
              }}
            >
              {player.photos.map((photo, index) => {
                return (
                  <div
                    className="w-full md:w-1/6"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="mr-3 my-3 cursor-pointer"
                      style={{
                        position: "relative",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                      onClick={() =>
                        navigate(`/highlight/${photo._id}/${player.userId}`)
                      }
                    >
                      {/* <div className="rounded-lg py-1 px-2 lg:px-6 shadow-md shadow-black/30 my-3"> */}
                      <img
                        src={photo.url}
                        alt="Basement Sports"
                        className={`md:block w-[90px] object-cover `}
                        style={{ width: "100%", height: "300px" }}
                      />

                      {/* Base ICON */}
                      <div
                        style={{
                          color: "yellow",
                          position: "absolute",
                          top: 5,
                          right: 5,
                        }}
                      >
                        <img
                          src={
                            RANDOM_AVATAR_URLS[player.user.avatar]
                              ? RANDOM_AVATAR_URLS[player.user.avatar]
                              : RANDOM_AVATAR_URLS["user4_asset4"]
                          }
                          alt="Basement Sports"
                          className={`md:block w-[90px] mr-2`}
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              {player.streamingRecords.length == 0 && <div>No videos yet</div>}
            </div>
          </div>
        )}
        {/* ✅ */}
      </div>
    </CommonBackdrop>
  );
};

export default KidDetails;
