import React, { useState, useContext, useEffect } from "react";
import { collection_data } from "../../data/collection_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { Tab, TabList } from "react-tabs";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import Modal from "react-modal";
// import "react-calendar/dist/Calendar.css";

// Query
import FIND_SUPER_BASE_PAYMENT_HISTORY from "../../gql/query/base/findBasePaymentHistory.query";

// MUTATION
import PARTICIPATE_EVENT from "../../gql/mutation/base/participateEvent.mutations";

import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  PAYMENT_METHOD,
  STRIPE_PAYMENT_RESPONSE_STATUSES,
} from "../../utils/constant";
import HeadLine from "../../component/bSports/HeadLine";
import { da } from "date-fns/locale";

const PaymentHistory = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);

  // Query
  const {
    data: basePaymentsHistory,
    loading: basePaymentsHistoryLoading,
    error: basePaymentsHistoryError,
    refetch: eventsRefetch,
  } = useQuery(FIND_SUPER_BASE_PAYMENT_HISTORY, {
    variables: {
      userId,
    },
  });

  if (basePaymentsHistoryLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (basePaymentsHistoryError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <CommonBackdrop>
      {/* <!-- Table --> */}
      <div className="overflow-x-auto w-72 sm:w-10/12 md:w-11/12 mx-auto">
        <div className="w-full bg-black/20 px-10 h-10 flex items-center flex-row mt-5">
          <h1 className="text-yellow-300">Account / </h1>
          <h1> Payment History</h1>
        </div>

        <table className="bg-slate-700/80 border-yellow-100 border border-b-transparent text-sm mx-auto mt-5">
          <thead className="border-b border-yellow-100">
            <tr>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Number</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Payment For</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Payment Method</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Paid Amount</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Payment Status</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Transaction Date</span>
              </th>
              {/* <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Details</span>
              </th> */}
            </tr>
          </thead>

          {basePaymentsHistory.findBasePaymentHistory.map((history, index) => {
            return (
              <tbody>
                <tr
                  className=" border-b border-yellow-100"
                  role="row"
                  key={index}
                >
                  {/* Number */}
                  <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                    <span className="mr-2 lg:mr-4">{index + 1}</span>
                  </td>

                  {/* Payment For */}
                  <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                    <span className="text-sm font-semibold">
                      {history.paymentFor ==
                      PAYMENT_FOR.PURCHASE_FULL_MONTH_EVENT_OF_BASE
                        ? "Monthly Subscription"
                        : history.paymentFor ==
                          PAYMENT_FOR.PURCHASE_SINGLE_EVENT_OF_BASE
                        ? "Single Event Purchase"
                        : history.paymentFor ==
                          PAYMENT_FOR.PURCHASE_SLOT_FOR_BASE_EVENT
                        ? "Slot Purchase"
                        : ""}
                    </span>
                  </td>

                  {/* Payment Method */}
                  <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                    <span className={"text-blue"}>{history.paymentMethod}</span>
                  </td>

                  {/* Paid Amount */}
                  <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                    <span>
                      {history.paymentMethod == PAYMENT_METHOD.STRIPE
                        ? `$${history.paidAmount / 100}`
                        : history.paidAmount}
                    </span>
                  </td>

                  {/* Payment STatus */}
                  <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                    <span>
                      {history.paymentStatus ===
                      STRIPE_PAYMENT_RESPONSE_STATUSES.SUCCEEDED
                        ? "Success"
                        : history.paymentStatus}
                    </span>
                  </td>

                  {/* Transaction Date */}
                  <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                    <span>
                      {format(Number(history.createdAt), "do MMM yyyy")}
                    </span>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </CommonBackdrop>
  );
};

export default PaymentHistory;
