import gql from "graphql-tag";

export default gql`
  mutation sendCustomEmailForEvent(
    $eventId: String
    $parentEmails: [String]
    $emailText: String
  ) {
    sendCustomEmailForEvent(
      eventId: $eventId
      parentEmails: $parentEmails
      emailText: $emailText
    ) {
      successMessage
      errorMessage
    }
  }
`;
