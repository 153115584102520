import { gql } from "@apollo/client";

export default gql`
  query findKidsForParent($parentId: String!) {
    findKidsForParent(parentId: $parentId) {
      _id
      name
      age
      dateOfBirth
      gender
      avatar
      player {
        _id
        sport
        parentRelation
      }
      createdAt
    }
  }
`;
