import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { countries } from "../../../utils/countries";
import { countryWiseCities } from "../../../utils/countryWiseCity";
import makeAnimated from "react-select/animated";

import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import SIGN_UP from "../../../gql/mutation/auth/signUp.mutation";
import CommonAuth from "../../../component/auth/CommonAuth";
import Select, { components } from "react-select";

export default function Signup() {
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();

  const bgImage = "/images/page-title/WWC-NFT-Cover.png";

  //   const [timeActiveText, setTimeActiveText] = useState();
  const [dropdownShow, setDropdownShow] = useState(false);
  const [cityDropdownShow, setCityDropdownShow] = useState(false);
  const [country, setCountry] = useState("United States");
  const [city, setCity] = useState("New York, NY");
  const [search, setSearch] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  // const [avatar, setAvatar] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  // const [isLogin, setIsLogin] = useState(false);
  // const [slot, setSlot] = useState("firstSlot");

  const USER = "userid";

  //mutation
  const [signUp, { loading, error }] = useMutation(SIGN_UP);

  const handleDropdown = (e) => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".dropdown-toggle")) {
        if (dropdownShow) {
          setDropdownShow(false);
        } else {
          setDropdownShow(true);
        }
      } else {
        setDropdownShow(false);
      }
    });
  };

  const handleCityDropdown = (e) => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".dropdown-toggle-city")) {
        if (cityDropdownShow) {
          setCityDropdownShow(false);
        } else {
          setCityDropdownShow(true);
        }
      } else {
        setCityDropdownShow(false);
      }
    });
  };

  const handleFilter = (text) => {
    setCountry(text);
    setCity("");
  };
  const handleCityFilter = (text) => {
    setCity(text);
  };

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  function validatePhone(phone) {
    // let reg = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{6})$/
    let reg = /^\+?\d+$/;
    let isphone = email.match(reg);

    console.log(isphone, "oioioi");
    if (isphone) {
      return true;
    } else {
      return false;
    }
  }

  async function pass() {
    if (username == null || username == "") {
      window.alert("Please set username");
      return null;
    } else if (email == null || email == "") {
      window.alert("Please set email");
      return null;
    } else if (password == null || password == "") {
      window.alert("Please set password");
      return null;
    } else if (country == "" || country == null) {
      window.alert("Please choose Country");
      return null;
    } else if (city == null || city == "") {
      window.alert("Please choose City");
      return null;
    } else {
      const checkMail = validateEmail(email);
      const checkPhone = validatePhone(email);
      console.log(checkPhone, "invalid phohne");
      if (!checkMail && !checkPhone) {
        window.alert("Ivalid email");
        return null;
      }

      console.log("A", email, password, username, city, country);
      const { data } = await signUp({
        variables: {
          email,
          password,
          username,
          avatar: "user3_asset8",
          dateOfBirth: "2018-06-11T10:00:00.000Z",
          city: city,
          country: country,
        },
      });
      console.log("B");
      if (data.signUp.errorMessage === null) {
        localStorage.setItem(USER, data.signUp.user._id);
        localStorage.setItem("bsports_user_name", data.signUp.user.name);
        localStorage.setItem("user_email", data.signUp.user.email);
        if (data.signUp.user.affiliateCouponCode) {
          localStorage.setItem(
            "affiliateCouponCode",
            data.signUp.user.affiliateCouponCode
          );
        }
        window.location.reload("/");
        navigate("/");
      }
      if (data.signUp.errorMessage) {
        console.log("error please");
        window.alert(`${data.signUp.errorMessage}`);
      }
      //if (data.errors) window.alert(`${data.errors[0].message}`)
    }
  }

  useEffect(() => {
    if (localStorage.getItem("userid")) {
      navigate("/");
    }
    return () => {};
  }, []);

  return (
    <CommonAuth>
      <div className="sm:mt-[30px]">
        <div className=" rounded-lg w-full ">
          <h1 className="text-end text-white font-display font-bold mb-7 text-4xl mt-10">
            Create your Account
          </h1>
          <input
            onChange={(e) => setUsername(e.target.value)}
            type="text"
            className=" bg-transparent border-yellow-300 focus:border-yellow-300 text-white font-bold font-display w-full py-2 px-5 rounded-full mb-4"
            name="name"
            placeholder="name"
          />

          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            className=" bg-transparent border-yellow-300 focus:border-yellow-300 text-white font-bold font-display w-full py-2 px-5 rounded-full mb-4"
            name="email"
            placeholder="Email Or Phone Number With Country Code"
            style={{ textTransform: "lowercase" }}
          />

          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            className=" bg-transparent border-yellow-300 focus:border-yellow-300 text-white font-bold font-display w-full py-2 px-5 rounded-full mb-4"
            name="password"
            placeholder="Password"
          />

          {/* <div className="mb-2 px-1 flex items-center justify-between ">
            <span className="dropdown-item font-normal text-yellow-300 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 block rounded-xl px-5 py-2 transition-colors">
              {country}
            </span>
            {country && (
              <span className="dropdown-item font-normal text-yellow-300 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 block rounded-xl px-5 py-2 transition-colors">
                {city}
              </span>
            )}
          </div> */}

          <div className="mb-6  pl-2 flex items-center justify-between ">
            {/* <Select
              placeholder={"Select country"}
              value={country ? country : null}
              onChange={(v) => {
                setCountry(v.label);
                setCity(null);
              }}
              options={countries}
            /> */}

            {/* <Select
              className="bg-black/40 px-2 w-1/3  text-yellow-300  "
              placeholder={country ? country : "Select City"}
              onChange={(v) => {
                setCountry(v.label);
                setCity(null);
              }}
              options={countries}
              unstyled={true}
            /> */}
            {/* {country && (
              <Select
                key={country}
                className="bg-black/40 px-2 w-1/3  text-yellow-300"
                placeholder={city ? city : "Select City"}
                onChange={(v) => setCity(v.label)}
                options={countryWiseCities[country]}
                unstyled={true}
              />
            )} */}
          </div>

          <button
            onClick={() => pass()}
            type="submit"
            className="w-2/3 mx-auto bg-black  flex items-center justify-center rounded-full  mb-4 py-4 px-8 text-center text-white duration-500 ease-in-out  font-extrabold font-display "
          >
            {loading ? "Creating..." : "Create Account"}
          </button>

          <div className="text-white text-sm text-center  mt-6 font-display">
            By signing up, you agree to the{" "}
            <a className="underline  text-grey-dark" href="#">
              Terms of Service
            </a>{" "}
            and{" "}
            <a className="underline text-grey-dark" href="#">
              Privacy Policy
            </a>
          </div>
        </div>

        <div className="text-white text-md text-center  mt-6 font-display">
          Already have an account?{" "}
          <Link to={"/login"} className="underline text-yellow-300 font-bold">
            Log in
          </Link>
          .
        </div>
      </div>
    </CommonAuth>
  );
}
