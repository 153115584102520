import { gql } from "@apollo/client";

export default gql`
  query findMatchStreamById($matchStreamId: String) {
    findMatchStreamById(matchStreamId: $matchStreamId) {
      _id
      userId
      userDetails {
        _id
        name
        avatar
      }
      appId
      channelName
      gameName
      baseDetails {
        _id
        name
        logo
        city
        country
      }
      tournamentId
      tournamentStatus
      teamIds
      gameId
      sport
      likes
      videoThumbnailUrl
      caption
      updatedAt
      createdAt
    }
  }
`;
