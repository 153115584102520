import { gql } from "@apollo/client";

export default gql`
  query findIndividualCountryMintedNft($collectionType: String) {
    findIndividualCountryMintedNft(collectionType: $collectionType) {
      name
      total
    }
  }
`;
