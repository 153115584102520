import { gql } from "@apollo/client";

export default gql`
  query findEventParticipantUsers($eventId: String) {
    findEventParticipantUsers(eventId: $eventId) {
      _id
      name
      avatar
      parentId
      role
      email
      verified

      player {
        _id
      }

      parentDetails {
        _id
        name
        email
        avatar
        role

        player {
          _id
        }
      }
    }
  }
`;
