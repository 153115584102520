// BaseRoute.js
import React from "react";
import { Route, useNavigate, Navigate } from "react-router-dom";
import { LOCAL_STORAGE_KEYS, USER_TYPE } from "../utils/constant";
import { navigate } from "react-big-calendar/lib/utils/constants";
import { useQuery, useMutation } from "@apollo/client";
import CommonBackdrop from "../component/common/CommonBackdrop";
import DataLoading from "../component/loader/DataLoading";

// Query
import FIND_BASE_DETAILS from "../gql/query/base/findBaseAdmins.query";
import DashMenu from "../component/adminPortal/DashMenu";

const BaseAdminRoute = ({ path, element }) => {
  // Query
  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  if (baseLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (baseError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  // If not logged in then redirect to login page
  if (!localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
    return <Navigate to="/login" state={{ comeFrom: "/admin" }} />;
  } else if (
    // If logged in then check if the user is a ADMIN of ANAR STUDIO
    baseData.findBaseDetails.adminIds.includes(
      localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
    )
  ) {
    return element;
  } else {
    // Else return the admin pages
    return (
      <div className="flex flex-row ">
        <DashMenu />
        <div
          className="w-1/3 ml-[300px] pt-10 text-2xl text-center font-medium"
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            color: "#bbf3ff",
          }}
        >
          <h1>
            You are not authorized to access Admin Panel. Please login with an
            admin account
          </h1>
        </div>
      </div>
    );
  }

  // if (
  //   localStorage.getItem(LOCAL_STORAGE_KEYS.USER_TYPE) !=
  //   USER_TYPE.SUPER_BASE_OWNER
  // ) {
  //   return <Navigate to="/admin/createBase" />;
  // }
};

export default BaseAdminRoute;
