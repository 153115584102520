import React from "react";
import { AgoraVideoPlayer } from "agora-rtc-react";
import { Animate } from "react-simple-animate";

// COMPONENTS
import SoccerAndHockeyScoreCard from "./soccerAndHockeyScoreCard.js";

// UTILITIES
import iconFinder from "../../utils/iconFinder.js";

export default function LiveStreamingView({
  windowSize,
  isFullScreenStreaming,
  users,
  scoreData,
  liveTime,
  selectedStream,
  agoraStreamingUsersCount,
  leaveChannel,
  setIsFullScreenStreaming,
  fullScreenForStreaming,
  autoIsFullScreen,
  isShowSoccerAndHockeyGoalCard,
  selfPlayDetails,
  currentHitPerson,
  openShareModal = null,
  autoPlayButton = false,
  handleAutoPlayButton,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: isFullScreenStreaming ? windowSize.innerWidth : "100%",
        maxWidth: isFullScreenStreaming ? windowSize.innerWidth : "100%",
        height: isFullScreenStreaming ? windowSize.innerHeight : "430px",
        maxHeight: isFullScreenStreaming ? windowSize.innerHeight : "430px",
        backgroundColor: "black",
      }}
    >
      <div
        style={{
          ...style.videoContainer,
          height: isFullScreenStreaming ? windowSize.innerHeight : "430px",
          width: isFullScreenStreaming ? windowSize.innerWidth : "100%",
        }}
      >
        {users && users.length > 0 ? (
          <div
            style={{
              ...style.videoContainer,
              height: isFullScreenStreaming ? windowSize.innerHeight : "430px",
              width: isFullScreenStreaming ? windowSize.innerWidth : "100%",
            }}
          >
            <AgoraVideoPlayer
              style={{
                ...style.vid,
                height: isFullScreenStreaming
                  ? windowSize.innerHeight
                  : "430px",
                width: isFullScreenStreaming ? windowSize.innerWidth : "100%",
              }}
              videoTrack={users[0].videoTrack}
              className="streamingMainVideo"
            />

            {/* COMMENTATOR */}
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "300px",
                display: "flex",
                justifyContent: "center",
                borderTopLeftRadius: "10px",
                overflow: "hidden",
              }}
            >
              {users[1] && users[1].videoTrack && (
                <AgoraVideoPlayer
                  style={{
                    ...style.vid,
                    height: "180px",
                  }}
                  videoTrack={users[1].videoTrack}
                  className="commentatorViewInLiveStreamScreen"
                />
              )}
            </div>

            {/* LIVE TIME*/}
            <div
              style={{
                position: "absolute",
                top: 17,
                left: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            ></div>

            {/* Basement Sports LOGO */}
            {windowSize.innerWidth > 800 && (
              <div
                style={{
                  position: "absolute",
                  bottom: 17,
                  left: 20,
                }}
              >
                {/* BSPORTS LOGO */}
                <div style={style.sportLogoContainer}>
                  {(selectedStream.gameName == "soccerSingle" ||
                    selectedStream.gameName == "soccerSeries" ||
                    selectedStream.gameName == "soccerTournament" ||
                    selectedStream.gameName == "SelfPlaySoccer") && (
                    <img
                      src={iconFinder("soccer_logo")}
                      style={style.sportLogo}
                    />
                  )}

                  {(selectedStream.gameName == "hockeySingle" ||
                    selectedStream.gameName == "hockeySeries" ||
                    selectedStream.gameName == "hockeyTournament" ||
                    selectedStream.gameName == "SelfPlayHockey") && (
                    <img
                      src={iconFinder("hockey_logo")}
                      style={style.sportLogo}
                    />
                  )}

                  {selectedStream.gameName == "SelfPlayBasketball" && (
                    <img
                      src={iconFinder("basketball_logo")}
                      style={style.sportLogo}
                    />
                  )}

                  {selectedStream.gameName == "baseballSingle" ||
                  selectedStream.gameName == "baseballSeries" ||
                  selectedStream.gameName == "baseballTournament" ||
                  selectedStream.gameName == "SelfPlayBaseball" ? (
                    scoreData.isWorldSeries ? (
                      <img
                        src={iconFinder("world_series_baseball")}
                        style={style.worldSeriesSportLogo}
                      />
                    ) : (
                      <img
                        src={iconFinder("baseball_logo")}
                        style={style.sportLogo}
                      />
                    )
                  ) : null}
                </div>
              </div>
            )}

            {/* LEFT SIDE NEW DESIGN SCORE VIEW */}
            {(selectedStream.gameName == "soccerSingle" ||
              selectedStream.gameName == "soccerSeries" ||
              selectedStream.gameName == "soccerTournament" ||
              selectedStream.gameName == "hockeySingle" ||
              selectedStream.gameName == "hockeySeries" ||
              selectedStream.gameName == "hockeyTournament" ||
              selectedStream.gameName == "baseballSingle" ||
              selectedStream.gameName == "baseballSeries" ||
              selectedStream.gameName == "baseballTournament") && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <SoccerAndHockeyScoreCard
                  containerStyle={{
                    ...style.soccerAndHockeyCardContainerForStreaming,
                    display: "flex",
                    alignItems:
                      windowSize.innerWidth > 800 ? "flex-start" : "flex-end",
                    paddingBottom: windowSize.innerWidth > 800 ? "0px" : "20px",
                  }}
                  team={scoreData.team}
                  teamLogo={scoreData.teamLogo}
                  teamOneGoals={scoreData.teamOneGoals}
                  against={scoreData.against}
                  againstLogo={scoreData.againstLogo}
                  teamTwoGoals={scoreData.teamTwoGoals}
                  firstHalf={scoreData.firstHalf}
                ></SoccerAndHockeyScoreCard>
              </div>
            )}

            {/* LEFT side Self play score view */}
            {/* LEFT side Self play score view */}
            {(selectedStream.gameName == "SelfPlaySoccer" ||
              selectedStream.gameName == "SelfPlayHockey" ||
              selectedStream.gameName == "SelfPlayBaseball" ||
              selectedStream.gameName == "SelfPlayBasketball") && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                {selfPlayDetails?.participantsPlayer.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      fontSize: "28px",
                      fontWeight: "bold",
                      width: "250px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "grey",
                      margin: "10px",
                      paddingTop: "7px",
                      paddingBottom: "7px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      borderRadius: "15px",
                      border:
                        currentHitPerson == index ? "2px solid yellow" : null,
                    }}
                  >
                    <img
                      src={
                        selfPlayDetails.gameType === "soccer"
                          ? iconFinder(item.soccerStats.soccerAvatar)
                          : selfPlayDetails.gameType === "hockey"
                          ? iconFinder(item.hockeyStats.hockeyAvatar)
                          : selfPlayDetails.gameType === "basketBall"
                          ? iconFinder(item.soccerStats.soccerAvatar)
                          : null
                      }
                      style={{ height: "40px", width: "40px" }}
                    />
                    <div style={{ color: "yellow" }}>
                      {item.name?.toUpperCase()?.slice(0, 7)}
                    </div>
                    <div style={{ color: "white" }}>
                      {selfPlayDetails?.successScore[index]}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* TOP RIGHT AUDIENCE/ LIVE indicator/ Cross button */}
            <div style={style.liveClockLogoContainer}>
              {/* LIVE TIME */}
              {(selectedStream.gameName == "soccerSingle" ||
                selectedStream.gameName == "soccerSeries" ||
                selectedStream.gameName == "soccerTournament" ||
                selectedStream.gameName == "SelfPlaySoccer" ||
                selectedStream.gameName == "hockeySingle" ||
                selectedStream.gameName == "hockeySeries" ||
                selectedStream.gameName == "hockeyTournament" ||
                selectedStream.gameName == "SelfPlayHockey" ||
                selectedStream.gameName == "SelfPlayBasketball") && (
                <div style={style.clock}>
                  <>
                    <div style={style.clockText}>0{~~(liveTime / 60)}</div>
                    <div style={style.clockText}>:</div>
                    {/* ISSUE */}
                    {liveTime % 60 == 0 ? (
                      <div style={style.clockText}>00</div>
                    ) : liveTime % 60 <= 9 ? (
                      <div style={style.clockText}>
                        0{Math.floor(liveTime % 60)}
                      </div>
                    ) : (
                      <div style={style.clockText}>
                        {Math.floor(liveTime % 60)}
                      </div>
                    )}
                  </>
                </div>
              )}

              {/* SOUND AUTO PLAY BUTTON */}
              {autoIsFullScreen != "true" && autoPlayButton && (
                <div
                  style={{
                    ...style.audienceCountSection,
                    width: "44px",
                    marginRight: "10px",
                  }}
                  className="cursor-pointer"
                  onClick={handleAutoPlayButton}
                >
                  <i
                    className="fa fa-volume-off"
                    style={{
                      color: "yellow",
                      fontSize: "25px",
                    }}
                  ></i>
                  <i
                    className="fa fa-times"
                    style={{
                      color: "yellow",
                      fontSize: "15px",
                      marginLeft: "3px",
                    }}
                  ></i>
                </div>
              )}

              {/* AUDIENCE COUNT */}
              <div style={style.audienceCountSection}>
                <i
                  className="fa fa-eye"
                  style={{
                    color: "yellow",
                    fontSize: "25px",
                  }}
                ></i>

                <div
                  style={{
                    fontSize: "30px",
                    fontWeight: "bolder",
                    color: "white",
                  }}
                >
                  {agoraStreamingUsersCount &&
                  agoraStreamingUsersCount.data &&
                  agoraStreamingUsersCount.data.audience_total
                    ? agoraStreamingUsersCount.data.audience_total
                    : 0}
                </div>
              </div>

              {/* LIVE */}
              {autoIsFullScreen != "true" && (
                <div style={style.liveTextButtonMediumView}>LIVE</div>
              )}

              {/* CROSS BUTTON */}
              {!isFullScreenStreaming && (
                <button
                  className="button-plain"
                  onClick={() => {
                    leaveChannel();
                  }}
                  style={{ fontSize: "5px" }}
                >
                  <i
                    style={{
                      color: "white",
                      fontSize: "200%",
                    }}
                    className="fa fa-times"
                  ></i>
                </button>
              )}
            </div>

            {/* BOTTOM RIGHT STREAMING CONTROLLER CONTAINER */}
            <div
              style={{
                position: "absolute",
                bottom: 21,
                right: 30,
                display: "flex",
              }}
            >
              {/* Show "Make FULL/ SMALL screen BUTTON" only when autoFullScreen is FALSE/ undefined */}
              {autoIsFullScreen != "true" &&
                (isFullScreenStreaming ? (
                  <div
                    onClick={() => {
                      setIsFullScreenStreaming(false);
                      // fullScreenForStreaming.exit();
                    }}
                  >
                    <i
                      style={{
                        color: "white",
                        fontSize: "120%",
                      }}
                      className="fa fa-compress cursor-pointer"
                    ></i>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setIsFullScreenStreaming(true);
                    }}
                  >
                    <i
                      style={{
                        color: "white",
                        fontSize: "120%",
                      }}
                      className="fa fa-arrows-alt cursor-pointer"
                    ></i>
                  </div>
                ))}
            </div>

            {/* FULL SCREEN OVERLAY (EX: Animation/ GOAL image) */}
            {isShowSoccerAndHockeyGoalCard && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#090606b3",
                }}
              >
                <Animate
                  play={isShowSoccerAndHockeyGoalCard} // Toggle when animation should start
                  start={{
                    transform: "rotate(360deg)",
                  }}
                  end={{ transform: "rotate(0deg)" }}
                >
                  <div style={{ display: "flex" }}>
                    {(selectedStream.gameName == "soccerSingle" ||
                      selectedStream.gameName == "soccerSeries" ||
                      selectedStream.gameName == "soccerTournament" ||
                      selectedStream.gameName == "hockeySingle" ||
                      selectedStream.gameName == "hockeySeries" ||
                      selectedStream.gameName == "hockeyTournament" ||
                      selectedStream.gameName == "baseballSingle" ||
                      selectedStream.gameName == "baseballSeries" ||
                      selectedStream.gameName == "baseballTournament" ||
                      selectedStream.gameName == "SelfPlaySoccer" ||
                      selectedStream.gameName == "SelfPlayHockey") && (
                      <div>
                        <img
                          src={iconFinder("goal")}
                          style={style.goalLargeImage}
                        />
                      </div>
                    )}

                    {/* SHOW SCORE */}
                    {/* SHOW SCORE */}
                    {(selectedStream.gameName == "soccerSingle" ||
                      selectedStream.gameName == "soccerSeries" ||
                      selectedStream.gameName == "soccerTournament" ||
                      selectedStream.gameName == "hockeySingle" ||
                      selectedStream.gameName == "hockeySeries" ||
                      selectedStream.gameName == "hockeyTournament" ||
                      selectedStream.gameName == "baseballSingle" ||
                      selectedStream.gameName == "baseballSeries" ||
                      selectedStream.gameName == "baseballTournament") && (
                      <SoccerAndHockeyScoreCard
                        containerStyle={
                          style.soccerAndHockeyCardContainerForAnimationResult
                        }
                        team={scoreData.team}
                        teamLogo={scoreData.teamLogo}
                        teamOneGoals={scoreData.teamOneGoals}
                        against={scoreData.against}
                        againstLogo={scoreData.againstLogo}
                        teamTwoGoals={scoreData.teamTwoGoals}
                        firstHalf={scoreData.firstHalf}
                      ></SoccerAndHockeyScoreCard>
                    )}

                    {/* SHOW SELF PLAY SCORE */}
                    {/* SHOW SELF PLAY SCORE */}
                    {(selectedStream.gameName == "SelfPlaySoccer" ||
                      selectedStream.gameName == "SelfPlayHockey" ||
                      selectedStream.gameName == "SelfPlayBasketball") && (
                      <div
                        style={
                          style.soccerAndHockeyCardContainerForAnimationResult
                        }
                      >
                        {selfPlayDetails?.participantsPlayer.map(
                          (item, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                fontSize: "28px",
                                fontWeight: "bold",
                                width: "250px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "grey",
                                margin: "10px",
                                paddingTop: "7px",
                                paddingBottom: "7px",
                                paddingLeft: "15px",
                                paddingRight: "15px",
                                borderRadius: "15px",
                                border:
                                  currentHitPerson == index
                                    ? "2px solid yellow"
                                    : null,
                              }}
                            >
                              <img
                                src={
                                  selfPlayDetails.gameType === "soccer"
                                    ? iconFinder(item.soccerStats.soccerAvatar)
                                    : selfPlayDetails.gameType === "hockey"
                                    ? iconFinder(item.hockeyStats.hockeyAvatar)
                                    : selfPlayDetails.gameType === "basketBall"
                                    ? iconFinder(item.soccerStats.soccerAvatar)
                                    : null
                                }
                                style={{ height: "40px", width: "40px" }}
                              />
                              <div style={{ color: "yellow" }}>
                                {item.name?.toUpperCase()?.slice(0, 7)}
                              </div>
                              <div style={{ color: "white" }}>
                                {selfPlayDetails?.successScore[index]}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </Animate>
              </div>
            )}
          </div>
        ) : isFullScreenStreaming ? (
          <div style={style.noBroadcasterText}>
            <div>Attempting to connect to the live stream!</div>
            {/* CROSS BUTTON */}
            <div style={{}}>
              <button
                className="button-plain"
                onClick={() => {
                  setIsFullScreenStreaming(false);
                  leaveChannel();
                }}
              >
                <i
                  style={{
                    color: "white",
                    fontSize: "250%",
                  }}
                  className="fa fa-times mt-5"
                ></i>
              </button>
            </div>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "430px",
            }}
          >
            <div style={style.noBroadcasterText}>
              Attempting to connect to the live stream!
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

// STYLE
const style = {
  soccerAndHockeyCardContainerForStreaming: {
    paddingLeft: "20px",
    paddingTop: "20px",
    width: "100%",
    height: "100%",
  },
  soccerAndHockeyCardContainerForAnimationResult: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },

  videoContainer: {
    width: "100%",
    height: "430px",
    position: "relative",
  },
  vid: {
    height: "430px",
    width: "100%",
  },
  noBroadcasterText: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    height: "400px",
    color: "lightgrey",
  },
  clock: {
    display: "flex",
    flexDirection: "row",
    width: "145px",
    backgroundColor: "green",
    height: "47px",
    borderRadius: "8px",
    justifyContent: "center",
    alignItems: "center",
  },
  clockText: {
    fontSize: "35px",
    fontWeight: "bold",
    color: "white",
    margin: "5px",
  },
  // ######### Upper Row related all CSS START #########
  // ######### Upper Row related all CSS START #########
  liveClockLogoContainer: {
    position: "absolute",
    top: 17,
    right: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sportLogoContainer: {
    width: "100px",
  },
  sportLogo: {
    height: "100px",
    width: "100px",
    opacity: 0.4,
  },
  goalLargeImage: {
    height: "300px",
    width: "300px",
  },
  worldSeriesSportLogo: {
    height: "70px",
    width: "85px",
  },
  audienceCountSection: {
    width: "70px",
    height: "30px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "rgba(94, 88, 88, 0.49)",
    borderRadius: "5px",
    padding: "10px",
    marginRight: "20px",
    marginLeft: "10px",
  },
  crossLiveTextSection: {
    width: "210px",
    height: "70px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  liveTextButtonMediumView: {
    height: "30px",
    width: "40px",
    fontSize: 13,
    display: "flex",
    fontWeight: "bold",
    backgroundColor: "red",
    borderRadius: "5px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
    color: "white",
  },

  // ########## Live Score For DESKTOP related all CSS START ##########
  // ########## Live Score For DESKTOP related all CSS START ##########
  liveScoreAndTimeBoxContainer: {
    position: "absolute",
    bottom: 21,
    width: "100%",
    height: "30px",
    display: "flex",
  },
  liveScoreBox: {
    width: "176px",
    height: "30px",
    display: "block",
    margin: "auto",
    backgroundColor: "black",
    border: ".3px solid gray",
    borderRadius: "8px",
  },
  liveScoreInnerBox: {
    display: "flex",
  },
  leftTeamLogoScoreSection: {
    display: "flex",
    width: "90px",
    height: "30px",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    justifyContent: "space-between",
    textAlign: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
    // backgroundColor: "red",
  },
  rightTeamLogoScoreSection: {
    display: "flex",
    width: "90px",
    height: "30px",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    justifyContent: "space-between",
    textAlign: "center",
    paddingLeft: "5px",
    paddingRight: "15px",
  },
  avatar: {
    width: "18px",
    height: "18px",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  largeAvatar: {
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  teamName: {
    width: "50px",
    height: "30px",
    fontSize: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  scoreText: {
    width: "15px",
    height: "70px",
    fontSize: 12,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  liveScoreMiddleLine: {
    width: "10px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 10,
    fontWeight: "bold",
  },
};
