import gql from "graphql-tag";

export default gql`
  mutation updateKidsParentRelation(
    $parentName: String
    $parentEmail: String
    $kidUserId: String
    $relation: String
    $baseSlug: String
  ) {
    updateKidsParentRelation(
      parentName: $parentName
      parentEmail: $parentEmail
      kidUserId: $kidUserId
      relation: $relation
      baseSlug: $baseSlug
    ) {
      errorMessage
    }
  }
`;
