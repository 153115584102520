import { gql } from "@apollo/client";

export default gql`
  query findMyInvitedEvent($userId: String) {
    findMyInvitedEvent(userId: $userId) {
      _id
      eventType
      caption
      dateTime
      normalDate
      eventPrice
      duration
      sports
      gameType
      slot
      participantKidIds
    }
  }
`;
