import gql from "graphql-tag";

export default gql`
  mutation initiateStripeSession(
    $project: String!
    $userId: String!
    $eventId: String
    $kidIds: [String]
    $baseSlug: String
    $couponCode: String
    $slotAmountToPurchase: Int
  ) {
    initiateStripeSession(
      project: $project
      userId: $userId
      eventId: $eventId
      kidIds: $kidIds
      baseSlug: $baseSlug
      couponCode: $couponCode
      slotAmountToPurchase: $slotAmountToPurchase
    ) {
      additionalData
      errorMessage
      successMessage
    }
  }
`;
