import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";

const gqlClient = new ApolloClient({
  uri: "https://basement-sports-server.herokuapp.com/graphql",
  // uri: "https://bsports-staging-server.herokuapp.com/graphql",
  // uri: "http://localhost:3000/graphql",
  cache: new InMemoryCache({ addTypename: false }),
});

export default gqlClient;
