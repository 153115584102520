import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import FIND_MY_NFTS from "../../../gql/query/nft/findMyNfts.query";
import CategoryItem from "../../../component/categories/CategoryItem";
import DataLoading from "../../../component/loader/DataLoading";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../component/collections-wide/sidebar/Sidebar";

import { Tab, TabList } from "react-tabs";
import { sk } from "date-fns/locale";
import CommonBackdrop from "../../../component/common/CommonBackdrop";

function MyNfts() {
  const navigate = useNavigate();

  const [countryFilter, setCountryFilter] = useState([]);
  const [skinFilter, setSkinFilter] = useState([]);
  const [positionFilter, setPositionFilter] = useState([]);
  const [jerseyFilter, setJerseyFilter] = useState([]);
  const [legFilter, setLegFilter] = useState([]);
  const [hairFilter, setHairFilter] = useState([]);
  const [mouthFilter, setMouthFilter] = useState([]);

  const [changeCurrentPage, setChangeCurrentPage] = useState(1);

  const [nftCollectionType, setNftCollectionType] = useState("Female");
  const [nftData, setNftData] = useState([]);
  const [itemsTabs, setItemsTabs] = useState(2);

  const collectionItemsTabs = [
    {
      id: 1,
      text: "Men's",
      icon: "items",
    },
    {
      id: 2,
      text: "Women's",
      icon: "activities",
    },
  ];

  const {
    data: myNftsData,
    loading: myNftsLoading,
    error: myNftsError,
    refetch: refetchMyNfts,
  } = useQuery(FIND_MY_NFTS, {
    variables: {
      mintBy: localStorage.getItem("userid"),
    },
  });

  function callData(data, type) {
    let tempNft = JSON.parse(JSON.stringify(data));
    tempNft = tempNft.filter((i) => i.collectionType === type);
    setNftData(tempNft);
  }

  async function extraFilterFunction(
    country,
    skin,
    mouth,
    position,
    leg,
    hair,
    jersey
  ) {
    let tempNft = JSON.parse(JSON.stringify(myNftsData.findMyNfts));
    if (country && country.length > 0) {
      tempNft = tempNft.filter((item) => country.includes(item.country));
    }

    if (skin && skin.length > 0) {
      tempNft = tempNft.filter((item) => skin.includes(item.skin));
    }

    if (mouth && mouth.length > 0) {
      tempNft = tempNft.filter((item) => mouth.includes(item.mouth));
    }

    if (position && position.length > 0) {
      tempNft = tempNft.filter((item) => position.includes(item.position));
    }

    if (leg && leg.length > 0) {
      tempNft = tempNft.filter((item) => leg.includes(item.dominantLeg));
    }

    if (hair && hair.length > 0) {
      tempNft = tempNft.filter((item) => hair.includes(item.hair));
    }

    if (jersey && jersey.length > 0) {
      tempNft = tempNft.filter((item) => jersey.includes(item.jersey));
    }

    callData(tempNft, nftCollectionType);
  }

  async function clearFilter() {
    setCountryFilter([]);
    setSkinFilter([]);
    setPositionFilter([]);
    setJerseyFilter([]);
    setLegFilter([]);
    setHairFilter([]);
    setMouthFilter([]);
    extraFilterFunction([]);
  }

  useEffect(() => {
    if (!localStorage.getItem("userid")) {
      navigate("/login");
    }
    if (myNftsData) {
      callData(myNftsData.findMyNfts, nftCollectionType);
    }
  }, [myNftsData]);

  if (myNftsError) return <DataLoading loadingType="error" />;

  return (
    <CommonBackdrop>
      <div className=" relative z-10  pb-24 lg:w-11/12 mx-auto">
        <div className="lg:flex  px-2">
          <aside
            key={itemsTabs || nftCollectionType}
            className="lg:fixed lg:top-28  lg:z-40 lg:w-72 lg:h-[85%] overflow-y-scroll no-scrollbar "
          >
            <div className="gap-2 flex  px-2 h-12 items-center  rounded-lg">
              <h1 className="font-display mr-2 border h-7 w-20 flex items-center justify-center rounded p-1 px-2  text-center font-semibold text-white  transition-all">
                filter
              </h1>

              <button
                onClick={() => clearFilter()}
                className="font-display border  h-7 w-20 rounded p-1 px-2  text-center font-semibold text-white  transition-all"
              >
                <h1 className="text-sm">Clear All</h1>
              </button>
            </div>
            <Sidebar
              nftCollectionType={nftCollectionType}
              setChangeCurrentPage={setChangeCurrentPage}
              setCountryFilter={setCountryFilter}
              countryFilter={countryFilter}
              setSkinFilter={setSkinFilter}
              skinFilter={skinFilter}
              setPositionFilter={setPositionFilter}
              positionFilter={positionFilter}
              setJerseyFilter={setJerseyFilter}
              jerseyFilter={jerseyFilter}
              setLegFilter={setLegFilter}
              legFilter={legFilter}
              setHairFilter={setHairFilter}
              hairFilter={hairFilter}
              setMouthFilter={setMouthFilter}
              mouthFilter={mouthFilter}
              extraFilterFunction={extraFilterFunction}
            />
          </aside>
          <div className="lg:ml-80  lg:w-10/12">
            {myNftsLoading ? (
              <DataLoading loadingType="success" />
            ) : (
              <div key={nftData} className="lg:w-full">
                {nftData.length > 0 ? (
                  <div>
                    <TabList className="nav nav-tabs border-yellow-300/30 mb-12 flex items-center justify-center border-b">
                      {collectionItemsTabs.map(({ id, text, icon }) => {
                        return (
                          <Tab
                            className="nav-item"
                            key={id}
                            onClick={async () => {
                              if (id === 1 && !myNftsLoading) {
                                await setNftCollectionType("Male");
                                await callData(myNftsData.findMyNfts, "Male");
                                setItemsTabs(id);
                                // await clearFilter();
                              } else if (id === 2 && !myNftsLoading) {
                                await setNftCollectionType("Female");
                                await callData(myNftsData.findMyNfts, "Female");
                                setItemsTabs(id);
                                // await clearFilter();
                              }
                            }}
                          >
                            <button
                              className={
                                itemsTabs === id
                                  ? "text-yellow-300 relative flex items-center whitespace-nowrap py-3 px-6 active border-b-2 border-yellow-300 ease-in-out duration-500"
                                  : "hover:text-yellow-200 text-gray-200 relative flex items-center whitespace-nowrap py-3 px-6 ease-in-out duration-500"
                              }
                            >
                              <span className="font-display text-base font-medium">
                                {text}
                              </span>
                            </button>
                          </Tab>
                        );
                      })}
                    </TabList>
                    <CategoryItem item={nftData} minted={true} />
                  </div>
                ) : (
                  <h1
                    className="w-[320px] mx-auto mt-20 text-xl text-center"
                    style={{ color: "#d0d0fd" }}
                  >
                    Sorry You Have No NFTs in this type
                  </h1>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </CommonBackdrop>
  );
}

export default MyNfts;
