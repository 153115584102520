import { gql } from "@apollo/client";

export default gql`
  query findBaseEventsByFilter(
    $baseSlug: String
    $baseId: String
    $year: Int
    $month: Int
    $particularDate: String
    $filterBy: String
    $searchName: String
  ) {
    findBaseEventsByFilter(
      baseSlug: $baseSlug
      baseId: $baseId
      year: $year
      month: $month
      particularDate: $particularDate
      filterBy: $filterBy
      searchName: $searchName
    ) {
      _id
      eventType
      caption
      dateTime
      eventPrice
      duration
      sports
      gameType
      slot
      participantKidIds
    }
  }
`;
