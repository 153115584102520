import gql from "graphql-tag";

export default gql`
  mutation acceptInvitationWithoutLogin(
    $email: String
    $eventId: String!
    $kids: [acceptInvitationKids]
  ) {
    acceptInvitationWithoutLogin(
      email: $email
      eventId: $eventId
      kids: $kids
    ) {
      token
      errorMessage
      user {
        _id
        email
        name
        avatar
        verified
        city
        country
        unreadNotificationCount
        kidCoinToken
        affiliateCouponCode
        userType
        baseInfo {
          _id
          name
          slug
        }
      }
    }
  }
`;
