import gql from "graphql-tag";

export default gql`
  mutation createCouponCodeByAdmin(
    $adminUserId: String
    $email: String
    $couponCode: String
    $baseSlug: String
  ) {
    createCouponCodeByAdmin(
      adminUserId: $adminUserId
      email: $email
      couponCode: $couponCode
      baseSlug: $baseSlug
    ) {
      _id
      userId
      affiliateCouponCode
      adminUserId
      totalNftSell
      totalNftPayout
      totalSingleEventSell
      totalMonthlySubscriptionSell
      totalBasePayout
      errorMessage
    }
  }
`;
