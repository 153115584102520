import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowRight,
  faCirclePlay,
} from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { format, formatISO } from "date-fns";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";

import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { Carousel } from "react-responsive-carousel";

// Query
import FIND_USER_DETAILS from "../../gql/query/profile/me.query";
import FIND_UPCOMING_EVENTS from "../../gql/query/base/findUpcomingEvents.query";
import CHECK_USER_SUBSCRIPTION from "../../gql/query/base/checkBaseEventSubscription.query";
import FIND_MY_KIDS from "../../gql/query/base/findKidsForParent.query";
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetailsForBaseDashboard.query";

// MUTATION
import PARTICIPATE_EVENT from "../../gql/mutation/base/participateEvent.mutations";
import ENROLL_KIDS_USING_SLOT from "../../gql/mutation/base/enrollKidsUsingSlot.mutations";
import INITIATE_STRIPE_SESSION from "../../gql/mutation/base/initiateStripeSession.mutations";
import INITIATE_STRIPE_BILLING_PORTAL_SESSION from "../../gql/mutation/base/initiateStripeBillingPortalSession.mutations";
import CHECK_COUPON_CODE_VALIDITY from "../../gql/mutation/nft/checkCouponCodeValidity.mutations";

import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  SUPER_BASE_COUPON_CODE_INFO,
  RANDOM_AVATAR_URLS,
  PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
  PROJECT_BSPORTS_SUPER_BASE_MONTHLY_SUBSCRIPTION,
  PROJECT_BSPORTS_SUPER_BASE_EVENT_SLOT_PURCHASE,
  EVENTS_TYPE,
  OTHER_VIDEO_TYPES_TO_FETCH,
  BASEMENT_SPORTS_OFFICAL_LOCATION,
} from "../../utils/constant";

const BaseHome = () => {
  const navigate = useNavigate();

  const { card, setCard } = useContext(ShoppingCardContext);

  const [showForMan, setShowForMan] = useState(false);
  const [itemsTabs, setItemsTabs] = useState(2);

  const [slotDetails, setSlotDetails] = useState({
    price: 0,
    discount: 0,
    subTtile: "",
    description: "",
  });
  const [membershipDetails, setMembershipDetails] = useState({
    price: 0,
    discount: 0,
    subTtile: "",
    description: "",
  });

  // Modal STATE
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedKidIds, setSelectedKidIds] = useState([]);

  // Coupon Code State
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeCheckResponse, setCouponCodeCheckResponse] = useState("");
  const [totalDiscountInDollar, setTotalDiscountInDollar] = useState(0);

  // Slot related STATE
  const [slotAmount, setSlotAmount] = useState(1);

  // Event price state
  const [selectedEventPrice, setSelectedEventPrice] = useState(0);

  NFT_COUNTRIES.sort((a, b) =>
    a.name.charAt(0).localeCompare(b.name.charAt(0))
  );

  WOMEN_NFT_COUNTRIES.sort((a, b) =>
    a.name.charAt(0).localeCompare(b.name.charAt(0))
  );

  // Calender State
  const [dateValue, setDateValu] = useState("");

  // Query
  const {
    data: findUserDetails,
    loading: loadingFindUserDetails,
    error: errorFindUserDetails,
    refetch: refetchFindUserDetails,
  } = useQuery(FIND_USER_DETAILS, {
    variables: {
      _id: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_UPCOMING_EVENTS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  const {
    data: userSubscriptionData,
    loading: userSubscriptionLoading,
    error: userSubscriptionError,
    refetch: userSubscriptionRefetch,
  } = useQuery(CHECK_USER_SUBSCRIPTION, {
    variables: {
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });
  const {
    data: findMyKids,
    loading: findMyKidsLoading,
    error: findMyKidsError,
    refetch: findMyKidsRefetch,
  } = useQuery(FIND_MY_KIDS, {
    variables: {
      parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });
  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // MUTATION
  const [
    participateEvent,
    { loading: participateEventLoading, error: participateEventError },
  ] = useMutation(PARTICIPATE_EVENT);
  const [
    enrollKidsUsingSlot,
    { loading: enrollKidsUsingSlotLoading, error: enrollKidsUsingSlotError },
  ] = useMutation(ENROLL_KIDS_USING_SLOT);
  const [
    initiateStripeSessionMutation,
    {
      loading: initiateStripeSessionMutationLoading,
      error: initiateStripeSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_SESSION);
  const [
    initiateStripeBillingPortalSessionMutation,
    {
      loading: initiateStripeBillingPortalSessionMutationLoading,
      error: initiateStripeBillingPortalSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_BILLING_PORTAL_SESSION);
  const [
    checkCouponCodeValidity,
    { loading: loadingCheckCouponValidity, error: errorCheckCouponValidity },
  ] = useMutation(CHECK_COUPON_CODE_VALIDITY);

  useEffect(() => {
    function initializePage() {
      if (card.collectionType === "Male") {
        setItemsTabs(1);
        setShowForMan(true);
      }
      eventsRefetch();
      userSubscriptionRefetch();
      findMyKidsRefetch();
      baseRefetch();
    }
    initializePage();
  }, []);

  async function joinEventAsASubscriptionUser(eventId) {
    if (!selectedKidIds || selectedKidIds.length == 0) {
      toast.error("Please select a KID to join the event!");
      return;
    }
    const { data } = await participateEvent({
      variables: {
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        eventId,
        kidIds: selectedKidIds,
      },
    });
    if (data.participateEvent.errorMessage == null) {
      toast.success("Successfully enrolled into this event!");
      eventsRefetch();
      setSelectedKidIds([]);
      setIsOpen(false);
      return;
    } else {
      toast.error(data.participateEvent.errorMessage);
      return;
    }
  }

  // Check CouponCode Validity
  async function checkCouponValidity(couponCode, paymentFor) {
    // Set coupon code
    setCouponCode(couponCode);
    // Call to backend for checking this coupon code
    const { data } = await checkCouponCodeValidity({
      variables: {
        couponCode,
      },
    });
    // If coupon code is correct then set discount price
    if (data.checkCouponCodeValidity.errorMessage == null) {
      setCouponCodeCheckResponse(data.checkCouponCodeValidity.successMessage);
      // If Monthly subscription
      if (paymentFor == PAYMENT_FOR.PURCHASE_FULL_MONTH_EVENT_OF_BASE) {
        setTotalDiscountInDollar(
          SUPER_BASE_COUPON_CODE_INFO.MONTHLY_SUBSCRIPTION_DISCOUNT_IN_DOLLAR
        );
      } else if (paymentFor == PAYMENT_FOR.PURCHASE_SINGLE_EVENT_OF_BASE) {
        // Else Single event purchase
        setTotalDiscountInDollar(
          SUPER_BASE_COUPON_CODE_INFO.SINGLE_EVENT_PURCHASE_DISCOUNT_IN_DOLLAR
        );
      }
    } else if (data.checkCouponCodeValidity.errorMessage) {
      setTotalDiscountInDollar(0);
      setCouponCodeCheckResponse(data.checkCouponCodeValidity.errorMessage);
    }
  }

  // Single Event Purchase
  async function initiateStripeCheckoutUi(eventId, parentUserAvailableSlot) {
    if (!selectedKidIds || selectedKidIds.length == 0) {
      toast.error("Please select a KID to join the event!");
      return;
    }
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // If slots available then enroll kids via slots
      if (parentUserAvailableSlot > 0) {
        // Else Call the Mutation to get STRIPE session URL
        const { data } = await enrollKidsUsingSlot({
          variables: {
            parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
            eventId,
            kidIds: selectedKidIds,
          },
        });
        if (data.enrollKidsUsingSlot.errorMessage == null) {
          baseRefetch();
          findMyKidsRefetch();
          eventsRefetch();
          // Off modal
          setIsOpen(false);
          toast.success(data.enrollKidsUsingSlot.successMessage);
        } else {
          toast.error(data.enrollKidsUsingSlot.errorMessage);
        }
      } else {
        // Else Call the Mutation to get STRIPE session URL
        const { data } = await initiateStripeSessionMutation({
          variables: {
            project: PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
            userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
            eventId,
            kidIds: selectedKidIds,
            couponCode,
          },
        });
        if (data.initiateStripeSession.errorMessage == null) {
          setSelectedKidIds([]);
          setIsOpen(false);
          window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
        } else {
          toast.error(data.initiateStripeSession.errorMessage);
        }
      }
    } else {
      // As user is not logged in, so navigate the user to registration page
      navigate(`/login`);
      return;
    }
  }

  // For Monthy subscription
  async function initiateStripeCheckoutUiForMonthlySubscription() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Call the Mutation to get STRIPE session URL
      const { data } = await initiateStripeSessionMutation({
        variables: {
          project: PROJECT_BSPORTS_SUPER_BASE_MONTHLY_SUBSCRIPTION,
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
          couponCode,
        },
      });
      if (data.initiateStripeSession.errorMessage == null) {
        window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      } else {
        toast.error(data.initiateStripeSession.errorMessage);
      }
    } else {
      // As user is not logged in, so navigate the user to registration page
      navigate(`/login`);
      return;
    }
  }

  // For Monthy subscription
  async function initiateStripeCheckoutUiForSlotPurchase() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Call the Mutation to get STRIPE session URL
      const { data } = await initiateStripeSessionMutation({
        variables: {
          project: PROJECT_BSPORTS_SUPER_BASE_EVENT_SLOT_PURCHASE,
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
          slotAmountToPurchase: Number(slotAmount),
        },
      });
      if (data.initiateStripeSession.errorMessage == null) {
        window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      } else {
        toast.error(data.initiateStripeSession.errorMessage);
      }
    } else {
      // As user is not logged in, so navigate the user to registration page
      navigate(`/login`);
      return;
    }
  }

  // This function will generate a Billing Portal for management of Subscriptions
  async function initiateStripeBillingPortalForSubscriptionManagment() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Call the Mutation to get STRIPE session URL
      const { data } = await initiateStripeBillingPortalSessionMutation({
        variables: {
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        },
      });
      if (data.initiateStripeBillingPortalSession.errorMessage == null) {
        window.location.href =
          data.initiateStripeBillingPortalSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      }
    } else {
      // As user is not logged in, so navigate the user to registration page
      navigate(`/login`);
      return;
    }
  }

  // Handle Calender Actions
  function handleClickDay(date) {
    // Today date
    const currentDate = new Date();

    // First convert the date from STRING to DATE format
    const selectedDate = new Date(date);

    // Set current Hour, Minute, Second (As DatePicker can't handle it)
    selectedDate.setHours(currentDate.getHours());
    selectedDate.setMinutes(currentDate.getMinutes());
    selectedDate.setSeconds(currentDate.getSeconds());

    let formattedDate = formatISO(selectedDate, { representation: "complete" });

    setDateValu(formattedDate);
  }

  // Modal functions
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);

    // Make coupon code info as empty for safety
    setCouponCode("");
    setCouponCodeCheckResponse("");
    setTotalDiscountInDollar(0);
  }

  useEffect(() => {
    function setDate() {
      if (baseData?.findBaseDetails?.slotDetails) {
        setSlotDetails(
          JSON.parse(JSON.stringify(baseData.findBaseDetails?.slotDetails))
        );
      }

      if (baseData?.findBaseDetails?.membershipDetails) {
        setMembershipDetails(
          JSON.parse(
            JSON.stringify(baseData.findBaseDetails?.membershipDetails)
          )
        );
      }
    }
    setDate();
    baseRefetch();
  }, [baseData]);

  if (
    eventsLoading ||
    userSubscriptionLoading ||
    findMyKidsLoading ||
    loadingFindUserDetails ||
    baseLoading
  )
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (
    eventsError ||
    userSubscriptionError ||
    findMyKidsError ||
    errorFindUserDetails ||
    baseError
  ) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  // Set available slot to use

  let allSlotPurchased = baseData?.findBaseDetails?.slotPurchased;
  let singleSlotPrice = baseData?.findBaseDetails?.slotDetails.price
    ? baseData?.findBaseDetails?.slotDetails?.price
    : 25;
  let parentUserAvailableSlot = 0;

  if (allSlotPurchased) {
    let parentUserSlotInfoIndex = allSlotPurchased.findIndex(
      (item) =>
        item.parentId === localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
    );

    if (parentUserSlotInfoIndex !== -1) {
      parentUserAvailableSlot =
        allSlotPurchased[parentUserSlotInfoIndex].slotAvailable;
    }
  }

  return (
    <CommonBackdrop>
      <div className="px-10">
        <div className="md:w-full sm:w-full sx:w-full px-2 w-full ">
          <section className="">
            <div className="w-full bg-black/20 px-10 h-10 flex items-center flex-row mt-5">
              <h1 className="text-yellow-300">
                {baseData?.findBaseDetails?.name} /{" "}
              </h1>
              <h1>Dashboard</h1>
            </div>

            {/* CAROUSEL */}
            <div
              style={{
                maxHeight: "500px",
                borderRadius: "5px",
                overflow: "hidden",
              }}
              className="mt-5"
            >
              {baseData?.findBaseDetails?.sliderImages && (
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  interval={5000}
                  showThumbs={false}
                >
                  {baseData?.findBaseDetails?.sliderImages?.map(
                    (image, index) => (
                      <div key={index}>
                        <img
                          src={image}
                          alt={`Image ${index}`}
                          style={{
                            maxHeight: "500px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    )
                  )}

                  {/* <div>
                  <img
                    src="https://res.cloudinary.com/dvqldxdfv/image/upload/v1704274879/cwjjddrdxoh5izzvx4zp.jpg"
                    alt="Image 1"
                  />
                  <p className="legend">Image 1</p>
                </div>
                <div>
                  <img
                    src="https://res.cloudinary.com/dvqldxdfv/image/upload/v1704275328/gycriuw8hcixlnscs1no.jpg"
                    alt="Image 2"
                  />
                  <p className="legend">Image 2</p>
                </div>
                <div>
                  <img
                    src="https://res.cloudinary.com/dvqldxdfv/image/upload/v1704275349/f8yt0odmjd8fvpits7nl.jpg"
                    alt="Image 3"
                  />
                  <p className="legend">Image 3</p>
                </div> */}
                </Carousel>
              )}
            </div>

            {/* CALENDER & SCHDULEs */}
            <div className="flex justify-between flex-wrap">
              {/* HOME IMAGE */}
              <div className="w-full md:w-1/2">
                {/* title */}
                <div
                  className="w-full flex items-center flex-row mt-5"
                  style={{ justifyContent: "space-between" }}
                >
                  <h1 style={{ fontWeight: "700" }}>
                    Welcome {findUserDetails?.me?.name}!
                  </h1>
                </div>
                {/* image */}
                <div className="my-4">
                  <img
                    src={baseData?.findBaseDetails?.bannerImage}
                    alt="Basement Sports"
                    className="object-cover"
                    width={"100%"}
                    height={"auto"}
                  />
                </div>

                {/* ADD MEMBER */}
                <div className="my-4">
                  <div className="bg-black/20 flex justify-between items-center font-display text-yellow-300 text-lg rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30">
                    <div className="font-semibold">
                      {/* title + button */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Membership</div>
                        {/* add member button */}
                        <button
                          className="text-sm font-bold py-1 px-5 rounded-full"
                          style={{
                            backgroundColor: "#0000008a",
                            color: "#FFFFFF",
                          }}
                          onClick={() => {
                            if (
                              !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
                            ) {
                              navigate(`/login`);
                            } else {
                              navigate("/add-kid");
                            }
                          }}
                        >
                          Add Member
                        </button>
                      </div>

                      <div
                        className="text-md"
                        style={{ color: "#ccd0e0", fontWeight: "bold" }}
                      >
                        {membershipDetails.subTtile}
                      </div>

                      <div
                        className="text-sm mt-5"
                        style={{ color: "#ccd0e0" }}
                      >
                        {membershipDetails.description}
                      </div>
                    </div>
                  </div>
                </div>

                {/* SINGLE EVENT PROMOTION */}
                <div className="my-4">
                  <div
                    className="bg-black/20 flex justify-between items-center font-display text-yellow-300 text-lg rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30 cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/${LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG}/eventscalender`
                      )
                    }
                  >
                    <div className="font-semibold">
                      {/* CAPTION */}
                      <div>Drop-In Events</div>
                      <div
                        className="text-md"
                        style={{ color: "#ccd0e0", fontWeight: "bold" }}
                      >
                        Enjoy the events
                      </div>

                      <div
                        className="text-sm mt-5"
                        style={{ color: "#ccd0e0" }}
                      >
                        Sessions run for 90-120 minutes. During weekdays, we
                        offer 2 sessions per day, with special events on
                        weekends.{" "}
                      </div>
                    </div>
                  </div>
                </div>

                {/* BASE LOCATION */}
                <div className="my-4">
                  <div className="bg-black/20 flex justify-between items-center font-display text-yellow-300 text-lg rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30">
                    <div className="font-semibold">
                      {/* CAPTION */}
                      <div>Location</div>

                      <div
                        className="text-sm mt-5"
                        style={{ color: "#ccd0e0" }}
                      >
                        {baseData?.findBaseDetails?.address
                          ? baseData?.findBaseDetails?.address
                          : BASEMENT_SPORTS_OFFICAL_LOCATION}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* SLOT PURCHASE + SCHDULES */}
              <div className="w-full md:w-1/2 ">
                {/* ADD MEMBER */}
                <div className="md:ml-5 mt-6">
                  <h1 style={{ fontWeight: "700" }}>Purchase Slot!</h1>
                </div>
                <div className="md:ml-5 mt-3">
                  <div className="bg-black/20 flex justify-between items-center font-display text-yellow-300 text-lg rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30">
                    <div className="font-semibold">
                      {/* title + button */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>#Slot Management</div>

                        {/* slot purchase number + button */}
                        <div className="ml-5">
                          {/* slot number to purchase */}
                          <input
                            onChange={(e) => setSlotAmount(e.target.value)}
                            className="bg-black/30 border-2  border-green-300  focus:border-yellow-300 focus:outline-none rounded-lg mr-3"
                            type="number"
                            min={1}
                            value={slotAmount}
                            style={{ width: "65px", height: "30px" }}
                          />
                          {/* slot purchase button */}
                          <button
                            className="text-sm font-bold py-1 px-5 rounded-full"
                            style={{
                              backgroundColor: "#0000008a",
                              color: "#FFFFFF",
                            }}
                            onClick={() => {
                              if (
                                !localStorage.getItem(
                                  LOCAL_STORAGE_KEYS.USER_ID
                                )
                              ) {
                                navigate(`/login`);
                              } else {
                                initiateStripeCheckoutUiForSlotPurchase();
                              }
                            }}
                          >
                            Purchase Slot
                          </button>
                        </div>
                      </div>

                      <div
                        className="text-md mt-2"
                        style={{ color: "#ccd0e0", fontWeight: "bold" }}
                      >
                        {/* ${singleSlotPrice}/Slot ($10 discount on each 4 slots) */}
                        {slotDetails.subTtile}
                      </div>

                      <div
                        className="text-sm mt-5"
                        style={{ color: "#ccd0e0" }}
                      >
                        {slotDetails.description}
                      </div>

                      {/* Total payable amount */}
                      <div className="mt-4">
                        Total amount to pay: $
                        {slotAmount * singleSlotPrice -
                          Math.floor(slotAmount / 4) * 10}
                      </div>

                      <div style={{ color: "#add8ff" }} className="mt-3">
                        Slot available to use: {parentUserAvailableSlot}
                      </div>
                    </div>
                  </div>
                </div>

                {/* event */}
                <div>
                  {/* title */}
                  <div
                    className="w-full flex items-center flex-row mt-5 md:ml-5 pr-5"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h1 style={{ fontWeight: "700" }}>Upcoming Events</h1>
                    <Link
                      to={`/${LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG}/eventscalender`}
                    >
                      <h1 style={{ fontWeight: "700" }}>
                        See All{" "}
                        <FontAwesomeIcon
                          className="mr-3"
                          icon={faCircleArrowRight}
                        />
                      </h1>
                    </Link>
                  </div>
                  {eventsData?.findUpcomingEvents?.map((event, index) => {
                    return (
                      <div>
                        {/* DATE + EVENT info + BOOK session button */}
                        <div
                          className="bg-black/20 mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 md:ml-5 py-2"
                          key={index}
                          style={{
                            borderLeft: "5px solid yellow",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* DATE & Event INFO */}
                          <div
                            style={{
                              display: "flex",
                            }}
                            className="py-2"
                          >
                            {/* DATE section */}
                            <div
                              style={{
                                color: "white",
                                width: "80px",
                                borderRight: "1px dotted #ffffff45",
                                paddingLeft: "15px",
                              }}
                              className="text-2xl"
                            >
                              {format(new Date(event.dateTime), "EEE MMM dd")}
                            </div>

                            {/* Schdule INFO section */}
                            <div className="font-semibold ml-5">
                              {/* TIME */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div class="w-3 h-3 rounded-full bg-blue-500 flex items-center justify-center">
                                  <div class="w-1 h-1 rounded-full bg-black"></div>
                                </div>
                                {/* Time value */}
                                <div
                                  style={{ color: "#8F9BB3" }}
                                  className="text-sm ml-2"
                                >
                                  {format(new Date(event.dateTime), "h:mm aa")}
                                </div>
                              </div>

                              {event.eventType == EVENTS_TYPE.SPECIAL_EVENTS ? (
                                <>
                                  {event.caption ? (
                                    <div className="text-xl mt-1 mb-2">
                                      {event.caption}
                                    </div>
                                  ) : (
                                    <div className="text-xl mt-1 mb-2">
                                      Special Event
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {/* CAPTION */}
                                  <div className="text-sm">
                                    Event Type:{" "}
                                    <b style={{ color: "#ccd0e0" }}>
                                      {event.eventType}
                                    </b>
                                  </div>

                                  {/* CAPTION */}
                                  {event.caption && (
                                    <div className="text-sm">
                                      Title:{" "}
                                      <b style={{ color: "#ccd0e0" }}>
                                        {event.caption}
                                      </b>
                                    </div>
                                  )}
                                </>
                              )}

                              {/* DURATIOON */}
                              {event.duration && (
                                <div className="text-sm">
                                  Duration:{" "}
                                  <b style={{ color: "#ccd0e0" }}>
                                    {event.duration}
                                  </b>
                                </div>
                              )}

                              {/* SPORTS */}
                              {event.sports && event.sports.length > 0 && (
                                <div className="text-sm">
                                  Sports:{" "}
                                  <b style={{ color: "#ccd0e0" }}>
                                    {event.sports.join(", ")}
                                  </b>
                                </div>
                              )}

                              {/* GAME TYPE */}
                              {event.gameType && (
                                <div className="text-sm">
                                  Game Type:{" "}
                                  <b style={{ color: "#ccd0e0" }}>
                                    {event.gameType}
                                  </b>
                                </div>
                              )}

                              {/* SLOT */}
                              {event.slot && (
                                <div className="text-sm">
                                  #SLOT:{" "}
                                  <b style={{ color: "#ccd0e0" }}>
                                    {event.slot}
                                  </b>
                                </div>
                              )}

                              {/* PRICE */}
                              {event.eventPrice && (
                                <div
                                  className="text-md mt-2"
                                  style={{ color: "#00fff5" }}
                                >
                                  Price: ${event.eventPrice}
                                </div>
                              )}
                            </div>
                          </div>

                          <div
                            className="mr-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {/* BOOKED KIDS */}
                            <div style={{ display: "flex" }}>
                              {findMyKids?.findKidsForParent
                                ?.filter((kid) =>
                                  event.participantKidIds.includes(kid._id)
                                )
                                .map((kid) => {
                                  return (
                                    <div
                                      className="mr-2 cursor-pointer"
                                      onClick={() => {
                                        navigate(
                                          `/kid-details/${kid.player._id}`
                                        );
                                      }}
                                    >
                                      <img
                                        src={
                                          RANDOM_AVATAR_URLS[kid.avatar]
                                            ? RANDOM_AVATAR_URLS[kid.avatar]
                                            : RANDOM_AVATAR_URLS["user4_asset4"]
                                        }
                                        alt="Basement Sports"
                                        className="object-contain h-8"
                                      />
                                    </div>
                                  );
                                })}
                            </div>

                            {/* JOIN */}
                            <button
                              className="text-xs font-bold py-2 px-5 rounded-full"
                              style={{
                                backgroundColor: "#0000008a",
                                color: "#FFFFFF",
                              }}
                              onClick={async () => {
                                navigate(`/event-details/${event._id}`);
                              }}
                            >
                              {findMyKids?.findKidsForParent?.filter((kid) =>
                                event.participantKidIds.includes(kid._id)
                              ).length > 0
                                ? "BOOKED"
                                : "BOOK SESSION"}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {(!eventsData?.findUpcomingEvents ||
                    eventsData?.findUpcomingEvents?.length == 0) && (
                    <div className="text-center mt-14">No events yet!</div>
                  )}
                </div>

                {/* VIDEOS */}
                <div>
                  {/* title */}
                  <div
                    className="w-full flex items-center flex-row mt-5 md:ml-5 pr-5"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h1 style={{ fontWeight: "700" }}>Superbase Videos</h1>
                    <Link
                      to={`/video/${baseData?.findBaseDetails.limitedStreamingRecords[0]._id}`}
                    >
                      <h1 style={{ fontWeight: "700" }}>
                        See All{" "}
                        <FontAwesomeIcon
                          className="mr-3"
                          icon={faCircleArrowRight}
                        />
                      </h1>
                    </Link>
                  </div>

                  {/* videos List */}
                  <div
                    className="overflow-x-scroll flex flex-nowrap md:ml-5"
                    // style={{
                    //   alignItems: "center",
                    // }}
                  >
                    {baseData?.findBaseDetails.limitedStreamingRecords.map(
                      (video, index) => {
                        return (
                          <div
                            // className="w-full md:w-1/3"
                            class="w-1/2"
                            style={{
                              justifyContent: "center",
                              minWidth: "220px",
                            }}
                          >
                            <div
                              className="mr-3 my-3 cursor-pointer"
                              style={{
                                position: "relative",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                              onClick={() =>
                                navigate(
                                  `/video/${video._id}?otherVideoTypes=${OTHER_VIDEO_TYPES_TO_FETCH.fetchSameBaseVideos}`
                                )
                              }
                            >
                              {/* <div className="rounded-lg py-1 px-2 lg:px-6 shadow-md shadow-black/30 my-3"> */}
                              <img
                                src={
                                  video?.videoThumbnailUrl
                                    ? video.videoThumbnailUrl
                                    : "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
                                }
                                alt="Basement Sports"
                                className={`md:block w-[90px] object-cover `}
                                style={{ width: "100%", height: "120px" }}
                              />

                              {/* PLAY ICON */}
                              <div
                                style={{
                                  color: "yellow",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  height: "70%",
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCirclePlay}
                                  size="3x"
                                />
                              </div>

                              {/* video details */}
                              <div
                                style={{
                                  backgroundColor: "#00000033",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                className="px-2 py-2"
                              >
                                {/* left panel */}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* avatar */}
                                  <img
                                    src={
                                      RANDOM_AVATAR_URLS[
                                        video.userDetails.avatar
                                      ]
                                        ? RANDOM_AVATAR_URLS[
                                            video.userDetails.avatar
                                          ]
                                        : RANDOM_AVATAR_URLS["user4_asset4"]
                                    }
                                    alt="Basement Sports"
                                    className={`md:block w-[90px] mr-2`}
                                    style={{ width: "40px", height: "40px" }}
                                  />
                                  {/* video caption & base name */}
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {/* video caption */}
                                    <div
                                      className="text-xs"
                                      style={{
                                        fontWeight: "700",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        width: "70px",
                                      }}
                                    >
                                      {video.caption}
                                    </div>
                                    {/* base name */}
                                    <div
                                      className="text-xs"
                                      style={{ opacity: "0.5" }}
                                    >
                                      @{"anarstudio"}
                                    </div>
                                  </div>
                                </div>

                                {/* right panel */}
                                <div style={{ display: "flex", width: "30%" }}>
                                  <div
                                    className="text-xs"
                                    style={{ opacity: "0.5" }}
                                  >
                                    {formatDistanceToNow(
                                      Number(video.createdAt)
                                    )}{" "}
                                    ago
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Subscription & single event INFO 👇 */}
            <div className="flex justify-between flex-wrap">
              {/* Single Event */}
              <div className="w-full md:w-1/2"> {/* ❌ */}</div>

              {/* Subscription */}
              <div className="w-full md:w-1/2">{/* ❌ */}</div>
            </div>
          </section>
        </div>
      </div>
    </CommonBackdrop>
  );
};

export default BaseHome;
