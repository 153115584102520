import React, { useState, useContext, useEffect } from "react";
import { collection_data } from "../../data/collection_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faUsers,
  faRotateBack,
} from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { Tab, TabList } from "react-tabs";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { format, formatISO } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import Modal from "react-modal";
// import "react-calendar/dist/Calendar.css";

import { debounce } from "lodash";

import { utcToZonedTime, format as tzFormat } from "date-fns-tz";

// Query
import FIND_BASE_EVENTS_BY_FILTER from "../../gql/query/base/findBaseEventsByFilter.query";
import FIND_MY_KIDS from "../../gql/query/base/findMyAllKidsToSubscribeBase.query";
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetailsForBaseDashboard.query";

// MUTATION
import PARTICIPATE_EVENT from "../../gql/mutation/base/participateEvent.mutations";
import INITIATE_STRIPE_SESSION from "../../gql/mutation/base/initiateStripeSession.mutations";
import INITIATE_STRIPE_BILLING_PORTAL_SESSION from "../../gql/mutation/base/initiateStripeBillingPortalSession.mutations";
import CHECK_EVENT_COUPON_CODE_VALIDITY from "../../gql/mutation/base/checkEventCouponValidity.mutations";
import ENROLL_KIDS_USING_SLOT from "../../gql/mutation/base/enrollKidsUsingSlot.mutations";

import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  EACH_NFT_AFFILIATE_COUPON_DISCOUNT_PRICE_FOR_ALL_USER_IN_DOLLAR,
  SUPER_BASE_COUPON_CODE_INFO,
  RANDOM_AVATAR_URLS,
  PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
  PROJECT_BSPORTS_SUPER_BASE_MONTHLY_SUBSCRIPTION,
  EVENTS_TYPE,
  EVENTS_FILTER_BY,
  MONTHS_BY_NUMBER,
} from "../../utils/constant";
import HeadLine from "../../component/bSports/HeadLine";
import { da } from "date-fns/locale";

const EventsCalender = () => {
  const navigate = useNavigate();

  const { card, setCard } = useContext(ShoppingCardContext);

  const [showForMan, setShowForMan] = useState(false);
  const [itemsTabs, setItemsTabs] = useState(2);

  // Modal STATE
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedEventDetails, setSelectedScheDetail] = useState(null);
  const [selectedKidIds, setSelectedKidIds] = useState([]);

  // Special Event Purchase Modal (For Coupon Code entry)
  const [specialEventModalIsOpen, setIsOpenSpecialEventModal] = useState(false);

  // Coupon Code State
  const [showCouponBox, setShowCouponBox] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeCheckResponse, setCouponCodeCheckResponse] = useState("");
  const [totalDiscountInDollar, setTotalDiscountInDollar] = useState(0);

  // Event price state
  const [selectedEventPrice, setSelectedEventPrice] = useState(0);

  // Calender State
  const [dateValue, setDateValue] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedFilterType, setFilterType] = useState(EVENTS_FILTER_BY.DATE);

  // Search filter STATE
  const [searchEventName, setSearchEventName] = useState("");

  NFT_COUNTRIES.sort((a, b) =>
    a.name.charAt(0).localeCompare(b.name.charAt(0))
  );

  WOMEN_NFT_COUNTRIES.sort((a, b) =>
    a.name.charAt(0).localeCompare(b.name.charAt(0))
  );

  // Query
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_BASE_EVENTS_BY_FILTER, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      particularDate: dateValue,
      filterBy: EVENTS_FILTER_BY.DATE,
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: findMyKids,
    loading: findMyKidsLoading,
    error: findMyKidsError,
    refetch: findMyKidsRefetch,
  } = useQuery(FIND_MY_KIDS, {
    variables: {
      parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // MUTATION
  const [
    participateEvent,
    { loading: participateEventLoading, error: participateEventError },
  ] = useMutation(PARTICIPATE_EVENT);

  const [
    initiateStripeSessionMutation,
    {
      loading: initiateStripeSessionMutationLoading,
      error: initiateStripeSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_SESSION);

  const [
    initiateStripeBillingPortalSessionMutation,
    {
      loading: initiateStripeBillingPortalSessionMutationLoading,
      error: initiateStripeBillingPortalSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_BILLING_PORTAL_SESSION);

  const [
    checkEventCouponValidity,
    { loading: loadingCheckCouponValidity, error: errorCheckCouponValidity },
  ] = useMutation(CHECK_EVENT_COUPON_CODE_VALIDITY);

  const [
    enrollKidsUsingSlot,
    { loading: enrollKidsUsingSlotLoading, error: enrollKidsUsingSlotError },
  ] = useMutation(ENROLL_KIDS_USING_SLOT);

  useEffect(() => {
    function initializePage() {
      if (card.collectionType === "Male") {
        setItemsTabs(1);
        setShowForMan(true);
      }
      eventsRefetch();
      findMyKidsRefetch();
    }
    initializePage();
  }, []);

  async function joinEventAsASubscriptionUser(eventId, kidIds) {
    console.log(kidIds, "okk");
    const { data } = await participateEvent({
      variables: {
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        eventId,
        kidIds,
      },
    });
    if (data.participateEvent.errorMessage == null) {
      toast.success("Successfully enrolled into this event!");
      eventsRefetch();
      setSelectedKidIds([]);
      setIsOpen(false);
      return;
    } else {
      toast.error(data.participateEvent.errorMessage);
      return;
    }
  }

  // Check CouponCode Validity
  const checkCouponValidity = debounce(
    async (couponCode, eventId, paymentFor) => {
      console.log("ASCEEEE DEBOUNCE");
      // Set coupon code
      setCouponCode(couponCode);
      // Call to backend for checking this coupon code
      const { data } = await checkEventCouponValidity({
        variables: {
          couponCode,
          eventId,
          paymentFor,
        },
      });
      // If coupon code is correct then set discount price
      if (data.checkEventCouponValidity.errorMessage == null) {
        setCouponCodeCheckResponse(
          data.checkEventCouponValidity.successMessage
        );
        setTotalDiscountInDollar(
          data.checkEventCouponValidity?.additionalData
            ? data.checkEventCouponValidity.additionalData
            : 0
        );
      } else if (data.checkEventCouponValidity?.errorMessage) {
        setTotalDiscountInDollar(0);
        setCouponCodeCheckResponse(data.checkEventCouponValidity.errorMessage);
      }
    },
    600
  );

  // Single Event Purchase (only special events)
  // Call the Mutation to get STRIPE session URL to redirect into STRIPE payment gateway
  async function initiateStripeCheckoutUiForSpecialEvent() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Get my all kid IDs
      let kidIds = findMyKids?.findMyAllKidsToSubscribeBase?.map(
        (kid) => kid._id
      );
      // Call mutation to get STRIPE session url
      const { data } = await initiateStripeSessionMutation({
        variables: {
          project: PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
          eventId: selectedEventId,
          kidIds, // Make all of my kids enrolled into this event
          couponCode,
        },
      });
      if (data.initiateStripeSession.errorMessage == null) {
        // Close Modal
        closeSpecialEventModal();
        // redirect to STRIPE gateway
        window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      } else {
        toast.error(data.initiateStripeSession.errorMessage);
      }
    } else {
      // As user is not logged in, so navigate the user to login page
      navigate(`/login`);
      return;
    }
  }

  // Single Event Purchase (except special events)
  async function initiateStripeCheckoutUi(eventId, parentUserAvailableSlot) {
    if (!selectedKidIds || selectedKidIds.length == 0) {
      toast.error("Please select a KID to join the event!");
      return;
    }
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // If slots available then enroll kids via slots
      if (parentUserAvailableSlot > 0) {
        // Else Call the Mutation to get STRIPE session URL
        const { data } = await enrollKidsUsingSlot({
          variables: {
            parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
            eventId,
            kidIds: selectedKidIds,
          },
        });
        if (data.enrollKidsUsingSlot.errorMessage == null) {
          baseRefetch();
          findMyKidsRefetch();
          eventsRefetch();
          // Off modal
          setSelectedKidIds([]);
          setIsOpen(false);
          toast.success(data.enrollKidsUsingSlot.successMessage);
        } else {
          toast.error(data.enrollKidsUsingSlot.errorMessage);
        }
      } else {
        // Call the Mutation to get STRIPE session URL
        const { data } = await initiateStripeSessionMutation({
          variables: {
            project: PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
            userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
            eventId,
            kidIds: selectedKidIds,
            couponCode,
          },
        });
        if (data.initiateStripeSession.errorMessage == null) {
          setSelectedKidIds([]);
          setIsOpen(false);
          window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
        } else {
          toast.error(data.initiateStripeSession.errorMessage);
        }
      }
    } else {
      // As user is not logged in, so navigate the user to login page
      navigate(`/login`);
      return;
    }
  }

  // Handle Calender Actions
  function handleClickDay(date) {
    // Today date
    const currentDate = new Date();

    // First convert the date from STRING to DATE format
    const selectedDate = new Date(date);

    // Set current Hour, Minute, Second (As DatePicker can't handle it)
    selectedDate.setHours(currentDate.getHours());
    selectedDate.setMinutes(currentDate.getMinutes());
    selectedDate.setSeconds(currentDate.getSeconds());

    let formattedDate = formatISO(selectedDate, { representation: "complete" });

    setDateValue(formattedDate);

    // Update filter STATE
    setFilterType(EVENTS_FILTER_BY.DATE);
    setSearchEventName("");
  }

  function handleOnActiveStartDateChange(view) {
    // Refetch data by MONTH
    eventsRefetch({
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
      year: view.activeStartDate.getFullYear(),
      month: view.activeStartDate.getMonth(),
      filterBy: EVENTS_FILTER_BY.MONTH,
    });

    // Update filter STATE
    setFilterType(EVENTS_FILTER_BY.MONTH);

    // Update Year & Month
    setSelectedYear(view.activeStartDate.getFullYear());
    setSelectedMonth(view.activeStartDate.getMonth());
  }

  // Modal functions
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);

    // Make coupon code info as empty for safety
    setShowCouponBox(false);
    setCouponCode("");
    setCouponCodeCheckResponse("");
    setTotalDiscountInDollar(0);
  }

  // Special Event Modal functions
  function closeSpecialEventModal() {
    setIsOpenSpecialEventModal(false);

    // Make coupon code info as empty for safety
    setShowCouponBox(false);
    setCouponCode("");
    setCouponCodeCheckResponse("");
    setTotalDiscountInDollar(0);
  }

  // search event by name
  const searchEventByName = debounce(async (searchName) => {
    // If "searchName" has a valid string. ELSE search by today date
    if (searchName && searchName != "") {
      // Update STATE
      setFilterType(EVENTS_FILTER_BY.SEARCH_NAME);

      // Call to backend for checking this coupon code
      eventsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        searchName,
        filterBy: EVENTS_FILTER_BY.SEARCH_NAME,
      });
    } else {
      // Update STATE
      setFilterType(EVENTS_FILTER_BY.DATE);

      // Call to backend for checking this coupon code
      eventsRefetch({
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        particularDate:
          !dateValue || dateValue == "" ? new Date() : new Date(dateValue), // Check if "dateValue" has value. If not then set today date as "particularDate" & refetch. ELSE IF "dateValue" has value, then refetch with that selected date
        filterBy: EVENTS_FILTER_BY.DATE,
      });
    }
  }, 400);

  if (findMyKidsLoading || baseLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (eventsError || findMyKidsError || baseError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  // Set available slot to use

  let allSlotPurchased = baseData?.findBaseDetails?.slotPurchased;
  let parentUserAvailableSlot = 0;

  if (allSlotPurchased) {
    let parentUserSlotInfoIndex = allSlotPurchased.findIndex(
      (item) =>
        item.parentId === localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
    );

    if (parentUserSlotInfoIndex !== -1) {
      parentUserAvailableSlot =
        allSlotPurchased[parentUserSlotInfoIndex].slotAvailable;
    }
  }

  return (
    <CommonBackdrop>
      <div className="px-10">
        <div className="md:w-full sm:w-full sx:w-full px-2 w-full ">
          <section className="">
            <div className="w-full bg-black/20 px-10 h-10 flex items-center flex-row mt-5">
              <h1 className="text-yellow-300">Event / </h1>
              <h1> Events Calendar</h1>
            </div>

            {/* FILTER & SEARCH bar */}
            <div
              className="flex justify-between mb-3 px-5 mt-5 py-1 flex-wrap"
              style={{
                alignItems: "center",
                backgroundColor: "#00000033",
                borderRadius: "5px",
              }}
            >
              {/* FILTER */}
              <div className="mt-2">
                {/* FILTER BY DATE */}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
                    onClick={() => {
                      // Update STATE
                      setFilterType(EVENTS_FILTER_BY.DATE);
                      setSearchEventName("");

                      // Check if "dateValue" has value. If not then set today date as "particularDate" & refetch
                      // ELSE IF "dateValue" has value, then refetch with that selected date
                      if (!dateValue || dateValue == "") {
                        let todayDate = new Date();
                        // Now refetch data
                        eventsRefetch({
                          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                          particularDate: todayDate,
                          filterBy: EVENTS_FILTER_BY.DATE,
                        });
                      } else if (dateValue && dateValue != "") {
                        let selectedDateValue = new Date(dateValue);
                        // Now refetch data
                        eventsRefetch({
                          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                          particularDate: selectedDateValue,
                          filterBy: EVENTS_FILTER_BY.DATE,
                        });
                      }
                    }}
                    style={{
                      opacity:
                        selectedFilterType == EVENTS_FILTER_BY.DATE ? 1 : 0.4,
                    }}
                    data-tooltip-id="tooltip-content"
                    data-tooltip-content="Filter events by date."
                  >
                    Filter by Date
                  </button>

                  {/* FILTER BY MONTH */}
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
                    onClick={() => {
                      // Update state
                      setFilterType(EVENTS_FILTER_BY.MONTH);
                      setSearchEventName("");

                      // Check if "dateValue" has value. If not then set today date (today year & today month) & refetch
                      // ELSE IF "dateValue" has value, then refetch with that selected date (Year & month from that selected date)
                      if (!dateValue || dateValue == "") {
                        let todayDate = new Date();
                        let year = todayDate.getFullYear();
                        let month = todayDate.getMonth();
                        // Now refetch data
                        eventsRefetch({
                          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                          year,
                          month,
                          filterBy: EVENTS_FILTER_BY.MONTH,
                        });
                        // Update Year & Month
                        setSelectedYear(year);
                        setSelectedMonth(month);
                      } else if (dateValue && dateValue != "") {
                        let selectedDateValue = new Date(dateValue);
                        let year = selectedDateValue.getFullYear();
                        let month = selectedDateValue.getMonth();
                        // Now refetch data
                        eventsRefetch({
                          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                          year,
                          month,
                          filterBy: EVENTS_FILTER_BY.MONTH,
                        });
                        // Update Year & Month
                        setSelectedYear(year);
                        setSelectedMonth(month);
                      }
                    }}
                    style={{
                      opacity:
                        selectedFilterType == EVENTS_FILTER_BY.MONTH ? 1 : 0.4,
                    }}
                    data-tooltip-id="tooltip-content"
                    data-tooltip-content="Filter events by month!"
                  >
                    Filter by Month
                  </button>

                  {/* UP_COMING EVENTS */}
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2 mb-2"
                    style={{
                      opacity:
                        selectedFilterType == EVENTS_FILTER_BY.UPCOMING
                          ? 1
                          : 0.4,
                    }}
                    onClick={() => {
                      // Update STATE
                      setFilterType(EVENTS_FILTER_BY.UPCOMING);
                      setSearchEventName("");
                      // Re-fetch events
                      eventsRefetch({
                        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                        filterBy: EVENTS_FILTER_BY.UPCOMING,
                      });
                    }}
                    data-tooltip-id="tooltip-content"
                    data-tooltip-content="Filter all upcoming events!"
                  >
                    Upcoming
                  </button>

                  {/* PAST EVENTS */}
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
                    style={{
                      opacity:
                        selectedFilterType == EVENTS_FILTER_BY.PAST ? 1 : 0.4,
                    }}
                    onClick={() => {
                      // Update STATE
                      setFilterType(EVENTS_FILTER_BY.PAST);
                      setSearchEventName("");
                      // Re-fetch events
                      eventsRefetch({
                        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                        filterBy: EVENTS_FILTER_BY.PAST,
                      });
                    }}
                    data-tooltip-id="tooltip-content"
                    data-tooltip-content="Filter all past events!"
                  >
                    Past
                  </button>

                  {/* ALL EVENTS */}
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded mr-2  mb-2"
                    style={{
                      opacity:
                        selectedFilterType == EVENTS_FILTER_BY.ALL ? 1 : 0.4,
                    }}
                    onClick={() => {
                      // Update state
                      setFilterType(EVENTS_FILTER_BY.ALL);
                      setSearchEventName("");
                      // Re-fetch events
                      eventsRefetch({
                        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
                        filterBy: EVENTS_FILTER_BY.ALL,
                      });
                    }}
                    data-tooltip-id="tooltip-content"
                    data-tooltip-content="Filter all events!"
                  >
                    All
                  </button>

                  <div
                    className=" mb-2"
                    data-tooltip-id="tooltip-content"
                    data-tooltip-content="Reset filter"
                  >
                    <FontAwesomeIcon
                      className="mt-1 cursor-pointer"
                      icon={faRotateBack}
                      onClick={() => {
                        handleClickDay(new Date());
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* SEARCH bar */}
              <div>
                <input
                  className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
                  placeholder="Search events by name"
                  onChange={(e) => {
                    // Update STATE
                    setSearchEventName(e.target.value);
                    // Call to re-fetch with debounce
                    searchEventByName(e.target.value);
                  }}
                  value={searchEventName}
                />
              </div>
            </div>

            {/* CALENDER & SCHDULEs */}
            <div className="flex justify-between flex-wrap">
              {/* CALENDER */}
              <div className="w-full md:w-1/2">
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="my-4"
                >
                  <Calendar
                    value={dateValue ? dateValue : new Date()}
                    onClickDay={handleClickDay}
                    className="bg-black/20"
                    onActiveStartDateChange={handleOnActiveStartDateChange}
                  />
                </div>

                {/* Subscription & single event INFO 👇 */}
                <div className="flex justify-between flex-wrap">
                  {/* Single Event */}
                  <div className="w-full ">
                    <div className="my-4 md:mr-5">
                      <div className="bg-black/20 flex justify-between items-center font-display text-yellow-300 text-lg rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30">
                        <div className="font-semibold my-auto ml-2 mr-5">
                          {/* CAPTION */}
                          <div>Drop-In Events</div>
                          <div
                            className="text-md"
                            style={{ color: "#ccd0e0", fontWeight: "bold" }}
                          >
                            Enjoy the events
                          </div>

                          <div
                            className="text-sm mt-5"
                            style={{ color: "#ccd0e0" }}
                          >
                            Sessions run for 90-120 minutes. During weekdays, we
                            offer 2 sessions per day, with special events on
                            weekends.{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* SCHDULES */}
              {eventsLoading ? (
                <div className="mt-20 w-full md:w-1/2">
                  <DataLoading loadingType="success" />
                </div>
              ) : (
                <div className="w-full md:w-1/2 ">
                  {eventsData.findBaseEventsByFilter.length > 0 && (
                    <div
                      className="md:ml-5 mt-2"
                      style={{ fontWeight: "bold", fontSize: "15px" }}
                    >
                      {selectedFilterType == EVENTS_FILTER_BY.DATE ? (
                        <div>
                          {eventsData.findBaseEventsByFilter.length} events were
                          found on{" "}
                          <b style={{ color: "gold" }}>
                            {dateValue && dateValue != ""
                              ? format(new Date(dateValue), "do MMMM, yyyy")
                              : format(new Date(), "do MMMM, yyyy")}
                          </b>
                          !
                        </div>
                      ) : selectedFilterType == EVENTS_FILTER_BY.MONTH ? (
                        <div>
                          {eventsData.findBaseEventsByFilter.length} events were
                          found in{" "}
                          <b style={{ color: "gold" }}>
                            {MONTHS_BY_NUMBER[selectedMonth]} {selectedYear}
                          </b>
                        </div>
                      ) : selectedFilterType == EVENTS_FILTER_BY.UPCOMING ? (
                        <div>
                          {eventsData.findBaseEventsByFilter.length}{" "}
                          <b style={{ color: "gold" }}>upcoming</b> events were
                          found!
                        </div>
                      ) : selectedFilterType == EVENTS_FILTER_BY.PAST ? (
                        <div>
                          {eventsData.findBaseEventsByFilter.length} events have
                          <b style={{ color: "gold" }}> passed</b>!
                        </div>
                      ) : selectedFilterType == EVENTS_FILTER_BY.ALL ? (
                        <div>
                          A <b style={{ color: "gold" }}>total</b> of{" "}
                          {eventsData.findBaseEventsByFilter.length} events were
                          found!
                        </div>
                      ) : (
                        <div>
                          <b style={{ color: "gold" }}>
                            {eventsData?.findBaseEventsByFilter?.length
                              ? eventsData?.findBaseEventsByFilter?.length
                              : ""}
                          </b>{" "}
                          Events found!
                        </div>
                      )}
                    </div>
                  )}

                  <div>
                    {eventsData.findBaseEventsByFilter?.map((event, index) => {
                      return (
                        <div>
                          {/* DATE + EVENT info + BOOK session button */}
                          <div
                            className="bg-black/20 mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 md:ml-5 py-2"
                            key={index}
                            style={{
                              borderLeft: "5px solid yellow",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* DATE & Event INFO */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="py-2"
                            >
                              {/* DATE section */}
                              <div
                                style={{
                                  color: "white",
                                  width: "80px",
                                  borderRight: "1px dotted #ffffff45",
                                  paddingLeft: "15px",
                                }}
                                className="text-2xl"
                              >
                                {format(new Date(event.dateTime), "EEE MMM dd")}
                              </div>

                              {/* Schdule INFO section */}
                              <div className="font-semibold ml-5">
                                {/* TIME */}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div class="w-3 h-3 rounded-full bg-blue-500 flex items-center justify-center">
                                    <div class="w-1 h-1 rounded-full bg-black"></div>
                                  </div>
                                  {/* Time value */}
                                  <div
                                    style={{ color: "#8F9BB3" }}
                                    className="text-sm ml-2"
                                  >
                                    {format(
                                      new Date(event.dateTime),
                                      "h:mm aa"
                                    )}
                                  </div>
                                </div>

                                {event.eventType ==
                                EVENTS_TYPE.SPECIAL_EVENTS ? (
                                  <>
                                    {event.caption ? (
                                      <div className="text-xl mt-1 mb-2">
                                        {event.caption}
                                      </div>
                                    ) : (
                                      <div className="text-xl mt-1 mb-2">
                                        Special Event
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {/* CAPTION */}
                                    <div className="text-sm">
                                      Event Type:{" "}
                                      <b style={{ color: "#ccd0e0" }}>
                                        {event.eventType}
                                      </b>
                                    </div>

                                    {/* CAPTION */}
                                    {event.caption && (
                                      <div className="text-sm">
                                        Title:{" "}
                                        <b style={{ color: "#ccd0e0" }}>
                                          {event.caption}
                                        </b>
                                      </div>
                                    )}
                                  </>
                                )}

                                {/* DURATIOON */}
                                {event.duration && (
                                  <div className="text-sm">
                                    Duration:{" "}
                                    <b style={{ color: "#ccd0e0" }}>
                                      {event.duration}
                                    </b>
                                  </div>
                                )}

                                {/* SPORTS */}
                                {event.sports && event.sports.length > 0 && (
                                  <div className="text-sm">
                                    Sports:{" "}
                                    <b style={{ color: "#ccd0e0" }}>
                                      {event.sports.join(", ")}
                                    </b>
                                  </div>
                                )}

                                {/* GAME TYPE */}
                                {event.gameType && (
                                  <div className="text-sm">
                                    Game Type:{" "}
                                    <b style={{ color: "#ccd0e0" }}>
                                      {event.gameType}
                                    </b>
                                  </div>
                                )}

                                {/* SLOT */}
                                {event.slot && (
                                  <div className="text-sm">
                                    #SLOT:{" "}
                                    <b style={{ color: "#ccd0e0" }}>
                                      {event.slot}
                                    </b>
                                  </div>
                                )}

                                {/* PRICE */}
                                {event.eventPrice && (
                                  <div
                                    className="text-md mt-2"
                                    style={{ color: "#00fff5" }}
                                  >
                                    Price: ${event.eventPrice}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="mr-5">
                              {/* BOOKED KIDS */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {findMyKids?.findMyAllKidsToSubscribeBase
                                  ?.filter((kid) =>
                                    event.participantKidIds.includes(kid._id)
                                  )
                                  .map((kid) => {
                                    return (
                                      <div
                                        className="mr-2 cursor-pointer"
                                        onClick={() => {
                                          navigate(
                                            `/kid-details/${kid.player._id}`
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            RANDOM_AVATAR_URLS[kid.avatar]
                                              ? RANDOM_AVATAR_URLS[kid.avatar]
                                              : RANDOM_AVATAR_URLS[
                                                  "user4_asset4"
                                                ]
                                          }
                                          alt="Basement Sports"
                                          className="max-h-8 object-contain"
                                        />
                                      </div>
                                    );
                                  })}

                                {/* JOIN */}
                                <button
                                  className="bg-blue-500 hover:bg-blue-700  text-white text-sm font-bold py-2 px-5 rounded"
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                  onClick={async () => {
                                    if (
                                      !localStorage.getItem(
                                        LOCAL_STORAGE_KEYS.USER_ID // ✅ If Not Logged In
                                      )
                                    ) {
                                      navigate(`/login`);
                                    } else if (
                                      event.eventType ==
                                      EVENTS_TYPE.SPECIAL_EVENTS // ✅ If special events
                                    ) {
                                      if (
                                        findMyKids?.findMyAllKidsToSubscribeBase?.filter(
                                          (kid) =>
                                            event.participantKidIds.includes(
                                              kid._id
                                            )
                                        ).length > 0 // ✅ If special events & if already booked special event
                                      ) {
                                        toast.error(
                                          "You have allready booked this session"
                                        );
                                        return;
                                      } else {
                                        // ✅ If special events & if not yet booked special event then open Special Event Modal to input Coupon Code
                                        setSelectedEventId(event._id);
                                        // Set event price
                                        setSelectedEventPrice(event.eventPrice);
                                        // Open Modal
                                        setIsOpenSpecialEventModal(true);
                                      }
                                    } else if (
                                      // ✅ If it is not a special event but no kid found (As Kids need to be selected for Other events from Modal)
                                      findMyKids?.findMyAllKidsToSubscribeBase
                                        ?.length < 1
                                    ) {
                                      navigate("/add-kid");
                                    } else {
                                      // ✅ If it is not a special event & kid found
                                      // Set IDs for getting data
                                      setSelectedEventId(event._id);
                                      setSelectedScheDetail(event);

                                      // Make coupon code info as empty for safety
                                      setCouponCode("");
                                      setCouponCodeCheckResponse("");
                                      setTotalDiscountInDollar(0);

                                      // Set event price
                                      setSelectedEventPrice(event.eventPrice);

                                      // Open Modal
                                      openModal();
                                    }
                                  }}
                                >
                                  {findMyKids?.findMyAllKidsToSubscribeBase?.filter(
                                    (kid) =>
                                      event.participantKidIds.includes(kid._id)
                                  ).length > 0
                                    ? "BOOKED"
                                    : "BOOK SESSION"}
                                </button>
                              </div>

                              <button
                                className="bg-yellow-500 hover:bg-yellow-700  text-white text-sm absolute  right-[68px]   font-bold py-2 px-5 rounded mt-5"
                                onClick={() => {
                                  navigate(`${`/event-details/${event._id}`}`);
                                }}
                              >
                                DETAILS
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {(!eventsData.findBaseEventsByFilter ||
                      eventsData.findBaseEventsByFilter.length == 0) && (
                      <div
                        className="text-center mt-10"
                        style={{ fontWeight: "bold", fontSize: "30px" }}
                      >
                        {selectedFilterType == EVENTS_FILTER_BY.DATE ? (
                          <div>
                            No events have been scheduled for{" "}
                            <b style={{ color: "gold" }}>
                              {dateValue && dateValue != ""
                                ? format(new Date(dateValue), "do MMMM, yyyy")
                                : format(new Date(), "do MMMM, yyyy")}
                            </b>{" "}
                            yet.
                          </div>
                        ) : selectedFilterType == EVENTS_FILTER_BY.MONTH ? (
                          <div>
                            No events have been scheduled for{" "}
                            <b style={{ color: "gold" }}>
                              {MONTHS_BY_NUMBER[selectedMonth]} {selectedYear}
                            </b>{" "}
                            yet.
                          </div>
                        ) : selectedFilterType == EVENTS_FILTER_BY.UPCOMING ? (
                          <div>
                            No
                            <b style={{ color: "gold" }}> upcoming</b> events
                            were found!
                          </div>
                        ) : selectedFilterType == EVENTS_FILTER_BY.PAST ? (
                          <div>
                            No events have
                            <b style={{ color: "gold" }}> passed</b>!
                          </div>
                        ) : selectedFilterType == EVENTS_FILTER_BY.ALL ? (
                          <div>No events found!</div>
                        ) : (
                          "No events found!"
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>

      {/* MODAL MODAL */}
      {/* MODAL MODAL */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#00000033",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* MODAL CLOSE BUTTON */}
        <button onClick={closeModal} style={{ float: "right" }}>
          <FontAwesomeIcon className="mt-1" icon={faClose} />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Assign to Members{" "}
          <FontAwesomeIcon
            className="mt-1"
            style={{ color: "white" }}
            icon={faUsers}
          />
        </h1>

        {/* SHOW KIDS */}
        {findMyKids &&
        findMyKids.findMyAllKidsToSubscribeBase &&
        findMyKids.findMyAllKidsToSubscribeBase.length > 0 ? (
          <div>
            {findMyKids.findMyAllKidsToSubscribeBase.map((kid, index) => {
              return (
                <div>
                  {/* KIDS */}
                  <div
                    className="bg-black/20 mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2"
                    key={index}
                    style={{
                      borderLeft: "5px solid yellow",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* KID AVATAR + Name + Check button */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="py-2"
                    >
                      {/* KID AVATAR */}
                      <img
                        src={
                          RANDOM_AVATAR_URLS[kid.avatar]
                            ? RANDOM_AVATAR_URLS[kid.avatar]
                            : RANDOM_AVATAR_URLS["user4_asset4"]
                        }
                        alt="Basement Sports"
                        className="max-h-17"
                        style={{
                          color: "white",
                          width: "100px",
                          borderRight: "1px dotted #ffffff45",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      />

                      {/* KID INFO section */}
                      <div className="font-semibold ml-5">
                        {/* CAPTION */}
                        <div>
                          Name: <b style={{ color: "#ccd0e0" }}>{kid.name}</b>
                        </div>
                        <div>
                          <b style={{ color: "#ccd0e0" }}>
                            {kid.gender == "male" ? "Boy" : "Girl"}
                          </b>
                        </div>
                        {kid.age && (
                          <div>
                            Age: <b style={{ color: "#ccd0e0" }}>{kid.age}</b>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className="mr-5"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {/* Check if KID not yet joined into the event */}
                      {!selectedEventDetails?.participantKidIds.includes(
                        kid._id
                      ) ? (
                        <>
                          {/* Check if the KID already a SUBSCRIBER for MONTH by parent */}
                          {kid.paymentDetails?.paymentFor ===
                          PAYMENT_FOR.PURCHASE_FULL_MONTH_EVENT_OF_BASE ? (
                            <button
                              onClick={() => {
                                joinEventAsASubscriptionUser(selectedEventId, [
                                  kid._id,
                                ]);
                              }}
                              className="text-xs font-bold py-1 px-5 rounded-full mr-3"
                              style={{
                                backgroundColor: "#0000008a",
                                color: "yellow",
                              }}
                            >
                              FREE TO JOIN
                            </button>
                          ) : (
                            <>
                              {/* If the KID not yet enrolled into the event && even not yet SUBSCRIBED for month */}
                              <div
                                onClick={() => {
                                  if (selectedKidIds.includes(kid._id)) {
                                    // Remove Array reference & create a new one
                                    let prevSelectedKidIds = [
                                      ...selectedKidIds,
                                    ];
                                    // Get KID ID index
                                    let index = prevSelectedKidIds.indexOf(
                                      kid._id
                                    );
                                    // SPLICE out the KID ID
                                    prevSelectedKidIds.splice(index, 1);
                                    // SET new ARRAY of IDs
                                    setSelectedKidIds(prevSelectedKidIds);
                                  } else {
                                    setSelectedKidIds([
                                      ...selectedKidIds,
                                      kid._id,
                                    ]);
                                  }
                                }}
                                className={`w-5 h-5 border-2 rounded-full border-yellow-300 mr-5 ${
                                  selectedKidIds.includes(kid._id)
                                    ? "bg-yellow-300"
                                    : null
                                }`}
                              ></div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {/* If the KID already joined into this event */}
                          <div
                            className="text-xs font-bold py-1 px-5 rounded-full mr-3"
                            style={{
                              backgroundColor: "#00000042",
                              color: "#ffff00bf",
                            }}
                          >
                            ENROLLED
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>No Kids added yet! Please add kid first!</div>
        )}

        {/* FINAL SUBMIT BUTTON for payment/ Free for Join */}
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="mt-5"
        >
          {/* If selected STRIPE payment then show coupon code & Amount to pay Info. Othersie show available SLOTs */}
          {Number(parentUserAvailableSlot) > 0 ? (
            <div className="text-center">
              <div style={{ color: "yellow" }} className="text-xl">
                Total selected kids: {selectedKidIds.length}
              </div>
              <div style={{ color: "#add8ff" }} className="text-xl my-3">
                Slot available to use: {parentUserAvailableSlot}
              </div>
            </div>
          ) : (
            <div>
              {/* Coupon Code */}
              <div>
                <div className="mb-4">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <div
                      onClick={() => {
                        setShowCouponBox(!showCouponBox);
                        setCouponCode("");
                        setCouponCodeCheckResponse("");
                        setTotalDiscountInDollar(0);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      className="cursor-pointer"
                    >
                      <input
                        className="relative bg-yellow-300 rounded"
                        type="checkbox"
                        id="exampleCheck2"
                        checked={showCouponBox}
                      />
                      <div className="ml-2">Do you have any coupon code?</div>
                    </div>

                    {showCouponBox && (
                      <div>
                        <input
                          onChange={(e) => {
                            checkCouponValidity(
                              e.target.value,
                              selectedEventDetails._id,
                              PAYMENT_FOR.PURCHASE_SINGLE_EVENT_OF_BASE
                            );
                          }}
                          className="bg-black/30 border-2  border-green-300  focus:border-yellow-300 focus:outline-none rounded-full h-8"
                          type="text"
                          placeholder="Coupon Code"
                        />
                        {couponCode && (
                          <p
                            className={`text-xs font-bold text-center ${
                              couponCodeCheckResponse === "Coupon found"
                                ? "text-green-400"
                                : "text-red-400"
                            }`}
                          >
                            {couponCodeCheckResponse}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Amount to pay */}
              <div
                className="text-center text-yellow-300 text-xl mb-4 mt-1"
                style={{ fontWeight: "700" }}
              >
                Total amount to pay: $
                {selectedEventPrice * selectedKidIds.length -
                  totalDiscountInDollar * selectedKidIds.length}{" "}
                {/* Discount */}
                {totalDiscountInDollar > 0 && (
                  <b
                    style={{ color: "white" }}
                    className="text-center text-sm mb-4"
                  >
                    (${totalDiscountInDollar * selectedKidIds.length} discount)
                  </b>
                )}
              </div>
            </div>
          )}

          {initiateStripeSessionMutationLoading ? (
            <button
              className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
              style={{
                backgroundColor: "#0000008a",
                color: "#FFFFFF",
              }}
            >
              Loading
            </button>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="text-sm font-bold py-3 px-6 rounded-lg  mt-2"
                style={{
                  backgroundColor: "#0078ffc7",
                  color: "#FFFFFF",
                  fontSize: "20px",
                }}
                onClick={() => {
                  initiateStripeCheckoutUi(
                    selectedEventId,
                    parentUserAvailableSlot
                  );
                }}
              >
                ENROLL KIDS
              </button>
            </div>
          )}
        </div>
      </Modal>

      {/* SPECIAL EVENT MODAL */}
      {/* SPECIAL EVENT MODAL */}
      <Modal
        isOpen={specialEventModalIsOpen}
        onRequestClose={closeSpecialEventModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#363b47f7",
            maxHeight: "80%",
            border: "none",
            paddingLeft: "30px",
            paddingRight: "30px",
          },
          overlay: {
            backgroundColor: "#00000033",
          },
        }}
        contentLabel="Example Modal"
      >
        {/* MODAL CLOSE BUTTON */}
        <button onClick={closeSpecialEventModal} style={{ float: "right" }}>
          <FontAwesomeIcon className="mt-1" icon={faClose} />
        </button>

        {/* MODAL TITLE */}
        <h1
          className="text-yellow-300 text-2xl mb-5"
          style={{ fontWeight: "600" }}
        >
          Special Event{" "}
          <FontAwesomeIcon
            className="mt-1"
            style={{ color: "white" }}
            icon={faUsers}
          />
        </h1>

        {/* COUPON CODE ENTRY */}
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="mt-5"
        >
          <div>
            {/* Coupon Code */}
            <div>
              <div className="mb-4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <div
                    onClick={() => {
                      setShowCouponBox(!showCouponBox);
                      setCouponCode("");
                      setCouponCodeCheckResponse("");
                      setTotalDiscountInDollar(0);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                    className="cursor-pointer"
                  >
                    <input
                      className="relative bg-yellow-300 rounded"
                      type="checkbox"
                      id="exampleCheck2"
                      checked={showCouponBox}
                    />
                    <div className="ml-2">Do you have any coupon code?</div>
                  </div>

                  {showCouponBox && (
                    <div>
                      <input
                        onChange={(e) => {
                          // setCouponCode(e.target.value);
                          checkCouponValidity(
                            e.target.value,
                            selectedEventId,
                            PAYMENT_FOR.PURCHASE_SINGLE_EVENT_OF_BASE
                          );
                        }}
                        className="bg-black/30 border-2  border-green-300  focus:border-yellow-300 focus:outline-none rounded-full h-8"
                        type="text"
                        placeholder="Coupon Code"
                      />
                      {couponCode && (
                        <p
                          className={`text-xs font-bold text-center ${
                            couponCodeCheckResponse === "Coupon found"
                              ? "text-green-400"
                              : "text-red-400"
                          }`}
                        >
                          {couponCodeCheckResponse}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Amount to pay */}
            <div
              className="text-center text-yellow-300 text-xl mb-4 mt-1"
              style={{ fontWeight: "700" }}
            >
              Total amount to pay: ${selectedEventPrice - totalDiscountInDollar}{" "}
              {/* Discount */}
              {totalDiscountInDollar > 0 && (
                <b
                  style={{ color: "white" }}
                  className="text-center text-sm mb-4"
                >
                  (${totalDiscountInDollar} discount)
                </b>
              )}
            </div>
          </div>

          {initiateStripeSessionMutationLoading ? (
            <button
              className="text-sm font-bold py-1 px-5 rounded-full max-h-7 mt-2"
              style={{
                backgroundColor: "#0000008a",
                color: "#FFFFFF",
              }}
            >
              Loading
            </button>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="text-sm font-bold py-3 px-6 rounded-lg  mt-2"
                style={{
                  backgroundColor: "#0078ffc7",
                  color: "#FFFFFF",
                  fontSize: "20px",
                }}
                onClick={() => {
                  initiateStripeCheckoutUiForSpecialEvent();
                }}
              >
                PURCHASE EVENT
              </button>
            </div>
          )}
        </div>
      </Modal>
    </CommonBackdrop>
  );
};

export default EventsCalender;
