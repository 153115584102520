import { gql } from "@apollo/client";

export default gql`
  query findEventsByDateFilter($date: String) {
    findEventsByDateFilter(date: $date) {
      _id
      eventType
      caption
      dateTime
      eventPrice
      duration
      sports
      gameType
      slot
      participantKidIds
    }
  }
`;
