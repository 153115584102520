import { gql } from "@apollo/client";

export default gql`
  query findSoccerTournamentMatch($tournamentId: String, $matchId: String) {
    findSoccerTournamentMatch(tournamentId: $tournamentId, matchId: $matchId) {
      _id
      team
      against
      time
      place
      winner
      timeLength
      restTime
      teamLogo
      againstLogo
      teamOneGoals
      teamTwoGoals
      hasEnded
      hasFirstMatchEnded
      firstHalf
      totalPlay
      goneToTieBreaker
      teamOneTieBreakerGoals
      teamTwoTieBreakerGoals
      teamOneSeriesWin
      teamTwoSeriesWin
      teamOneSeriesGoals
      teamTwoSeriesGoals
      isSeries
      endedByTb
      bestOf
      teamOneSeriesPenalties
      teamTwoSeriesPenalties
      fullSeriesPenaltyStatus
      teamMembers {
        _id
        userId
        name
        avatar
        score
      }
      againstMembers {
        _id
        userId
        name
        avatar
        score
      }
      base {
        logo
        name
      }
    }
  }
`;
