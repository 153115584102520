import React, { useState } from "react";
import AdminPortal from "./AdminPortal";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { format, addYears } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";

// Query
import FIND_AFFILIATES from "../../gql/query/base/findAffiliates.query";

// Mutations
import CREATE_EVENT from "../../gql/mutation/base/createEvent.mutations";
import { LOCAL_STORAGE_KEYS, PAYMENT_FOR } from "../../utils/constant";
import { useEffect } from "react";

function Affiliates() {
  const navigate = useNavigate();

  // Query
  const {
    data: affiliates,
    loading: affiliatesLoading,
    error: affiliatesError,
    refetch: affiliatesRefetch,
  } = useQuery(FIND_AFFILIATES, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  useEffect(() => {
    function initializeScreen() {
      affiliatesRefetch();
    }
    initializeScreen();
  });

  if (affiliatesLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  return (
    <AdminPortal>
      <div className="md:w-11/12 mx-auto bg-slate-700 flex justify-between items-center px-4 py-3 mt-5 mb-3 ">
        <h1 className="font-display font-bold text-lg">Affiliates</h1>{" "}
        <Link
          to={"/admin/add-affiliate"}
          className="bg-slate-800 py-1 px-2 rounded"
        >
          + Add Affiliate
        </Link>
      </div>
      <div className="overflow-x-auto w-72 sm:w-10/12 md:w-11/12 mx-auto mt-4">
        <table className="bg-slate-700/80 border-yellow-100 border border-b-transparent text-sm mx-auto">
          <thead className="border-b border-yellow-100">
            <tr>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Serial</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span className="">Affiliate Name</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Email</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Coupon Code</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Total Events</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Total Monthly Subscription</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Total Payout ($)</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Created At</span>
              </th>
            </tr>
          </thead>

          {affiliates?.findAffiliates?.map((affiliate, index) => {
            return (
              <tbody>
                <tr
                  className=" border-b border-yellow-100"
                  role="row"
                  key={index}
                >
                  {/* Serial */}
                  <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                    <span className="mr-2 lg:mr-4">{index + 1}</span>
                  </td>

                  {/* Name */}
                  <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                    <span className="mr-2 lg:mr-4">
                      {affiliate.affiliateUserDetails?.name}
                    </span>
                  </td>

                  {/* Email */}
                  <td className=" lg:w-40 py-3 px-3 text-center" role="cell">
                    <span className={"text-red cursor-pointer"}>
                      {affiliate.affiliateUserDetails?.email}
                    </span>
                  </td>

                  {/* Coupon Code */}
                  <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                    <span className={"text-blue"}>
                      {affiliate.affiliateCouponCode}
                    </span>
                  </td>

                  {/* Total events */}
                  <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                    <span className={"text-blue"}>
                      {affiliate.totalSingleEventSell}
                    </span>
                  </td>

                  {/* Total monthly Subscrption sell */}
                  <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                    <span className={"text-blue"}>
                      {affiliate.totalMonthlySubscriptionSell}
                    </span>
                  </td>

                  {/* Total Payout */}
                  <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                    <span className="mr-2 lg:mr-4">
                      ${affiliate.totalBasePayout}
                    </span>
                  </td>

                  {/* Created At */}
                  <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                    {affiliate.createdAt && (
                      <span>
                        {format(Number(affiliate.createdAt), "do MMM yyyy")}
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </AdminPortal>
  );
}
export default Affiliates;
