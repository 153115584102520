import { gql } from "@apollo/client";

export default gql`
  query findAffiliates($baseSlug: String!) {
    findAffiliates(baseSlug: $baseSlug) {
      _id
      userId
      affiliateCouponCode
      adminUserId
      totalNftSell
      totalNftPayout
      totalSingleEventSell
      totalMonthlySubscriptionSell
      totalBasePayout
      affiliateUserDetails {
        _id
        name
        email
      }
      affiliateBasePurchaseDetails {
        parentId
        kidIds
        eventId
        baseId
        paymentId
      }
      createdAt
    }
  }
`;
