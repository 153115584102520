import React, { useEffect, useContext } from "react";

import { useState } from "react";

import { useMutation, useQuery } from "@apollo/client";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Mutations
import MINT_NFT from "../../../gql/mutation/nft/mintMultipleNftWithKidCoin.mutation";

import ME from "../../../gql/query/profile/me.query";
import { ShoppingCardContext } from "../../../context/cardContext";
import DataLoading from "../../../component/loader/DataLoading";
import CommonBackdrop from "../../../component/common/CommonBackdrop";

export default function Kidcoinpayment() {
  const navigate = useNavigate();
  const { card, setCard } = useContext(ShoppingCardContext);

  const search = useLocation().search;
  const [loadingButotn, setLoadingButton] = useState(false);
  const [fetchingData, setFetchingDAta] = useState(false);
  const [leftKidCoin, setLeftKidCoin] = useState(null);
  const [password, setPassword] = useState(null);
  const [authEmail, setAuthEmail] = useState(null);
  const [loginProcess, setLoginProcess] = useState(true);

  let countries = new URLSearchParams(search).get("countries");
  let collectionTypeForMint = new URLSearchParams(search).get("collectiontype");
  const paymentFor = new URLSearchParams(search).get("paymentFor");
  const bSportsUserId = new URLSearchParams(search).get("bSportsUserId");

  const [kidPayLoading, setKidPayLoading] = useState(false);

  const [reloadPage, setReloadPage] = useState(0);

  countries = JSON.parse(countries);
  let totalAmount = 0;
  if (countries) {
    countries.map((country) => {
      totalAmount += country.amount;
    });
  }

  // Mutations
  const [mintMultipleNftWithKidCoin, { error: errorMintNft }] =
    useMutation(MINT_NFT);

  // Query
  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch: userRefetch,
  } = useQuery(ME, {
    variables: {
      _id: localStorage.getItem("userid"),
    },
  });

  async function kidCoinPay(price) {
    setKidPayLoading(true);
    let successPaid = true;
    if (userData.me.kidCoinToken) {
      let payDetails = await fetch(
        "https://kdcn-exp.herokuapp.com/integrations/bsp/purchase",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Version: "kidcoin-and/0.9.1 api/1.0.0",
            "Content-Type": "application/json",
            Authorization: `JWT ${userData.me.kidCoinToken}`,
          },
          body: JSON.stringify({
            product_id: "123ae-a2342-34s5-34345s-45gfd",
            amount: price,
            wallet_id: "dgdfg2323k.j4h2k4h23hk2j34hkjh34k2j3h4.2k3h4k23j4h",
            note: "something or null",
            payload: {
              image: "www.image.com",
              description: "This product is the best",
              quantity: 1,
            },
          }),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          console.log(response, "Find this response?");
          if (response.title === "Error") {
            window.alert(`${response.message}`);
            successPaid = false;
            setKidPayLoading(false);
          }
          return response;
        })
        .catch((error) => {
          successPaid = false;
        });

      if (successPaid) {
        const { data } = await mintMultipleNftWithKidCoin({
          variables: {
            collectionType: collectionTypeForMint,
            paymentBy: localStorage.getItem("userid"),
            nfts: countries,
            transaction_id: payDetails.transaction_id,
            paidKidCoin: totalAmount * 10 * 6,
          },
        });

        setCard({
          ...card,
          totalNft: 0,
          allSelectedNft: [],
        });

        navigate("/my-nfts");
      }

      setKidPayLoading(false);
    }

    setKidPayLoading(false);
  }

  useEffect(() => {
    if (!localStorage.getItem("userid")) {
      navigate("/");
    }
    userRefetch();

    (async function loadKidcoinProfile() {
      setFetchingDAta(true);

      if (userData?.me.kidCoinToken) {
        console.log("Come here to get token?");
        let kidCoinProfile = await fetch(
          "https://kdcn-exp.herokuapp.com/integrations/bsp/profile",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Version: "kidcoin-and/0.9.1 api/1.0.0",
              "Content-Type": "application/json",
              Authorization: `JWT ${userData.me.kidCoinToken}`,
            },
          }
        )
          .then((response) => response.json())
          .catch((error) => {
            console.error(error, "This is firebaseerror");
          });
        setLeftKidCoin(kidCoinProfile.stats.k_balance);
        setFetchingDAta(false);
      }
      setFetchingDAta(false);
    })();
  }, [reloadPage, userData]);

  if (!userLoading && !userData.me.kidCoinToken) {
    console.log("Come here to auth firebase?");
    navigate(`/kidcoinLogin?bSportsUserId=${localStorage.getItem("userid")}`);

    return null;
  }

  if (fetchingData || userLoading)
    return (
      <CommonBackdrop>
        <div className="mt-20">
          <DataLoading loadingType="success" />
        </div>
      </CommonBackdrop>
    );

  return (
    <CommonBackdrop>
      <div className="text-yellow-300 px-3 font-display">
        <div className="w-1/2 mx-auto mt-5">
          <img
            src={"/images/kidcoin/kidcoin-logo-color-02.png"}
            alt="product-image"
            className="w-full"
          />
        </div>

        <div className="mx-auto mt-12 mb-6">
          {userData.me.kidCoinToken && (
            <div className="w-full">
              <div className="sm:w-96 text-center mx-auto bg-black/40 p-5 rounded">
                <h2 className="text-xl bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-green-300">
                  You have total {leftKidCoin} Kidcoin
                </h2>

                <div className="mt-3">
                  <h3 className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-green-300">
                    Total NFTs = {totalAmount}
                  </h3>
                  <h3 className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-green-300">
                    Those Will Cost = {totalAmount * 10 * 6} Kidcoin
                  </h3>
                  <div className="flex flex-wrap justify-evenly mt-3">
                    {countries &&
                      countries.map((item, index) => (
                        <div
                          className="mt-2 bg-green-200/10 bg-black px-3 py-1 mx-1 rounded-full"
                          key={index}
                        >
                          <h5 className="text-yellow-300">
                            {item.country} = {item.amount}
                          </h5>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <button
                onClick={() => kidCoinPay(totalAmount * 10 * 6)}
                className="w-full bg-yellow-300 py-2 mt-6 text-slate-800 border-2 border-transparent font-bold text-xl rounded-full duration-700 hover:bg-transparent hover:border-yellow-300 hover:text-yellow-300 "
              >
                {kidPayLoading ? "Paying" : "Pay"}
              </button>
            </div>
          )}
        </div>
      </div>
    </CommonBackdrop>
  );
}
