import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import AdminPortal from "./AdminPortal";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CONTACT_FORM_URL, LOCAL_STORAGE_KEYS } from "../../utils/constant";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";

// Query
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetails.query";

// MUTATION
import UPDATE_BASE from "../../gql/mutation/base/updateBaseByAdmin.mutations";

function AdminProfile() {
  let baseId = localStorage.getItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);

  const [value, setValue] = useState("Text to copy");
  const [copied, setCopied] = useState(false);
  const [slotPrice, setSlotPrice] = useState(0);

  const [slotDetails, setSlotDetails] = useState({
    price: 0,
    discount: 0,
    subTtile: "",
    description: "",
  });
  const [membershipDetails, setMembershipDetails] = useState({
    price: 0,
    discount: 0,
    subTtile: "",
    description: "",
  });

  // Location STATES
  const [baseAddress, setBaseAddress] = useState("");

  // Image state
  const [image, setImage] = useState(null);
  const [isImageUploading, setisImageUploading] = useState(false);

  // Slider image states
  const [sliderImages, setSliderImages] = useState([]);
  const [isSliderImageUploading, setisSliderImageUploading] = useState(false);

  // Spinner loading css
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  // Query
  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // Mutations
  const [updateBaseMutation, { error: updateBaseError }] =
    useMutation(UPDATE_BASE);

  // Update Image State from Database data
  useEffect(() => {
    function setDate() {
      if (baseData?.findBaseDetails?.bannerImage) {
        setImage(baseData.findBaseDetails?.bannerImage);
      }

      if (baseData?.findBaseDetails?.sliderImages?.length > 0) {
        setSliderImages(baseData.findBaseDetails.sliderImages);
      }

      if (baseData?.findBaseDetails?.address) {
        setBaseAddress(baseData.findBaseDetails.address);
      }

      if (baseData?.findBaseDetails?.slotDetails) {
        setSlotDetails(
          JSON.parse(JSON.stringify(baseData.findBaseDetails?.slotDetails))
        );
      }

      if (baseData?.findBaseDetails?.membershipDetails) {
        setMembershipDetails(
          JSON.parse(
            JSON.stringify(baseData.findBaseDetails?.membershipDetails)
          )
        );
      }
    }
    setDate();
    baseRefetch();
  }, [baseData]);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  // Upload cloudinary image
  const handleFileChange = async (e) => {
    setisImageUploading(true);
    const file = e.target.files[0];

    let formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "blueLinerfile");
    formData.append("cloud_name", "dva2poi3c");

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dvqldxdfv/image/upload`,
        {
          method: "post",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        setImage(data.secure_url);
      }
      setisImageUploading(false);
    } catch (error) {
      setisImageUploading(false);
    }
  };

  // Upload cloudinary image
  const handleSliderImagesUpload = async (e) => {
    if (e?.target?.files?.length > 0) {
      const photoFiles = Object.values(e.target.files);
      // photoFiles.pop();

      setisSliderImageUploading(true);

      let newImages = [];

      await Promise.all(
        photoFiles.map(async (file) => {
          let formData = new FormData();
          formData.append("file", file);
          formData.append("upload_preset", "blueLinerfile");
          formData.append("cloud_name", "dva2poi3c");

          try {
            const response = await fetch(
              `https://api.cloudinary.com/v1_1/dvqldxdfv/image/upload`,
              {
                method: "post",
                body: formData,
                headers: {
                  Accept: "application/json",
                },
              }
            );

            if (response.ok) {
              const data = await response.json();

              newImages.push(data.secure_url);
            }
          } catch (error) {}
        })
      );

      setSliderImages([...sliderImages, ...newImages]);

      setisSliderImageUploading(false);
    }
  };

  // Submit to create Event
  async function updateBase() {
    if (image == "" || image == null) {
      toast.error("Please insert a banner image!");
      return;
    }

    const { data } = await updateBaseMutation({
      variables: {
        baseId: baseData.findBaseDetails._id,
        bannerImage: image,
        slotDetails,
        membershipDetails,
        sliderImages,
        baseAddress,
      },
    });

    if (data.updateBaseByAdmin.errorMessage == null) {
      toast.success("Successfully updated Base");
    } else {
      toast.error(data.updateBaseByAdmin.errorMessage);
    }
  }

  if (baseLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (baseError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <AdminPortal>
      <div className="max-w-4xl mx-auto bg-slate-700 mt-10 py-3 px-8 mb-8 rounded-md">
        <h1 className="text-center font-bold text-2xl mt-2 mb-4">
          Base Details
        </h1>
        {/* BANNER IMAGE */}
        <div className="mt-5">
          <div className="mr-2 pr-2 font-bold flex items-center">
            <div className="text-2xl font-bold text-yellow-100">
              Update Banner Image
            </div>
            <div className="ml-5">
              {isImageUploading ? (
                <ClipLoader
                  color={"#ffffff"}
                  loading={true}
                  css={override}
                  size={20}
                />
              ) : (
                <input
                  type="file"
                  onChange={handleFileChange}
                  disabled={isImageUploading}
                />
              )}
            </div>
          </div>
          <div>
            {image && (
              <img
                src={image}
                alt="Uploaded"
                style={{ borderRadius: "10px", maxHeight: "250px" }}
                className="mt-3"
              />
            )}
          </div>
        </div>

        {/* SLIDER IMAGES */}
        <div className="my-10">
          <div className="mr-2 pr-2 font-bold flex items-center">
            <div className="text-2xl font-bold text-yellow-100">
              Update Base Home Slider Image
            </div>

            {/* Input Image file */}
            <div className="ml-5">
              {isSliderImageUploading ? (
                <ClipLoader
                  color={"#ffffff"}
                  loading={true}
                  css={override}
                  size={20}
                />
              ) : (
                <input
                  type="file"
                  onChange={handleSliderImagesUpload}
                  disabled={isSliderImageUploading}
                  multiple={true}
                />
              )}
            </div>
          </div>

          <div>
            <div className="flex flex-wrap">
              {sliderImages &&
                sliderImages.length > 0 &&
                sliderImages.map((image) => (
                  <div style={{ position: "relative" }} className="mt-5 mr-5">
                    <img
                      src={image}
                      alt="Uploaded"
                      style={{ borderRadius: "10px", maxHeight: "120px" }}
                    />
                    {/* PLAY ICON */}
                    <div
                      style={{
                        color: "yellow",
                        position: "absolute",
                        top: 5,
                        right: 10,
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        setSliderImages(
                          sliderImages.filter((str) => str !== image)
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} size="2x" />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {/* Setup Base Location */}
        <div className="text-2xl mt-6 mb-2 font-bold text-yellow-100">
          Setup Base Location
        </div>
        <div>
          <div className="my-3 flex flex-row w-[630px] items-center">
            <h1 className="w-1/4 font-bold">
              Address/ Area<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            <input
              className="border-2 w-3/4 border-slate-500 px-2 py-2  bg-transparent rounded  text-white font-bold font-display"
              onChange={(e) => setBaseAddress(e.target.value)}
              placeholder="Enter base address"
              value={baseAddress}
            />
          </div>
        </div>

        {/* Slot details */}
        <div className="text-2xl mt-6 mb-2 font-bold text-yellow-100">
          Slot Management
        </div>
        <div>
          <div className="my-3 flex flex-row w-[630px]">
            <h1 className="w-1/4 font-bold">
              Slot price<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            <input
              className="border-2 w-3/4 border-slate-500 px-2 bg-transparent rounded  text-white font-bold font-display"
              onChange={(e) =>
                setSlotDetails({
                  ...slotDetails,
                  price: Number(e.target.value),
                })
              }
              type="number"
              placeholder="Enter price in $"
              value={slotDetails.price ? slotDetails.price : null}
              min={0}
            />
          </div>

          <div className="my-3 flex  flex-row w-[630px] items-center">
            <h1 className=" w-1/4  font-bold">
              Slot Sub Title<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            <input
              className="border-2 w-3/4 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
              onChange={(e) =>
                setSlotDetails({ ...slotDetails, subTtile: e.target.value })
              }
              value={slotDetails.subTtile ? slotDetails.subTtile : null}
            />
          </div>

          <div className="my-3 flex flex-row w-[630px] items-center">
            <h1 className=" w-1/4  font-bold">
              Slot Description<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            <textarea
              className=" border-2 w-3/4  h-[100px] border-slate-500 px-2  bg-transparent rounded text-white font-bold font-display"
              onChange={(e) =>
                setSlotDetails({ ...slotDetails, description: e.target.value })
              }
              value={slotDetails.description ? slotDetails.description : null}
            />
          </div>
        </div>

        {/* Membership details */}
        <div className="text-2xl mt-6 mb-2 font-bold text-yellow-100">
          Membership Management
        </div>
        <div>
          <div className="my-3 flex flex-row w-[630px] items-center">
            <h1 className="w-1/4 font-bold">
              Membership price<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            <input
              className="border-2 w-3/4 border-slate-500 px-2 bg-transparent rounded  text-white font-bold font-display"
              onChange={(e) =>
                setMembershipDetails({
                  ...membershipDetails,
                  price: Number(e.target.value),
                })
              }
              type="number"
              placeholder="Enter price in $"
              value={membershipDetails.price ? membershipDetails.price : null}
              min={0}
            />
          </div>

          <div className="my-3 flex  flex-row w-[630px] items-center">
            <h1 className=" w-1/4  font-bold">
              Membership Sub Title<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            <input
              className="border-2 w-3/4 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
              onChange={(e) =>
                setMembershipDetails({
                  ...membershipDetails,
                  subTtile: e.target.value,
                })
              }
              value={
                membershipDetails.subTtile ? membershipDetails.subTtile : null
              }
            />
          </div>

          <div className="my-3 flex flex-row w-[630px] items-center">
            <h1 className=" w-1/4  font-bold">
              Membership Description<b style={{ color: "#ff0000a1" }}> </b>
            </h1>
            <textarea
              className=" border-2 w-3/4  h-[100px] border-slate-500 px-2  bg-transparent rounded text-white font-bold font-display"
              onChange={(e) =>
                setMembershipDetails({
                  ...membershipDetails,
                  description: e.target.value,
                })
              }
              value={
                membershipDetails.description
                  ? membershipDetails.description
                  : null
              }
            />
          </div>
        </div>

        {/* SUBMIT button */}
        <button
          className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
          onClick={() => {
            updateBase();
          }}
        >
          Update
        </button>
      </div>
    </AdminPortal>
  );
}
export default AdminProfile;
