import React, { useState, useEffect } from "react";
import {
  EACH_NFT_AFFILIATE_COUPON_DISCOUNT_PRICE_FOR_ALL_USER_IN_DOLLAR,
  STRIPE_CREDENTIALS,
  EVENT_PAY,
  LOCAL_STORAGE_KEYS,
} from "../../../utils/constant";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import CommonBackdrop from "../../../component/common/CommonBackdrop";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";

// Mutations
import MINT_MULTIPLE_NFTS from "../../../gql/mutation/nft/mintMultipleNft.mutations";
import CHECK_COUPON_CODE_VALIDITY from "../../../gql/mutation/nft/checkCouponCodeValidity.mutations";
import PARTICIPATE_EVENT from "../../../gql/mutation/base/participateEvent.mutations";
import SUBSCRIBE_EVENTS_FOR_MONTH from "../../../gql/mutation/base/subscribeEventsForMonth.mutations";

// Query
import FIND_A_EVENT from "../../../gql/query/base/findEvent.query";
import FIND_BASE_DETAILS from "../../../gql/query/base/findBaseDetails.query";

import DataLoading from "../../../component/loader/DataLoading";

function VisaPayment() {
  const navigate = useNavigate();
  const search = useLocation().search;
  let countries = new URLSearchParams(search).get("countries");
  const paymentFor = new URLSearchParams(search).get("paymentFor");
  const bSportsUserId = new URLSearchParams(search).get("bSportsUserId");
  let collectionTypeForMint = new URLSearchParams(search).get("collectiontype");
  let eventId = new URLSearchParams(search).get("eventId");

  const [loadingButton, setLoadingButton] = useState(false);

  const [giftPurchase, setGiftPurchase] = useState(false);
  const [giftEmail, setGiftEmail] = useState("");
  const [giftMessage, setGiftMessage] = useState("");

  const [couponCode, setCouponCode] = useState("");
  const [couponCodeCheckResponse, setCouponCodeCheckResponse] = useState("");
  const [totalDiscountInDollar, setTotalDiscountInDollar] = useState(0);

  // Query
  const {
    data: sceduleData,
    loading: sceduleLoading,
    error: eventError,
    refetch: sceduleRefetch,
  } = useQuery(FIND_A_EVENT, {
    skip: !eventId,
    variables: {
      eventId,
    },
  });

  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  countries = JSON.parse(countries);
  let totalAmount = 0;
  if (countries) {
    countries.map((country) => {
      totalAmount += country.amount;
    });
  }

  // Mutations
  const [mintMultipleNft, { loading: loadingPaying, error: errorMintNft }] =
    useMutation(MINT_MULTIPLE_NFTS);
  const [
    participateEvent,
    { loading: participateEventLoading, error: participateEventError },
  ] = useMutation(PARTICIPATE_EVENT);
  const [
    subscribeEventsForMonth,
    {
      loading: subscribeEventsForMonthLoading,
      error: subscribeEventsForMonthError,
    },
  ] = useMutation(SUBSCRIBE_EVENTS_FOR_MONTH);
  const [
    checkCouponCodeValidity,
    { loading: loadingCheckCouponValidity, error: errorCheckCouponValidity },
  ] = useMutation(CHECK_COUPON_CODE_VALIDITY);

  // Query
  // const { data, loading, error } = useQuery(FIND_A_NFT, {
  //   variables: { _id: nftId },
  // });

  const [cardNumber, setCardNumber] = useState();
  const [expiry, setExpiry] = useState();
  const [cardCvv, setCardCvv] = useState();
  const [CardInput, setCardInput] = useState({});
  const [walletPublicAddress, setWalletPublicAddress] = useState("");

  let CARD_TOKEN = null;

  const _onChange = (data) => {
    setCardInput(data);
  };

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  const onSubmit = async () => {
    if (giftPurchase && giftEmail === "") {
      window.alert("Please enter gift receiver email");
      return null;
    }
    if (giftEmail != "") {
      if (!validateEmail(giftEmail)) {
        window.alert("Invalid Email");
        return null;
      }
    }
    setLoadingButton(true);
    // Check the Credit Card Input as Valid or Invalid
    if (!expiry || !cardNumber || !cardCvv) {
      window.alert("Missing valid card data");
      setLoadingButton(false);
      return false;
    }

    // Create Credit card token to pay
    let creditCardToken = null;

    try {
      creditCardToken = await getCreditCardToken();

      if (creditCardToken.error) {
        throw new Error("Credit Card Token error");
      }
      CARD_TOKEN = creditCardToken.id;
    } catch (error) {
      setLoadingButton(false);
      return;
    }

    // Charge the credit CardPAY`
    let pament_data = await charges();

    // Save payment to Database
    // Success/Pending/Failed payment must be saved in Database for further investigation

    if (paymentFor === "mintNft") {
      const { data } = await mintMultipleNft({
        variables: {
          collectionType: collectionTypeForMint,
          paymentMethod: "Stripe",
          paymentBy: bSportsUserId,
          nfts: countries,
          token: JSON.stringify(creditCardToken),
          paymentResponse: JSON.stringify(pament_data),
          toPublicAddress: walletPublicAddress,
          purchaseAsAGiftEmail: giftPurchase ? giftEmail : null,
          couponCode,
          giftMessage: giftPurchase ? giftMessage : null,
        },
      });
      if (data.mintMultipleNft.errorMessage == null) {
        setLoadingButton(false);
        navigate("/my-nfts");
        return;
      }
      if (data.mintMultipleNft.errorMessage) {
        setLoadingButton(false);
        window.alert(`${data.mintMultipleNft.errorMessage}`);
      }
    } else if (paymentFor === "singleEvent") {
      const { data } = await participateEvent({
        variables: {
          userId: bSportsUserId,
          eventId,
          paymentFor,
          token: JSON.stringify(creditCardToken),
          paymentResponse: JSON.stringify(pament_data),
        },
      });
      if (data.participateEvent.errorMessage == null) {
        setLoadingButton(false);
        toast.success("Successfully registered for this event!");
        navigate("/");
        return;
      } else {
        setLoadingButton(false);
        toast.error(data.participateEvent.errorMessage);
        return;
      }
    } else if (paymentFor === "fullMonthEvent") {
      const { data } = await subscribeEventsForMonth({
        variables: {
          userId: bSportsUserId,
          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
          token: JSON.stringify(creditCardToken),
          paymentResponse: JSON.stringify(pament_data),
        },
      });
      if (data.subscribeEventsForMonth.errorMessage == null) {
        setLoadingButton(false);
        toast.success("You have successfully subscribed for this month!");
        navigate("/");
        return;
      } else {
        setLoadingButton(false);
        toast.error(data.subscribeEventsForMonth.errorMessage);
        return;
      }
    }
  };

  async function getCreditCardToken() {
    const card = {
      "card[number]": cardNumber,
      "card[exp_month]": expiry.split("/")[0],
      "card[exp_year]": expiry.slice(-2),
      "card[cvc]": cardCvv,
    };

    return fetch("https://api.stripe.com/v1/tokens", {
      headers: {
        // Use the correct MIME type for your server
        Accept: "application/json",
        // Use the correct Content Type to send data to Stripe
        "Content-Type": "application/x-www-form-urlencoded",
        // Use the Stripe publishable key as Bearer
        Authorization: `Bearer ${STRIPE_CREDENTIALS.PUBLISHABLE_KEY}`,
      },
      // Use a proper HTTP method
      method: "post",
      // Format the credit card data to a string of key-value pairs
      // divided by &
      body: Object.keys(card)
        .map((key) => key + "=" + card[key])
        .join("&"),
    })
      .then((response) => response.json())
      .catch((error) => {
        setLoadingButton(false);
        console.log(error);
      });
  }

  // Charge the credit Card
  // Charge the credit Card
  const charges = async () => {
    let costAmount =
      paymentFor === "mintNft"
        ? (totalAmount * 10 - totalDiscountInDollar) * 100
        : paymentFor === "singleEvent"
        ? EVENT_PAY.PER_EVENT_PAY * 100
        : paymentFor === "fullMonthEvent"
        ? EVENT_PAY.PER_MONTH_PAY * 100
        : null;

    const card = {
      amount: costAmount,
      // Amount in 'Cents'. Decimal is invalid.
      currency: "USD",
      source: CARD_TOKEN,
      description: "World Cup 2022 nft mint",
    };

    return fetch("https://api.stripe.com/v1/charges", {
      headers: {
        // Use the correct MIME type for your server
        Accept: "application/json",
        // Use the correct Content Type to send data to Stripe
        "Content-Type": "application/x-www-form-urlencoded",
        // Use the Stripe Secret Key as Bearer
        Authorization: `Bearer ${STRIPE_CREDENTIALS.SECRET_KEY}`,
      },
      // Use a proper HTTP method
      method: "post",
      // Format the credit card data to a string of key-value pairs
      // divided by &
      body: Object.keys(card)
        .map((key) => key + "=" + card[key])
        .join("&"),
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      });
  };

  // Check CouponCode Validity
  async function checkCouponValidity(couponCode) {
    const { data } = await checkCouponCodeValidity({
      variables: {
        couponCode,
      },
    });
    if (data.checkCouponCodeValidity.errorMessage == null) {
      setTotalDiscountInDollar(
        totalAmount *
          EACH_NFT_AFFILIATE_COUPON_DISCOUNT_PRICE_FOR_ALL_USER_IN_DOLLAR
      );
      setCouponCode(couponCode);
      setCouponCodeCheckResponse(data.checkCouponCodeValidity.successMessage);
    } else if (data.checkCouponCodeValidity.errorMessage) {
      setTotalDiscountInDollar(0);
      setCouponCodeCheckResponse(data.checkCouponCodeValidity.errorMessage);
    }
  }

  useEffect(() => {
    function initializePage() {
      sceduleRefetch();
      baseRefetch();
    }
    initializePage();
  }, []);

  if (sceduleLoading || baseLoading) {
    return (
      <CommonBackdrop>
        <div className="mt-20">
          <DataLoading loadingType="success" />
        </div>
      </CommonBackdrop>
    );
  }

  if (eventError || baseError) {
    return (
      <CommonBackdrop>
        <div className="mt-20">
          <DataLoading loadingType="error" />
        </div>
      </CommonBackdrop>
    );
  }

  return (
    <CommonBackdrop>
      <>
        <div className="text-yellow-300 font-display">
          {paymentFor === "singleEvent" && (
            <div className="sx:w-[300px] md:w-[500px] sm:w-[400px]  mx-auto mt-20 bg-black/30  rounded-md overflow-hidden">
              <div className="px-6 py-4  border-b border-yellow-300">
                <h1 className="text-lg font-bold">Event Details</h1>

                <div>
                  Event Type:{" "}
                  <b className="text-white">
                    {sceduleData.findEvent.eventType}
                  </b>
                </div>

                <div>
                  Event date:{" "}
                  <b className="text-white">
                    {format(
                      new Date(sceduleData.findEvent.dateTime),
                      "do MMM yyyy, h:mm aa"
                    )}
                  </b>
                </div>

                {sceduleData.findEvent.sports &&
                  sceduleData.findEvent.sports.length > 0 && (
                    <div>
                      Sports:{" "}
                      <b className="text-white">
                        {sceduleData.findEvent.sports.join(", ")}
                      </b>
                    </div>
                  )}

                <div>
                  Total Costs:{" "}
                  <b className="text-white">${EVENT_PAY.PER_EVENT_PAY}</b>
                </div>
              </div>
            </div>
          )}

          {paymentFor === "fullMonthEvent" && (
            <div className="sx:w-[300px] md:w-[500px] sm:w-[400px]  mx-auto mt-20 bg-black/30  rounded-md overflow-hidden">
              <div className="px-6 py-4  border-b border-yellow-300">
                <h1 className="text-lg font-bold">Base Details</h1>

                <div>
                  Base Name:{" "}
                  <b className="text-white">{baseData.findBaseDetails.name}</b>
                </div>

                <div>
                  Country:{" "}
                  <b className="text-white">
                    {baseData.findBaseDetails.country}
                  </b>
                </div>

                <div>
                  City:{" "}
                  <b className="text-white">{baseData.findBaseDetails.city}</b>
                </div>

                <div>
                  Subscription for:{" "}
                  <b className="text-white">1 Month (All events)</b>
                </div>

                <div>
                  Total Costs:{" "}
                  <b className="text-white">${EVENT_PAY.PER_MONTH_PAY}</b>
                </div>
              </div>
            </div>
          )}

          {paymentFor === "mintNft" && (
            <div className=" flex justify-center items-center ">
              <form className="w-11/12 sm:w-8/12 text-center">
                <p style={{ fontSize: "20px" }} className=" text-accent mb-0">
                  Enter {giftPurchase ? "gift receiver" : "your"} ETH Wallet
                  address (MetaMask or Coinbase Wallet preferred){" "}
                  {!giftPurchase && "*"}
                </p>
                <p className="text-muted text-sm mb-2">
                  * If left empty, we will create one for you and share it in
                  the confirmation email, post minting.
                </p>

                <input
                  className="form-control w-full bg-black/30 border-2 border-yellow-300 border-green-300  focus:border-yellow-300 focus:outline-none rounded-full"
                  onChange={(e) => setWalletPublicAddress(e.target.value)}
                  id="giftEmail"
                  type="text"
                  name="walletAddress"
                  placeholder="Wallet Public Address"
                />

                {giftPurchase && (
                  <div>
                    <p className="my-3 text-xl">
                      Enter gift receiver Email address *
                    </p>

                    <input
                      onChange={(e) => {
                        setGiftEmail(e.target.value);
                      }}
                      className="form-control w-full bg-black/30 border-2 border-yellow-300 border-green-300  focus:border-yellow-300 rounded-full"
                      id="emailAddress"
                      type="email"
                      name="emailAddress"
                      placeholder="Gift receiver email"
                    />

                    <p className="mt-4 mb-2 text-xl">Enter gift message</p>

                    <input
                      onChange={(e) => {
                        setGiftMessage(e.target.value);
                      }}
                      className="form-control w-full bg-black/30 border-2 border-yellow-300 border-green-300  focus:border-yellow-300 rounded-full"
                      id="giftMessage"
                      type="text"
                      name="giftMessage"
                      placeholder="Gift message"
                    />
                  </div>
                )}
              </form>
            </div>
          )}

          <div className="sx:w-[300px] md:w-[500px] sm:w-[400px]  mx-auto mt-20 bg-black/30  rounded-md overflow-hidden">
            <div className="px-6 py-4 text-center border-b border-yellow-300">
              <h1 className="text-lg font-bold">Credit Card</h1>
            </div>
            <div className="px-6 py-4">
              <div className="mb-4">
                <label className="block font-bold mb-2" for="card-number">
                  Card Number
                </label>
                <input
                  onChange={(e) => setCardNumber(e.target.value)}
                  className="form-control w-full bg-black/30 border-2 border-yellow-300 border-green-300  focus:border-yellow-300 focus:outline-none rounded-full"
                  id="card-number"
                  type="text"
                  placeholder="**** **** **** ****"
                />
              </div>

              <div className="mb-4">
                <label className="block font-bold mb-2" for="expiration-date">
                  Expiration Date
                </label>
                <input
                  onChange={(e) => setExpiry(e.target.value)}
                  className="form-control w-full bg-black/30 border-2 border-yellow-300 border-green-300  focus:border-yellow-300 focus:outline-none rounded-full"
                  id="expiration-date"
                  type="text"
                  placeholder="MM/YY"
                />
              </div>

              <div className="mb-4">
                <label className="block font-bold mb-2" for="cvv">
                  CVV
                </label>
                <input
                  onChange={(e) => setCardCvv(e.target.value)}
                  className="form-control w-full bg-black/30 border-2 border-yellow-300 border-green-300  focus:border-yellow-300 focus:outline-none rounded-full"
                  id="cvv"
                  type="text"
                  placeholder="***"
                />
              </div>

              <div className="mb-4">
                <label className="block font-bold mb-2" for="cvv">
                  Cardholder Name
                </label>
                <input
                  className="form-control w-full bg-black/30 border-2 border-yellow-300 border-green-300  focus:border-yellow-300 focus:outline-none rounded-full"
                  type="text"
                  placeholder="Full Name"
                />
              </div>

              {paymentFor === "mintNft" && (
                <div className="mb-4">
                  <label className="block font-bold mb-2" for="cvv">
                    Coupon Code (Optional)
                  </label>
                  <input
                    onChange={(e) => checkCouponValidity(e.target.value)}
                    className="form-control w-full bg-black/30 border-2  border-green-300  focus:border-yellow-300 focus:outline-none rounded-full"
                    type="text"
                    placeholder="Coupon Code"
                  />

                  <p
                    className={`text-xl font-bold text-center my-3 ${
                      couponCodeCheckResponse === "Coupon found"
                        ? "text-green-400"
                        : "text-red-400"
                    }`}
                  >
                    {couponCodeCheckResponse}
                  </p>
                </div>
              )}

              <button
                onClick={() => {
                  if (!loadingPaying) {
                    onSubmit();
                  }
                }}
                className="w-full bg-black/30 border border-yellow-100 text-lg flex items-center justify-center mb-2 py-2 hover:bg-transparent hover:border-yellow-300 duration-500 rounded-full"
              >
                {loadingButton ? "PAYING..." : "PAY"}
              </button>
            </div>
          </div>

          {paymentFor === "mintNft" && (
            <div className="max-w-lg mx-auto lg:flex gap-2 md:flex items-center px-6 py-6">
              <button
                onClick={() => {
                  setGiftPurchase(false);
                  setGiftEmail("");
                }}
                className={`w-full bg-black/30 text-lg ${
                  !giftPurchase ? "text-green-400" : "text-white"
                } flex items-center justify-center mb-2 py-1 border border-yellow-100 hover:border-yellow-300 hover:bg-transparent duration-500 rounded-full`}
              >
                Purchase for myself
              </button>

              <button
                onClick={() => setGiftPurchase(true)}
                className={`w-full bg-black/30 text-lg ${
                  giftPurchase ? "text-green-300" : "text-white"
                } flex items-center justify-center mb-2 py-1 border border-yellow-100 hover:border-yellow-300 hover:bg-transparent duration-500 rounded-full`}
              >
                Purchase as a gift
              </button>
            </div>
          )}

          {paymentFor === "mintNft" && (
            <div className="max-w-lg mx-auto p-5 mb-5 bg-black/30  rounded-md overflow-hidden">
              <h3 className="text-center text-xl">
                Total NFTs = {totalAmount}
              </h3>
              <h3 className="text-center text-xl">
                Total amount = {totalAmount * 10 - totalDiscountInDollar}$
              </h3>
              <div className="flex flex-wrap justify-evenly">
                {countries?.map((item, index) => (
                  <div
                    className="mt-2 bg-green-200/10 bg-black px-3 py-1 mx-1 rounded-full"
                    key={index}
                  >
                    <h5 className="text-yellow-300">
                      {item.country} = {item.amount}
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </>
    </CommonBackdrop>
  );
}

export default VisaPayment;
