import React, { useState, useContext, useEffect } from "react";
import { collection_data } from "../../data/collection_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { Tab, TabList } from "react-tabs";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { format, formatISO } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import Modal from "react-modal";
// import "react-calendar/dist/Calendar.css";

import { utcToZonedTime, format as tzFormat } from "date-fns-tz";

// Query
import FIND_EVENTS_BY_DATE from "../../gql/query/base/findEventsByDateFilter.query";
import CHECK_USER_SUBSCRIPTION from "../../gql/query/base/checkBaseEventSubscription.query";
import FIND_MY_KIDS from "../../gql/query/base/findKidsForParent.query";

// MUTATION
import PARTICIPATE_EVENT from "../../gql/mutation/base/participateEvent.mutations";
import INITIATE_STRIPE_SESSION from "../../gql/mutation/base/initiateStripeSession.mutations";
import INITIATE_STRIPE_BILLING_PORTAL_SESSION from "../../gql/mutation/base/initiateStripeBillingPortalSession.mutations";
import CHECK_COUPON_CODE_VALIDITY from "../../gql/mutation/nft/checkCouponCodeValidity.mutations";

import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  EACH_NFT_AFFILIATE_COUPON_DISCOUNT_PRICE_FOR_ALL_USER_IN_DOLLAR,
  SUPER_BASE_COUPON_CODE_INFO,
  PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
  PROJECT_BSPORTS_SUPER_BASE_MONTHLY_SUBSCRIPTION,
} from "../../utils/constant";
import HeadLine from "../../component/bSports/HeadLine";
import { da } from "date-fns/locale";

const BaseDashboard = () => {
  const navigate = useNavigate();

  const { card, setCard } = useContext(ShoppingCardContext);

  const [showForMan, setShowForMan] = useState(false);
  const [itemsTabs, setItemsTabs] = useState(2);

  // Modal STATE
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedEventDetails, setSelectedScheDetail] = useState(null);
  const [selectedKidIds, setSelectedKidIds] = useState([]);

  // Coupon Code State
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeCheckResponse, setCouponCodeCheckResponse] = useState("");
  const [totalDiscountInDollar, setTotalDiscountInDollar] = useState(0);

  NFT_COUNTRIES.sort((a, b) =>
    a.name.charAt(0).localeCompare(b.name.charAt(0))
  );

  WOMEN_NFT_COUNTRIES.sort((a, b) =>
    a.name.charAt(0).localeCompare(b.name.charAt(0))
  );

  // Calender State
  const [dateValue, setDateValu] = useState("");

  // Query
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_EVENTS_BY_DATE, {
    variables: {
      date: dateValue,
    },
  });
  const {
    data: userSubscriptionData,
    loading: userSubscriptionLoading,
    error: userSubscriptionError,
    refetch: userSubscriptionRefetch,
  } = useQuery(CHECK_USER_SUBSCRIPTION, {
    variables: {
      userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });
  const {
    data: findMyKids,
    loading: findMyKidsLoading,
    error: findMyKidsError,
    refetch: findMyKidsRefetch,
  } = useQuery(FIND_MY_KIDS, {
    variables: {
      parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });

  // MUTATION
  const [
    participateEvent,
    { loading: participateEventLoading, error: participateEventError },
  ] = useMutation(PARTICIPATE_EVENT);
  const [
    initiateStripeSessionMutation,
    {
      loading: initiateStripeSessionMutationLoading,
      error: initiateStripeSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_SESSION);
  const [
    initiateStripeBillingPortalSessionMutation,
    {
      loading: initiateStripeBillingPortalSessionMutationLoading,
      error: initiateStripeBillingPortalSessionMutationError,
    },
  ] = useMutation(INITIATE_STRIPE_BILLING_PORTAL_SESSION);
  const [
    checkCouponCodeValidity,
    { loading: loadingCheckCouponValidity, error: errorCheckCouponValidity },
  ] = useMutation(CHECK_COUPON_CODE_VALIDITY);

  useEffect(() => {
    function initializePage() {
      if (card.collectionType === "Male") {
        setItemsTabs(1);
        setShowForMan(true);
      }
      eventsRefetch();
      userSubscriptionRefetch();
      findMyKidsRefetch();
    }
    initializePage();
  }, []);

  async function joinEventAsASubscriptionUser(eventId) {
    if (!selectedKidIds || selectedKidIds.length == 0) {
      toast.error("Please select a KID to join the event!");
      return;
    }
    const { data } = await participateEvent({
      variables: {
        userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        eventId,
        kidIds: selectedKidIds,
      },
    });
    if (data.participateEvent.errorMessage == null) {
      toast.success("Successfully enrolled into this event!");
      eventsRefetch();
      setSelectedKidIds([]);
      setIsOpen(false);
      return;
    } else {
      toast.error(data.participateEvent.errorMessage);
      return;
    }
  }

  // Check CouponCode Validity
  async function checkCouponValidity(couponCode, paymentFor) {
    // Set coupon code
    setCouponCode(couponCode);
    // Call to backend for checking this coupon code
    const { data } = await checkCouponCodeValidity({
      variables: {
        couponCode,
      },
    });
    // If coupon code is correct then set discount price
    if (data.checkCouponCodeValidity.errorMessage == null) {
      setCouponCodeCheckResponse(data.checkCouponCodeValidity.successMessage);
      // If Monthly subscription
      if (paymentFor == PAYMENT_FOR.PURCHASE_FULL_MONTH_EVENT_OF_BASE) {
        setTotalDiscountInDollar(
          SUPER_BASE_COUPON_CODE_INFO.MONTHLY_SUBSCRIPTION_DISCOUNT_IN_DOLLAR
        );
      } else if (paymentFor == PAYMENT_FOR.PURCHASE_SINGLE_EVENT_OF_BASE) {
        // Else Single event purchase
        setTotalDiscountInDollar(
          SUPER_BASE_COUPON_CODE_INFO.SINGLE_EVENT_PURCHASE_DISCOUNT_IN_DOLLAR
        );
      }
    } else if (data.checkCouponCodeValidity.errorMessage) {
      setTotalDiscountInDollar(0);
      setCouponCodeCheckResponse(data.checkCouponCodeValidity.errorMessage);
    }
  }

  // Single Event Purchase
  async function initiateStripeCheckoutUi(eventId) {
    if (!selectedKidIds || selectedKidIds.length == 0) {
      toast.error("Please select a KID to join the event!");
      return;
    }
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Call the Mutation to get STRIPE session URL
      const { data } = await initiateStripeSessionMutation({
        variables: {
          project: PROJECT_BSPORTS_SUPER_BASE_SINGLE_EVENT_PURCHASE,
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
          eventId,
          kidIds: selectedKidIds,
          couponCode,
        },
      });
      if (data.initiateStripeSession.errorMessage == null) {
        setSelectedKidIds([]);
        setIsOpen(false);
        window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      } else {
        toast.error(data.initiateStripeSession.errorMessage);
      }
    } else {
      // As user is not logged in, so navigate the user to login page
      navigate(`login`);
      return;
    }
  }

  // For Monthy subscription
  async function initiateStripeCheckoutUiForMonthlySubscription() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Call the Mutation to get STRIPE session URL
      const { data } = await initiateStripeSessionMutation({
        variables: {
          project: PROJECT_BSPORTS_SUPER_BASE_MONTHLY_SUBSCRIPTION,
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
          baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
          couponCode,
        },
      });
      if (data.initiateStripeSession.errorMessage == null) {
        window.location.href = data.initiateStripeSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      } else {
        toast.error(data.initiateStripeSession.errorMessage);
      }
    } else {
      // As user is not logged in, so navigate the user to login page
      navigate(`/login`);
      return;
    }
  }

  // This function will generate a Billing Portal for management of Subscriptions
  async function initiateStripeBillingPortalForSubscriptionManagment() {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
      // Call the Mutation to get STRIPE session URL
      const { data } = await initiateStripeBillingPortalSessionMutation({
        variables: {
          userId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        },
      });
      if (data.initiateStripeBillingPortalSession.errorMessage == null) {
        window.location.href =
          data.initiateStripeBillingPortalSession.additionalData; // Here additionalData is the STRIPE checkout url (generated by our server)
      }
    } else {
      // As user is not logged in, so navigate the user to login page
      navigate(`/login`);
      return;
    }
  }

  // Handle Calender Actions
  function handleClickDay(date) {
    // Today date
    const currentDate = new Date();

    // First convert the date from STRING to DATE format
    const selectedDate = new Date(date);

    // Set current Hour, Minute, Second (As DatePicker can't handle it)
    selectedDate.setHours(currentDate.getHours());
    selectedDate.setMinutes(currentDate.getMinutes());
    selectedDate.setSeconds(currentDate.getSeconds());

    let formattedDate = formatISO(selectedDate, { representation: "complete" });

    setDateValu(formattedDate);
  }

  // Modal functions
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);

    // Make coupon code info as empty for safety
    setCouponCode("");
    setCouponCodeCheckResponse("");
    setTotalDiscountInDollar(0);
  }

  if (eventsLoading || userSubscriptionLoading || findMyKidsLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (eventsError || userSubscriptionError || findMyKidsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <CommonBackdrop>
      <div className="px-12">
        <div className="md:w-full sm:w-full sx:w-full px-2 w-full ">
          <section className="">
            <div className="w-full bg-black/20 px-10 h-10 flex items-center flex-row mt-5">
              <h1 className="text-yellow-300">Event / </h1>
              <h1> Events Calendar</h1>
            </div>

            {/* CALENDER & SCHDULEs */}
            <div className="flex justify-between flex-wrap mt-5">
              {/* SCHDULES */}
              <div className="w-full md:w-1/2">
                <div>
                  <div style={{ fontWeight: "700" }}>Upcoming Events</div>
                  {eventsData.findEventsByDateFilter?.map((event, index) => {
                    return (
                      <div>
                        {/* DATE + EVENT info + BOOK session button */}
                        <div
                          className="bg-black/20 mt-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 md:mr-5 py-2"
                          key={index}
                          style={{
                            borderLeft: "5px solid yellow",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* DATE & Event INFO */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {/* DATE section */}
                            <div
                              style={{
                                color: "white",
                                width: "80px",
                                borderRight: "1px dotted #ffffff45",
                                paddingLeft: "15px",
                              }}
                              className="text-2xl"
                            >
                              {format(new Date(event.dateTime), "EEE MMM dd")}
                            </div>

                            {/* Schdule INFO section */}
                            <div className="font-semibold ml-5">
                              {/* TIME */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div class="w-3 h-3 rounded-full bg-blue-500 flex items-center justify-center">
                                  <div class="w-1 h-1 rounded-full bg-black"></div>
                                </div>
                                {/* Time value */}
                                <div
                                  style={{ color: "#8F9BB3" }}
                                  className="text-sm ml-2"
                                >
                                  {format(new Date(event.dateTime), "h:mm aa")}
                                </div>
                              </div>

                              {/* CAPTION */}
                              <div className="text-sm">
                                Event Type:{" "}
                                <b style={{ color: "#ccd0e0" }}>
                                  {event.eventType}
                                </b>
                              </div>

                              {/* CAPTION */}
                              {event.caption && (
                                <div className="text-sm">
                                  Title:{" "}
                                  <b style={{ color: "#ccd0e0" }}>
                                    {event.caption}
                                  </b>
                                </div>
                              )}

                              {/* DURATIOON */}
                              {event.duration && (
                                <div className="text-sm">
                                  Duration:{" "}
                                  <b style={{ color: "#ccd0e0" }}>
                                    {event.duration}
                                  </b>
                                </div>
                              )}

                              {/* SPORTS */}
                              {event.sports && event.sports.length > 0 && (
                                <div className="text-sm">
                                  Sports:{" "}
                                  <b style={{ color: "#ccd0e0" }}>
                                    {event.sports.join(", ")}
                                  </b>
                                </div>
                              )}

                              {/* GAME TYPE */}
                              {event.gameType && (
                                <div className="text-sm">
                                  Game Type:{" "}
                                  <b style={{ color: "#ccd0e0" }}>
                                    {event.gameType}
                                  </b>
                                </div>
                              )}

                              {/* SLOT */}
                              {event.slot && (
                                <div className="text-sm">
                                  #SLOT:{" "}
                                  <b style={{ color: "#ccd0e0" }}>
                                    {event.slot}
                                  </b>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="flex mr-5">
                            {/* JOIN */}
                            <button
                              className="text-sm font-bold py-1 px-5 rounded-full"
                              style={{
                                backgroundColor: "#0000008a",
                                color: "#FFFFFF",
                              }}
                              onClick={() => {
                                if (
                                  !localStorage.getItem(
                                    LOCAL_STORAGE_KEYS.USER_ID
                                  )
                                ) {
                                  navigate(`/login`);
                                } else if (
                                  findMyKids.findKidsForParent.length < 1
                                ) {
                                  navigate("add-kid");
                                } else {
                                  // Set IDs for getting data
                                  setSelectedEventId(event._id);
                                  setSelectedScheDetail(event);

                                  // Make coupon code info as empty for safety
                                  setCouponCode("");
                                  setCouponCodeCheckResponse("");
                                  setTotalDiscountInDollar(0);

                                  // Open Modal
                                  openModal();
                                }
                              }}
                            >
                              BOOKED
                            </button>
                          </div>

                          <div className="flex mr-5">
                            {/* JOIN */}
                            <button
                              className="text-sm font-bold py-1 px-5 rounded-full"
                              style={{
                                backgroundColor: "#0000008a",
                                color: "#FFFFFF",
                              }}
                              onClick={() => {
                                if (
                                  !localStorage.getItem(
                                    LOCAL_STORAGE_KEYS.USER_ID
                                  )
                                ) {
                                  navigate(`/login`);
                                } else if (
                                  findMyKids.findKidsForParent.length < 1
                                ) {
                                  navigate("add-kid");
                                } else {
                                  // Set IDs for getting data
                                  setSelectedEventId(event._id);
                                  setSelectedScheDetail(event);

                                  // Make coupon code info as empty for safety
                                  setCouponCode("");
                                  setCouponCodeCheckResponse("");
                                  setTotalDiscountInDollar(0);

                                  // Open Modal
                                  openModal();
                                }
                              }}
                            >
                              BOOKED
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {(!eventsData.findEventsByDateFilter ||
                    eventsData.findEventsByDateFilter.length == 0) && (
                    <div className="text-center mt-5">No events today!</div>
                  )}
                </div>
              </div>

              {/* MEMBER SHIP speech */}
              {/* Single Event */}
              <div className="w-full md:w-1/2">
                <div className="my-4 md:ml-5">
                  <div className="bg-black/20 flex justify-between items-center font-display text-yellow-300 text-lg rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30">
                    <div className="font-semibold my-auto ml-2 mr-5">
                      {/* CAPTION */}
                      <div>Membership</div>
                      <div
                        className="text-md"
                        style={{ color: "#ccd0e0", fontWeight: "bold" }}
                      >
                        $200/Month
                      </div>

                      <div
                        className="text-sm mt-5"
                        style={{ color: "#ccd0e0" }}
                      >
                        Our first Basement Sports Super Base is opening in
                        Jersey City on September 13, 2023. Register your kids
                        NOW! Space is limited (to 200 kid members).{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </CommonBackdrop>
  );
};

export default BaseDashboard;
