import gql from "graphql-tag";

export default gql`
  mutation createBaseByAdmin(
    $name: String
    $slug: String
    $logo: String
    $organizerUserId: String
    $playersType: [String]
    $sport: [String]
  ) {
    createBaseByAdmin(
      name: $name
      slug: $slug
      logo: $logo
      organizerUserId: $organizerUserId
      playersType: $playersType
      sport: $sport
    ) {
      _id
      name
      logo
      bannerImage
      lattitude
      longitude
      city
      country
      difficulty
      sport
      errorMessage
    }
  }
`;
