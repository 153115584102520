import gql from "graphql-tag";

export default gql`
  mutation updateVideoPlayerTag(
    $streamingRecordId: String
    $playerIds: [String]
  ) {
    updateVideoPlayerTag(
      streamingRecordId: $streamingRecordId
      playerIds: $playerIds
    ) {
      errorMessage
      successMessage
    }
  }
`;
