import { format, addYears, formatISO } from "date-fns";
import { BASEMENT_SPORTS_WEBSITE_URL } from "./constant";

export function setDefaultEventMail({
  eventId,
  caption,
  dateTime,
  duration,
  sports,
  gameType,
  slot,
}) {
  return `
    Hi dear Parents,
  
    We're thrilled to welcome you and your kids to Super Base, the ultimate sports experience for young players!
  
    Here is an upcoming event from the organizer.
    
    ${caption ? `Event Title: ${caption}` : ""}${
    dateTime
      ? `\n    Event Date: ${format(
          new Date(dateTime),
          "do MMM yyyy, h:mm aa"
        )}`
      : ""
  }${duration ? `\n    Event Duration: ${duration} minutes` : ""}${
    sports ? `\n    Event Sports: ${sports.join(", ")}` : ""
  }${gameType ? `\n    Game Type: ${gameType}` : ""}${
    slot ? `\n    #SLOT: ${slot}` : ""
  }

    Event details page: ${
      BASEMENT_SPORTS_WEBSITE_URL + "event-details/" + eventId
    }
  
    Super Base is more than just a sports camp. It's a place where kids can explore different games, express themselves, make new friends, and have fun in a safe and supportive environment.
  
    We can't wait to see you and your kid at Super Base, located at 150 Bay Street, Suite 718, Jersey City, NJ 07302. If you have any questions or concerns, please feel free to contact us at info@basementsports.com or 555.233.6767. Let's get ready to play!
  
    Sincerely
  
    Super Base Team
  `;
}
