import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import EventList from "../../component/home/EventList";
import EventsCalender from "../base/eventsCalender";
import BaseHome from "../base/baseHome";

function HomePage() {
  const navigate = useNavigate();

  return <BaseHome />;
}

export default HomePage;
