import { gql } from "@apollo/client";

export default gql`
  query findAGame($id: String) {
    findAGame(id: $id) {
      _id
      team
      teamId
      teamLogo
      against
      againstId
      againstLogo
      time
      place
      winner
      hasEnded
      isSeries
      bestOf

      seriesTeamOneWin
      seriesTeamTwoWin
      teamOneRuns
      teamTwoRuns
      teamOneExtraRuns
      teamTwoExtraRuns

      allGamesExtraInningsListed

      allGamesScore {
        teamOneRuns
        teamTwoRuns
      }

      allGamesExtraRuns {
        teamOneExtraRuns
        teamTwoExtraRuns
      }

      battingTeam
      currentInnings
      numberOfInnings
      extraInnings

      teamOneSO
      teamTwoSO
      teamOneLOB
      teamTwoLOB
      teamOneHits
      teamTwoHits
      teamOnePC
      teamTwoPC
      teamOneBB
      teamTwoBB

      teamMembers {
        userId
        name
        avatar
        isOut
        totalPitch
        pitcher
        battingInHomeBase
        jersey
      }
      againstMembers {
        userId
        name
        avatar
        isOut
        totalPitch
        pitcher
        battingInHomeBase
        jersey
      }
    }
  }
`;
