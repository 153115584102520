import gql from "graphql-tag";

export default gql`
  mutation participateEvent(
    $userId: String
    $eventId: String!
    $kidIds: [String]!
  ) {
    participateEvent(userId: $userId, eventId: $eventId, kidIds: $kidIds) {
      _id
      paymentMethod
      paymentFor
      paymentBy
      amountToPay
      paidAmount
      currency
      paymentStatus
      paymentTransactionId
      createdAt
      errorMessage
    }
  }
`;
