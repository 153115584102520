// BaseRoute.js
import React from "react";
import { Route, useNavigate, Navigate } from "react-router-dom";
import { LOCAL_STORAGE_KEYS, USER_TYPE } from "../utils/constant";
import { navigate } from "react-big-calendar/lib/utils/constants";

const CheckBaseLoggedInUser = ({ path, element }) => {
  if (!localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
    return <Navigate to={`/login`} />;
  }
  return element;
};

export default CheckBaseLoggedInUser;
