import gql from "graphql-tag";

export default gql`
  mutation initiateStripeSetupSession($userId: String!) {
    initiateStripeSetupSession(userId: $userId) {
      additionalData
      errorMessage
      successMessage
    }
  }
`;
