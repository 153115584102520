import { gql } from "@apollo/client";

export default gql`
  query findMyBookedEvents($userId: String) {
    findMyBookedEvents(userId: $userId) {
      _id
      eventType
      caption
      dateTime
      normalDate
      eventPrice
      duration
      sports
      gameType
      slot
      participantKidIds
    }
  }
`;
