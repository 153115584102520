import React from "react";

function DataLoading({ loadingType }) {
  return (
    <div className="flex items-center self-auto">
      <div
        className={`${
          loadingType === "success"
            ? "bg-gradient-to-tl from-green-300/70 via-red-300/70  to-yellow-300/70 bg-black"
            : "bg-red-400"
        } mx-auto m-auto inline-block h-20 w-20 rounded-full animate-spin  border-4 border-solid border-current border-r-transparent border-l-transparent border-yellow-300 align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)] bg-white">
          Loading...
        </span>
      </div>
    </div>
  );
}

export default DataLoading;
