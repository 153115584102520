import gql from "graphql-tag";

export default gql`
  mutation makeUserAffiliate($userId: String!) {
    makeUserAffiliate(userId: $userId) {
      _id
      userId
      affiliateCouponCode
      totalNftSell
      totalNftPayout
    }
  }
`;
