import React from "react";

import { Link, Navigate } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "../../utils/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

export default function DashMenu() {
  return (
    <div
      id="menu"
      className="bg-slate-700 min-h-screen z-10 text-slate-300 w-64 fixed left-0 h-screen overflow-y-scroll no-scrollbar"
    >
      <div id="logo" className="my-4 px-6">
        <h1 className="text-lg md:text-2xl font-bold text-yellow-300">
          Base <span className="text-white">Admin</span>.
        </h1>
        <p className="text-slate-500 text-sm">
          Manage your actions and activities
        </p>
      </div>
      {/* <div id="profile" className="px-6 py-5">
        <p className="text-slate-500">Welcome back,</p>
        <a href="#" className="inline-flex space-x-2 items-center">
          <span>
            <img
              className="rounded-full w-8 h-8"
              src="https://images.unsplash.com/photo-1542909168-82c3e7fdca5c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=128&q=80"
              alt=""
            />
          </span>
          <span className="text-sm md:text-base font-bold">Admin Name</span>
        </a>
      </div> */}
      <div id="nav" className="w-full px-6">
        {/* <Link
          to={"/"}
          className="w-full px-2 inline-flex space-x-2 items-center border-b border-slate-500 py-3 hover:bg-white/5 transition ease-linear duration-150"
        >
          <div className="flex flex-col">
            <span className="text-lg text-slate-300 font-bold leading-5">
              Home
            </span>
          </div>
        </Link> */}

        <Link
          to={"/admin/profile"}
          className="w-full px-2 inline-flex space-x-2 items-center border-b border-slate-500 py-3 hover:bg-white/5 transition ease-linear duration-150"
          style={{
            backgroundColor: window.location.pathname.includes("/admin/profile")
              ? "#3864a8ab"
              : null,
          }}
        >
          <div className="flex flex-col">
            <span className="text-lg text-slate-300 font-bold leading-5">
              Base Details
            </span>
          </div>
        </Link>

        <Link
          to={"/admin/sub-admins"}
          className="w-full px-2 inline-flex space-x-2 items-center border-b border-slate-500 py-3 hover:bg-white/5 transition ease-linear duration-150"
          style={{
            backgroundColor: window.location.pathname.includes(
              "/admin/sub-admins"
            )
              ? "#3864a8ab"
              : null,
          }}
        >
          <div className="flex flex-col">
            <span className="text-lg text-slate-300 font-bold leading-5">
              Admin Overview
            </span>
          </div>
        </Link>

        <Link
          to={"/admin/kid-management"}
          className="w-full px-2 inline-flex space-x-2 items-center border-b border-slate-500 py-3 hover:bg-white/5 transition ease-linear duration-150"
          style={{
            backgroundColor:
              window.location.pathname.includes("/admin/kid-management") ||
              window.location.pathname.includes("/admin/kid-details")
                ? "#3864a8ab"
                : null,
          }}
        >
          <div className="flex flex-col">
            <span className="text-lg text-slate-300 font-bold leading-5">
              Members
            </span>
          </div>
        </Link>

        <Link
          to={"/admin/base-highlights"}
          className="w-full px-2 inline-flex space-x-2 items-center border-b border-slate-500 py-3 hover:bg-white/5 transition ease-linear duration-150"
          style={{
            backgroundColor:
              window.location.pathname.includes("/admin/base-highlights") ||
              window.location.pathname.includes("/admin/video")
                ? "#3864a8ab"
                : null,
          }}
        >
          <div className="flex flex-col">
            <span className="text-lg text-slate-300 font-bold leading-5">
              Base Highlights
            </span>
          </div>
        </Link>

        <Link
          to={"/admin/event"}
          className="w-full px-2 inline-flex space-x-2 items-center border-b border-slate-500 py-3 hover:bg-white/5 transition ease-linear duration-150"
          style={{
            backgroundColor:
              window.location.pathname == "/admin" ||
              window.location.pathname.includes("/admin/event") ||
              window.location.pathname.includes("/admin/add-event") ||
              window.location.pathname.includes("/admin/update-event") ||
              window.location.pathname.includes("/admin/add-participants") ||
              window.location.pathname.includes("/admin/participants") ||
              window.location.pathname.includes("/admin/event-send-mail")
                ? "#3864a8ab"
                : null,
          }}
        >
          <div className="flex flex-col">
            <span className="text-lg text-slate-300 font-bold leading-5">
              Events
            </span>
          </div>
        </Link>

        <Link
          to={"/admin/payments"}
          className="w-full px-2 inline-flex space-x-2 items-center border-b border-slate-500 py-3 hover:bg-white/5 transition ease-linear duration-150"
          style={{
            backgroundColor: window.location.pathname.includes(
              "/admin/payments"
            )
              ? "#3864a8ab"
              : null,
          }}
        >
          <div className="flex flex-col">
            <span className="text-lg text-slate-300 font-bold leading-5">
              Payments
            </span>
          </div>
        </Link>

        <Link
          to={"/admin/affiliates"}
          className="w-full px-2 inline-flex space-x-2 items-center border-b border-slate-500 py-3 hover:bg-white/5 transition ease-linear duration-150"
          style={{
            backgroundColor:
              window.location.pathname.includes("/admin/affiliates") ||
              window.location.pathname.includes("/admin/add-affiliate")
                ? "#3864a8ab"
                : null,
          }}
        >
          <div className="flex flex-col">
            <span className="text-lg text-slate-300 font-bold leading-5">
              Affiliates
            </span>
          </div>
        </Link>

        {localStorage.getItem("userid") ? (
          <>
            <div
              onClick={() => {
                localStorage.removeItem("userid");
                localStorage.removeItem("bsports_user_name");
                localStorage.removeItem("user_email");
                localStorage.removeItem("kidcointoken");
                localStorage.removeItem("affiliateCouponCode");
                localStorage.removeItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);
                localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_TYPE);

                window.location.reload("/admin");
              }}
              className="flex mt-3 cursor-pointer"
            >
              <h1 className="ml-1 text-lg text-slate-300 font-bold leading-5">
                Log out
              </h1>
            </div>
          </>
        ) : (
          <>
            <Link to="/login" className="flex mt-3 cursor-pointer">
              <h1 className="ml-1 text-lg text-slate-300 font-bold leading-5">
                Log In
              </h1>
            </Link>
          </>
        )}
      </div>
    </div>
  );
}
