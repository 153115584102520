import React, { useEffect } from "react";
import AdminPortal from "./AdminPortal";
import { Link } from "react-router-dom";
import { users } from "../../utils/user";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery } from "@apollo/client";
import { format } from "date-fns";

// Queries
// import FIND_BASE_CONTACTS from "../../gql/query/base/findBaseContacts.query";
import FIND_BASE_DETAILS from "../../gql/query/base/findBaseDetails.query";
import { LOCAL_STORAGE_KEYS } from "../../utils/constant";

function Users(props) {
  let baseId = localStorage.getItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);

  // Query
  const {
    data: baseData,
    loading: baseLoading,
    error: baseError,
    refetch: baseRefetch,
  } = useQuery(FIND_BASE_DETAILS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // Re-fetch data
  useEffect(() => {
    function refetchData() {
      baseRefetch();
    }
    refetchData();
  }, []);

  function showEventTitle(event) {
    // Create SPORTS name for Event TITLE in Calender
    let sports = "";
    if (event?.sports && event?.sports.length > 0) {
      // Create SPORTS name for TITLE
      event?.sports.map((sport) => {
        sports += sport + " + ";
      });

      // Remove last extra " + "
      if (sports.endsWith(" + ")) {
        sports = sports.slice(0, -3);
      }
    }

    let eventInfo = `${event?.eventType}${
      event?.caption ? ", " + event?.caption + " " : ""
    }${sports != "" ? ", " + sports + " " : ""}${
      event?.gameType ? ", " + event?.gameType + " GAME " : ""
    }${event?.duration ? ", " + event?.duration + " minutes " : ""}${
      event?.slot ? ", #SLOT " + event?.slot + " " : ""
    }`;

    if (
      eventInfo == undefined ||
      eventInfo == null ||
      eventInfo == "undefined" ||
      eventInfo == "null"
    ) {
      eventInfo = "-";
    }

    return eventInfo;
  }

  if (baseLoading)
    return (
      <CommonBackdrop>
        <div className="mt-20">
          <DataLoading loadingType="success" />
        </div>
      </CommonBackdrop>
    );

  return (
    <AdminPortal>
      <div className="md:w-11/12 mx-auto bg-slate-700 flex justify-between items-center px-4 py-3 mb-3 mt-5">
        <h1 className="font-display font-bold text-lg">Members</h1>{" "}
        {/* <Link to={"/admin/add-user"} className="bg-slate-800 py-1 px-2 rounded">
          + Add User
        </Link> */}
      </div>
      <div className="overflow-x-auto w-72 sm:w-10/12 md:w-11/12 mx-auto">
        {baseData.findBaseDetails &&
        baseData.findBaseDetails.membersDetail &&
        baseData.findBaseDetails.membersDetail.length > 0 ? (
          <table className="bg-slate-700/80 border-yellow-100 border border-b-transparent text-sm mx-auto">
            <thead className="border-b border-yellow-100">
              <tr>
                <th
                  className="lg:w-40 py-3 px-3 text-center"
                  role="columnheader"
                >
                  <span>Serial</span>
                </th>
                <th
                  className="lg:w-40 py-3 px-3 text-center"
                  role="columnheader"
                >
                  <span className="">Guardian Name</span>
                </th>
                <th
                  className="lg:w-40 py-3 px-3 text-center"
                  role="columnheader"
                >
                  <span>Email/ Phone</span>
                </th>
                {/* <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Child name</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Child gender</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Relation</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Sports</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Register date</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Event Name</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Event Time</span>
              </th> */}
              </tr>
            </thead>

            {baseData.findBaseDetails.membersDetail.map((contact, index) => {
              return (
                <tbody>
                  <tr
                    className=" border-b border-yellow-100"
                    role="row"
                    key={index}
                  >
                    <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                      <span className="mr-2 lg:mr-4">{index + 1}</span>
                    </td>
                    <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                      <span className="mr-2 lg:mr-4">{contact.name}</span>
                    </td>

                    <td className=" lg:w-40 py-3 px-3 text-center" role="cell">
                      <span className={"text-red cursor-pointer"}>
                        {contact.email}
                      </span>
                    </td>
                    {/* <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                      <span className={"text-blue"}>{contact.childName}</span>
                    </td>

                    <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                      <span className="mr-2 lg:mr-4">{contact.kidGender}</span>
                    </td>

                    <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                      <span>{contact.relation}</span>
                    </td>
                    <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                      <span>{contact.sports && contact.sports.join(", ")}</span>
                    </td>

                    <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                      <span>
                        {format(Number(contact.createdAt), "do MMM yyyy")}
                      </span>
                    </td>

                    <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                      <span>{showEventTitle(contact.eventDetails)}</span>
                    </td>

                    <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                      <span>
                        {contact.eventDetails &&
                        contact.eventDetails.dateTime
                          ? format(
                              new Date(contact.eventDetails.dateTime),
                              "do MMM yyyy, h:mm aa"
                            )
                          : "-"}
                      </span>
                    </td> */}

                    {/* eventDetails */}
                  </tr>
                </tbody>
              );
            })}
          </table>
        ) : (
          <div className="text-center">No members yet!</div>
        )}
      </div>
    </AdminPortal>
  );
}
export default Users;
