import React, { useState, useEffect } from "react";
import AdminPortal from "./AdminPortal";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { format, addYears, formatISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";
import { utcToZonedTime, format as tzFormat } from "date-fns-tz";
import { Image } from "cloudinary-react";
import DataLoading from "../../component/loader/DataLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";

// Rich text editor import
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  CLOUDINARY_INFO,
  LOCAL_STORAGE_KEYS,
  RANDOM_AVATAR_URLS,
} from "../../utils/constant";
import { setDefaultEventMail } from "../../utils/eventEmail";

// Query
import FIND_A_EVENT from "../../gql/query/base/findEvent.query";
import FIND_BASE_PLAYERS from "../../gql/query/base/findBasePlayers.query";

// Mutations
import SEND_EMAIL from "../../gql/mutation/base/sendCustomEmailForEvent.mutations";

function EventSendMail() {
  const navigate = useNavigate();
  const { eventId } = useParams();

  // Email STATE
  const [selectedEmailsOfParent, setSelectedEmailsOfParent] = useState([]);
  const [customEmail, setCustomEmail] = useState("");
  const [mailTextToSend, setMailTextToSend] = useState("");

  // Query
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_A_EVENT, {
    variables: {
      eventId,
    },
  });

  const {
    data: basePlayers,
    loading: basePlayersLoading,
    error: basePlayersError,
    refetch: basePlayersRefetch,
  } = useQuery(FIND_BASE_PLAYERS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  // Mutations
  const [
    sendEmailMutation,
    { loading: sendEmailMutationLoading, error: createEventError },
  ] = useMutation(SEND_EMAIL);

  // DATE variables
  let oneYearFuture = addYears(new Date(), 1);

  // Initialize event data
  useEffect(() => {
    function initializeEventData() {
      // Refetch event data
      eventsRefetch();

      // If data successfully came
      if (eventsData && eventsData.findEvent) {
        let eventDetails = eventsData.findEvent;
        // Set Default MAIL TEXT
        setMailTextToSend(
          setDefaultEventMail({
            eventId,
            caption:
              eventDetails.caption && eventDetails.caption != null
                ? eventDetails.caption
                : null,
            dateTime: eventDetails.dateTime,
            duration:
              eventDetails.duration &&
              eventDetails.duration != 0 &&
              eventDetails.duration != null
                ? eventDetails.duration
                : null,
            sports:
              eventDetails.sports && eventDetails.sports.length > 0
                ? eventDetails.sports
                : null,
            gameType:
              eventDetails.gameType && eventDetails.gameType != null
                ? eventDetails.gameType
                : null,
            slot:
              eventDetails.slot &&
              eventDetails.slot != 0 &&
              eventDetails.slot != null
                ? eventDetails.slot
                : null,
          })
        );
      }
    }
    initializeEventData();
  }, [eventsData]);

  // Submit to update Event
  async function sendMail() {
    if (selectedEmailsOfParent.length == 0) {
      toast.error("Please add at-least one email!");
      return;
    }

    let isError = false;
    selectedEmailsOfParent.map((email) => {
      const checkMail = validateEmail(email);
      if (!checkMail) {
        toast.error(`Email: ${email} is not valid!`);
        isError = true;
      }
    });
    if (isError) {
      return;
    }

    const { data } = await sendEmailMutation({
      variables: {
        eventId,
        parentEmails: selectedEmailsOfParent,
        emailText: mailTextToSend,
      },
    });

    if (data.sendCustomEmailForEvent.errorMessage == null) {
      toast.success("Successfully sent email!");
    } else {
      toast.error("Error occurred!");
    }
  }

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    // Valid format:  example@something.com, example@something.io
  }

  if (eventsLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (eventsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  console.log("Called 3: ", eventsData.findEvent);

  return (
    <AdminPortal>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        // className="ml-10 mr-10 mt-6"
        className="md:w-11/12 mx-auto bg-slate-700 flex justify-between items-center px-4 py-3 mt-8 mb-3 "
      >
        <div className="">
          <Link
            to={`/admin/update-event/${eventId}`}
            className="font-bold text-lg px-4 m-1 py-1 rounded"
          >
            <FontAwesomeIcon className="mr-3" icon={faCircleArrowLeft} />
            Back
          </Link>
        </div>
      </div>

      <div className="max-w-4xl mx-auto bg-slate-700 mt-5 py-3 px-4 mb-14">
        <h3 className="text-center font-bold text-[26px] mt-2 mb-6">
          Mail Engine
        </h3>

        {/* Emails to send */}
        {/* Emails to send */}
        <div className="my-3" style={{ display: "flex" }}>
          <label className="mr-5 font-bold" style={{ minWidth: "200px" }}>
            Set Custom Emails to send
          </label>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {/* selectedEmailsOfParent with parent INFO (avatar/ name) */}
            {selectedEmailsOfParent.map((email) => (
              <div className="mb-2">
                <div
                  style={{
                    border: "1px solid #525252fa",
                    display: "flex",
                    flexWrap: "wrap",
                    borderRadius: "5px",
                    alignItems: "center",
                  }}
                  className="px-2 py-2 mr-2"
                >
                  <div>{email}</div>
                  <FontAwesomeIcon
                    className="ml-2 cursor-pointer"
                    color="yellow"
                    icon={faClose}
                    onClick={() => {
                      // Remove Array reference & create a new one
                      let prevSelectedParentEmails = [
                        ...selectedEmailsOfParent,
                      ];
                      // Get EMAIL index
                      let index = prevSelectedParentEmails.indexOf(email);
                      // SPLICE out the PARENT email
                      prevSelectedParentEmails.splice(index, 1);
                      // SET new ARRAY of IDs
                      setSelectedEmailsOfParent(prevSelectedParentEmails);
                    }}
                  />
                </div>
                <div className="text-xs">
                  {validateEmail(email) ? (
                    <div style={{ color: "green" }}>Valid email!</div>
                  ) : (
                    <div style={{ color: "red" }}>Not valid!</div>
                  )}
                </div>
              </div>
            ))}
            {/* Add new custom email */}
            <div>
              <div
                style={{
                  display: "flex",
                  border: "1px solid #3b5d9078",
                  borderRadius: "5px",
                }}
                className="px-1 py-1"
              >
                <input
                  className="border-2 border-slate-500 px-1 py-1 bg-transparent rounded text-white font-bold font-display"
                  type="text"
                  // style={{ width: "150px" }}
                  placeholder="Add custom email"
                  onChange={(e) => {
                    setCustomEmail(e.target.value);
                  }}
                  value={customEmail}
                />
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mx-auto block ml-2"
                  onClick={(e) => {
                    if (!customEmail || customEmail == "") {
                      toast.error("Please type an email!");
                      return;
                    }
                    let parentEmailsArray = [
                      ...selectedEmailsOfParent,
                      customEmail,
                    ];
                    setSelectedEmailsOfParent(parentEmailsArray);
                    setCustomEmail("");
                  }}
                >
                  ADD
                </button>
              </div>
              {customEmail && (
                <div className="text-xs">
                  {validateEmail(customEmail) ? (
                    <div style={{ color: "green" }}>Valid email!</div>
                  ) : (
                    <div style={{ color: "red" }}>Not valid!</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Add emails of PARENT from Base members */}
        {/* Add emails of PARENT from Base members */}
        <div style={{ display: "flex" }}>
          <label className="mr-9 pr-3 font-bold" style={{ minWidth: "200px" }}>
            Select Emails from Base Members
          </label>
          {basePlayersLoading ? (
            <DataLoading loadingType="success" />
          ) : (
            <div
              style={{ height: "400px" }}
              className="overflow-y-scroll overflow-x-hidden w-full mr-5"
            >
              {basePlayers.findBasePlayers.map((kid, index) => {
                return (
                  <div>
                    {/* KIDS */}
                    <div
                      className="bg-black/20 mb-4 flex items-center font-display text-yellow-300 text-lg rounded-lg shadow-md shadow-black/30 py-2 mr-3"
                      key={index}
                      style={{
                        borderLeft: "5px solid yellow",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* KID AVATAR + Name + Check button */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="py-2"
                      >
                        {/* KID AVATAR */}
                        <img
                          src={
                            RANDOM_AVATAR_URLS[kid.avatar]
                              ? RANDOM_AVATAR_URLS[kid.avatar]
                              : RANDOM_AVATAR_URLS["user4_asset4"]
                          }
                          alt="Basement Sports"
                          className="max-h-17"
                          style={{
                            color: "white",
                            width: "70px",
                            borderRight: "1px dotted #ffffff45",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                          }}
                        />

                        {/* KID INFO section */}
                        <div className="font-semibold ml-5 text-sm">
                          {/* Kid Name */}
                          <div>
                            Kid Name:{" "}
                            <b style={{ color: "#ccd0e0" }}>{kid.name}</b>
                          </div>
                          {/* Parent Name */}
                          <div style={{ color: "#37b3ffcf" }}>
                            Parent Name:{" "}
                            <b style={{ color: "#ccd0e0" }}>
                              {kid.user.parentDetails?.name
                                ? kid.user.parentDetails?.name
                                : "-"}
                            </b>
                          </div>
                          {/* Parent Email */}
                          <div style={{ color: "#37b3ffcf" }}>
                            Parent Email:{" "}
                            <b style={{ color: "#ccd0e0" }}>
                              {kid.user.parentDetails?.email
                                ? kid.user.parentDetails?.email
                                : "-"}
                            </b>
                          </div>
                        </div>
                      </div>

                      {/* Action Part (Check box / Button) for each KID */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* Check if KID was selected or not */}
                        <div
                          onClick={() => {
                            // Check if, newly selected PARENT's email exist or not
                            if (kid.user.parentDetails?.email) {
                              if (
                                selectedEmailsOfParent.includes(
                                  kid.user.parentDetails?.email
                                )
                              ) {
                                // Remove Array reference & create a new one
                                let prevSelectedParentEmails = [
                                  ...selectedEmailsOfParent,
                                ];
                                // Get KID ID index
                                let index = prevSelectedParentEmails.indexOf(
                                  kid.user.parentDetails?.email
                                );
                                // SPLICE out the PARENT email
                                prevSelectedParentEmails.splice(index, 1);
                                // SET new ARRAY of IDs
                                setSelectedEmailsOfParent(
                                  prevSelectedParentEmails
                                );
                              } else {
                                setSelectedEmailsOfParent([
                                  ...selectedEmailsOfParent,
                                  kid.user.parentDetails?.email,
                                ]);
                              }
                            } else {
                              toast.error(
                                "No email found! Please add a PARENT for this Kid first."
                              );
                            }
                          }}
                          className={`w-5 h-5 border-2 rounded-full border-yellow-300 mr-5 ${
                            selectedEmailsOfParent.includes(
                              kid.user.parentDetails?.email
                            )
                              ? "bg-yellow-300"
                              : null
                          }`}
                        ></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {/* EMAIL TEXT to send */}
        {/* EMAIL TEXT to send */}
        <div
          className="my-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label className="mr-5 font-bold mb-2" style={{ minWidth: "200px" }}>
            Email Text
          </label>
          <textarea
            className=" border-2 w-full  h-[500px] border-slate-500 px-2  bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => setMailTextToSend(e.target.value)}
            value={mailTextToSend}
          />
        </div>

        {/* SUBMIT button */}
        {sendEmailMutationLoading ? (
          <button className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block">
            Loading
          </button>
        ) : (
          <button
            className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
            onClick={() => {
              sendMail();
            }}
          >
            Send Mail
          </button>
        )}
      </div>
    </AdminPortal>
  );
}
export default EventSendMail;
