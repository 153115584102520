import React from "react";

import AdminPortal from "./AdminPortal";

function AddUser() {
  return (
    <AdminPortal>
      <div>Add user</div>
    </AdminPortal>
  );
}
export default AddUser;
