import React, { useEffect, useContext } from "react";

import { useState } from "react";

import { useMutation, useQuery } from "@apollo/client";

import { Await, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Mutations

import ADD_KID_KOIN_TOKEN from "../../../gql/mutation/kidcoin/addKidCoinTokenToUser.mutation";
import { firebaseAuth } from "../firebase/firebaseconfig.js";
import {
  createUserWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  RecaptchaVerifier,
} from "firebase/auth";

import { async } from "@firebase/util";
import { ShoppingCardContext } from "../../../context/cardContext";

export default function FirebaseAuth() {
  const navigate = useNavigate();
  const { card, setCard } = useContext(ShoppingCardContext);

  const search = useLocation().search;
  const [loadingButotn, setLoadingButton] = useState(false);
  const [fetchingData, setFetchingDAta] = useState(false);
  const [leftKidCoin, setLeftKidCoin] = useState(null);
  const [password, setPassword] = useState(null);
  const [authEmail, setAuthEmail] = useState(null);
  const [loginProcess, setLoginProcess] = useState(true);

  const bSportsUserId = new URLSearchParams(search).get("bSportsUserId");

  const [otpBoxShow, setOtpBoxShow] = useState(false);
  const [final, setFinal] = useState("");
  const [otp, setOtp] = useState("");
  const [successLoginProcess, setSuccessLoginProcess] = useState(false);

  //   countries = JSON.parse(countries);
  //   let totalAmount = 0;
  //   countries.map((country) => {
  //     totalAmount += country.amount;
  //   });

  // Mutations

  const [
    addKidCoinTokenToUser,
    { loading: kidKoinAddLoading, error: kidcoinAddError },
  ] = useMutation(ADD_KID_KOIN_TOKEN);

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    // Valid format:  example@something.com, example@something.io
  }

  function validatePhone(phone) {
    // let reg = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{6})$/
    let reg = /^\+?\d+$/;
    let isphone = phone.match(reg);

    if (isphone) {
      return true;
    } else {
      return false;
    }
  }

  async function addKidcoinToken(authToken) {
    let kidCoinToken;
    await fetch("https://kdcn-exp.herokuapp.com/integrations/bsp/auth", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Version: "kidcoin-and/0.9.1 api/1.0.0",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: authToken,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        kidCoinToken = json.token;
      })
      .catch((error) => {
        console.error(error);
      });

    console.log("Come here to go back");

    await addKidCoinTokenToUser({
      variables: {
        _id: bSportsUserId,
        kidToken: kidCoinToken,
      },
    });

    console.log("yes Come here to go back");

    navigate(
      `/mintNftWithKidcoin?countries=${JSON.stringify(
        card.allSelectedNft
      )}&paymentFor=mintNft&bSportsUserId=${localStorage.getItem(
        "bSportsUserId"
      )}&collectiontype=${card.collectionType}`
    );
    window.location.reload();
    console.log("yes Come here to go bac2k");
  }

  async function otpConfirmation(params) {
    setLoadingButton(true);
    if (otp === "") {
      window.alert("Pleae enter otp");
      return null;
    }

    await final
      .confirm(otp)
      .then((result) => {
        addKidcoinToken(result.user.accessToken);
        setSuccessLoginProcess(true);
      })
      .catch((err) => {
        window.alert("Wrong code");
        setLoadingButton(false);
      });
    setLoadingButton(false);
  }

  async function kidcoinLogin() {
    setLoadingButton(true);
    if (!authEmail) {
      window.alert("Please enter valid email or phone!");
      setLoadingButton(false);
      return;
    }

    // Email validation check
    const checkMail = validateEmail(authEmail);
    const checkPhone = validatePhone(authEmail);

    if (!checkMail && !checkPhone) {
      window.alert("Please enter valid email or phone number");
      setLoadingButton(false);
      return null;
    }
    let authToken;

    if (checkMail) {
      if (!password) {
        window.alert("Please enter password");
        return null;
      }
      await signInWithEmailAndPassword(firebaseAuth, authEmail, password)
        .then((res) => {
          addKidcoinToken(res.user.accessToken);
          setSuccessLoginProcess(true);
          return res;
        })
        .catch((error) => {
          let e = JSON.parse(JSON.stringify(error));

          if (e.code === "auth/user-not-found") {
            window.alert("User not found with this email & password");
            window.location.reload();
            return null;
          }
          setLoadingButton(false);
          return null;
        });
    } else if (checkPhone) {
      let verify = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: function (response, error) {
            console.log("Success", response, error);
          },
        },
        firebaseAuth
      );

      await signInWithPhoneNumber(firebaseAuth, authEmail, verify)
        .then((res) => {
          setFinal(res);
          window.alert("code sent");
          setOtpBoxShow(true);
          return res;
        })
        .catch((error) => {
          let e = JSON.parse(JSON.stringify(error));
          window.alert(e.code);
          window.location.reload();
          setLoadingButton(false);
          return null;
        });
    }
    setLoadingButton(false);
  }

  return (
    <div className="font-display h-screen dark:bg-jacarta-800 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
      <div className="h-screen text-accent pt-20">
        <div id="recaptcha-container"></div>
        <div className="w-1/2 mx-auto mt-5">
          <img
            src={"/images/kidcoin/kidcoin-logo-color-02.png"}
            alt="product-image"
            className="w-full"
          />
        </div>

        {successLoginProcess && (
          <>
            <h5
              style={{ textAlign: "center", marginTop: "10%" }}
              className="text-white text-center fw-bold fs-4"
            >
              Kidcoin Login succeeded. You are able to use kidcoin
            </h5>
          </>
        )}

        {!successLoginProcess && (
          <>
            <div
              className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mx-auto"
              style={{ height: "80%" }}
            >
              <div className="mx-auto mt-12   md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12">
                <div className="flex flex-row items-center justify-center lg:justify-start">
                  <p className="mb-0 mr-4 text-lg">
                    {!otpBoxShow ? "Kidcoin Login" : "Confirm"}
                  </p>
                </div>

                {!otpBoxShow ? (
                  <>
                    <div className="relative my-6" data-te-input-wrapper-init>
                      <h1>Email or Phone with country code</h1>
                      <input
                        onChange={(e) => setAuthEmail(e.target.value)}
                        type="text"
                        className="peer block min-h-[auto] w-full  rounded-lg border border-accent focue:border-accent  px-3 py-[0.32rem]bg-red text-black leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                        placeholder={authEmail ? authEmail : "Email"}
                        style={{ textTransform: "lowercase" }}
                      />
                    </div>

                    {validateEmail(authEmail) && (
                      <>
                        <div
                          className="relative mb-6"
                          data-te-input-wrapper-init
                        >
                          <h1>Password</h1>
                          <input
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            className="peer bg-accent block min-h-[auto] w-full rounded border-0  px-3 py-[0.32rem] text-black leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                            placeholder={password ? password : "Password"}
                          />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      key={otpBoxShow}
                      className="relative mt-6"
                      data-te-input-wrapper-init
                    >
                      <h1 className="mb-4 bg-blue-600">
                        {" "}
                        A confirmation code has been sent to your phone. Please
                        enter this code
                      </h1>
                      <input
                        onChange={(e) => setOtp(e.target.value)}
                        type="text"
                        className=" bg-accent block min-h-[auto] w-full rounded border-0  px-3 py-[0.32rem] text-black leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                        // placeholder="Enter the varification code"
                      />
                    </div>
                  </>
                )}

                <a
                  onClick={() => {
                    if (!loadingButotn) {
                      if (!otpBoxShow) {
                        kidcoinLogin();
                      } else {
                        otpConfirmation();
                      }
                    }
                  }}
                  className="bg-accent border hover:border-accent hover:bg-jacarta-800 hover:text-accent rounded-full py-3  text-center font-bold text-jacarta-800 transition-all"
                >
                  <button className="mt-6 w-full rounded-md  py-1.5 font-medium ">
                    {loadingButotn ? "Wait" : otpBoxShow ? "Proceed" : "Login"}
                  </button>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
