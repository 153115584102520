import { TOURNAMENT_STATUS } from "./constant";

// ########## Send Common RestTime for any game, series or Tournament match ###########

// ########## Send Common RestTime for any game, series or Tournament match ###########
export function findRestTime(
  gameName,
  {
    soccerSingle = {},
    soccerTournament = {},
    hockeySingle = {},
    hockeyTournament = {},
    selfPlay = {},
  }
) {
  let restTime = 0;
  if (gameName == "soccerSingle" || gameName == "soccerSeries") {
    restTime =
      soccerSingle &&
      soccerSingle.findASoccerMatch &&
      soccerSingle.findASoccerMatch.restTime
        ? soccerSingle.findASoccerMatch.restTime * 60
        : 0;
  } else if (gameName == "soccerTournament") {
    restTime =
      soccerTournament &&
      soccerTournament.findSoccerTournamentMatch &&
      soccerTournament.findSoccerTournamentMatch.restTime
        ? soccerTournament.findSoccerTournamentMatch.restTime * 60
        : 0;
  } else if (gameName == "hockeySingle" || gameName == "hockeySeries") {
    restTime =
      hockeySingle &&
      hockeySingle.findAHockeyMatch &&
      hockeySingle.findAHockeyMatch.restTime
        ? hockeySingle.findAHockeyMatch.restTime * 60
        : 0;
  } else if (gameName == "hockeyTournament") {
    restTime =
      hockeyTournament &&
      hockeyTournament.findHockeyTournamentMatch &&
      hockeyTournament.findHockeyTournamentMatch.restTime
        ? hockeyTournament.findHockeyTournamentMatch.restTime * 60
        : 0;
  } else if (
    gameName == "SelfPlaySoccer" ||
    gameName == "SelfPlayHockey" ||
    gameName == "SelfPlayBasketball"
  ) {
    restTime =
      selfPlay &&
      selfPlay.findASelfPlayMatch &&
      selfPlay.findASelfPlayMatch.timeLength
        ? selfPlay.findASelfPlayMatch.timeLength * 60
        : 0;
  }
  return restTime;
}

// ########### Send Common data for any game, series or Tournament match ###########
// ########### Send Common data for any game, series or Tournament match ###########
export default function (
  gameName,
  matchId,
  {
    soccerSingle = {},
    soccerTournament = {},
    hockeySingle = {},
    hockeyTournament = {},
    baseballSingle = {},
    baseballTournament = {},
    tournamentStatus = null,
  }
) {
  let gameScore = {
    team: null,
    teamOneGoals: null,
    teamLogo: null,
    against: null,
    teamTwoGoals: null,
    againstLogo: null,
    firstHalf: true,
    tournamentname: null,
    isWorldSeries: false,
  };
  if (gameName == "soccerSingle" || gameName == "soccerSeries") {
    gameScore = {
      ...gameScore,
      team: soccerSingle.team,
      teamOneGoals: soccerSingle.teamOneGoals,
      teamLogo: soccerSingle.teamLogo,
      against: soccerSingle.against,
      teamTwoGoals: soccerSingle.teamTwoGoals,
      againstLogo: soccerSingle.againstLogo,
      firstHalf: soccerSingle.firstHalf,
    };
  } else if (gameName == "soccerTournament") {
    gameScore = {
      ...gameScore,
      team: soccerTournament.team,
      teamOneGoals: soccerTournament.teamOneGoals,
      teamLogo: soccerTournament.teamLogo,
      against: soccerTournament.against,
      teamTwoGoals: soccerTournament.teamTwoGoals,
      againstLogo: soccerTournament.againstLogo,
      firstHalf: soccerTournament.firstHalf,
    };
  } else if (gameName == "hockeySingle" || gameName == "hockeySeries") {
    gameScore = {
      ...gameScore,
      team: hockeySingle.team,
      teamOneGoals: hockeySingle.teamOneGoals,
      teamLogo: hockeySingle.teamLogo,
      against: hockeySingle.against,
      teamTwoGoals: hockeySingle.teamTwoGoals,
      againstLogo: hockeySingle.againstLogo,
      firstHalf: hockeySingle.firstHalf,
    };
  } else if (gameName == "hockeyTournament") {
    gameScore = {
      ...gameScore,
      team: hockeyTournament.team,
      teamOneGoals: hockeyTournament.teamOneGoals,
      teamLogo: hockeyTournament.teamLogo,
      against: hockeyTournament.against,
      teamTwoGoals: hockeyTournament.teamTwoGoals,
      againstLogo: hockeyTournament.againstLogo,
      firstHalf: hockeyTournament.firstHalf,
    };
  } else if (gameName == "baseballSingle" || gameName == "baseballSeries") {
    gameScore = {
      ...gameScore,
      team: baseballSingle.team,
      teamOneGoals: baseballSingle.isSeries
        ? baseballSingle.seriesTeamOneWin
        : baseballSingle.extraInnings
        ? baseballSingle.teamOneRuns.reduce((acc, val) => acc + val, 0) +
          baseballSingle.teamOneExtraRuns.reduce((acc, val) => acc + val, 0)
        : baseballSingle.teamOneRuns.reduce((acc, val) => acc + val, 0),
      teamLogo: baseballSingle.teamLogo,
      against: baseballSingle.against,
      teamTwoGoals: baseballSingle.isSeries
        ? baseballSingle.seriesTeamTwoWin
        : baseballSingle.extraInnings
        ? baseballSingle.teamTwoRuns.reduce((acc, val) => acc + val, 0) +
          baseballSingle.teamTwoExtraRuns.reduce((acc, val) => acc + val, 0)
        : baseballSingle.teamTwoRuns.reduce((acc, val) => acc + val, 0),
      againstLogo: baseballSingle.againstLogo,
    };
  } else if (gameName == "baseballTournament") {
    let match = null;
    // This bellow is code is coppied from runningBaseBallTournamentMatch.scree.js method callData()  of BasementSportsApp
    let t = baseballTournament;
    if (tournamentStatus == TOURNAMENT_STATUS.FINAL) {
      match = t.finalTeams;
    } else if (tournamentStatus == TOURNAMENT_STATUS.SEME_FINAL) {
      match = t.semiFinalTeams.find((i) => i._id == matchId);
    } else if (tournamentStatus == TOURNAMENT_STATUS.QUATER_FINAL) {
      match = t.quarterFinalTeams.find((i) => i._id == matchId);
    }
    if (match) {
      gameScore = {
        ...gameScore,
        team: match.team,
        teamOneGoals: match.extraInnings
          ? match.teamOneRuns.reduce((acc, val) => acc + val, 0) +
            match.teamOneExtraRuns.reduce((acc, val) => acc + val, 0)
          : match.teamOneRuns.reduce((acc, val) => acc + val, 0),
        teamLogo: match.teamLogo,
        against: match.against,
        teamTwoGoals: match.extraInnings
          ? match.teamTwoRuns.reduce((acc, val) => acc + val, 0) +
            match.teamTwoExtraRuns.reduce((acc, val) => acc + val, 0)
          : match.teamTwoRuns.reduce((acc, val) => acc + val, 0),
        againstLogo: match.againstLogo,
        isWorldSeries: match.isWorldSeries,
      };
    }
  }
  return gameScore;
}
