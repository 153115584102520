import React, { useState, useContext, useEffect, useRef } from "react";
import { collection_data } from "../../data/collection_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { Tab, TabList } from "react-tabs";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { format, formatDistance } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Calendar from "react-calendar";
import Modal from "react-modal";
import differenceInYears from "date-fns/differenceInYears";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";

// CSS
// import "react-calendar/dist/Calendar.css";
import "video-react/dist/video-react.css";

// Query
import FIND_HIGHLIGHT_DETAILS from "../../gql/query/base/findHighlightDetails.query";

import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  PAYMENT_METHOD,
  STRIPE_PAYMENT_RESPONSE_STATUSES,
  RANDOM_AVATAR_URLS,
} from "../../utils/constant";
import HeadLine from "../../component/bSports/HeadLine";
import { da } from "date-fns/locale";
import { PhotoCarousel } from "../../component/base/photoCarousel";

const Highlights = () => {
  // Common data
  const navigate = useNavigate();
  const { highlightId, userId } = useParams();

  // Highlights states
  const ref = useRef();

  // Window SIZE STATE
  const [windowSize, setWindowSize] = useState(getWindowSize());

  // Query
  const {
    data: findHighlightDetails,
    loading: findHighlightDetailsLoading,
    error: findHighlightDetailsError,
    refetch: findHighlightDetailsRefetch,
  } = useQuery(FIND_HIGHLIGHT_DETAILS, {
    variables: {
      highlightId,
      userId,
    },
  });

  // WINDOW SCREEN SIZE CHANGE
  useEffect(() => {
    function handleWindowResize() {
      // Commented the Screen REALOAD on SCREEN size change just for now 👇👇👇
      setWindowSize(getWindowSize());
      // window.location.reload();
    }
    window?.addEventListener("resize", handleWindowResize);

    return () => {
      window?.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  // Load the page on Top after loading
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // Load the page on Top after loading
  useEffect(() => {
    function initializeCarouselSelectedPhoto() {
      let photoIndex = 0;
      findHighlightDetails?.findHighlightDetails?.otherPhotos.map(
        (photo, index) => {
          if (photo._id == highlightId) {
            photoIndex = index;
          }
        }
      );
      setTimeout(() => {
        if (ref.current?.swipeTo) {
          ref.current?.swipeTo(photoIndex);
        }
      }, 300);
    }

    // Only if data successfully fetched
    if (findHighlightDetails?.findHighlightDetails?.otherPhotos) {
      initializeCarouselSelectedPhoto();
    }
  }, [findHighlightDetails]);

  if (findHighlightDetailsLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (findHighlightDetailsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  const highlightDetails = findHighlightDetails.findHighlightDetails;
  const allHighlights = findHighlightDetails.findHighlightDetails.otherPhotos;
  const allVideos = findHighlightDetails.findHighlightDetails.otherVideos;

  return (
    <CommonBackdrop>
      <div className="overflow-x-auto w-72 sm:w-10/12 md:w-11/12 mx-auto">
        <div className="w-full bg-black/20 px-10 h-10 flex items-center flex-row mt-5">
          <h1 className="text-yellow-300">Bases / </h1>
          <h1> Highlights </h1>
        </div>

        {/* Container */}
        <div className="font-semibold my-auto mt-5">
          {/* Items */}
          <div className="flex justify-between flex-wrap">
            {/* HIGHLIGHTS CAROUSEL */}
            {/* HIGHLIGHTS CAROUSEL */}
            <div className="w-full md:w-3/4">
              <div className="md:mr-5">
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    // backgroundColor: "black",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ResponsiveContainer
                    carouselRef={ref}
                    render={(parentWidth, carouselRef) => {
                      let currentVisibleSlide = 1;
                      // if (parentWidth <= 1440) currentVisibleSlide = 3;
                      if (parentWidth <= 900) currentVisibleSlide = 1;
                      return (
                        <StackedCarousel
                          fadeDistance={0.6}
                          height={windowSize.innerHeight - 20}
                          ref={carouselRef}
                          slideComponent={PhotoCarousel()}
                          slideWidth={
                            parentWidth < 800 ? parentWidth - 40 : 900
                          }
                          carouselWidth={parentWidth}
                          data={allHighlights}
                          currentVisibleSlide={currentVisibleSlide}
                          maxVisibleSlide={1}
                          useGrabCursor
                        />
                      );
                    }}
                  />

                  {/* LEFT Button */}
                  <div
                    className="photo-carousel-navigation-button"
                    style={{
                      position: "absolute",
                      top: "35%",
                      left: 0,
                      zIndex: 10,
                    }}
                    onClick={() => {
                      ref.current?.goBack();
                    }}
                  >
                    <i
                      style={{
                        color: "#a9accf",
                        fontSize: "300%",
                      }}
                      className="fa fa-arrow-circle-o-left"
                    ></i>
                  </div>

                  {/* RIGHT Button */}
                  <div
                    className="photo-carousel-navigation-button"
                    style={{
                      position: "absolute",
                      top: "35%",
                      right: 0,
                      zIndex: 10,
                    }}
                    onClick={() => {
                      ref.current?.goNext(6);
                    }}
                  >
                    <i
                      style={{
                        color: "#a9accf",
                        fontSize: "300%",
                      }}
                      className="fa fa-arrow-circle-o-right"
                    ></i>
                  </div>
                </div>
                {/* ---------------✅ */}
              </div>
            </div>

            {/* OTHER VIDEOS */}
            {/* OTHER VIDEOS */}
            <div className="w-full md:w-1/4">
              {allVideos.map((video, index) => {
                return (
                  <div className="mb-3">
                    <div
                      className="cursor-pointer"
                      style={{
                        position: "relative",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                      onClick={() => navigate(`/video/${video._id}`)}
                    >
                      {/* <div className="rounded-lg py-1 px-2 lg:px-6 shadow-md shadow-black/30 my-3"> */}
                      <img
                        src={
                          video?.videoThumbnailUrl
                            ? video.videoThumbnailUrl
                            : "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
                        }
                        alt="Basement Sports"
                        className={`md:block w-[90px] object-cover `}
                        style={{ width: "100%", height: "130px" }}
                      />

                      {/* PLAY ICON */}
                      <div
                        style={{
                          color: "yellow",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "80%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon icon={faCirclePlay} size="3x" />
                      </div>

                      {/* video details */}
                      <div
                        style={{
                          backgroundColor: "#00000033",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="px-2 py-2"
                      >
                        {/* left panel */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* avatar */}
                          <img
                            src={
                              RANDOM_AVATAR_URLS[video.userDetails.avatar]
                                ? RANDOM_AVATAR_URLS[video.userDetails.avatar]
                                : RANDOM_AVATAR_URLS["user4_asset4"]
                            }
                            alt="Basement Sports"
                            className={`md:block w-[90px] mr-2`}
                            style={{ width: "35px", height: "35px" }}
                          />
                          {/* video caption & base name */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {/* video caption */}
                            <div
                              className="text-sm"
                              style={{
                                fontWeight: "700",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                width: "80%",
                              }}
                            >
                              {video.caption}
                            </div>
                            {/* base name */}
                            <div className="text-xs" style={{ opacity: "0.5" }}>
                              @{"base"}
                            </div>
                          </div>
                        </div>

                        {/* right panel */}
                        <div style={{ display: "flex" }}>
                          <div style={{ opacity: "0.5", fontSize: "10px" }}>
                            {formatDistanceToNow(Number(video.createdAt))} ago
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* ✅✅✅✅✅✅✅✅ */}
      </div>
    </CommonBackdrop>
  );
};

export default Highlights;
