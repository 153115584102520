import { useEffect, useState, useContext } from "react";
import { ShoppingCardContext } from "../../context/cardContext";
import { Link } from "react-router-dom";
import FIND_AVAILABLE_MULTIPLE_NFT from "../../gql/query/nft/checkMultipleNftsAvailableOrNot.query";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import { CART_COUNTRIES } from "../../utils/nft_countries";

function ShopingCard() {
  const { card, setCard } = useContext(ShoppingCardContext);
  const [getAlart, setGetAlert] = useState([]);

  const navigate = useNavigate();
  const USER = "userid";

  const {
    data: findAvailableNft,
    loading: availableNftLoading,
    error: availableNftError,
    refetch,
    fetchMore,
  } = useQuery(FIND_AVAILABLE_MULTIPLE_NFT, {
    variables: { nfts: JSON.parse(JSON.stringify(card.allSelectedNft)) },
    skip: card.allSelectedNft === 0,
  });

  async function checkAvailabeNft(paymethod) {
    let countries = JSON.parse(JSON.stringify(card.allSelectedNft));
    if (card.allSelectedNft === 0) {
      window.alert("Select country and amount");
      return null;
    }

    const { data } = await fetchMore({
      variables: {
        nfts: card.allSelectedNft,
      },
    });
    if (data.checkMultipleNftsAvailableOrNot.notAvailableCountries) {
      setGetAlert(data.checkMultipleNftsAvailableOrNot.notAvailableCountries);
    } else {
      console.log("Come here to mint nft with visa?");
      if (paymethod === "visa") {
        navigate(
          `/mintNftWithVisa?countries=${JSON.stringify(
            countries
          )}&paymentFor=mintNft&bSportsUserId=${localStorage.getItem(
            USER
          )}&collectiontype=${card.collectionType}`
        );
      } else if (paymethod === "kidcoin") {
        console.log("Come here to mint nft with kidcoion?");
        navigate(
          `/mintNftWithKidcoin?countries=${JSON.stringify(
            countries
          )}&paymentFor=mintNft&bSportsUserId=${localStorage.getItem(
            USER
          )}&collectiontype=${card.collectionType}`
        );
      }
    }
  }

  function removeItem(item) {
    let selectItem = card.allSelectedNft;
    selectItem = selectItem.filter((i) => i != item);
    setCard({
      ...card,
      totalNft: card.totalNft - item.amount,
      allSelectedNft: selectItem,
    });
  }

  return (
    <CommonBackdrop>
      <div className="mx-auto relative w-11/12 sm:w-9/12 md:w-7/12 lg:w-11/12 xl:w-10/12 z-10 text-yellow-300 font-display">
        {card.totalNft ? (
          <h1 className="mb-10 text-center text-2xl font-bold mt-3">
            Cart Items
          </h1>
        ) : (
          <h1 className="mb-10 text-center text-2xl font-bold mt-3 ">
            Your cart is empty
          </h1>
        )}

        {card.totalNft && (
          <div className="flex flex-col-reverse lg:flex-row lg:justify-center lg:items-start">
            <div className="rounded  lg:w-6/12 xl:w-5/12">
              {card.allSelectedNft.map((item, index) => (
                <div
                  key={index}
                  className="border border-yellow-300 p-6 pt-3 pr-3  mb-5 rounded-lg"
                >
                  <div
                    onClick={() => removeItem(item)}
                    className="flex items-center justify-end"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="h-5 w-5 cursor-pointer duration-500 hover:text-red-500 hover:rotate-180"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col items-center sm:flex-row sm:items-center ">
                    <img
                      src={CART_COUNTRIES[item.country]}
                      alt="product-image"
                      className="rounded-lg sm:w-24 w-48 mb-5 sm:mb-0"
                    />

                    <div className="flex justify-evenly items-center sm:ml-8 w-96">
                      <h2 className="text-lg font-bold">{item.country}</h2>
                      <p className="mt-1 text-xs">Total: {item.amount}</p>
                      <p className="mt-1 text-xs">
                        {item.amount * 10}$ / {item.amount * 60}K
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <!-- Sub total --> */}
            <div className="lg:w-80 px-5 py-5 mb-5 lg:ml-5 lg:mb-0  rounded-lg border border-yellow-300/70">
              <hr className="my-4 border-yellow-300" />
              <div className="flex justify-between mb-5">
                <p className="text-lg font-bold ">Total</p>

                <p className="text-lg font-bold">
                  ${card.totalNft * 10} / {card.totalNft * 60}K
                </p>
              </div>

              <a
                onClick={() => checkAvailabeNft("visa")}
                className="bg-yellow-300 w-full flex justify-center items-center  text-gray-700 font-extrabold px-8 py-3 rounded-full border border-yellow-300 hover:bg-transparent hover:text-yellow-300 duration-700 ease-in-out cursor-pointer mb-3"
              >
                Pay With Card
              </a>

              <a
                onClick={() => checkAvailabeNft("kidcoin")}
                className="bg-yellow-300 w-full flex justify-center items-center  text-gray-700 font-extrabold px-8 py-3 rounded-full border border-yellow-300 hover:bg-transparent hover:text-yellow-300 duration-700 ease-in-out cursor-pointer"
              >
                Pay With Kidcoin
              </a>
            </div>
          </div>
        )}
      </div>
    </CommonBackdrop>
  );
}

export default ShopingCard;
