import { gql } from "@apollo/client";
export default gql`
  query findVideoDetails(
    $videoId: String
    $otherVideoTypes: String
    $playerUserId: String
  ) {
    findVideoDetails(
      videoId: $videoId
      otherVideoTypes: $otherVideoTypes
      playerUserId: $playerUserId
    ) {
      _id
      userId
      userDetails {
        _id
        avatar
      }
      url
      city
      country
      baseId
      gameId
      sport
      likes
      videoThumbnailUrl
      caption
      createdAt
      baseDetails {
        _id
        logo
        name
        members
      }

      playerIds
      playersDetails {
        _id
        name
        avatar
      }

      otherVideos {
        _id
        userDetails {
          _id
          avatar
        }
        likes
        videoThumbnailUrl
        caption
        createdAt
      }
    }
  }
`;
