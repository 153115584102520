import React from "react";
import DashMenu from "../../component/adminPortal/DashMenu";

function AdminPortal(props) {
  return (
    <div className="bg-slate-900 overflow-y-scroll no-scrollbar h-screen antialiased text-slate-300 selection:bg-blue-600 selection:text-white">
      <div className="flex flex-col relative w-screen">
        <DashMenu />
        <div className="ml-64 px-8 ">{props.children}</div>
      </div>
    </div>
  );
}

export default AdminPortal;
