import { gql } from "@apollo/client";

export default gql`
  query findBasePaymentHistory($userId: String) {
    findBasePaymentHistory(userId: $userId) {
      _id
      paymentMethod
      paymentFor
      paymentBy
      paidAmount
      currency
      paymentStatus
      createdAt
      subscriptionDate
      baseDetails {
        _id
        name
        slug
        logo
        bannerImage
        lattitude
        longitude
        city
        country
        playersType
        difficulty
        sport
        type
        description
      }
      kidDetails {
        _id
        name
        avatar
        age
        dateOfBirth
        gender
      }
      eventDetails {
        _id
        createdBy
        baseId
        eventType
        caption
        dateTime
        eventPrice
        duration
        sports
        gameType
        slot
      }
      errorMessage
    }
  }
`;
