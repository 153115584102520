import React, { useState, useContext, useEffect } from "react";
import { collection_data } from "../../data/collection_data";
import HeadLine from "../bSports/HeadLine";
import { ShoppingCardContext } from "../../context/cardContext";
import { Tab, TabList } from "react-tabs";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";

const TopCollection = () => {
  // const [data, setData] = useState(collection_data);
  const { card, setCard } = useContext(ShoppingCardContext);
  // const [countries, setCountries] = useState([]);

  const [showForMan, setShowForMan] = useState(false);
  const [itemsTabs, setItemsTabs] = useState(2);

  // const NFT_COUNTRIES = [
  //   "Qatar",
  //   "Ecuador",
  //   "Senegal",
  //   "Netherlands",
  //   "England",
  //   "Iran",
  //   "USA",
  //   "Wales",
  //   "Argentina",
  //   "Saudi Arabia",
  //   "Mexico",
  //   "Poland",
  //   "France",
  //   "Australia",
  //   "Denmark",
  //   "Tunisia",
  //   "Spain",
  //   "Costa Rica",
  //   "Germany",
  //   "Japan",
  //   "Belgium",
  //   "Canada",
  //   "Portugal",
  //   "Uruguay",
  //   "Brazil",
  //   "Cameroon",
  //   "Croatia",
  //   "Ghana",
  //   "Morocco",
  //   "Serbia",
  //   "South Korea",
  //   "Switzerland",
  // ];

  NFT_COUNTRIES.sort((a, b) =>
    a.name.charAt(0).localeCompare(b.name.charAt(0))
  );

  WOMEN_NFT_COUNTRIES.sort((a, b) =>
    a.name.charAt(0).localeCompare(b.name.charAt(0))
  );

  async function addNftInList(country) {
    let checkCountry = JSON.parse(JSON.stringify(card.allSelectedNft));
    let commonCountry = false;
    for (let i = 0; i < checkCountry.length; i++) {
      if (checkCountry[i].country === country) {
        commonCountry = true;
        checkCountry[i].amount = checkCountry[i].amount + 1;
      }
    }

    if (commonCountry) {
      setCard({
        ...card,
        totalNft: card.totalNft + 1,
        allSelectedNft: checkCountry,
      });
    } else {
      setCard({
        ...card,
        totalNft: card.totalNft + 1,
        allSelectedNft: [...card.allSelectedNft, { country, amount: 1 }],
      });
    }
  }

  async function removeNftFromList(country) {
    let allSelectedCountryWiseNfts = JSON.parse(
      JSON.stringify(card.allSelectedNft)
    );
    let totalSelectedNftCount = card.totalNft;

    // If amount for a COUNTRY is already 1 then we need to delete that country. To do this first send null inside "map" then in "filter" remove that
    let modifiedNewCountryWiseNfts = allSelectedCountryWiseNfts
      .map((countryWiseNftCount) => {
        if (countryWiseNftCount.country === country) {
          // MINUS 1 from Total Selected Count
          --totalSelectedNftCount;
          // Delete or decrease the amount from particular country
          if (countryWiseNftCount.amount == 1) {
            return null;
          } else {
            return {
              ...countryWiseNftCount,
              amount: countryWiseNftCount.amount - 1,
            };
          }
        }
        return countryWiseNftCount;
      })
      .filter((countryWiseNftCount) => countryWiseNftCount);

    setCard({
      ...card,
      totalNft: totalSelectedNftCount,
      allSelectedNft: modifiedNewCountryWiseNfts,
    });
  }

  const collectionItemsTabs = [
    {
      id: 1,
      text: " Men's",
      icon: "items",
    },
    {
      id: 2,
      text: "Women's",
      icon: "activities",
    },
  ];

  useEffect(() => {
    if (card.collectionType === "Male") {
      console.log("Realy Come here?");
      setItemsTabs(1);
      setShowForMan(true);
    }
  }, []);

  return (
    <div>
      <section className="relative z-10 py-24 ">
        <div className="sm:container mx-auto">
          <div className="font-display text-yellow-300 mb-12 text-center text-lg sm:text-3xl flex justify-center items-center gap-x-3">
            <HeadLine text="Choose World Cup NFTs to Mint" classes="inline" />
          </div>
          <TabList
            key={showForMan}
            className="nav nav-tabs border-yellow-300/30 mb-12 flex items-center justify-center border-b"
          >
            {collectionItemsTabs.map(({ id, text, icon }) => {
              return (
                <Tab
                  className="nav-item"
                  key={id}
                  onClick={async () => {
                    if (id === 1) {
                      await setShowForMan(true);
                      setCard({
                        totalNft: 0,
                        allSelectedNft: [],
                        collectionType: "Male",
                      });
                    } else if (id === 2) {
                      await setShowForMan(false);
                      setCard({
                        totalNft: 0,
                        allSelectedNft: [],
                        collectionType: "Female",
                      });
                    }
                    setItemsTabs(id);
                  }}
                >
                  <button
                    className={` 
                        text-gray-200 flex items-center whitespace-nowrap py-3 px-6 ease-in-out duration-500 hover:text-yellow-200
                   ${
                     itemsTabs === id &&
                     "text-yellow-300 active border-b-2 border-yellow-300"
                   }
                        `}
                  >
                    <span className="font-display text-base font-medium">
                      {text}
                    </span>
                  </button>
                </Tab>
              );
            })}
          </TabList>
          <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-6 lg:grid-cols-3">
            {(showForMan ? NFT_COUNTRIES : WOMEN_NFT_COUNTRIES).map(
              (country, index) => {
                return (
                  <div
                    className="bg-slate-800/90 flex justify-between items-center font-display text-yellow-300 text-lg rounded-lg py-4 px-4 lg:px-6 shadow-md shadow-black/30"
                    key={index}
                  >
                    <figure className="mr-4 shrink-0 flex ">
                      {/* <img src={image} alt={title} className="rounded-2lg" /> */}
                      <img
                        src={country.url}
                        alt={country.name}
                        height={55}
                        width={55}
                        objectFit="cover"
                      />

                      <span className="font-semibold my-auto ml-2">
                        {country.name}
                      </span>
                    </figure>

                    <div className="flex items-center">
                      {/* MINUS */}
                      {/* MINUS */}
                      <i
                        className="fa fa-minus-circle cursor-pointer fa-lg  "
                        onClick={() => {
                          removeNftFromList(country.name);
                        }}
                      ></i>
                      <span className="mx-3">
                        {card.allSelectedNft.filter(
                          (countryWiseNft) =>
                            countryWiseNft.country === country.name
                        ).length > 0
                          ? card.allSelectedNft.filter(
                              (countryWiseNft) =>
                                countryWiseNft.country === country.name
                            )[0].amount
                          : 0}
                      </span>

                      {/* PLUS */}
                      {/* PLUS */}
                      <i
                        className="fa fa-plus-circle cursor-pointer fa-lg"
                        onClick={() => {
                          addNftInList(country.name);
                        }}
                      ></i>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default TopCollection;
