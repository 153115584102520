import React, { useState, useContext, useEffect, useRef } from "react";
import { collection_data } from "../../data/collection_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faCopy } from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { Tab, TabList } from "react-tabs";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { format, formatDistance } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Calendar from "react-calendar";
import Modal from "react-modal";
import differenceInYears from "date-fns/differenceInYears";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {
  Player,
  BigPlayButton,
  StaticPlayerInstanceMethods,
} from "video-react";
// Share
import {
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  FacebookShareButton,
  TwitterShareButton,
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
} from "react-share";
import copy from "copy-to-clipboard";

// CSS
// import "react-calendar/dist/Calendar.css";
import "video-react/dist/video-react.css";

// Query
import FIND_VIDEO_DETAILS from "../../gql/query/base/findVideoDetails.query";

import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  PAYMENT_METHOD,
  STRIPE_PAYMENT_RESPONSE_STATUSES,
  RANDOM_AVATAR_URLS,
  OTHER_VIDEO_TYPES_TO_FETCH,
} from "../../utils/constant";
import HeadLine from "../../component/bSports/HeadLine";
import { da } from "date-fns/locale";

const VideoPlay = () => {
  // Common data
  const navigate = useNavigate();
  const userId = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);
  const { videoId } = useParams();

  // Query parameters
  const search = useLocation().search;
  const otherVideoTypes = new URLSearchParams(search).get("otherVideoTypes");
  const playerUserId = new URLSearchParams(search).get("playerUserId");

  // Video Player stat
  const playerRef = useRef(null);

  // Query
  const {
    data: findVideoDetails,
    loading: findVideoDetailsLoading,
    error: findVideoDetailsError,
    refetch: findVideoDetailsRefetch,
  } = useQuery(FIND_VIDEO_DETAILS, {
    variables: {
      videoId,
      otherVideoTypes,
      playerUserId: playerUserId ? playerUserId : null,
    },
  });

  useEffect(() => {
    function fetchData() {
      findVideoDetailsRefetch();
    }
    fetchData();
  });

  const copyToClipboard = (url) => {
    copy(url);
    toast.success("Successfully coppied link to share!");
  };

  if (findVideoDetailsLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  if (findVideoDetailsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  const videoDetails = findVideoDetails.findVideoDetails;
  const otherVideos = findVideoDetails.findVideoDetails.otherVideos;

  return (
    <CommonBackdrop>
      <div className="overflow-x-auto w-72 sm:w-10/12 md:w-11/12 mx-auto">
        <div className="w-full bg-black/20 px-10 h-10 flex items-center flex-row mt-5">
          <h1 className="text-yellow-300">Bases / </h1>
          <h1> Video Play </h1>
        </div>

        {/* Container */}
        <div className="font-semibold my-auto mt-5">
          {/* Items */}
          <div className="flex justify-between flex-wrap">
            {/* VIDEO PLAYER */}
            {/* VIDEO PLAYER */}
            <div className="w-full md:w-3/4">
              <div className="md:mr-5">
                {/* video player */}
                <div style={{ borderRadius: "6px", overflow: "hidden" }}>
                  <Player
                    ref={playerRef}
                    autoPlay={true}
                    poster={videoDetails.videoThumbnailUrl}
                    height={"200px"}
                    width={"200px"}
                  >
                    <source src={videoDetails.url} height={"100px"} />
                    <BigPlayButton position="center" />
                  </Player>
                </div>

                <div
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  {/* VIDEO Info + SHARE button */}
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* VIDEO info */}
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ marginRight: "10px" }}>
                        <img
                          src={
                            RANDOM_AVATAR_URLS[videoDetails.userDetails.avatar]
                              ? RANDOM_AVATAR_URLS[
                                  videoDetails.userDetails.avatar
                                ]
                              : RANDOM_AVATAR_URLS["user4_asset4"]
                          }
                          alt="Basement Sports"
                          className={`md:block w-[90px] mr-2`}
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>

                      <div style={{}}>
                        <div
                          className="pb-0"
                          style={{
                            color: "white",
                            fontSize: "20px",
                            fontWeight: "bolder",
                          }}
                        >
                          {videoDetails.caption}
                        </div>
                        {videoDetails?.createdAt && (
                          <div
                            className="pb-0"
                            style={{
                              fontSize: "13px",
                              color: "#aaaaaa",
                              fontWeight: "bold",
                            }}
                          >
                            {formatDistance(
                              new Date(),
                              new Date(videoDetails.createdAt)
                            )}{" "}
                            ago
                          </div>
                        )}
                      </div>
                    </div>

                    {/* SHARE button at the right panel */}
                    {/* <div
                    style={{
                      display: "flex",
                      width: "95px",
                      height: "40px",
                      backgroundColor: "#212121",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "30px",
                    }}
                    className="onHover"
                    onClick={() => {
                      // openShareModal("Share Video", "video", videoDetails);
                    }}
                  ></div> */}
                  </div>

                  {/* SHARE */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <EmailShareButton
                      url={`${window.location.href}`}
                      separator={"\n"}
                      className="mr-2"
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                    <WhatsappShareButton
                      url={`${window.location.href}`}
                      separator=":: "
                      className="mr-2"
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    <TelegramShareButton
                      url={`${window.location.href}`}
                      className="mr-2"
                    >
                      <TelegramIcon size={32} round />
                    </TelegramShareButton>
                    <FacebookShareButton
                      url={`${window.location.href}`}
                      className="mr-2"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={window.location.href}
                      className="mr-2"
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <button
                      onClick={() => copyToClipboard(`${window.location.href}`)}
                    >
                      <FontAwesomeIcon
                        icon={faCopy}
                        size="2x"
                        className="text-sm font-bold py-1 px-1 rounded-full max-h-7"
                        style={{
                          backgroundColor: "#0000008a",
                          color: "#FFFFFF",
                        }}
                        fade={true}
                      />
                    </button>
                  </div>
                </div>

                {/* SPACER */}
                <div
                  style={{
                    height: "0px",
                    borderTop: "1px solid #5252526e",
                    marginTop: "15px",
                  }}
                ></div>
              </div>
            </div>

            {/* OTHER VIDEOS */}
            {/* OTHER VIDEOS */}
            <div className="w-full md:w-1/4">
              {otherVideos.map((video, index) => {
                return (
                  <div className="mb-3">
                    <div
                      className="cursor-pointer"
                      style={{
                        position: "relative",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                      onClick={() =>
                        navigate(
                          `/video/${video._id}?otherVideoTypes=${
                            otherVideoTypes
                              ? otherVideoTypes
                              : OTHER_VIDEO_TYPES_TO_FETCH.fetchSameBaseVideos
                          }&playerUserId=${playerUserId ? playerUserId : null}`
                        )
                      }
                    >
                      {/* <div className="rounded-lg py-1 px-2 lg:px-6 shadow-md shadow-black/30 my-3"> */}
                      <img
                        src={
                          video?.videoThumbnailUrl
                            ? video.videoThumbnailUrl
                            : "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
                        }
                        alt="Basement Sports"
                        className={`md:block w-[90px] object-cover `}
                        style={{ width: "100%", height: "130px" }}
                      />

                      {/* PLAY ICON */}
                      <div
                        style={{
                          color: "yellow",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "80%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon icon={faCirclePlay} size="3x" />
                      </div>

                      {/* video details */}
                      <div
                        style={{
                          backgroundColor: "#00000033",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="px-2 py-2"
                      >
                        {/* left panel */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* avatar */}
                          <img
                            src={
                              RANDOM_AVATAR_URLS[
                                videoDetails.userDetails.avatar
                              ]
                                ? RANDOM_AVATAR_URLS[
                                    videoDetails.userDetails.avatar
                                  ]
                                : RANDOM_AVATAR_URLS["user4_asset4"]
                            }
                            alt="Basement Sports"
                            className={`md:block w-[90px] mr-2`}
                            style={{ width: "35px", height: "35px" }}
                          />
                          {/* video caption & base name */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {/* video caption */}
                            <div
                              className="text-sm"
                              style={{
                                fontWeight: "700",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                minWidth: "50px",
                                maxWidth: "140px",
                              }}
                            >
                              {video.caption}
                            </div>
                            {/* base name */}
                            <div className="text-xs" style={{ opacity: "0.5" }}>
                              @{"base"}
                            </div>
                          </div>
                        </div>

                        {/* right panel */}
                        <div style={{ display: "flex" }}>
                          <div style={{ opacity: "0.5", fontSize: "10px" }}>
                            {formatDistanceToNow(Number(video.createdAt))} ago
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </CommonBackdrop>
  );
};

export default VideoPlay;
