import gql from "graphql-tag";

export default gql`
  mutation saveBaseContactInfo(
    $name: String
    $email: String!
    $contactType: String!
    $baseSlug: String!
    $eventId: String
    $childName: String!
    $kidGender: String!
    $sports: [String]!
    $relation: String!
  ) {
    saveBaseContactInfo(
      name: $name
      email: $email
      contactType: $contactType
      baseSlug: $baseSlug
      eventId: $eventId
      childName: $childName
      kidGender: $kidGender
      sports: $sports
      relation: $relation
    ) {
      token
      errorMessage
      user {
        _id
        email
        name
        avatar
        verified
        city
        country
        unreadNotificationCount
        kidCoinToken
        affiliateCouponCode
        userType
        baseInfo {
          _id
          name
          slug
        }
      }
    }
  }
`;
