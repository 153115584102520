import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import gqlClient from "./gql/gqlClient";
import { render } from "react-dom";

// Tooltip
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

// Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
render(
  <React.StrictMode>
    <ApolloProvider client={gqlClient}>
      <App />
      <Tooltip id="tooltip-content" variant="info" opacity={1} />
      <Tooltip id="tooltip-content-danger" variant="warning" opacity={1} />
      <Tooltip id="tooltip-content-ok" variant="success" opacity={1} />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
