import gql from "graphql-tag";

export default gql`
  mutation updateBaseByAdmin(
    $baseId: String
    $bannerImage: String
    $slotDetails: baseExtraDetails
    $membershipDetails: baseExtraDetails
    $sliderImages: [String]
    $baseAddress: String
  ) {
    updateBaseByAdmin(
      baseId: $baseId
      bannerImage: $bannerImage
      slotDetails: $slotDetails
      membershipDetails: $membershipDetails
      sliderImages: $sliderImages
      baseAddress: $baseAddress
    ) {
      _id
      name
      logo
      bannerImage
      lattitude
      longitude
      city
      country
      difficulty
      sport
      errorMessage
    }
  }
`;
