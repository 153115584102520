import gql from "graphql-tag";

export default gql`
  mutation deleteAccount($userId: String) {
    deleteAccount(userId: $userId) {
      errorMessage
      successMessage
    }
  }
`;
