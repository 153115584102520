import React, { useState, useEffect } from "react";
import AdminPortal from "./AdminPortal";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { format, addYears, formatISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";
import { utcToZonedTime, format as tzFormat } from "date-fns-tz";
import { Image } from "cloudinary-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { CLOUDINARY_INFO, LOCAL_STORAGE_KEYS } from "../../utils/constant";
import DataLoading from "../../component/loader/DataLoading";

// Rich text editor import
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Query
import FIND_A_EVENT from "../../gql/query/base/findEvent.query";

// Mutations
import CREATE_EVENT from "../../gql/mutation/base/createEvent.mutations";

function AddEvent() {
  const navigate = useNavigate();
  const { eventId } = useParams();

  const [dropdownShow, setDropdownShow] = useState(false);
  const [eventType, setEventType] = useState("");
  const [caption, setCaption] = useState("");
  const [address, setAddress] = useState("");
  const [duration, setDuration] = useState(0);
  const [eventPrice, setEventPrice] = useState(0);

  // Coupon State
  const [promoCode, setPromoCode] = useState("");
  const [coupons, setCoupons] = useState([]);

  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedSportsForComponent, setSelectedSportsForComponent] = useState(
    []
  );
  const [selectedGametype, setSelectedGametype] = useState("");
  const [selectedGametypeForComponent, setSelectedGametypeForComponent] =
    useState("");
  const [slot, setSlot] = useState(0);
  const [startDate, setStartDate] = useState(new Date());

  const eventHour = [1, 2, 3, 4, 6, 12];
  // const [selectHour, setselectHour] = useState(1)

  // Rich text editor state
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // Image state
  const [image, setImage] = useState(null);

  // Query
  const {
    data: eventsData,
    loading: eventsLoading,
    error: eventsError,
    refetch: eventsRefetch,
  } = useQuery(FIND_A_EVENT, {
    variables: {
      eventId,
    },
  });

  // Mutations
  const [createEventMutation, { error: createEventError }] =
    useMutation(CREATE_EVENT);

  // DATE variables
  let oneYearFuture = addYears(new Date(), 1);

  // Initialize event data
  useEffect(() => {
    function initializeEventData() {
      // Refetch event data
      eventsRefetch();

      // If data successfully came
      if (eventsData && eventsData.findEvent) {
        let eventDetails = eventsData.findEvent;
        // Set event type
        setEventType(eventDetails.eventType);
        // set caption
        setCaption(eventDetails.caption);
        // set duration
        if (
          eventDetails.duration &&
          eventDetails.duration != 0 &&
          eventDetails.duration != null
        ) {
          setDuration(eventDetails.duration);
        }

        // set event price
        setEventPrice(eventDetails.eventPrice);
        // set sports
        if (eventDetails.sports && eventDetails.sports.length > 0) {
          setSelectedSports(eventDetails.sports);
          let sportsForComponent = sportsOption.filter((sport) =>
            eventDetails.sports.includes(sport.value)
          );
          setSelectedSportsForComponent(sportsForComponent);
        }
        // Set coupon
        if (eventDetails.promoCodes && eventDetails.promoCodes.length > 0) {
          let newPromoCodesArray = eventDetails.promoCodes.map((promoCode) => {
            return {
              code: promoCode.code,
            };
          });
          setCoupons(newPromoCodesArray);
        }
        // set game type
        if (eventDetails.gameType && eventDetails.gameType != null) {
          setSelectedGametype(eventDetails.gameType);
          let sportsForComponent = gameTypesOption.filter((gameType) =>
            eventDetails.gameType.includes(gameType.value)
          );
          setSelectedGametypeForComponent(sportsForComponent);
        }
        // set slot
        if (
          eventDetails.slot &&
          eventDetails.slot != 0 &&
          eventDetails.slot != null
        ) {
          setSlot(eventDetails.slot);
        }
        // set date
        setStartDate(new Date(eventDetails.dateTime));
        // Set banner image
        setImage(eventDetails.bannerImage);
        // set description in Editor state
        const content = JSON.parse(eventDetails.description);
        const contentState = convertFromRaw(content);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
    initializeEventData();
  }, [eventsData]);

  // Handle Event Type Dropdown
  const handleDropdown = (e) => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".dropdown-toggle")) {
        if (dropdownShow) {
          setDropdownShow(false);
        } else {
          setDropdownShow(true);
        }
      } else {
        setDropdownShow(false);
      }
    });
  };

  // Handle Event Type selection & clean other STATEs
  function handleEventTypeSelection(value) {
    setEventType(value);

    // Clean STATEs
    setSelectedSports([]);
    setSelectedSportsForComponent([]);
    setSelectedGametype("");
    setSelectedGametypeForComponent("");
    setSlot(0);
  }

  // Dropdown Options of Event Type
  const eventTypeList = [
    { type: "Tournament" },
    { type: "Special Events" },
    { type: "Contest" },
  ];

  // Handle Sports dropdown
  const handleSelectSportsChange = (selectedOptions) => {
    setSelectedSportsForComponent(selectedOptions);
    const values = selectedOptions.map((option) => option.value);
    setSelectedSports(values);
  };

  // Dropdown Options of Sports
  const sportsOption = [
    { value: "soccer", label: "Soccer" },
    { value: "hockey", label: "Hockey" },
    { value: "baseBall", label: "Baseball" },
    { value: "basketBall", label: "Basketball" },
    { value: "pickelBall", label: "Pickelball" },
    { value: "tennis", label: "Tennis" },
    { value: "racket", label: "Racket" },
    { value: "volleyBall", label: "Volleyball" },
  ];

  // Handle Gametype dropdown
  const handleSelectGameTypeChange = (selectedOptions) => {
    setSelectedGametypeForComponent(selectedOptions);
    setSelectedGametype(selectedOptions.value);
  };

  // Dropdown Options of Game Type
  const gameTypesOption = [
    { value: "1v", label: "1V" },
    { value: "2v", label: "2V" },
    { value: "3v", label: "3V" },
  ];

  // Set custom style in react-select
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black", // Set the color for all options
    }),
  };

  // Coupon related method
  const addCoupon = () => {
    // a function to add a new coupon object to the array
    setCoupons([
      ...coupons,
      {
        code: "",
        // startDate: new Date(),
        // endDate: null,
      },
    ]);
  };

  // a function to remove a coupon object from the array by index
  const removeCoupon = (index) => {
    setCoupons(coupons.filter((_, i) => i !== index));
  };

  // a function to update a coupon object by index, key and value
  const handleChange = (index, key, value) => {
    setCoupons(
      coupons.map((coupon, i) =>
        i === index ? { ...coupon, [key]: value } : coupon
      )
    );
  };

  // Check if coupon is valid or not
  function isValidCoupon(promoCode) {
    return String(promoCode).match(/^[a-zA-Z][a-zA-Z0-9]*[0-9]$/);
  }

  // Submit to create Event
  async function createEvent() {
    // Check EventType was given or not
    if (eventType == "" || eventType == null) {
      toast.error("Please select Event Type!");
      return;
    }
    // Check eventprice
    if (!eventPrice || eventPrice == null || eventPrice == 0) {
      toast.error("Please choose a valid price!");
      return;
    }
    // Check Coupons are valid or not
    if (coupons && coupons.length > 0) {
      // Check if invalid coupon code exist or not
      for (let index = 0; index < coupons.length; index++) {
        const coupon = coupons[index];
        if (!coupon.code || coupon.code == "") {
          toast.error("Coupon code can't be empty!");
          return;
        }
        if (!String(coupon.code).match(/^[a-zA-Z][a-zA-Z0-9]*[0-9]$/)) {
          toast.error("Invalid coupon codes found!");
          return;
        }
      }
      // Check if all given coupon codes are unique
      let codes = coupons.map((obj) => obj.code); // Create an array of codes
      let set = new Set(codes); // Remove all repeated codes from the array
      if (set.size != coupons.length) {
        toast.error("All coupon codes must be unique!");
        return;
      }
    }
    // Check startedate
    if (startDate == "" || startDate == null) {
      toast.error("Please select a date & time!");
      return;
    }
    // Check if EventType is Tournament or League or Contest
    if (
      (eventType === "Tournament" ||
        eventType === "League" ||
        eventType === "Contest") &&
      (!selectedSports || selectedSports == null || selectedSports.length == 0)
    ) {
      toast.error("Please select at least one Sport!");
      return;
    }
    // Check if EventType Tournament or League
    if (
      (eventType === "Tournament" || eventType === "League") &&
      (selectedGametype == "" || selectedGametype == null)
    ) {
      toast.error("Please select Game Type!");
      return;
    }
    // Check if EventType Special Events
    if (eventType === "Special Events" && (slot == 0 || slot == null)) {
      toast.error("Please select a slot!");
      return;
    }
    // Check Image
    if (!image || image == null || image == "") {
      toast.error("Please insert a banner image!");
      return;
    }
    // Now check is Rich Editor state empty or not
    const checkContentState = editorState.getCurrentContent();
    const plainText = checkContentState.getPlainText().trim();
    const isEmpty = plainText.length === 0;
    if (isEmpty) {
      toast.error("Please insert some description!");
      return;
    }

    let dateToSubmit = formatISO(startDate, { representation: "complete" });

    const { data } = await createEventMutation({
      variables: {
        createdBy: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
        baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
        eventType: eventType,
        caption: caption == "" ? null : caption,
        dateTime: dateToSubmit,
        eventPrice: Number(eventPrice),
        duration: duration == 0 ? null : Number(duration),
        sports: selectedSports.length == 0 ? null : selectedSports,
        gameType: selectedGametype == "" ? null : selectedGametype,
        slot: slot == 0 ? null : Number(slot),
        description: JSON.stringify(
          convertToRaw(editorState.getCurrentContent())
        ),
        easyDescription: plainText,
        bannerImage: image,
        promoCodes: coupons,
        address,
      },
    });

    if (data.createEvent.errorMessage == null) {
      toast.success("Successfully added event");
      navigate(`/admin/update-event/${data.createEvent._id}`);
    }
  }

  // Upload cloudinary image
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log("JJJJ: ", file);

    let formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "blueLinerfile");
    formData.append("cloud_name", "dva2poi3c");

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dvqldxdfv/image/upload`,
        {
          method: "post",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        }
      );

      console.log("DONE: ", response);

      if (response.ok) {
        const data = await response.json();
        console.log("DONE 222: ", data);

        setImage(data.secure_url);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  if (eventsLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  return (
    <AdminPortal>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        // className="ml-10 mr-10 mt-6"
        className="md:w-11/12 mx-auto bg-slate-700 flex justify-between items-center px-4 py-3 mt-8 mb-3 "
      >
        <div className="">
          <Link
            to={eventId ? `/admin/update-event/${eventId}` : "/admin/event"}
            className="font-bold text-lg px-4 m-1 py-1 rounded"
          >
            <FontAwesomeIcon className="mr-3" icon={faCircleArrowLeft} />
            Back
          </Link>
        </div>
      </div>

      <div className="max-w-4xl mx-auto bg-slate-700 mt-10 py-3 px-4 mb-14">
        <h3 className="text-center font-bold text-[26px] mt-2 mb-6">
          Create Event
        </h3>

        {/* Event TYPE */}
        {/* Event TYPE */}
        <div className="dropdown cursor-pointer relative my-2 ">
          <div>
            <label className="mr-9 font-bold">
              Event Type <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <button
              className="border-2 border-slate-500 px-2 py-5 rounded dropdown-toggle  inline-flex items-center  h-6"
              type="button"
              onClick={(e) => handleDropdown(e)}
            >
              <h1 className="text-white font-display font-bold">
                {eventType ? eventType : "Event type"}
              </h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="fill-white h-6 w-6 "
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
              </svg>
            </button>
          </div>

          <div
            className={
              dropdownShow
                ? "dropdown-menu bg-black/90 z-10  min-w-[200px] whitespace-nowrap rounded-xl py-4 px-2 text-left shadow-xl show h-40  text-yellow-300 absolute  top-full mx-auto"
                : "dropdown-menu bg-black/90 z-10  min-w-[200px] whitespace-nowrap rounded-xl py-4 px-2 text-left shadow-xl hidden text-yellow-300 absolute top-full"
            }
          >
            {eventTypeList.map((event) => (
              <button
                key={event.type}
                onClick={() => handleEventTypeSelection(event.type)}
                className="block dropdown-text mx-auto"
              >
                {event.type}
              </button>
            ))}
          </div>
        </div>

        {/* Caption */}
        {/* Caption */}
        <div className="my-3">
          <label className="mr-16 pr-3 font-bold">Caption</label>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => setCaption(e.target.value)}
            value={caption}
          />
        </div>

        {/* Duration */}
        {/* Duration */}
        <div className="flex flex-row">
          <label className="mr-11  pr-6 font-bold">Duration</label>
          <div>
            <input
              className="border-2 border-slate-500 px-2 bg-transparent rounded w-64 text-white font-bold font-display"
              onChange={(e) => setDuration(e.target.value)}
              value={`${duration}`}
              type="number"
              placeholder="Enter minutes"
              min={0}
            />{" "}
            in min
            <div className="flex flex-row px-2 w-64">
              {eventHour.map((h, index) => (
                <div
                  onClick={() => setDuration(h * 60)}
                  className="w-10 mt-1 bg-black/90 mx-1 text-center rounded cursor-pointer"
                  key={index}
                >
                  <h1
                    className={`${
                      duration / 60 === h ? "text-yellow-300" : null
                    }`}
                  >
                    {h} h
                  </h1>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Event Price */}
        {/* Event Price */}
        <div className="my-3">
          <label className="mr-14 pr-6 font-bold">
            Price<b style={{ color: "#ff0000a1" }}> * </b>
          </label>
          <input
            className="border-2 border-slate-500 px-2 bg-transparent rounded w-64 text-white font-bold font-display"
            onChange={(e) => setEventPrice(e.target.value)}
            type="number"
            placeholder="Enter price in $"
            min={0}
            value={eventPrice}
          />
        </div>

        {/* Event PROMO CODE */}
        {/* Event PROMO CODE */}
        <div className="my-3">
          <div style={{ display: "flex" }}>
            <label className="mr-4 pr-6 pt-2 font-bold">Promo Code</label>
            <button
              type="button"
              onClick={addCoupon}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Add Coupon
            </button>
            <div
              className="text-xs ml-6"
              style={{ color: "#c1e9ef99", alignSelf: "center" }}
            >
              <i>NOTE:</i> Example coupon{" "}
              <b style={{ color: "yellow" }}>Morning200</b> (where 200
              represents a $200 discount).
            </div>
          </div>

          {coupons && coupons.length > 0 && (
            <div
              style={{
                padding: "10px 10px",
                border: "1px solid #7a787869",
              }}
              className="rounded-lg mt-3 mb-6"
            >
              {coupons.map((coupon, index) => (
                <div>
                  <div
                    key={index}
                    className="coupon my-2"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label>Code </label>
                    <input
                      className="border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display w-full mx-5"
                      onChange={(e) =>
                        handleChange(index, "code", e.target.value)
                      }
                      value={coupon.code}
                      type="text"
                      // style={{ width: "150px" }}
                      placeholder="Ex:  asdf10"
                    />

                    {/* <label>Start At </label>
                    <DatePicker
                      className="rounded text-black"
                      selected={startDate}
                      onChange={(date) =>
                        handleChange(index, "startDate", date)
                      }
                      maxDate={oneYearFuture}
                      minDate={new Date()}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      timeCaption="Time"
                      placeholderText="Select Date and Time"
                      value={coupon.startDate}
                    />

                    <label>End At </label>
                    <DatePicker
                      className="rounded text-black"
                      selected={startDate}
                      onChange={(date) => handleChange(index, "endDate", date)}
                      maxDate={oneYearFuture}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      timeCaption="Time"
                      placeholderText="Select Date and Time"
                      value={coupon.endDate}
                      minDate={coupon.startDate}
                    /> */}

                    <button onClick={() => removeCoupon(index)}>
                      <FontAwesomeIcon className="" icon={faClose} />
                    </button>
                  </div>
                  {coupon.code != "" && (
                    <div className="mb-4">
                      {String(coupon.code).match(
                        /^[a-zA-Z][a-zA-Z0-9]*[0-9]$/
                      ) ? (
                        <div style={{ color: "#06ff06" }}>
                          Valid coupon code!
                        </div>
                      ) : (
                        <div style={{ color: "red" }}>Invalid coupon code!</div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Date Picker for DATE */}
        {/* Date Picker for DATE */}
        <div className="my-2">
          <label className="mr-20 pr-1 font-bold">
            Date <b style={{ color: "#ff0000a1" }}> * </b>
          </label>

          <DatePicker
            className="rounded w-64 text-black"
            style={{ width: "100px" }}
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
            maxDate={oneYearFuture}
            minDate={new Date()}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="Time"
            placeholderText="Select Date and Time"
          />
        </div>

        {/* Sports */}
        {/* Sports */}
        {(eventType === "Tournament" ||
          eventType === "League" ||
          eventType === "Contest") && (
          <div className="my-2 flex items-center">
            {" "}
            <label className="mr-16 pr-2 font-bold">
              Sports <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <Select
              className="w-64"
              value={selectedSportsForComponent}
              onChange={handleSelectSportsChange}
              options={sportsOption}
              isMulti
              styles={customStyles}
            />
          </div>
        )}

        {/* Game Type */}
        {/* Game Type */}
        {(eventType === "Tournament" || eventType === "League") && (
          <div className="my-2 flex items-center ">
            <label className="mr-10 font-bold">
              Game type <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <Select
              className="w-64"
              value={selectedGametypeForComponent}
              onChange={handleSelectGameTypeChange}
              options={gameTypesOption}
              isMulti={false}
              styles={customStyles}
            />
          </div>
        )}

        {/* Slot */}
        {/* Slot */}
        {eventType === "Special Events" && (
          <div>
            <label className="mr-20 pr-2 font-bold">
              Slot <b style={{ color: "#ff0000a1" }}> * </b>
            </label>
            <input
              className="border-2 border-slate-500 px-2 bg-transparent rounded w-64 text-white font-bold font-display"
              onChange={(e) => setSlot(e.target.value)}
              type="number"
              min={0}
              value={slot}
            />
          </div>
        )}

        {/* BANNER IMAGE */}
        <div style={{ display: "flex" }} className="mt-5">
          <label className="mr-2 pr-2 font-bold">
            Banner Image <b style={{ color: "#ff0000a1" }}> * </b>
          </label>
          <div>
            <input type="file" onChange={handleFileChange} />
            {image && (
              <img
                src={image}
                alt="Uploaded"
                style={{ borderRadius: "10px", maxHeight: "300px" }}
                className="mt-5"
              />
            )}
          </div>
        </div>

        {/* Event Location */}
        {/* Event Location */}
        <div className="my-3">
          <label className="mr-16 pr-1 font-bold">Location</label>
          <input
            className="w-64 border-2 border-slate-500 px-2 py-2 bg-transparent rounded text-white font-bold font-display"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
          />
        </div>

        {/* Description */}
        <div className="mt-5">
          <label className="mr-10 pr-2 font-bold">
            Description <b style={{ color: "#ff0000a1" }}> * </b>
          </label>
        </div>

        {/* DEWCRIPTION body */}
        <div className="mt-2 text-black">
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            placeholder="Enter event description."
            // toolbar={{
            //   options: [
            //     "inline",
            //     "blockType",
            //     "fontSize",
            //     "fontFamily",
            //     "colorPicker",
            //   ],
            //   inline: {
            //     options: ["bold", "italic", "underline"],
            //   },
            //   fontFamily: {
            //     options: [
            //       "Arial",
            //       "Georgia",
            //       "Impact",
            //       "Tahoma",
            //       "Times New Roman",
            //       "Verdana",
            //     ],
            //   },
            // }}
          />
        </div>

        {/* <div
          className="descriptionDiv"
          dangerouslySetInnerHTML={{
            __html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          }}
        ></div> */}

        {/* SUBMIT button */}
        <button
          className="mt-7 mb-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto block"
          onClick={() => {
            createEvent();
          }}
        >
          Submit
        </button>
      </div>
    </AdminPortal>
  );
}
export default AddEvent;
