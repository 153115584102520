import React, { useState, useContext } from "react";
import Header from "../Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faDatabase,
  faCalendarDay,
  faUserClock,
  faBucket,
  faSignOutAlt,
  faArrowLeft,
  faArrowRight,
  faUserPen,
  faBasketShopping,
} from "@fortawesome/free-solid-svg-icons";
import {
  ADMIN_USER_IDS,
  LOCAL_STORAGE_KEYS,
  RANDOM_AVATAR_URLS,
} from "../../utils/constant";
import { Link, useNavigate } from "react-router-dom";
import { ShoppingCardContext } from "../../context/cardContext";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";

// QUERY
import FIND_MY_KIDS from "../../gql/query/base/findKidsForParent.query";

export default function CommonBackdrop(props) {
  const navigate = useNavigate();

  const currentUrl = window.location.pathname;

  // This ARRAY is to HIDE SIDE BAR in particular URLs
  let hideBar = ["/base/registration"]; // This "/base/registration" is stale. Now we are using "/superbase/registration".

  const pathContainsEventDetails = currentUrl.includes("/event-details");

  const [currentTab, setCurrentTab] = useState("Dashboard");
  const [showSideBar, setShowSideBar] = useState(true);
  const [headerComponentReloader, setHeaderComponentReloader] = useState(1);

  const { card, setCard } = useContext(ShoppingCardContext);

  // Query
  const {
    data: findMyKids,
    loading: findMyKidsLoading,
    error: findMyKidsError,
    refetch: findMyKidsRefetch,
  } = useQuery(FIND_MY_KIDS, {
    variables: {
      parentId: localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
    },
    skip: !localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID),
  });

  if (findMyKidsLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  function hideSideBar() {
    if (hideBar.includes(currentUrl)) return true;
    else if (pathContainsEventDetails) {
      if (!localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)) {
        return true;
      }
    }
    return false;
  }

  if (findMyKidsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  // const bgImage = "/images/page-title/WWC-NFT-Cover.png";
  const bgImage =
    "https://res.cloudinary.com/dva2poi3c/image/upload/v1694410382/bSportsbackground_oezmdo.png";
  return (
    <div
      className="  h-full pb-[300px] bg-cover bg-center    "
      style={{
        // backgroundSize: "contain",
        backgroundRepeat: "repeat",

        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="md:flex flex-row  ">
        {/* Side bar for pc view */}
        {!hideSideBar() && (
          <aside
            key={headerComponentReloader || showSideBar}
            className={` items-start  h-screen bg-gray-600 bg-opacity-30 md:mr-1  sx:hidden md:block ${
              showSideBar ? "lg:w-1/4 md:w-1/3" : null
            }`}
          >
            <div
              className={`fixed  pl-3  ${
                !showSideBar ? "w-[40px]" : "md:w-1/4 lg:w-1/5"
              } `}
            >
              <div className={` ${showSideBar ? "block" : "hidden"}`}>
                <img
                  src="https://res.cloudinary.com/dva2poi3c/image/upload/v1694423337/bSportsHLogo_hehbig.png"
                  alt="Basement Sports"
                  className={`md:block w-3/5 mt-6 mb-6  object-contain `}
                />
              </div>

              <div className={` ${!showSideBar ? "block" : "hidden"}`}>
                <img
                  src="https://res.cloudinary.com/dva2poi3c/image/upload/v1694406557/bSportsVLogo_pel9sf.png"
                  alt="Basement Sports"
                  className={`md:block w-7 mr-1 mt-6 mb-6  object-contain `}
                />
              </div>

              <div
                onClick={() => setCurrentTab("Dashboard")}
                className={`flex mt-3 cursor-pointer ${
                  currentTab === "Dashboard" ? " text-yellow-300" : null
                }`}
              >
                <FontAwesomeIcon className="mt-1" icon={faBars} />
                <div className={`${!showSideBar ? "hidden" : "block"}`}>
                  <Link
                    to={`/${LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG}/dashboard`}
                    className="ml-1 font-medium"
                  >
                    Dashboard
                  </Link>
                </div>
              </div>

              <div
                onClick={() => setCurrentTab("Bases")}
                className={`flex mt-3 cursor-pointer ${
                  currentTab === "Bases" ? " text-yellow-300" : null
                }`}
              >
                <FontAwesomeIcon icon={faDatabase} className="mt-1 fa" />
                <div className={`${!showSideBar ? "hidden" : "block"}`}>
                  <h1 className="ml-1 font-medium">Bases</h1>
                  <div className="ml-5 font-display text-white text-[13px] flex flex-col gap-2 py-2">
                    {/* <h1>Super Base</h1> */}
                    {/* <h1>Manage Membership</h1> */}
                    <Link
                      to={
                        localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
                          ? "/add-kid"
                          : "/login"
                      }
                    >
                      Add Kids
                    </Link>
                    <Link
                      to={
                        localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
                          ? "/manage-kids"
                          : "/login"
                      }
                    >
                      Manage Membership
                    </Link>
                    <Link to={"/highlights"}>Video Highlights</Link>
                    <Link to={"/streams"}>Live Streams</Link>
                    {/* <Link to={"/highlights"}>Photo Highlights</Link> */}
                  </div>
                </div>
              </div>

              <div
                onClick={() => setCurrentTab("Event")}
                className={`flex mt-3 cursor-pointer ${
                  currentTab === "Event" ? " text-yellow-300" : null
                }`}
              >
                <FontAwesomeIcon icon={faCalendarDay} className="mt-1" />
                <div className={`${!showSideBar ? "hidden" : "block"}`}>
                  <h1 className="ml-1 font-medium">Event</h1>
                  <Link
                    to="/base/eventscalender"
                    className="ml-5 font-display text-white text-[13px] flex flex-col gap-2 py-2"
                  >
                    <h1>Events Calendar</h1>
                  </Link>

                  <Link
                    to="/base/my-events"
                    className="ml-5 font-display text-white text-[13px] flex flex-col gap-2 py-2"
                  >
                    <h1>My events</h1>
                  </Link>
                </div>
              </div>

              {localStorage.getItem("userid") && (
                <>
                  <div
                    onClick={() => setCurrentTab("Account")}
                    className={`flex mt-3 cursor-pointer ${
                      currentTab === "Account" ? " text-yellow-300" : null
                    }`}
                  >
                    <FontAwesomeIcon icon={faUserClock} className="mt-1" />
                    <div className={`${!showSideBar ? "hidden" : "block"}`}>
                      <h1 className="ml-1 font-medium">Account</h1>
                      <div className="ml-5 text-white flex flex-col gap-2 font-display text-[13px] py-2 ">
                        <Link to="/profile">Profile</Link>

                        <Link
                          to={
                            localStorage.getItem("userid")
                              ? "/payment-history"
                              : "/login"
                          }
                        >
                          Payment History
                        </Link>

                        {/* <h1>Kidcoin Integration</h1> */}
                      </div>
                    </div>
                  </div>

                  <Link to={"/cart"}>
                    <div className="relative mt-4 w-7">
                      <div className="absolute text-red-600  right-[-5%] top-[-40%]">
                        {card.totalNft}
                      </div>
                      <FontAwesomeIcon
                        icon={faBasketShopping}
                        className="mt-1"
                      />
                    </div>
                  </Link>
                </>
              )}

              <div
                onClick={() => setCurrentTab("Marketplace")}
                className={`flex mt-3 cursor-pointer ${
                  currentTab === "Marketplace" ? " text-yellow-300" : null
                }`}
              >
                <FontAwesomeIcon icon={faBucket} className="mt-1" />
                <div className={`${!showSideBar ? "hidden" : "block"}`}>
                  <h1 className="ml-1 font-medium">NFTs</h1>
                  <div className="ml-5 text-white flex flex-col gap-2 font-display text-[13px] py-2 ">
                    <Link
                      to={
                        localStorage.getItem("userid")
                          ? "/nft-account"
                          : "/login"
                      }
                    >
                      NFT Account
                    </Link>
                    <Link
                      to={
                        localStorage.getItem("userid") ? "/mint-nfts" : "/login"
                      }
                    >
                      MINT NFTs
                    </Link>
                    <Link
                      to={
                        localStorage.getItem("userid") ? "/my-nfts" : "/login"
                      }
                    >
                      MY NFTs
                    </Link>
                  </div>
                </div>
              </div>

              {localStorage.getItem("userid") && (
                <>
                  {/* {ADMIN_USER_IDS.includes(localStorage.getItem("userid")) && (
                    <Link
                      onClick={() => setCurrentTab("Admin")}
                      className={`flex mt-3 cursor-pointer ${
                        currentTab === "Admin" ? " text-yellow-300" : null
                      }`}
                      to={
                        localStorage.getItem("userid")
                          ? "/admin/event"
                          : "/login"
                      }
                    >
                      <FontAwesomeIcon className="mt-1" icon={faUserPen} />
                      <div className={`${!showSideBar ? "hidden" : "block"}`}>
                        <Link
                          to={
                            localStorage.getItem("userid")
                              ? "/admin/event"
                              : "/login"
                          }
                          className="ml-1 font-medium"
                        >
                          Admin
                        </Link>
                      </div>
                    </Link>
                  )} */}
                </>
              )}

              {localStorage.getItem("userid") ? (
                <>
                  <div
                    onClick={() => {
                      localStorage.removeItem("userid");
                      localStorage.removeItem("bsports_user_name");
                      localStorage.removeItem("user_email");
                      localStorage.removeItem("kidcointoken");
                      localStorage.removeItem("affiliateCouponCode");
                      localStorage.removeItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);
                      localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_TYPE);
                      setHeaderComponentReloader(headerComponentReloader + 1);
                      navigate(`/`);
                    }}
                    className="flex mt-3 cursor-pointer"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="mt-1" />
                    <div className={`${!showSideBar ? "hidden" : "block"}`}>
                      <h1 className="ml-1 font-medium">Log out</h1>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Link to="/login" className="flex mt-3 cursor-pointer">
                    <FontAwesomeIcon icon={faSignOutAlt} className="mt-1" />
                    <div className={`${!showSideBar ? "hidden" : "block"}`}>
                      <h1 className="ml-1 font-medium">Log In</h1>
                    </div>
                  </Link>
                </>
              )}
            </div>
          </aside>
        )}

        {/* Pc view sidebar side bar */}

        {/* Mobile Viwe side bar start */}

        <div
          className={` z-50 rounded w-11/12 bg-black/90 opacity-90 pb-[20px] px-5 flex flex-col  md:hidden  absolute duration-500 sm:duration-700 ${
            showSideBar ? "translate-x-[0%] " : "-translate-x-[200%]"
          }`}
        >
          <div className="w-full flex flex-row    justify-between  ">
            <img
              src="https://res.cloudinary.com/dva2poi3c/image/upload/v1694423337/bSportsHLogo_hehbig.png"
              alt="Basement Sports"
              className={`md:block w-[90px] mt-6 mb-6  object-contain `}
            />
            <div className="flex flex-row items-center my-7 ml-4">
              <FontAwesomeIcon
                onClick={() => setShowSideBar(false)}
                className={`cursor-pointer ${!showSideBar && "hidden"}`}
                icon={faArrowLeft}
              />
              <div className="h-4 w-.5 border-2 border-white mx-1" />
            </div>
          </div>
          <div className={` ${!showSideBar ? "block" : "hidden"}`}>
            <img
              src="https://res.cloudinary.com/dva2poi3c/image/upload/v1694406557/bSportsVLogo_pel9sf.png"
              alt="Basement Sports"
              className={`md:block w-7 mr-1 mt-6 mb-6  object-contain `}
            />
          </div>
          <div
            onClick={() => setCurrentTab("Dashboard")}
            className={`flex mt-3 cursor-pointer ${
              currentTab === "Dashboard" ? " text-yellow-300" : null
            }`}
          >
            <FontAwesomeIcon className="mt-1" icon={faBars} />
            <div className={`${!showSideBar ? "hidden" : "block"}`}>
              <Link to="/" className="ml-1 font-medium">
                Dashboard
              </Link>
            </div>
          </div>

          <div
            onClick={() => setCurrentTab("Bases")}
            className={`flex mt-3 cursor-pointer ${
              currentTab === "Bases" ? " text-yellow-300" : null
            }`}
          >
            <FontAwesomeIcon icon={faDatabase} className="mt-1 fa" />
            <div className={`${!showSideBar ? "hidden" : "block"}`}>
              <h1 className="ml-1 font-medium">Bases</h1>
              <div className="ml-5 font-display text-white text-[13px] flex flex-col gap-2 py-2">
                {/* <h1>Super Base</h1> */}
                {/* <h1>Manage Membership</h1> */}
                <Link
                  to={
                    localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
                      ? "/add-kid"
                      : "/login"
                  }
                >
                  Add Kids
                </Link>
                <Link
                  to={
                    localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID)
                      ? "/manage-kids"
                      : "/login"
                  }
                >
                  Manage Membership
                </Link>
              </div>
            </div>
          </div>

          <div
            onClick={() => setCurrentTab("Event")}
            className={`flex mt-3 cursor-pointer ${
              currentTab === "Event" ? " text-yellow-300" : null
            }`}
          >
            <FontAwesomeIcon icon={faCalendarDay} className="mt-1" />
            <div className={`${!showSideBar ? "hidden" : "block"}`}>
              <h1 className="ml-1 font-medium">Event</h1>
              <div className="ml-5 font-display text-white text-[13px] flex flex-col gap-2 py-2">
                <h1>Events Calendar</h1>
              </div>
            </div>
          </div>

          {localStorage.getItem("userid") && (
            <>
              <div
                onClick={() => setCurrentTab("Account")}
                className={`flex mt-3 cursor-pointer ${
                  currentTab === "Account" ? " text-yellow-300" : null
                }`}
              >
                <FontAwesomeIcon icon={faUserClock} className="mt-1" />
                <div className={`${!showSideBar ? "hidden" : "block"}`}>
                  <h1 className="ml-1 font-medium">Account</h1>
                  <div className="ml-5 text-white flex flex-col gap-2 font-display text-[13px] py-2 ">
                    <Link to="/profile">Profile</Link>

                    <Link
                      to={
                        localStorage.getItem("userid")
                          ? "/payment-history"
                          : "/login"
                      }
                    >
                      Payment History
                    </Link>

                    {/* <h1>Kidcoin Integration</h1> */}
                  </div>
                </div>
              </div>

              <Link to={"/cart"}>
                <div className="relative mt-4 w-7">
                  <div className="absolute text-red-600  right-[-5%] top-[-40%]">
                    {card.totalNft}
                  </div>
                  <FontAwesomeIcon icon={faBasketShopping} className="mt-1" />
                </div>
              </Link>
            </>
          )}

          <div
            onClick={() => setCurrentTab("Marketplace")}
            className={`flex mt-3 cursor-pointer ${
              currentTab === "Marketplace" ? " text-yellow-300" : null
            }`}
          >
            <FontAwesomeIcon icon={faBucket} className="mt-1" />
            <div className={`${!showSideBar ? "hidden" : "block"}`}>
              <h1 className="ml-1 font-medium">NFTs</h1>
              <div className="ml-5 text-white flex flex-col gap-2 font-display text-[13px] py-2 ">
                <Link
                  to={localStorage.getItem("userid") ? "/mint-nfts" : "/login"}
                >
                  MINT NFTs
                </Link>
                <Link
                  to={localStorage.getItem("userid") ? "/my-nfts" : "/login"}
                >
                  MY NFTs
                </Link>
              </div>
            </div>
          </div>

          {localStorage.getItem("userid") && (
            <>
              {ADMIN_USER_IDS.includes(localStorage.getItem("userid")) && (
                <Link
                  onClick={() => setCurrentTab("Admin")}
                  to={
                    localStorage.getItem("userid") ? "/admin/event" : "/login"
                  }
                  className={`flex mt-3 cursor-pointer ${
                    currentTab === "Admin" ? " text-yellow-300" : null
                  }`}
                >
                  <FontAwesomeIcon className="mt-1" icon={faUserPen} />
                  <div className={`${!showSideBar ? "hidden" : "block"}`}>
                    <Link
                      to={
                        localStorage.getItem("userid")
                          ? "/admin/event"
                          : "/login"
                      }
                      className="ml-1 font-medium"
                    >
                      Admin
                    </Link>
                  </div>
                </Link>
              )}
            </>
          )}

          {localStorage.getItem("userid") ? (
            <>
              <div
                onClick={() => {
                  localStorage.removeItem("userid");
                  localStorage.removeItem("bsports_user_name");
                  localStorage.removeItem("user_email");
                  localStorage.removeItem("kidcointoken");
                  localStorage.removeItem("affiliateCouponCode");
                  localStorage.removeItem(LOCAL_STORAGE_KEYS.SUPER_BASE_ID);
                  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_TYPE);
                  setHeaderComponentReloader(headerComponentReloader + 1);
                  navigate(`/`);
                }}
                className="flex mt-3 cursor-pointer"
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="mt-1" />
                <div className={`${!showSideBar ? "hidden" : "block"}`}>
                  <h1 className="ml-1 font-medium">Log out</h1>
                </div>
              </div>
            </>
          ) : (
            <>
              <Link to="/login" className="flex mt-3 cursor-pointer">
                <FontAwesomeIcon icon={faSignOutAlt} className="mt-1" />
                <div className={`${!showSideBar ? "hidden" : "block"}`}>
                  <h1 className="ml-1 font-medium">Log In</h1>
                </div>
              </Link>
            </>
          )}

          {/* <SideMenu hideMenu={hideMenu}></SideMenu> */}
        </div>

        <div className="mx-auto  w-full">
          {/* sidebar controller for pc view */}

          <div
            className={`flex flex-row px-10 mt-6 ml-4  ${
              hideSideBar() ? "hidden" : null
            }`}
            style={{ justifyContent: "space-between" }}
          >
            <div className="flex flex-row items-center">
              <FontAwesomeIcon
                onClick={() => setShowSideBar(!showSideBar)}
                className={`cursor-pointer ${!showSideBar && "hidden"}`}
                icon={faArrowLeft}
              />
              <FontAwesomeIcon
                onClick={() => setShowSideBar(!showSideBar)}
                className={`cursor-pointer ${showSideBar && "hidden"}`}
                icon={faArrowRight}
              />
              <div className="h-4 w-.5 border-2 border-white mx-1" />
              <h1 className="font-medium ml-3">{currentTab}</h1>
            </div>

            {localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID) &&
              findMyKids &&
              findMyKids.findKidsForParent &&
              findMyKids.findKidsForParent.length > 0 && (
                <div style={{ display: "flex" }}>
                  {findMyKids.findKidsForParent.map((kid) => {
                    return (
                      <div
                        className="cursor-pointer mr-3"
                        onClick={() => {
                          navigate(`/kid-details/${kid.player._id}`);
                        }}
                      >
                        <img
                          src={
                            RANDOM_AVATAR_URLS[kid.avatar]
                              ? RANDOM_AVATAR_URLS[kid.avatar]
                              : RANDOM_AVATAR_URLS["user4_asset4"]
                          }
                          alt="Basement Sports"
                          className="max-h-10 object-contain"
                        />
                      </div>
                    );
                  })}
                </div>
              )}
          </div>

          <div className="  w-full">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
