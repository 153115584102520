import { gql } from "@apollo/client";

export default gql`
  query findMyNfts($mintBy: String) {
    findMyNfts(mintBy: $mintBy) {
      _id
      url
      easyImageUrl
      isMinted
      mintBy
      nftName
      mintByWalletAddress
      country
      skin
      hair
      mouth
      eyebrows
      jersey
      dominantLeg
      position
      jerseyNumber
      collectionType
    }
  }
`;
