import gql from "graphql-tag";

export default gql`
  mutation subscribeEventsForMonth(
    $userId: String!
    $baseSlug: String!
    $token: String!
    $paymentResponse: String!
  ) {
    subscribeEventsForMonth(
      userId: $userId
      baseSlug: $baseSlug
      token: $token
      paymentResponse: $paymentResponse
    ) {
      _id
      paymentMethod
      paymentFor
      paymentBy
      paymentByDetails {
        _id
        name
      }
      amountToPay
      paidAmount
      currency
      paymentStatus
      paymentTransactionId
      createdAt
      errorMessage
    }
  }
`;
