// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getFirestore } from 'firebase/firestore'
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// Fire base staging

// const firebaseConfig = {
//   apiKey: "AIzaSyCpHS0Y4BKSHlfNlF0ISJpauX1Zu2nUki4",
//   authDomain: "kidcoin-stage.firebaseapp.com",
//   projectId: "kidcoin-stage",
//   storageBucket: "kidcoin-stage.appspot.com",
//   messagingSenderId: "964010809778",
//   appId: "1:964010809778:web:140efbfd5f84489675acab",
//   measurementId: "G-74MFQ5YYB0",

// };

// Fire base production

const firebaseConfig = {
  apiKey: "AIzaSyAxtuwK2BUvzJd8rlFRV7iSKM2NJ2yV3rc",
  authDomain: "kidcoin.firebaseapp.com",
  projectId: "kidcoin",
  storageBucket: "kidcoin.appspot.com",
  messagingSenderId: "24801294336",
  appId: "1:24801294336:web:c10ad91f3b58144405c0e5",
  measurementId: "G-G7817QP4WW",
};

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(app);
// const db = getFirestore()

export { firebaseAuth };
