import React, { useState, useContext, useEffect } from "react";
import { collection_data } from "../../data/collection_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import { ShoppingCardContext } from "../../context/cardContext";
import { Tab, TabList } from "react-tabs";
import { WOMEN_NFT_COUNTRIES, NFT_COUNTRIES } from "../../utils/nft_countries";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";
import { useQuery, useMutation } from "@apollo/client";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Calendar from "react-calendar";
import Modal from "react-modal";
import differenceInYears from "date-fns/differenceInYears";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
// import "react-calendar/dist/Calendar.css";

// Query
import FIND_ALL_LIVE_STREAMS from "../../gql/query/base/findAllStreamsByFilter.query";

// MUTATION
import PARTICIPATE_EVENT from "../../gql/mutation/base/participateEvent.mutations";

import {
  LOCAL_STORAGE_KEYS,
  EVENT_PAY,
  PAYMENT_FOR,
  PAYMENT_METHOD,
  STRIPE_PAYMENT_RESPONSE_STATUSES,
  RANDOM_AVATAR_URLS,
  OTHER_VIDEO_TYPES_TO_FETCH,
} from "../../utils/constant";
import HeadLine from "../../component/bSports/HeadLine";
import { da } from "date-fns/locale";

const LiveStreams = () => {
  // Common data
  const navigate = useNavigate();
  const { kidPlayerId } = useParams();

  // FILTER states
  const [isSelectedBase, setIsSelectedBase] = useState(true);
  const [selectedKidIds, setSelectedKidIds] = useState([]);

  // Query
  const {
    data: findAllStreams,
    loading: findAllStreamsLoading,
    error: findAllStreamsError,
    refetch: refetchAllVideoHighlights,
  } = useQuery(FIND_ALL_LIVE_STREAMS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  if (findAllStreamsError) {
    return (
      <div className="mt-20">
        <DataLoading loadingType="error" />
      </div>
    );
  }

  return (
    <CommonBackdrop>
      <div className="overflow-x-auto w-72 sm:w-10/12 md:w-11/12 mx-auto">
        <div className="w-full bg-black/20 px-10 h-10 flex items-center flex-row mt-5">
          <h1 className="text-yellow-300">Bases / </h1>
          <h1> Live Streams </h1>
        </div>

        {/* LIVE STREAMS */}
        <div className="font-semibold my-auto mt-5">
          <h1 className="text-xl" style={{ color: "white" }}>
            Live Streams
          </h1>
          {/* Streams */}
          <div
            className="flex flex-wrap"
            style={{
              alignItems: "center",
            }}
          >
            {findAllStreamsLoading && (
              <div style={{ display: "block", margin: "auto" }}>
                <DataLoading loadingType="success" />
              </div>
            )}
            {!findAllStreamsLoading &&
              findAllStreams.findAllStreamsByFilter.map((stream, index) => {
                return (
                  <div
                    className="w-full md:w-1/3"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="mr-3 my-3 cursor-pointer"
                      style={{
                        position: "relative",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                      onClick={() =>
                        navigate(
                          `/${LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG}/stream/${stream._id}?isFullScreen=false`
                        )
                      }
                    >
                      {/* <div className="rounded-lg py-1 px-2 lg:px-6 shadow-md shadow-black/30 my-3"> */}
                      <img
                        src={
                          "https://res.cloudinary.com/dvqldxdfv/image/upload/v1682695432/Screenshot_2023-04-28_at_9.09.42_srr2ch.png"
                        }
                        alt="Basement Sports"
                        className={`md:block w-[90px] object-cover `}
                        style={{ width: "100%", height: "200px" }}
                      />

                      {/* PLAY ICON */}
                      <div
                        style={{
                          color: "yellow",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon icon={faCirclePlay} size="3x" />
                      </div>

                      {/* stream details */}
                      <div
                        style={{
                          backgroundColor: "#00000033",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="px-4 py-4"
                      >
                        {/* left panel */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* avatar */}
                          <img
                            src={
                              RANDOM_AVATAR_URLS[stream?.userDetails?.avatar]
                                ? RANDOM_AVATAR_URLS[
                                    stream?.userDetails?.avatar
                                  ]
                                : RANDOM_AVATAR_URLS["user4_asset4"]
                            }
                            alt="Basement Sports"
                            className={`md:block w-[90px] mr-2`}
                            style={{ width: "40px", height: "40px" }}
                          />
                          {/* stream caption & base name */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {/* stream caption */}
                            <div
                              className="text-sm"
                              style={{
                                fontWeight: "700",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                minWidth: "50px",
                                maxWidth: "140px",
                              }}
                            >
                              {stream.caption}
                            </div>
                            {/* base name */}
                            <div className="text-sm" style={{ opacity: "0.5" }}>
                              @{"base"}
                            </div>
                          </div>
                        </div>

                        {/* right panel */}
                        <div style={{ display: "flex" }}>
                          <div className="text-xs" style={{ opacity: "0.5" }}>
                            {formatDistanceToNow(Number(stream.createdAt))} ago
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {!findAllStreamsLoading &&
              findAllStreams.findAllStreamsByFilter.length == 0 && (
                <div>No streams yet</div>
              )}
          </div>
        </div>
      </div>
    </CommonBackdrop>
  );
};

export default LiveStreams;
