import gql from "graphql-tag";

export default gql`
  mutation acceptInvitationWithLogin(
    $userId: String
    $eventId: String!
    $kidIds: [String]
  ) {
    acceptInvitationWithLogin(
      userId: $userId
      eventId: $eventId
      kidIds: $kidIds
    ) {
      successMessage
      errorMessage
    }
  }
`;
