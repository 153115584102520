import React, { useState } from "react";
import AdminPortal from "./AdminPortal";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { format, addYears } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";
import CommonBackdrop from "../../component/common/CommonBackdrop";
import DataLoading from "../../component/loader/DataLoading";

// Query
import FIND_PAYMENTS from "../../gql/query/payment/findPaymentsOfBase.query";

// Mutations
import CREATE_EVENT from "../../gql/mutation/base/createEvent.mutations";
import { LOCAL_STORAGE_KEYS, PAYMENT_FOR } from "../../utils/constant";

function Payments() {
  const navigate = useNavigate();
  const { eventId } = useParams();

  const [dropdownShow, setDropdownShow] = useState(false);
  const [eventType, setEventType] = useState("");
  const [caption, setCaption] = useState("");
  const [duration, setDuration] = useState(0);
  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedSportsForComponent, setSelectedSportsForComponent] = useState(
    []
  );
  const [selectedGametype, setSelectedGametype] = useState("");
  const [selectedGametypeForComponent, setSelectedGametypeForComponent] =
    useState("");
  const [slot, setSlot] = useState(0);
  const [startDate, setStartDate] = useState(new Date());

  // Query
  const {
    data: basePayments,
    loading: basePaymentsLoading,
    error: basePaymentsError,
    refetch: basePaymentsRefetch,
  } = useQuery(FIND_PAYMENTS, {
    variables: {
      baseSlug: LOCAL_STORAGE_KEYS.SUPER_BASE_SLUG,
    },
  });

  if (basePaymentsLoading)
    return (
      <div className="mt-20">
        <DataLoading loadingType="success" />
      </div>
    );

  return (
    <AdminPortal>
      <div className="md:w-11/12 mx-auto bg-slate-700 flex justify-between items-center px-4 py-3 mt-5 mb-3 ">
        <h1 className="font-display font-bold text-lg">Payment History</h1>{" "}
      </div>
      <div className="overflow-x-auto w-72 sm:w-10/12 md:w-11/12 mx-auto mt-4">
        <table className="bg-slate-700/80 border-yellow-100 border border-b-transparent text-sm mx-auto">
          <thead className="border-b border-yellow-100">
            <tr>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Serial</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span className="">Payment By</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Email/ Phone</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Payment Type</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Paid Amount</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Event Type</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Event Date</span>
              </th>
              <th className="lg:w-40 py-3 px-3 text-center" role="columnheader">
                <span>Payment date</span>
              </th>
            </tr>
          </thead>

          {basePayments?.findPaymentsOfBase?.map((payment, index) => {
            return (
              <tbody>
                <tr
                  className=" border-b border-yellow-100"
                  role="row"
                  key={index}
                >
                  <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                    <span className="mr-2 lg:mr-4">{index + 1}</span>
                  </td>
                  <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                    <span className="mr-2 lg:mr-4">
                      {payment.paymentByDetails?.name}
                    </span>
                  </td>

                  <td className=" lg:w-40 py-3 px-3 text-center" role="cell">
                    <span className={"text-red cursor-pointer"}>
                      {payment.paymentByDetails?.email}
                    </span>
                  </td>
                  <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                    <span className={"text-blue"}>
                      {payment.paymentFor ==
                      PAYMENT_FOR.PURCHASE_FULL_MONTH_EVENT_OF_BASE
                        ? "Monthly Subscription"
                        : payment.paymentFor ==
                          PAYMENT_FOR.PURCHASE_SINGLE_EVENT_OF_BASE
                        ? "Single Event Purchase"
                        : payment.paymentFor ==
                          PAYMENT_FOR.PURCHASE_SLOT_FOR_BASE_EVENT
                        ? "Slot Purchase"
                        : ""}
                    </span>
                  </td>

                  <td className="lg:w-40 py-3 px-3 text-center" role="cell">
                    <span className="mr-2 lg:mr-4">
                      $
                      {!payment.paidAmount || payment.paidAmount == 0
                        ? 0
                        : payment.paidAmount / 100}
                    </span>
                  </td>

                  <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                    <span>
                      {payment.eventDetails?.eventType
                        ? payment.eventDetails?.eventType
                        : "-"}
                    </span>
                  </td>
                  <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                    {console.log("----: ", payment.eventDetails?.dateTime)}
                    <span>
                      {payment &&
                      payment.eventDetails &&
                      payment.eventDetails.dateTime
                        ? format(
                            new Date(payment.eventDetails.dateTime),
                            "do MMM yyyy, h:mm aa"
                          )
                        : "-"}
                    </span>
                  </td>

                  <td className="lg:w-40  py-3 px-3 text-center" role="cell">
                    {payment?.createdAt && (
                      <span>
                        {format(Number(payment?.createdAt), "do MMM yyyy")}
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </AdminPortal>
  );
}
export default Payments;
