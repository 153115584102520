import gql from "graphql-tag";

export default gql`
  mutation invitePeoplesToJoinEvent(
    $userId: String
    $eventId: String!
    $invitedTo: [invitedToEmail]
  ) {
    invitePeoplesToJoinEvent(
      userId: $userId
      eventId: $eventId
      invitedTo: $invitedTo
    ) {
      errorMessage
    }
  }
`;
