import { gql } from "@apollo/client";

export default gql`
  query findAllStreamsByFilter($baseSlug: String) {
    findAllStreamsByFilter(baseSlug: $baseSlug) {
      _id
      userId
      userDetails {
        _id
        name
        avatar
      }
      appId
      channelName
      gameName
      baseDetails {
        _id
        name
        logo
        city
        country
      }
      tournamentStatus
      teamIds
      videoThumbnailUrl
      caption
      createdAt
    }
  }
`;
